<script>
import _ from 'lodash'

import { extractChunksFromSuggestedDiagnosis } from './diagnosis_justifications.js'
import JustificationSummary from './JustificationSummary.vue'

export default {
  components: {
    'justification-summary': JustificationSummary,
  },
  props: {
    appSettings: { type: Object, required: true },
    type: { type: String, required: true },
    isLoading: { type: Boolean, default: false },
    isWildcard: { type: Boolean, default: false },
    selected: { type: Boolean, required: true },
    selection: { type: Object, default: null },
    suggestedDiagnosis: { type: Object, required: true },
    healthData: { type: Object, required: true },
    savedSearchIntersectionChunks: { type: Array, default: null },
    displayedRumIdx: { type: Number, required: true },
    rumPredictedLabels: { type: Array, default: null },
    readonly: { type: Boolean, default: false },
  },
  emits: ['make-selection'],
  data() {
    return {
      showJustificationSummary: false,
    }
  },
  computed: {
    chunks() {
      if (this.isWildcard) {
        return null
      }

      return extractChunksFromSuggestedDiagnosis(this.suggestedDiagnosis, this.savedSearchIntersectionChunks)
    },
  },
  methods: {
    toggleRationals() {
      if (!this.isWildcard) {
        this.showJustificationSummary = !this.showJustificationSummary
      }
    },
    handleHealthDataSelection(mainContent) {
      this.$store.commit('shouldScroll', true)
      this.$store.commit('setMainContent', mainContent)
      if (!this.selected) {
        this.$emit('make-selection', {
          labelType: this.type,
          labelReference: this.suggestedDiagnosis.reference,
          savedSearchIds: _.map(this.suggestedDiagnosis.staySavedSearches, (ss) =>ss.search.id),
        })
      }
      this.showJustificationSummary = !this.showJustificationSummary
    }
  }
}
</script>

<template>
  <div
    :class="{
      selected: showJustificationSummary,
      'diagnosis-title': !isWildcard,
      'diagnosis-group-title': isWildcard,
    }"
    @click="toggleRationals"
  >
    <v-dropdown
      :shown="showJustificationSummary"
      placement="left"
      class="popover-container"
      popover-class="justification-popover"
      @hide="showJustificationSummary=false"
    >
      <slot />
      <template #popper>
        <justification-summary
          :is-loading="isLoading"
          :chunks="suggestedDiagnosis.canShowRational ? chunks : null"
          :health-data="healthData"
          :saved-searches="suggestedDiagnosis.staySavedSearches"
          :no-value-label="suggestedDiagnosis.hasRational ? 'Justification non disponible en raison des paramètres' : 'Pas de justification'"
          :displayed-rum-idx="displayedRumIdx"
          :rum-predicted-labels="rumPredictedLabels"
          :selection="selection"
          :readonly="readonly"
          @select-health-data="handleHealthDataSelection"
        />
      </template>
    </v-dropdown>
  </div>
</template>
