<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { mapState } from 'vuex'

import { AutomationTypeEnum } from '../store/modules/automation/types'
import AutomationCriteriaGroup from './AutomationCriteriaGroup.vue'

export default {
  components: {
    'automation-criteria-group': AutomationCriteriaGroup,
    'sancare-octicon': SancareOcticon,
  },
  props: {
    disabled: { type: Boolean, required: true },
    automationType: { type: String, default: AutomationTypeEnum.AI },
  },
  emits: ['clear-message'],
  data() {
    return {
      AutomationTypeEnum,
    }
  },
  computed: {
    ...mapState({
      globalCriteriaGroup: (state) => state.automation.editedAutomationGroup.globalCriteriaGroup,
      criteriaGroups: (state) => state.automation.editedAutomationGroup.criteriaGroups,
      externalOperator: (state) => state.automation.editedAutomationGroup.searchOperators === 'andOr' ? 'ET' : 'OU',
      internalOperator: (state) => state.automation.editedAutomationGroup.searchOperators === 'andOr' ? 'OU' : 'ET',
    }),
  },
  methods: {
    clearMessage() {
      this.$emit('clear-message')
    },
    addCriteriaGroup() {
      this.clearMessage()
      if (!this.disabled) {
        this.$store.commit('addCriteriaGroup')
      }
    },
    deleteCriteriaGroup(id) {
      this.clearMessage()
      this.$store.commit('removeCriteriaGroup', id)
    }
  }
}
</script>

<template>
  <div>
    <div
      v-if="automationType === AutomationTypeEnum.RULES"
      class="row"
    >
      <div class="col-6 offset-3 row align-items-stretch">
        <automation-criteria-group
          :internal-operator="'ET'"
          :group="globalCriteriaGroup"
          :group-is-global="true"
          :automation-type="automationType"
          class="col-12 my-4"
        />
      </div>
    </div>
    <div class="row justify-content-center">
      <div
        v-for="(group, idx) in criteriaGroups"
        :key="group.id"
        class="col-4 row align-items-stretch"
      >
        <automation-criteria-group
          class="col-10 p-0 my-4"
          :internal-operator="internalOperator"
          :group="group"
          :group-is-global="false"
          :automation-type="automationType"
          @delete-criteria-group="deleteCriteriaGroup"
          @clear-message="clearMessage"
        />
        <div
          v-if="idx % 3 !== 2"
          class="col-2 row no-gutters align-items-center"
        >
          <div class="col-12">
            {{ externalOperator }}
          </div>
        </div>
      </div>
      <div class="col-4 row">
        <div class="col-10 row no-gutters align-items-stretch my-4">
          <div
            class="col-12 automation-criteria-group automation-criteria-group-add card card-primary"
            :class="{ disabled: disabled }"
            @click="addCriteriaGroup"
          >
            <sancare-octicon
              name="diff-added"
              :width="30"
            />
            <div>Ajouter un groupe de critères</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.automation-criteria-group-add {
  background: #F0F0F0;
  border-radius: 0.3rem;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #007BFF;
  cursor: pointer;
  border: solid 1px #007BFF;
  &:hover {
    border-color: #0069D9;
    color: #0069D9;
    svg {
      fill:#0069D9 !important;
    }
  }
  transition: border-color 0.15s, color 0.15s;
  &.disabled {
    cursor: default;
  }
  &.disabled:hover {
    border-color: #E0E0E0;
    color: #A0A0A0;
  }
  svg {
    fill:#007BFF !important;
  }
}
</style>
