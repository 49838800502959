import { AutomationTypeEnum } from '@/store/modules/automation/types'
import { CountryEnum, ModeEnum, StayListColumns } from '@/store/modules/settings/types'

/**
 * Column names must be synced with backend/src/Resources/schemas/user_settings.json
 */
const AvailableColumns: StayListColumns = {
  notification: {
    icon: 'bell-fill',
    name: 'Notification',
    label: null,
    country: [CountryEnum.FR],
    order: 'notification',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    isDefault: true,
    invertedOrder: false,
  },
  ssrId: {
    label: 'N°',
    country: [CountryEnum.FR],
    order: 'ssrId',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    isDefault: false,
    invertedOrder: false,
  },
  administrativeLocalStayId: {
    label: 'N° administratif',
    country: [CountryEnum.FR],
    order: 'administrativeLocalStayId',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    isDefault: true,
    invertedOrder: false,
  },
  patientName: {
    name: 'Patient',
    label: 'Nom - Prénom',
    country: [CountryEnum.FR],
    order: 'patientName',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    isDefault: true,
    invertedOrder: false,
  },
  firstValue: {
    isTranslated: false,
    label: 'Tarif GME pré-revalo.',
    country: [CountryEnum.FR],
    order: 'startingValue',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    isDefault: false,
    invertedOrder: true,
  },
  currentValue: {
    isTranslated: false,
    label: 'Tarif',
    country: [CountryEnum.FR],
    order: 'currentValue',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    isDefault: false,
    invertedOrder: true,
  },
  initialGme: {
    label: 'GME pré-revalo.',
    country: [CountryEnum.FR],
    order: 'initialGme',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    isDefault: false,
    invertedOrder: false,
  },
  currentGme: {
    label: 'GME',
    country: [CountryEnum.FR],
    order: 'currentGme',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    isDefault: true,
    invertedOrder: false,
  },
  moneyGap: {
    label: 'Montant revalorisé',
    country: [CountryEnum.FR],
    order: 'moneyGap',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    isDefault: false,
    invertedOrder: true,
  },
  rhsCount: {
    label: 'Nb de RHS',
    country: [CountryEnum.FR],
    order: 'rumCount',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    isDefault: true,
    invertedOrder: true,
  },
  stayStart: {
    label: 'Date d\'entrée',
    country: [CountryEnum.FR],
    order: 'stayStart',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    isDefault: false,
    invertedOrder: false,
  },
  stayEnd: {
    label: 'Date de sortie',
    country: [CountryEnum.FR],
    order: 'stayEnd',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    isDefault: false,
    invertedOrder: false,
  },
  validationDate: {
    label: 'Date de validation',
    country: [CountryEnum.FR],
    order: 'validationDate',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    isDefault: false,
    invertedOrder: false,
  },
  stayDuration: {
    label: 'Durée',
    country: [CountryEnum.FR],
    order: 'stayDuration',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    isDefault: true,
    invertedOrder: true,
  },
  countSavedSearches: {
    label: 'Nb de recherches',
    country: [CountryEnum.FR],
    order: null,
    mode: [ModeEnum.OPTIMIZABLE],
    isDefault: false,
    invertedOrder: true,
  },
  savedSearches: {
    label: 'Recherche(s)',
    country: [CountryEnum.FR],
    order: null,
    mode: [ModeEnum.OPTIMIZABLE],
    isDefault: false,
    invertedOrder: false,
  },
  status: {
    label: 'Statut',
    country: [CountryEnum.FR],
    order: 'status',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    isDefault: true,
    invertedOrder: false,
  },
  externalPmsiStatus: {
    label: 'Statut externe',
    country: [CountryEnum.FR],
    order: 'externalPmsiStatus',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    isDefault: false,
    invertedOrder: false,
  },
  seen: {
    label: 'Vu',
    country: [CountryEnum.FR],
    order: 'seen',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    isDefault: true,
    invertedOrder: false,
  },
  comment: {
    label: 'Commentaire',
    country: [CountryEnum.FR],
    order: 'comment',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    isDefault: false,
    invertedOrder: false,
  }
}

export default AvailableColumns