<script>
import { SancarePagination } from '@sancare/ui-frontend-commons'
import { mapState } from 'vuex'

import AutomationGroupStayListTable from '@/automation/stay-list/AutomationGroupStayListTable.vue'
import { DEFAULTS } from '@/stay-list/storeDefaultState'

import StayListActions from '../stay-list/StayListActions.vue'
import { AutomationModeEnum } from '../store/modules/automation/types'
import { ModeEnum, PmsiTypeEnum } from '../store/modules/settings/types'
import { StayStatusEnum } from '../store/modules/stay/types'
import MissingAutomationCriteria from './AutomationGroupCriteriaExplanation.vue'
import StayListCriteria from './StayListCriteria.vue'

export default {
  components: {
    'stay-list-criteria': StayListCriteria,
    'automation-group-stay-list-table': AutomationGroupStayListTable,
    'stay-list-actions': StayListActions,
    'sancare-pagination': SancarePagination,
    'missing-automation-criteria': MissingAutomationCriteria
  },
  data() {
    return {
      modeLabel: {
        automatable: 'Séjours automatisables',
        waiting: 'Séjours en attente d\'information',
        expired: 'Séjours à faire manuellement',
      },
      stay: null,
      AutomationModeEnum,
      ModeEnum,
      PmsiTypeEnum
    }
  },
  computed: {
    ...mapState({
      earlyLimit: (state) => state.settings.earlyLimit,
      lateLimit: (state) => state.settings.lateLimit,
      pageCount: (state) => state.automation.automationStayList.pageCount,
      totalCount: (state) => state.automation.automationStayList.totalCount,
      stayList: (state) => state.automation.automationStayList.items,
      currentPage: (state) => state.automation.automationStayListParams.currentPage,
      pageSize: (state) => state.automation.automationStayListParams.pageSize,
      order: (state) => state.automation.automationStayListParams.order,
      showSeen: (state) => state.automation.automationStayListParams.showSeen,
      showStatus: (state) => state.automation.automationStayListParams.showStatus,
      reverseOrder: (state) => state.automation.automationStayListParams.reverseOrder,
      automationGroup: (state) => state.automation.editedAutomationGroup,
      automationMode: (state) => state.automation.automationStayListParams.automationMode,
      automationGroupId: (state) => state.automation.automationStayListParams.automationGroupId,
      automationGroupRequest: (state) => state.automation.getAutomationGroupRequest,
      fetchStayListRequest: (state) => state.automation.automationStayListRequest,
    }),
  },
  watch: {
    automationGroupRequest({ fetching, ok }) {
      if(fetching || !ok) {
        return
      }
      let showStatus = DEFAULTS().STAYLIST_STATUS
      if (this.automationMode === AutomationModeEnum.AUTOMATABLE && this.automationGroup.automated) {
        showStatus = [StayStatusEnum.VALIDATED]
      }
      this.$store.commit(
        'setAutomStayListParam',
        {
          type: 'showStatus', value: showStatus
        }
      )
      this.refreshStayList()
    }
  },
  mounted() {
    this.$store.dispatch('getAutomationGroup', this.automationGroupId)
  },
  methods: {
    refreshStayList() {
      if (this.automationMode && this.automationGroupId) {
        this.$store.dispatch('getAutomationStayList')
        this.$store.dispatch('refreshAutomStayListUrl')
      }
    },
    setParameter(type, value) {
      this.$store.commit('setAutomStayListParam', { type, value })
      this.refreshStayList()
    },
    setPage(value) {
      this.setParameter('currentPage', value)
    },
    displayStayMissingCriteria(stay) {
      this.stay = stay
    },
    buildExportRequest() {
      const params = {
        currentPage: 0,
        pageSize: 9999,
        order: this.order,
        reverseOrder: this.reverseOrder,
        showSeen: this.showSeen,
        showStatus: this.showStatus,
        earlyLimit: this.earlyLimit.format(),
        lateLimit: this.lateLimit.format(),
      }

      return {
        mode: this.automationMode,
        automationGroupId: this.automationGroupId,
        params
      }
    },
  }
}
</script>

<template>
  <div class="container">
    <div class="row align-items-end">
      <h1 class="col-auto text-center">
        <span name="automationStayList_count">
          {{ totalCount }} {{ totalCount > 9999 ? '+' : '' }}
        </span>
        <div class="undertitle">
          séjours
        </div>
      </h1>
      <h1 class="col text-right">
        <div class="undertitle">
          {{ $t('automation.mode.'+automationMode) }}
        </div>
      </h1>
    </div>
    <div>
      <stay-list-criteria
        :automation-mode="automationMode"
        @set-stay-list-param="setParameter"
        @refresh-staylist="refreshStayList"
      />
      <stay-list-actions
        :pmsi-type="PmsiTypeEnum.MCO"
        :stay-list="stayList"
        :request="buildExportRequest()"
        :mode="ModeEnum.AUTOMATION"
        :params="{showSeen, pageSize}"
        @refresh-staylist="refreshStayList"
        @set-stay-list-parameter="setParameter"
      />
      <div class="row">
        <automation-group-stay-list-table
          :class="{
            'col-8 list-with-panel': automationMode === AutomationModeEnum.WAITING || automationMode === AutomationModeEnum.EXPIRED,
            'col-12': automationMode !== AutomationModeEnum.WAITING && automationMode !== AutomationModeEnum.EXPIRED,
          }"
          @display-stay-missing-criteria="displayStayMissingCriteria"
        />
        <div
          v-if="(automationMode === AutomationModeEnum.WAITING || automationMode === AutomationModeEnum.EXPIRED) && stay && automationGroup"
          class="col-4"
        >
          <missing-automation-criteria
            :automation-group="automationGroup"
            :stay="stay"
          />
        </div>
      </div>
      <sancare-pagination
        :current-page="currentPage"
        :page-count="pageCount"
        @set-page="setPage"
      />
    </div>
  </div>
</template>
