import { AutomationAction, AutomationActionEnum, AutomationState } from '@/store/modules/automation/types'

export const mutations = {
  addAutomationGroupAction(state: AutomationState, action: AutomationAction) {
    // avoid duplicates
    if(state.editedAutomationGroup.actions.find((a) => a.action === action.action && a.value === action.value)) {
      return
    }
    // avoid multiple DP or DR
    if (action.action !== AutomationActionEnum.ADD_DAS) {
      state.editedAutomationGroup.actions = state.editedAutomationGroup.actions.filter((a) => a.action !== action.action)
    }
    state.editedAutomationGroup.actions.push(action)
  },
  removeAutomationGroupAction(state: AutomationState, action: AutomationAction) {
    state.editedAutomationGroup.actions = state.editedAutomationGroup.actions.filter((a) => a.action !== action.action || a.value !== action.value)
  }
}
