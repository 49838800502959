<script>
import CriteriaDisplayer from '../filters/CriteriaDisplayer.vue'

export default {
  components: {
    'criteria-displayer': CriteriaDisplayer,
  },
  props: {
    savedSearch: { type: Object, required: true },
    canRemoveCriteria: { type: Boolean, default: true },
    maxCriteria: { type: Number, default: 0 }
  },
  computed: {
    internalOperator() {
      return this.savedSearch.searchOperators === 'andOr' ? 'OU' : 'ET'
    },
    externalOperator() {
      return this.savedSearch.searchOperators === 'andOr' ? 'ET' : 'OU'
    },
  },
}
</script>

<template>
  <div name="SavedSearchDetails">
    <div
      v-if="savedSearch.globalCriteriaGroup !== null"
    >
      <criteria-displayer
        :can-remove-criteria="canRemoveCriteria"
        :criteria-group="savedSearch.globalCriteriaGroup"
        :display-grouped-criteria="true"
        :max-criteria="maxCriteria"
        :mode="savedSearch.type"
        internal-operator=""
      />
      <hr>
    </div>
    <div
      v-for="(criteriaGroup, idx) in savedSearch.criteriaGroups"
      :key="idx"
    >
      <div v-if="idx > 0">
        <div class="text-center">
          {{ externalOperator }}
        </div>
      </div>
      <criteria-displayer
        class="card"
        :can-remove-criteria="canRemoveCriteria"
        :criteria-group="criteriaGroup"
        :max-criteria="maxCriteria"
        :internal-operator="internalOperator"
        :mode="savedSearch.type"
      />
    </div>
  </div>
</template>
