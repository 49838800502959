import Api from '@/misc/api'

export default {
  state: {
    globalStats: [],
    financialStats: [],
    automatableStats: [],
    newCodingStaysStats: [],
    optimizableStaysStats: [],

    globalStatsRequest:       { fetching: false, error: null, ok: false },
    financialStatsRequest:    { fetching: false, error: null, ok: false },
    automatablesStatsRequest: { fetching: false, error: null, ok: false },
    newCodingStaysStatsRequest:  { fetching: false, error: null, ok: false },
    optimizableStaysStatsRequest:   { fetching: false, error: null, ok: false },
  },
  mutations: {
    getGlobalStatsStart(state) {
      state.globalStatsRequest = { fetching: true, error: null, ok: false }
    },
    getGlobalStatsSuccess(state, globalStats) {
      state.globalStats = globalStats
      state.globalStatsRequest.fetching = false
      state.globalStatsRequest.ok = true
    },
    getGlobalStatsError(state, res) {
      state.globalStatsRequest.fetching = false
      state.globalStatsRequest.error = res.message
    },

    getAutomatableStatsStart(state) {
      state.automatableStatsRequest = { fetching: true, error: null, ok: false }
    },
    getAutomatableStatsSuccess(state, automatableStats) {
      state.automatableStats = automatableStats
      state.automatableStatsRequest.fetching = false
      state.automatableStatsRequest.ok = true
    },
    getAutomatableStatsError(state, res) {
      state.automatableStatsRequest.fetching = false
      state.automatableStatsRequest.error = res.message
    },

    getNewCodingStaysStatsStart(state) {
      state.newCodingStaysStatsRequest = { fetching: true, error: null, ok: false }
    },
    getNewCodingStaysStatsSuccess(state, newCodingStaysStats) {
      state.newCodingStaysStats = newCodingStaysStats
      state.newCodingStaysStatsRequest.fetching = false
      state.newCodingStaysStatsRequest.ok = true
    },
    getNewCodingStaysStatsError(state, res) {
      state.newCodingStaysStatsRequest.fetching = false
      state.newCodingStaysStatsRequest.error = res.message
    },

    getOptimizableStaysStatsStart(state) {
      state.optimizableStaysStatsRequest = { fetching: true, error: null, ok: false }
    },
    getOptimizableStaysStatsSuccess(state, optimizableStaysStats) {
      state.optimizableStaysStats = optimizableStaysStats
      state.optimizableStaysStatsRequest.fetching = false
      state.optimizableStaysStatsRequest.ok = true
    },
    getOptimizableStaysStatsError(state, res) {
      state.optimizableStaysStatsRequest.fetching = false
      state.optimizableStaysStatsRequest.error = res.message
    },

    getFinancialStatsStart(state) {
      state.financialStatsRequest = { fetching: true, error: null, ok: false }
    },
    getFinancialStatsSuccess(state, financialStats) {
      state.financialStats = financialStats
      state.financialStatsRequest.fetching = false
      state.financialStatsRequest.ok = true
    },
    getFinancialStatsError(state, res) {
      state.financialStatsRequest.fetching = false
      state.financialStatsRequest.error = res.message
    },
  },
  actions: {
    // FIXME all the requests in here should be GET, not POST
    getGlobalStats({ commit }, urlArgs) {
      return Api.post(
        {
          url: '/api/stats/global',
          body: urlArgs,
          startMut: 'getGlobalStatsStart',
          successMut: 'getGlobalStatsSuccess',
          errorMut: 'getGlobalStatsError',
        },
        commit
      )
    },
    getFinancialStats({ commit }, urlArgs) {
      return Api.post(
        {
          url: '/api/stats/financial',
          body: urlArgs,
          startMut: 'getFinancialStatsStart',
          successMut: 'getFinancialStatsSuccess',
          errorMut: 'getFinancialStatsError',
        },
        commit
      )
    },
    getAutomatableStaysStats({ commit }, urlArgs) {
      return Api.post(
        {
          url: '/api/stats/automatable-stays',
          body: urlArgs,
          startMut: 'getAutomatableStatsStart',
          successMut: 'getAutomatableStatsSuccess',
          errorMut: 'getAutomatableStatsError',
        },
        commit
      )
    },
    getNewCodingStaysStats({ commit }, urlArgs){
      return Api.post(
        {
          url: '/api/stats/new-coding',
          body: urlArgs,
          startMut: 'getNewCodingStaysStatsStart',
          successMut: 'getNewCodingStaysStatsSuccess',
          errorMut: 'getNewCodingStaysStatsError',
        },
        commit
      )
    },
    getOptimizableStaysStats({ commit }, urlArgs) {
      return Api.post(
        {
          url: '/api/stats/optimizable',
          body: urlArgs,
          startMut: 'getOptimizableStaysStatsStart',
          successMut: 'getOptimizableStaysStatsSuccess',
          errorMut: 'getOptimizableStaysStatsError',
        },
        commit
      )
    },
  }
}
