<script>
import { getDrugEventPrestationTypeLabel, getDrugEventPrestationTypes } from '@sancare/ui-frontend-commons/src/misc/DrugEventHelper'
import {
  DrugEventPrestationType,
  DrugEventPrestationTypeLabel
} from '@sancare/ui-frontend-commons/src/types/health-data'
import { HealthDataType, TextualHealthEntryType } from '@sancare/ui-frontend-commons/src/types/health-data'
import _ from 'lodash'

import { documentCategories, getDocumentCategoryLabel, getHealthDataLabel, healthDataLabels } from './health-data/documentLabels.js'

export default {
  props: {
    healthData: { type: Object, required: true },
    selectedChunks: { type: Object, default: null },
    mainContent: { type: Object, default: null },
  },
  emits: ['setMainContent'],
  data() {
    return {
      DrugEventPrestationType,
      DrugEventPrestationTypeLabel,
      HealthDataType,
      healthDataTypes: Object.keys(healthDataLabels)
    }
  },
  computed: {
    highlightedCategories() {
      if (!this.selectedChunks) {
        return {}
      }
      // We extract the highlighted items from the chunks either present in predictedDiagnosisGroup, staySavedSearches or savedSearchIntersectionChunks
      let highlightedCategories = {
        reports: _.filter(_.keys(this.selectedChunks.reports), (id) => this.selectedChunks.reports[id]),
        labResults: _.some(this.selectedChunks.labResults) || _.some(this.selectedChunks.categoricalLabResults),
      }
      _.forEach(['medicalHistory', 'nurseReport', 'targetNurseReport', 'labResultComment', 'surgeryLabel'], (sourceType) => {
        highlightedCategories[sourceType] = _.some(this.healthData[sourceType], (item) => this.selectedChunks.textualHealthEntries[item.id])
      })
      _.forEach(['healthConstants', 'drugEvents'], (sourceType) => {
        highlightedCategories[sourceType] = _.some(this.selectedChunks[sourceType])
      })

      highlightedCategories = { ...highlightedCategories, ...{ drugEvents: this.getHighlightedDrugEventCategories() } }

      return highlightedCategories
    },
    presentCategories() {
      const data = _.merge(HealthDataType, TextualHealthEntryType)

      _.forEach(data, (dataType) => {
        if (this.healthData[dataType]) {
          data[dataType] = this.healthData[dataType].length
        } else if (HealthDataType.CONSTANT === dataType) {
          data[dataType] = this.healthData.healthConstants.length
        } else if (HealthDataType.NAMED_ENTITY === dataType) {
          data[dataType] = this.healthData.namedEntities.length
        } else if (HealthDataType.LAB_RESULT === dataType) {
          data[dataType] = this.healthData.labResults.length || this.healthData.categoricalLabResults.length
        }
      })

      const presentCategories = []
      _.forEach(this.healthDataTypes, (healthDataType) => {
        if (data[healthDataType]) {
          presentCategories.push(healthDataType)
        }
      })

      return presentCategories
    },
    drugEventPrestationTypes() {
      return getDrugEventPrestationTypes(this.healthData.drugEvents)
    }
  },
  methods: {
    getHealthDataLabel(type) {
      return getHealthDataLabel(type)
    },
    getDocumentCategoryLabel(type) {
      return getDocumentCategoryLabel(type)
    },
    getDrugEventPrestationTypeLabel,
    getHighlightedDrugEventCategories() {
      if (!this.selectedChunks.drugEvents) {
        return null
      }
      const highlightedCategories = {}
      const highlightedDrugEventsIds = Object
        .keys(this.selectedChunks.drugEvents).filter((id) => this.selectedChunks.drugEvents[id] === true)
        .map((id) => Number(id))
      const highlightedDrugEvents = this.healthData.drugEvents.filter((drugEvent) => highlightedDrugEventsIds.includes(drugEvent.id))

      Object.values(DrugEventPrestationType).forEach((type) => {
        highlightedCategories[type] = highlightedDrugEvents.some((drugEvent) => {
          const typeFinal = type === DrugEventPrestationType.NONE ? null : type
          return drugEvent.prestationType === typeFinal
        })
      })

      return highlightedCategories
    },
    handleHealthDataSelection(mainContent) {
      this.$emit('setMainContent', mainContent)
    },
    displayedReportDescription(report) {
      return report.documentTitle ? report.documentTitle : '[ Sans Titre ]'
    },
    sortedReports() {
      const documentTemplates = Object.keys(documentCategories)
      const sortedReports = {}
      this.healthData.reports.forEach((report) => {
        if (documentTemplates.indexOf(report.documentTemplate) !== -1) {
          if (!sortedReports[report.documentTemplate]) {
            sortedReports[report.documentTemplate] = []
          }
          sortedReports[report.documentTemplate].push(report)
          return
        }
        if (!sortedReports['Documents']) {
          sortedReports['Documents'] = []
        }
        sortedReports['Documents'].push(report)
      })
      return sortedReports
    },
  },
}
</script>

<template>
  <div class="report-selector">
    <div
      v-for="reports, category in sortedReports()"
      :key="category"
      class="row"
    >
      <div class="col">
        <div class="row">
          <span class="col">{{ getDocumentCategoryLabel(category) }} :</span>
        </div>
        <button
          v-for="report in reports"
          :key="report.id"
          :class="{
            active: mainContent.type === HealthDataType.REPORT && report.id === mainContent.targetId,
            highlighted: highlightedCategories.reports && highlightedCategories.reports.indexOf(String(report.id)) !== -1,
          }"
          class="btn btn-sm btn-report-select"
          @click="handleHealthDataSelection({ type: 'report', targetId: report.id })"
        >
          {{ displayedReportDescription(report) }}
        </button>
      </div>
    </div>
    <div
      v-if="presentCategories.length"
      class="row"
    >
      <span class="col">Documents regroupés :</span>
    </div>
    <button
      v-for="type in drugEventPrestationTypes"
      :key="type"
      :class="{
        active: mainContent.type === HealthDataType.DRUG_EVENT && mainContent.drugEventPrestationType === type,
        highlighted: highlightedCategories && highlightedCategories.drugEvents && highlightedCategories['drugEvents'][type]
      }"
      class="btn btn-sm btn-report-select"
      @click="handleHealthDataSelection({ type: 'drugEvent', targetId: null, drugEventPrestationType: type })"
    >
      {{ getDrugEventPrestationTypeLabel(type) }}
    </button>
    <button
      v-for="healthDataType in presentCategories"
      :key="healthDataType"
      :class="{
        active: mainContent.type === healthDataType,
        highlighted: highlightedCategories[healthDataType],
      }"
      class="btn btn-sm btn-report-select"
      @click="handleHealthDataSelection({ type: healthDataType, targetId: null })"
    >
      {{ getHealthDataLabel(healthDataType) }}
    </button>
  </div>
</template>
