import _ from 'lodash'

import { PredictedLabelType } from '@/store/modules/diagnosis/types'

const requirementDR = ['Z491', 'Z492', 'Z4920', 'Z4921', 'Z5100', 'Z5101', 'Z511', 'Z512', 'Z513', 'Z5130', 'Z5131', 'Z5180']

const getPredictedRelatedDiags = (diagnosisGroup, predictedLabels) => {
  if (diagnosisGroup) {
    const refDP = diagnosisGroup.currentDP ? diagnosisGroup.currentDP.reference : null
    if (refDP && refDP[0] === 'Z') {
      const DRs = predictedLabels.filter((diag) => diag.type === PredictedLabelType.DR && diag.diagnosis.reference !== refDP)

      return (requirementDR.indexOf(refDP) !== -1) ? DRs.slice(0, 1) : DRs
    }
  }

  return []
}

const getPredictedAssociatedDiags = (diagnosisGroup, predictedLabels) => {
  if (diagnosisGroup) {
    const refDP = diagnosisGroup.currentDP ? diagnosisGroup.currentDP.reference : null
    const refDR = diagnosisGroup.currentDR ? diagnosisGroup.currentDR.reference : null

    const filteredDiags = predictedLabels.filter((diag) => diag.type === PredictedLabelType.DAS && diag.diagnosis.reference !== refDP && diag.diagnosis.reference !== refDR)

    return _.orderBy(filteredDiags, ['excluded', 'diagnosis.level', 'confidence'], ['desc', 'desc', 'desc'])
  }

  return []
}

export {
  getPredictedRelatedDiags,
  getPredictedAssociatedDiags,
}
