const useCoreGroupingCostProps = () => {
  return {
    cost: {
      type: Number,
      default: null
    },
    size: {
      validator(value: string) {
        return ['sm', 'md'].includes(value)
      },
      default: 'md'
    }
  }
}

export default useCoreGroupingCostProps