<script>
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex'

import { AutomationTypeEnum } from '../store/modules/automation/types'
import AutomationGroupThumbnail from './AutomationGroupThumbnail.vue'

export default {
  components: {
    'automation-group-thumbnail': AutomationGroupThumbnail,
  },
  props: {
    isAutomationAllowed: { type: Boolean, required: true },
    isAutomationEnabled: { type: Boolean, required: true },
    earlyLimit: { type: Object, required: true },
    lateLimit: { type: Object, required: true }
  },
  data() {
    return {
      AutomationTypeEnum
    }
  },
  computed: {
    ... mapState({
      getAutomationGroupsStatsRequest: (state) => state.automation.getAutomationGroupsStatsRequest,
      getAllAutomationGroupsRequest: (state) => state.automation.getAllAutomationGroupsRequest,
      getAllAutomationGroupsGhmCollisionsRequest: (state) => state.automation.getAllAutomationGroupsGhmCollisionsRequest,
      automationGroupStats: (state) => state.automation.automationGroupStats,
      automationGroups: (state) => state.automation.automationGroups,
      automationGroupsGhmCollisions: (state) => state.automation.automationGroupsGhmCollisions,
    }),
    ... mapGetters(['flatSancareSettings']),
    isSancareUser() {
      return this.$store.state.settings.currentUser.sancareUser
    },
    canAddRulesGroup() {
      return this.flatSancareSettings['rules_automation']
    }
  },
  watch: {
    automationGroups() {
      this.refreshStats()
    },
    earlyLimit() {
      this.refreshStats()
    },
    lateLimit() {
      this.refreshStats()
    }
  },
  mounted() {
    if(!this.isAutomationAllowed || !this.isAutomationEnabled) {
      return
    }
    this.$store.commit('setStayListParam', { type: 'currentPage', value: 1 })
  },
  methods: {
    removeAutomationGroup(id) {
      this.$store.dispatch('removeAutomationGroup', id)
    },
    toggleAutomation(group) {
      if (group.automated) {
        this.$store.dispatch('disableAutomationGroup', group.id)
      } else {
        this.$store.dispatch('enableAutomationGroup', group.id)
      }
    },
    getGroupStats(id) {
      return _.find(this.automationGroupStats, { id: id })
    },
    getGhmCollidingGroupNames(id) {
      const group = _.find(this.automationGroupsGhmCollisions, { id: id })
      return typeof(group) === 'undefined' ? [] : group.collidingGroups
    },
    refreshStats() {
      this.$store.dispatch('getAutomationGroupsStats', {
        earlyLimit: this.earlyLimit,
        lateLimit: this.lateLimit,
      })
    },
    getDemoMode() {
      return this.flatSancareSettings.demo_mode
    },
    sancareSettings(key) {
      const setting = this.flatSancareSettings[key]
      const demo = this.flatSancareSettings['demo_mode'] === true ? ' (avec codage initial)' : ''
      switch (setting) {
        case true:
          return `Activée${demo}`
        case false:
          return 'Désactivée'
        default:
          return setting
      }
    }
  },
}
</script>

<template>
  <div v-if="getAutomationGroupsStatsRequest.ok">
    <div class="home-list-part border-top-0">
      <div class="row no-gutters justify-content-between col p-3">
        <h2 class="home-list-part-title">
          Groupes d'automatisation IA
        </h2>
        <router-link
          class="btn btn-link"
          :to="'/automation/edit-automation-group?mode=automation&automationType=ai'"
        >
          +&nbsp;Créer un groupe d'automatisation IA
        </router-link>
      </div>

      <div
        class="col my-3"
      >
        <div class="alert alert-secondary">
          Mode d'automatisation des séjours :
          <ul class="m-0">
            <li>Séances : <strong>{{ sancareSettings('session_automation') }}</strong></li>
            <li>Hors séances : <strong>{{ sancareSettings('non_session_automation') }}</strong></li>
          </ul>
        </div>
      </div>

      <div class="row no-gutters">
        <div
          v-for="automationGroup in automationGroups[AutomationTypeEnum.AI]"
          :key="automationGroup.id"
          class="col-4 px-3"
        >
          <automation-group-thumbnail
            :early-limit="earlyLimit"
            :late-limit="lateLimit"
            :stats-group="getGroupStats(automationGroup.id)"
            :full-group="automationGroup"
            :ghm-colliding-group-names="getGhmCollidingGroupNames(automationGroup.id)"
            :demo-mode="getDemoMode()"
            @remove-automation-group="removeAutomationGroup"
          />
        </div>
      </div>
    </div>

    <div
      v-if="canAddRulesGroup"
      class="home-list-part"
    >
      <div class="row no-gutters justify-content-between col p-3">
        <h2 class="home-list-part-title">
          Groupes d'automatisation règles
        </h2>
        <router-link
          class="btn btn-link"
          :to="'/automation/edit-automation-group?mode=automation&automationType=rules'"
        >
          +&nbsp;Créer un groupe d'automatisation règles
        </router-link>
      </div>

      <div class="row no-gutters">
        <div
          v-for="automationGroup in automationGroups[AutomationTypeEnum.RULES]"
          :key="automationGroup.id"
          class="col-4 px-3"
        >
          <automation-group-thumbnail
            :early-limit="earlyLimit"
            :late-limit="lateLimit"
            :stats-group="getGroupStats(automationGroup.id)"
            :full-group="automationGroup"
            :ghm-colliding-group-names="getGhmCollidingGroupNames(automationGroup.id)"
            :demo-mode="getDemoMode()"
            @remove-automation-group="removeAutomationGroup"
          />
        </div>
      </div>
    </div>
  </div>
</template>
