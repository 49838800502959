import { ApiRequest } from '@sancare/ui-frontend-commons/src/misc/api-request'

import { MedicalActState } from './types'

const baseUrl = '/api/{pmsiType}/summary-unit/{summaryUnitId}/medical-act'

const state = new MedicalActState()
state.requests = {
  addMedicalAct: new ApiRequest('addMedicalAct', 'POST', baseUrl),
  removeMedicalAct: new ApiRequest('removeMedicalAct', 'DELETE', `${baseUrl}/{id}`),
}

export { state }
