import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'

import { StayAction, StayActionDtoIn } from '@/store/modules/stay/mco/types'

export function convertMcoStayActionIn(rawStayAction: StayActionDtoIn): StayAction {
  return {
    ...rawStayAction,
    creationDate: dayjs(rawStayAction.creationDate),
  }
}
