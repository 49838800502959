<script>
export default {
  props: {
    hasMatch: { type: Boolean, default: true },
    svgHeight: { type: Number, required: true },
    svgWidth: { type: Number, required: true },
    svgLinks: { type: Array, required: true }
  }
}
</script>


<template>
  <div class="col-2">
    <svg
      v-if="hasMatch"
      :height="svgHeight"
      :width="svgWidth"
    >
      <g
        v-for="link in svgLinks"
        :key="link.path"
        class="diagnosis-match-arrow"
      >
        <path
          :d="link.path"
          stroke-width="2"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <circle
          :cy="link.startHeight"
          cx="8"
          r="5"
          stroke="none"
        />
        <circle
          :cx="svgWidth - 8"
          :cy="link.endHeight"
          r="5"
          stroke="none"
        />
      </g>
    </svg>
  </div>
</template>
