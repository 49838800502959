import _ from 'lodash'

function getAppSettings(settingsState) {
  const flatAppSettings = {}
  if (settingsState.appSettings.sliderSettings) {
    _.forEach(settingsState.appSettings.sliderSettings, (setting, name) => {
      flatAppSettings[name] = setting.values[setting.index !== null ? setting.index : setting.default]
    })
  }
  if (settingsState.appSettings.boolSettings) {
    _.forEach(settingsState.appSettings.boolSettings, (setting, name) => {
      flatAppSettings[name] = setting.value !== null ? setting.value : setting.default
    })
  }
  return flatAppSettings
}

function getSancareSettings(settingsState) {
  const flatSancareSettings = {}
  if (settingsState.sancareSettings.arraySettings) {
    _.forEach(settingsState.sancareSettings.arraySettings, (setting, name) => {
      flatSancareSettings[name] = setting.values
    })
  }
  if (settingsState.sancareSettings.boolSettings) {
    _.forEach(settingsState.sancareSettings.boolSettings, (setting, name) => {
      flatSancareSettings[name] = setting.value !== null ? setting.value : setting.default
    })
  }

  return flatSancareSettings
}

export {
  getAppSettings,
  getSancareSettings
}
