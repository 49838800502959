<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { nextTick } from 'vue'

export default {
  components: {
    'sancare-octicon': SancareOcticon,
  },
  props: {
    stay: { type: Object, required: true }
  },
  data() {
    return {
      commentEditing: '',
      isEditing: false,
      formOpen: false,
      noComment: 'Aucun commentaire'
    }
  },
  computed: {
    comment() {
      return (this.stay.comment && this.stay.comment.length > 0) ? this.stay.comment : this.noComment
    },
    stayComment() {
      return this.stay.comment ? this.stay.comment : ''
    }
  },
  methods: {
    commentStay: async function() {
      this.isEditing = true
      await this.$store.dispatch(
        'commentStay',
        {
          stayId: this.stay.id,
          comment: this.commentEditing
        }
      )
      this.isEditing = false
      this.formOpen = false
    },
    async edit() {
      if (this.formOpen) {
        return
      }
      this.commentEditing = this.stayComment
      this.formOpen = true
      await nextTick()
      this.$refs.commentTextarea.focus()

    },
  }
}
</script>

<template>
  <div>
    <div
      class="btn-divided btn-divided-mco row no-gutters align-items-center"
    >
      <div
        class="col-auto py-2 px-3 text-center cursor_default btn-divided__action"
        @click="edit"
      >
        <sancare-octicon name="comment" />
      </div>
      <div class="col py-2 px-3">
        Commentaire
      </div>
    </div>
    <div
      v-show="!formOpen"
      class="mt-2 no-gutters"
    >
      <div class="border col">
        <div
          class="comment-displayer"
          @click="edit"
        >
          {{ comment }}
        </div>
      </div>
    </div>
    <div
      v-show="formOpen"
      class="mt-3 no-gutters align-items-center"
    >
      <textarea
        ref="commentTextarea"
        v-model="commentEditing"
        type="text"
        placeholder="Votre commentaire..."
        maxlength="255"
        class="col comment-area p-1"
      />
      <div
        class="mt-1 row no-gutters"
      >
        <button
          class="btn btn-danger col mr-1"
          @click="formOpen = false"
        >
          Annuler
        </button>
        <button
          :disabled="isEditing"
          class="btn btn-primary col ml-1"
          @click="commentStay()"
        >
          Enregistrer le commentaire
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="less">
.cursor_default {
  cursor: default;
}
.comment-area {
  min-height: 7rem;
}
.comment-displayer {
  min-height: 1rem;
  white-space: pre-wrap;
  padding: 0.3rem 0.3rem;
}
</style>
