/**
 * Helper method to update state list order related params
 */
export function getNewStayListOrderParams(
  toBeOrderedColumn: string,
  { order: currentOrderingColumn, reverseOrder: currentReverseOrder }: { order: string, reverseOrder: boolean }
) {
  return {
    currentPage: 1,
    order: toBeOrderedColumn,
    reverseOrder: toBeOrderedColumn === currentOrderingColumn ? !currentReverseOrder : false
  }
}

