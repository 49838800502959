import { computed } from 'vue'

import {
  formatCost,
  formatDate,
  formatOptionalDate,
  formatPatientName,
  formatSearches,
  formatSeenStatus
} from '@/stay-list/stay-list-table/helpers/line/lineContentFormatters'
import {
  filterMatchingStaySavedSearches,
  getStaySeenStatus
} from '@/stay-list/stay-list-table/helpers/line/lineHelper'
import { ListStay } from '@/stay-list/stay-list-table/types'
import { useStore } from '@/store'

export default function useStayListLine(stay: ListStay) {
  const store = useStore()
  const user = computed(() => store.state.settings.currentUser)

  const patientName = computed(() => formatPatientName(stay.patient))
  const staySeen = computed(() => getStaySeenStatus(stay.statuses, user.value.username))
  const stayStart = computed(() => formatDate(stay.stayStart))
  const stayEnd = computed(() => formatDate(stay.stayEnd))
  const validationDate = computed(() => formatOptionalDate(stay.validationDate))
  const moneyGap = computed(() => formatCost(stay.moneyGap))
  const displayedSeenStatus = computed(() => formatSeenStatus(staySeen.value.statusValue))
  const searches = computed(() => {
    const matchingStaySavedSearches = filterMatchingStaySavedSearches(stay.staySavedSearches, user.value)

    return formatSearches(matchingStaySavedSearches)
  })

  return {
    patientName,
    staySeen,
    stayStart,
    stayEnd,
    validationDate,
    moneyGap,
    displayedSeenStatus,
    searches
  }
}