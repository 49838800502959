<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { mapState } from 'vuex'

import { CountryEnum } from '../../../store/modules/settings/types'
import { extractChunksFromSuggestedDiagnosis, extractIndicesFromChunkObject } from '../diagnosis_justifications.js'
import InitialDiagnosis from '../InitialDiagnosis.vue'
import ManualDiagnosisButton from '../ManualDiagnosisButton'
import SuggestedDiagnosisCards from '../SuggestedDiagnosisCards.vue'
import SuggestedDiagnosisList from '../SuggestedDiagnosisList.vue'
import SvgLink from '../SvgLink.vue'
import { computeSvgLink } from '../svgLinkCalculator.js'

const CODING_ORIGIN_ORDER = {
  external: 0,
  prediction_default: 1,
  prediction_unsure: 1,
  unsure: 1,
  prediction_manual: 2,
  manual: 2,
}

export default {
  components: {
    'suggested-diagnosis-list': SuggestedDiagnosisList,
    'suggested-diagnosis-cards': SuggestedDiagnosisCards,
    'manual-diagnosis-button': ManualDiagnosisButton,
    'svg-link': SvgLink,
    'initial-diagnosis': InitialDiagnosis,
    'sancare-octicon': SancareOcticon,
  },
  props: {
    appSettings: { type: Object, required: true },
    sancareSettings: { type: Object, required: true },
    mode: { type: String, required: true },
    suggestedChronicDas: { type: Object, required: true },
    suggestedDiagnoses: { type: Array, required: true },
    rumId: { type: Number, required: true },
    standardModeDiagnosisGroup: { type: Object, required: true },
    ghm: { type: Object, default: null },
    healthData: { type: Object, required: true },
    selection: { type: Object, default: null },
    isLoading: { type: Boolean, default: false },
    savedSearchIntersectionChunks: { type: Array, default: null },
    staySavedSearches: { type: Array, default: null },
    standardModeExcludedDiagnoses: { type: Array, required: true },
    displayedRumIdx: { type: Number, required: true },
    rumPredictedLabels: { type: Array, default: null },
    type: { type: String, default: null },
  },
  emits: ['make-selection'],
  data() {
    return {
      width: 0,
      height: 0,
      showMore: false,
      svgLinks: [],
      extendedSuggestions: [],
      CountryEnum,
    }
  },
  computed: {
    ...mapState({
      suggestedDasDisplayMode: (state) => state.stayDisplayer.suggestedDasDisplayMode,
      pmsiCountry: (state) => state.settings.pmsi.country,
    }),
    displayedDiagnoses() {
      // hide disabled DAS from CurrentCoding
      const displayableAssociatedDiagnoses = this.standardModeDiagnosisGroup.associatedDiags.filter((das) => !das.disabled)
      return _.orderBy(
        displayableAssociatedDiagnoses,
        [(d) => CODING_ORIGIN_ORDER[d.codingOrigin], 'excluded', 'diagnosis.level', 'diagnosis.reference'],
        ['asc', 'asc', 'desc', 'desc']
      )
    },
    displayedExternalDiagnoses() {
      // return all CurrentCoding diagnoses that come from outside the UI
      return _.filter(this.displayedDiagnoses, (diag) => {
        return this.isExternalDiagnosis(diag) && !diag.disabled
      })
    },
    displayedInternalDiagnoses() {
      // return all CurrentCoding diagnoses that have been coded on the UI
      return _.filter(this.displayedDiagnoses, (diag) => {
        return !this.isExternalDiagnosis(diag)
      })
    },
    hasMatch() {
      const suggestedDiagsReference = _.map(this.suggestedDiagnoses, (suggestion) => {
        return suggestion.reference
      })

      return _.some(this.displayedExternalDiagnoses, (das) => _.findIndex(suggestedDiagsReference, (d) => das.associatedDiagnosis.reference.match(d)) !== -1)
    },
  },
  mounted() {
    this.recomputeSvg = () => {
      this.$nextTick(() => {
        const initialDiagnosesCard = this.$refs.initialDiagnosesCard
        const suggestedDiagnosesCard = this.$refs.suggestedDiagnosesCard
        const initialDiagsTitle = this.$refs.initialDiagsTitle

        if (!suggestedDiagnosesCard) {
          return
        }

        const suggestedDiagsTitle = suggestedDiagnosesCard.$refs.suggestedDiagnosisTitle
        if (!initialDiagnosesCard || !initialDiagsTitle || !suggestedDiagsTitle) {
          return
        }

        const elementRect = this.$el.getBoundingClientRect()
        const svgLinks = []

        this.width = this.$el.offsetWidth - suggestedDiagnosesCard.$el.offsetWidth - initialDiagnosesCard.offsetWidth
        this.height = Math.max(suggestedDiagnosesCard.$el.offsetHeight, initialDiagnosesCard.offsetHeight)

        _.forEach(this.displayedExternalDiagnoses, (displayedDiag) => {
          const suggestedTitle = _.find(suggestedDiagsTitle, (ref) => ref.dataset.reference === displayedDiag.associatedDiagnosis.reference)
          const initialTitle = _.find(initialDiagsTitle, (ref) => ref.dataset.reference === displayedDiag.associatedDiagnosis.reference)

          if (initialTitle && suggestedTitle) {
            const endRect = suggestedTitle.getBoundingClientRect()
            const startRect = initialTitle.getBoundingClientRect()
            const startHeight = startRect.top - elementRect.top + (startRect.bottom - startRect.top) / 2
            const endHeight = endRect.top - elementRect.top + (endRect.bottom - endRect.top) / 2
            const invertDirection = endRect.top < startRect.top

            svgLinks.push(computeSvgLink(startHeight, endHeight, this.width, invertDirection))
          }
        })

        // This part is because if we re-assign this.svgLinks every time, we enter an endless update loop.
        // The workaround is to make a deep check
        if (!_.isEqual(svgLinks, this.svgLinks)) {
          this.svgLinks = svgLinks
        }
      })
    }

    this.recomputeSvg()
    window.addEventListener('resize', this.recomputeSvg)
  },
  updated() {
    this.recomputeSvg()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.recomputeSvg)
  },
  methods: {
    getSuggestionFromInitialDiagnosis(currentDiagnosis) {
      const suggestion =  _.find(this.suggestedDiagnoses, (suggestion) => {
        if(!suggestion.isWildcard){
          return suggestion.reference === currentDiagnosis.associatedDiagnosis.reference
        }
        return _.some(suggestion.diagnoses, (diag) => diag.reference === currentDiagnosis.associatedDiagnosis.reference)
      })
      return suggestion || null
    },
    isExternalDiagnosis(diagnosis) {
      return ['external', 'unsure', 'prediction_unsure'].indexOf(diagnosis.codingOrigin) !== -1
    },
    addDiagnosis(suggestedDiagnosis) {
      this.$store.dispatch('addDiagnosis', {
        rumId: this.rumId,
        diagnosisType: 'DAS',
        reference: suggestedDiagnosis.reference,
        justifications: extractIndicesFromChunkObject(extractChunksFromSuggestedDiagnosis(suggestedDiagnosis, this.savedSearchIntersectionChunks)),
        mode: this.mode
      })
    },
    removeDAS(diagId) {
      this.$store.dispatch('removeDAS', { diagId: diagId, mode: this.mode })
    },
    switchSuggestedDasDisplayMode() {
      this.$store.commit('setSuggestedDasDisplayMode', this.suggestedDasDisplayMode === 'list' ? 'cards' : 'list')
    },
    getSearchesIds(diagnosis) {
      return _.map(diagnosis.staySavedSearches, (ss) => ss.search.id)
    },
    stringifySuggestionSearches(suggestion, sep, cut = false) {
      const content = _.map([...suggestion.staySavedSearches], (savedSearch) => savedSearch.search.name).join(sep)
      if (cut) {
        return content.substring(0, 20)
      }
      return content
    },
    toggleSuggestionDiagnoses(suggestion) {
      if (suggestion.isWildcard) {
        if (this.isExtendedSuggestion(suggestion)) {
          this.extendedSuggestions = _.filter(this.extendedSuggestions, (ref) => ref !== suggestion.reference)
        } else {
          this.extendedSuggestions.push(suggestion.reference)
        }
      }
    },
    isExtendedSuggestion(suggestion) {
      return this.extendedSuggestions.indexOf(suggestion.reference) !== -1
    },
    makeSelection(selection) {
      this.$emit('make-selection', selection)
    },
    readOnly() {
      return this.rumId === 0
    },
    showMoreDiags() {
      this.showMore = !this.showMore
      this.recomputeSvg()
    }
  }
}
</script>

<template>
  <div class="row no-gutters align-items-start">
    <div class="col-5">
      <div
        ref="initialDiagnosesCard"
        class="card diagnosis-card highlighted row"
      >
        <div class="card-body">
          <h1 class="card-title">
            {{ $t('diagnosis.'+type) }} <small>- Codage en cours</small>
          </h1>
        </div>
        <div
          v-if="!displayedDiagnoses.length"
          class="card-body"
        >
          <h2>Pas de {{ $t('diagnosis.'+type) }}</h2>
        </div>
        <div
          v-for="currentDiagnosis in displayedExternalDiagnoses"
          :key="currentDiagnosis.id"
          class="card-body diagnosis-initial"
        >
          <div
            :class="{ excluded: currentDiagnosis.excluded }"
            class="row no-gutters align-items-center diagnosis-item"
          >
            <div
              ref="initialDiagsTitle"
              :data-reference="currentDiagnosis.reference"
              :title="currentDiagnosis.associatedDiagnosis.description"
              class="col"
            >
              <h2 class="diagnosis-reference">
                {{ currentDiagnosis.reference }}
              </h2>
              <div
                v-if="'level' in currentDiagnosis.associatedDiagnosis"
                class="diagnosis-level"
              >
                <span v-if="!currentDiagnosis.excluded">Niv.{{ currentDiagnosis.associatedDiagnosis.level }}</span>
                <span v-else>Exclu</span>
              </div>
            </div>
            <div
              v-if="!readOnly()"
              title="Supprimer le diagnostic"
              class="col-auto predicted-diagnosis-remover"
              @click.stop="removeDAS(currentDiagnosis.id)"
            >
              <sancare-octicon
                name="x"
                :width="18"
                :height="18"
              />
            </div>
          </div>
        </div>
        <div
          v-for="currentDiagnosis in displayedInternalDiagnoses"
          :key="currentDiagnosis.id"
          class="card-body diagnosis-initial"
        >
          <initial-diagnosis
            :app-settings="appSettings"
            :type="type"
            :is-loading="isLoading"
            :selected="selection !== null && selection.labelType === type && selection.labelReference === currentDiagnosis.associatedDiagnosis.reference"
            :selection="selection"
            :initial-diagnosis="currentDiagnosis"
            :suggestion="getSuggestionFromInitialDiagnosis(currentDiagnosis)"
            :health-data="healthData"
            :saved-search-intersection-chunks="savedSearchIntersectionChunks"
            :displayed-rum-idx="displayedRumIdx"
            :rum-predicted-labels="rumPredictedLabels"
            :readonly="readOnly()"
            @make-selection="makeSelection"
            @remove-das="removeDAS"
          />
        </div>
      </div>
      <div class="row no-gutters pb-2">
        <manual-diagnosis-button
          :type="'DAS'"
          :read-only="readOnly()"
          :can-add-uncommon-diagnoses="pmsiCountry === CountryEnum.FR"
          @add-diagnosis="reference => addDiagnosis({ reference })"
        />
      </div>
    </div>
    <svg-link
      :has-match="hasMatch"
      :svg-height="height"
      :svg-width="width"
      :svg-links="svgLinks"
    />
    <component
      :is="'suggested-diagnosis-' + suggestedDasDisplayMode"
      ref="suggestedDiagnosesCard"
      class="card diagnosis-card col-5"
      :app-settings="appSettings"
      :type="type"
      :is-loading="isLoading"
      :mode="mode"
      :selection="selection"
      :suggested-chronic-das="suggestedChronicDas"
      :suggested-diagnoses="suggestedDiagnoses"
      :rum-id="rumId"
      :standard-mode-diagnosis-group="standardModeDiagnosisGroup"
      :health-data="healthData"
      :saved-search-intersection-chunks="savedSearchIntersectionChunks"
      :displayed-rum-idx="displayedRumIdx"
      :rum-predicted-labels="rumPredictedLabels"
      :readonly="readOnly()"
      @switch-display-mode="switchSuggestedDasDisplayMode()"
      @make-selection="makeSelection"
      @show-more-diags="showMoreDiags"
    />
  </div>
</template>
