import _ from 'lodash'

import Api from '@/misc/api'

export default {
  state: {
    logs: {
      totalMatching: 1,
      pageCount: 1,
      currentPage: 1,
      currentSource: '',
      filters: {},
      logEvents: [],
      logEventShown: {},
    },
    prefs: { stayEditions: { seMoreEventsGrouping: false } },
    stayEditions: { raw:[], formated: [] },
    stayStatusList: {},
    requests: {
      getLogEventsRequest: { fetching: false, error: null, ok: false },
      getStayEditionRequest: { fetching: false, error: null, ok: false },
      getStayStatusListRequest: { fetching: false, error: null, ok: false },
    },
  },
  mutations: {
    setLogCurrentPage(state, currentPage) {
      state.logs.currentPage = currentPage
    },
    setLogCurrentSource(state, currentSource) {
      state.logs.currentSource = currentSource
    },
    setLogFilters(state, filters) {
      state.logs.filters = _.cloneDeep(filters)
    },
    moveLogEventShown(state, move) {
      const currentIndex = state.logs.logEvents.map((event) => event.id).indexOf(state.logs.logEventShown.id)
      let newIndex = currentIndex + move
      if (newIndex > (state.logs.logEvents.length - 1)) {
        newIndex = 0
      }
      if (newIndex < 0) {
        newIndex = (state.logs.logEvents.length - 1)
      }

      state.logs.logEventShown = state.logs.logEvents[newIndex]
    },
    setLogEventShown(state, LogEvent) {
      state.logs.logEventShown = LogEvent
    },
    getLogEventsStart(state) {
      state.requests.getLogEventsRequest = { fetching: true, error: null, ok: false }
      state.logs.logEventShown = {}
      state.logs.logEvents = []
    },
    getLogEventsSuccess(state, logEvents) {
      state.requests.getLogEventsRequest = { fetching: false, error: null, ok: true }
      state.logs.logEvents = logEvents['content']
      state.logs.pageCount = logEvents['_hateoas']['pageCount'] ?? 1
      state.logs.page = logEvents['_hateoas']['page']
      state.logs.totalMatching = logEvents['_hateoas']['totalMatching'] ?? 0
    },
    getLogEventsError(state, res) {
      state.requests.getLogEventsRequest = { fetching: false, error: res.message, ok: false }
    },
    getStayEditionStart(state) {
      state.requests.getStayEditionRequest = { fetching: true, error: null, ok: false }
      state.stayEditions = { raw:[], formated: [] }
    },
    getStayEditionSuccess(state, stayEditions) {
      state.requests.getStayEditionRequest = { fetching: false, error: null, ok: true }
      state.stayEditions = stayEditions
    },
    getStayEditionError(state, res) {
      state.requests.getStayEditionRequest = { fetching: false, error: res.message, ok: false }
    },
    logsReset(state) {
      state.requests.getStayEditionRequest = { fetching: false, error: null, ok: false }
      state.stayEditions = { raw:[], formated: [] }
      state.requests.getStayStatusListRequest = { fetching: false, error: null, ok: false }
    },
    getStayStatusListStart(state) {
      state.requests.getStayStatusListRequest = { fetching: true, error: null, ok: false }
    },
    getStayStatusListSuccess(state, stayStatusList) {
      state.requests.getStayStatusListRequest = { fetching: false, error: null, ok: true }
      state.stayStatusList = stayStatusList
    },
    getStayStatusListError(state, res) {
      state.requests.getStayStatusListRequest = { fetching: false, error: res.message, ok: false }
    },
    updatePrefs(state, { prefArea, prefKey, prefValue }) {
      state.prefs[prefArea][prefKey] = prefValue
    },
  },
  actions: {
    getLogEvents({ commit }, { source, filters, page, pageSize }) {
      return Api.post(
        {
          url: `/api/maintenance/logs/${source}`,
          body: {
            filters: filters,
            page: page ?? 1,
            pageSize: pageSize ?? 50,
          },
          startMut: 'getLogEventsStart',
          successMut: 'getLogEventsSuccess',
          errorMut: 'getLogEventsError',
        },
        commit
      )
    },
    getStayEditions({ commit, state }, { rssId }) {
      return Api.post(
        {
          url: `/api/maintenance/stay-editions/${rssId}`,
          body: {
            seMoreEventsGrouping: state.prefs.stayEditions.seMoreEventsGrouping ? 1 : 0,
          },
          startMut: 'getStayEditionStart',
          successMut: 'getStayEditionSuccess',
          errorMut: 'getStayEditionError',
        },
        commit
      )
    },
    getStayStatusList({ commit }, { rssId }) {
      return Api.post(
        {
          url: `/api/maintenance/stay-status/${rssId}`,
          startMut: 'getStayStatusListStart',
          successMut: 'getStayStatusListSuccess',
          errorMut: 'getStayStatusListError',
        },
        commit
      )
    }
  }
}
