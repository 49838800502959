<script>
import { SancareOcticon, SancareToggle } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex'

import router from '@/router'

import { actionLabels, AutomationActionEnum, AutomationTypeEnum } from '../store/modules/automation/types'
import { ModeEnum } from '../store/modules/settings/types'
import AutomationActionEditor from './AutomationActionEditor.vue'
import AutomationCriteriaGroups from './AutomationCriteriaGroups.vue'

export default {
  components: {
    'sancare-octicon': SancareOcticon,
    'automation-criteria-groups': AutomationCriteriaGroups,
    'sancare-toggle': SancareToggle,
    'automation-action-editor': AutomationActionEditor
  },
  data() {
    return {
      requestError: null,
      requestSuccess: null,
      additionMode: true,
      ghmCmd: '*',
      ghmType: '*',
      ghmSubCmd: '*',
      ghmLevel: '*',
      cmdList: _.range(1, 29).map((n) => n < 10 ? `0${n}` : `${n}`),
      typeList: ['M', 'C', 'K', 'Z'],
      subCmdList: _.range(1, 63).map((n) => n < 10 ? `0${n}` : `${n}`),
      levelList: ['1', '2', '3', '4', 'A', 'B', 'C', 'D', 'J', 'T', 'Z'],
      AutomationTypeEnum,
      actionLabels,
    }
  },
  computed: {
    ...mapGetters(['enableWebpimsAccess']),
    ...mapState({
      automationGroup: (state) => state.automation.editedAutomationGroup,
      addAutomationGroupRequest: (state) => state.automation.addAutomationGroupRequest,
      updateAutomationGroupRequest: (state) => state.automation.updateAutomationGroupRequest,
      getAutomationGroupRequest: (state) => state.automation.getAutomationGroupRequest,
      editedGroupId: (state) => state.route.query ? state.route.query.groupId : null,
      ghmCollidingGroupNames: (state) => state.automation.ghmCollidingGroupNames,
      ghmUnknown: (state) => state.automation.ghmUnknown,
    }),
    automationType() {
      return this.editedGroupId ? this.automationGroup.type : (this.$store.state.route.query.automationType ?? AutomationTypeEnum.AI)
    },
    conditionsExplanationSentence() {
      return this.automationType === AutomationTypeEnum.RULES
        ? 'Les actions d\'automatisation se déclencheront sur les séjours qui vérifient les conditions suivantes'
        : 'Les séjours automatisés avec les GHM définis ci-dessus peuvent être facturés lorsqu\'ils vérifient les conditions suivantes'
    }
  },
  watch: {
    addAutomationGroupRequest(req) {
      if (req.error) {
        this.requestSuccess = null
        this.requestError = req.error
      } else if (req.ok) {
        this.requestError = null
        this.requestSuccess = 'Groupe d\'automatisation enregistré'
      }
    },
    updateAutomationGroupRequest(req) {
      if (req.error) {
        this.requestSuccess = null
        this.requestError = req.error
      } else if (req.ok) {
        this.requestError = null
        this.requestSuccess = 'Modifications enregistrées'
      }
    },
  },
  mounted() {
    if (this.editedGroupId) {
      this.$store.dispatch('getAutomationGroup', this.editedGroupId).then(() => {
        this.$store.dispatch('getGhmWarnings', {
          presentGhmList: this.automationGroup.presentGhmList,
          absentGhmList: this.automationGroup.absentGhmList,
          groupId: this.automationGroup.id ? this.automationGroup.id : null,
        })
      })
    } else {
      this.$store.commit('getAutomationGroupReset', this.automationType)
      this.$store.dispatch('getGhmWarnings', {
        presentGhmList: this.automationGroup.presentGhmList,
        absentGhmList: this.automationGroup.absentGhmList,
        groupId: null,
      })
    }
  },
  methods: {
    clearMessage() {
      this.requestSuccess = null
      this.requestError = null
    },
    setGroupName(name) {
      this.clearMessage()
      this.$store.commit('setGroupName', name)
    },
    setWaitDuration(waitDuration) {
      this.clearMessage()
      this.$store.commit('setWaitDuration', Number(waitDuration))
    },
    setFulfillAnapathRequests(anapath) {
      this.clearMessage()
      this.$store.commit('setFulfillAnapathRequests', anapath)
    },
    setWaitForAnesthesia(anesthesia) {
      this.clearMessage()
      this.$store.commit('setWaitForAnesthesia', anesthesia)
    },
    setAlertNoCro(alertNoCro) {
      this.clearMessage()
      this.$store.commit('setAlertNoCro', alertNoCro)
    },
    setAlertNoCrh(alertNoCrh) {
      this.clearMessage()
      this.$store.commit('setAlertNoCrh', alertNoCrh)
    },
    populateGhmList() {
      this.clearMessage()
      if (this.ghmCmd === null || this.ghmType === null || this.ghmSubCmd === null || this.ghmLevel === null) {
        return
      }

      const ghm = _.replace(this.ghmCmd + this.ghmType + this.ghmSubCmd + this.ghmLevel, /\*+/g, '*')
      const additionMode = this.additionMode

      this.$store.commit('populateGhmList', { additionMode, ghm })
      this.$store.dispatch('getGhmWarnings', {
        presentGhmList: this.automationGroup.presentGhmList,
        absentGhmList: this.automationGroup.absentGhmList,
        groupId: 'id' in this.automationGroup ? this.automationGroup.id : null,
      })
    },
    removeGhmFromList(type, value) {
      this.clearMessage()
      this.$store.commit('removeGhmFromList', { type, value })
      this.$store.dispatch('getGhmWarnings', {
        presentGhmList: this.automationGroup.presentGhmList,
        absentGhmList: this.automationGroup.absentGhmList,
        groupId: 'id' in this.automationGroup ? this.automationGroup.id : null,
      })
    },
    switchOperators() {
      this.clearMessage()
      let operatorSystem = 'orAnd'
      if (this.automationGroup.searchOperators === 'orAnd') {
        operatorSystem = 'andOr'
      }

      this.$store.commit('updateOperators', operatorSystem)
    },
    hasOneOrMoreCondition(automationGroup) {
      for (const criteriaGroup of automationGroup.criteriaGroups) {
        if (criteriaGroup.criteriaList.length) {
          return true
        }
      }
      return false
    },
    saveAutomationGroup() {
      const automationGroup = this.automationGroup

      if (!this.automationGroup.name || this.automationGroup.name === '') {
        this.requestError = 'Nom du groupe requis'
        return
      }

      if (this.automationType === AutomationTypeEnum.AI && !this.automationGroup.presentGhmList.length) {
        this.requestError = 'Critère de GHM requis'
        return
      }

      if (this.automationType === AutomationTypeEnum.RULES) {
        if (!this.automationGroup.actions.filter((action) => action.action === AutomationActionEnum.ADD_DP).length) {
          this.requestError = `Impossible d'enregistrer un groupe d'automatisation règles sans action '${actionLabels[AutomationActionEnum.ADD_DP]}'.`
          return
        }
        if (!this.hasOneOrMoreCondition(automationGroup)) {
          this.requestError = 'Un groupe d\'automatisation règles doit avoir au moins une condition.'
          return
        }
      }

      if (!this.automationGroup.waitDuration && this.automationGroup.waitDuration !== 0) {
        this.requestError = 'Durée d\'attente requise'
        return
      }

      if (this.automationGroup.waitDuration < 0 || this.automationGroup.waitDuration > 30) {
        this.requestError = 'La durée d\'attente doit être positive et inférieure à 30 jours'
        return
      }

      if (!this.editedGroupId) {
        this.$store.dispatch('addAutomationGroup', automationGroup).then(() => {
          // When the request succeeds, this.automationGroup has an id
          if (this.automationGroup.id) {
            router.replace({ path: this.$store.state.route.path, query: { groupId: this.automationGroup.id, mode: ModeEnum.AUTOMATION, automationType: this.automationType } })
          }
        })
        return
      }

      this.$store.dispatch('updateAutomationGroup', { id: this.editedGroupId, automationGroup })
    },
    saveAction(action) {
      this.$store.commit('addAutomationGroupAction', action)
    },
    removeAction(action) {
      this.$store.commit('removeAutomationGroupAction', action)
    }
  }
}

</script>


<template>
  <div class="container">
    <div class="stay-list-criteria card autom-group-editor shadow">
      <div class="card-header">
        <h1>
          {{ (automationGroup.id !== null || getAutomationGroupRequest.fetching)
            ? 'Édition d\'un groupe'
            : 'Création d\'un nouveau groupe' }}
          {{ automationType === AutomationTypeEnum.AI
            ? "d'automatisation IA"
            : "d'automatisation règle" }}
        </h1>
      </div>
      <div class="card-body">
        <h2 class="mb-4">
          Informations Générales
        </h2>
        <div class="row align-items-center mb-3">
          <div class="col-3 font-weight-bold text-right">
            Nom du groupe
          </div>
          <div class="col-3">
            <input
              :value="automationGroup.name"
              :disabled="getAutomationGroupRequest.fetching"
              type="text"
              class="form-control"
              @input="event => setGroupName(event.srcElement.value)"
            >
          </div>
        </div>
      </div>
      <div
        v-if="automationType === AutomationTypeEnum.AI"
        class="card-body"
      >
        <h2 class="mb-4">
          Filtrer par GHM
        </h2>
        <div>
          <div class="row align-items-center mb-3">
            <div class="col-3 font-weight-bold text-right">
              Critères de GHM
            </div>
            <div class="col-9 row">
              <div class="col-5">
                <div class="ghm-selector">
                  <div class="ghm-selector__cmd">
                    <label>CMD</label>
                    <select
                      v-model="ghmCmd"
                      :disabled="getAutomationGroupRequest.fetching"
                      name="ghm-cmd"
                      @keyup.enter="populateGhmList"
                    >
                      <option value="*">
                        *
                      </option>
                      <option
                        v-for="i in cmdList"
                        :key="i"
                        :value="i"
                      >
                        {{ i }}
                      </option>
                    </select>
                  </div>
                  <div class="ghm-selector__type">
                    <label>Type</label>
                    <select
                      v-model="ghmType"
                      :disabled="getAutomationGroupRequest.fetching"
                      name="ghm-type"
                      @keyup.enter="populateGhmList"
                    >
                      <option value="*">
                        *
                      </option>
                      <option
                        v-for="i in typeList"
                        :key="i"
                        :value="i"
                      >
                        {{ i }}
                      </option>
                    </select>
                  </div>
                  <div class="ghm-selector__sub-cmd">
                    <label>Numéro</label>
                    <select
                      v-model="ghmSubCmd"
                      :disabled="getAutomationGroupRequest.fetching"
                      name="ghm-sub-cmd"
                      @keyup.enter="populateGhmList"
                    >
                      <option value="*">
                        *
                      </option>
                      <option
                        v-for="i in subCmdList"
                        :key="i"
                        :value="i"
                      >
                        {{ i }}
                      </option>
                    </select>
                  </div>
                  <div class="ghm-selector__level">
                    <label>Niveau</label>
                    <select
                      v-model="ghmLevel"
                      :disabled="getAutomationGroupRequest.fetching"
                      name="ghm-level"
                      @keyup.enter="populateGhmList"
                    >
                      <option value="*">
                        *
                      </option>
                      <option
                        v-for="i in levelList"
                        :key="i"
                        :value="i"
                      >
                        {{ i }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <select
                  v-model="additionMode"
                  :disabled="getAutomationGroupRequest.fetching"
                  class="custom-select"
                >
                  <option :value="true">
                    Ajouter des GHM
                  </option>
                  <option :value="false">
                    Exclure des GHM
                  </option>
                </select>
              </div>
              <div class="col-auto">
                <div class="input-group">
                  <button
                    class="btn"
                    :class="{
                      'btn-primary': additionMode,
                      'btn-danger': !additionMode,
                    }"
                    :disabled="getAutomationGroupRequest.fetching"
                    type="button"
                    @click="populateGhmList"
                  >
                    {{ additionMode ? 'Ajouter' : 'Exclure' }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="offset-3 row">
              <v-dropdown
                v-if="ghmCollidingGroupNames.length"
                :triggers="['hover']"
                placement="top"
                class="col"
                popover-class="criteria-popover"
              >
                <span>
                  <sancare-octicon
                    name="alert"
                    :width="30"
                    :height="30"
                  />
                </span>
                <slot />
                <template #popper>
                  <div style="width: 30rem;">
                    Les critères du groupe contiennent des GHM en commun avec le(s) groupe(s) suivant(s) :
                    <div style="text-align:center; font-weight: bold">
                      "{{ ghmCollidingGroupNames.join('", "') }}"
                    </div>
                    <hr>
                    Rappel :<br>
                    <small>
                      Si un séjour est dans au moins un groupe activé dont les critères sont satisfaits, il est considéré comme automatisable. Et ce, même si un autre groupe dont il fait partie n'a pas ses critères satisfaits.
                    </small>
                  </div>
                </template>
              </v-dropdown>
              <v-dropdown
                v-if="ghmUnknown.length"
                :triggers="['hover']"
                placement="top"
                class="col"
                popover-class="criteria-popover"
              >
                <span>
                  <sancare-octicon
                    name="alert"
                    :width="30"
                    :height="30"
                  />
                </span>
                <slot />
                <template #popper>
                  <div style="width: 30rem;">
                    Les critères du groupe contiennent des GHM inconnus :
                    <div style="text-align:center; font-weight: bold">
                      "{{ ghmUnknown.join('", "') }}"
                    </div>
                  </div>
                </template>
              </v-dropdown>
            </div>
          </div>
          <div
            v-if="automationGroup.presentGhmList && automationGroup.presentGhmList.length > 0"
            class="row align-items-center"
          >
            <div class="offset-3 col-auto">
              GHM détecté(s) :
            </div>
            <div class="col row no-gutters py-2">
              <div>
                <div
                  v-for="(value, idx) in automationGroup.presentGhmList"
                  :key="`${idx}`"
                  class="col-auto mx-1 mb-1 pl-2 pr-2 badge badge-primary"
                >
                  <span>{{ value }}</span>
                  <span
                    @click="removeGhmFromList('presentGhmList', value)"
                  >
                    <sancare-octicon
                      name="x"
                      :width="14"
                      :height="11"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="automationGroup.absentGhmList && automationGroup.absentGhmList.length > 0"
            class="row align-items-center"
          >
            <div class="offset-3 col-auto">
              GHM exclus :
            </div>
            <div class="col row no-gutters py-2">
              <div>
                <div
                  v-for="(value, idx) in automationGroup.absentGhmList"
                  :key="`${idx}`"
                  class="col-auto mx-1 mb-1 pl-2 pr-2 badge badge-primary"
                >
                  <span>{{ value }}</span>
                  <span
                    @click="removeGhmFromList('absentGhmList', value)"
                  >
                    <sancare-octicon
                      name="x"
                      :width="14"
                      :height="11"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <h2 class="mb-2">
          Conditions du groupe
        </h2>
        <div class="mb-4 font-italic text-secondary">
          {{ conditionsExplanationSentence }}
        </div>
        <automation-criteria-groups
          :disabled="getAutomationGroupRequest.fetching"
          :automation-type="automationType"
          @clear-message="clearMessage"
        />
        <sancare-toggle
          class="py-2 align-items-center justify-content-center"
          :label="'Inverser les opérateurs &quot;ET&quot;/&quot;OU&quot; dans les conditions du groupe'"
          :label-position="'left'"
          :value="automationGroup.searchOperators === 'orAnd'"
          :disabled="getAutomationGroupRequest.fetching"
          @toggle-change="switchOperators"
        />
        <sancare-toggle
          v-if="automationType === AutomationTypeEnum.AI"
          class="py-2 align-items-center justify-content-center"
          :label="'Quand une biopsie est demandée, attendre l\'acte ou le compte-rendu correspondant'"
          :label-position="'left'"
          :value="automationGroup.fulfillAnapathRequests"
          :disabled="getAutomationGroupRequest.fetching"
          @toggle-change="setFulfillAnapathRequests(!automationGroup.fulfillAnapathRequests)"
        />
        <sancare-toggle
          v-if="automationType === AutomationTypeEnum.AI"
          class="py-2 align-items-center justify-content-center"
          :label="'Attendre un acte d\'anesthésie pour les GHM de type K'"
          :label-position="'left'"
          :value="automationGroup.waitForAnesthesia"
          :disabled="getAutomationGroupRequest.fetching"
          @toggle-change="setWaitForAnesthesia(!automationGroup.waitForAnesthesia)"
        />
        <sancare-toggle
          v-if="enableWebpimsAccess"
          class="py-2 align-items-center justify-content-center"
          :label="'Alerter si pas de CRO (GHM type C et K)'"
          :label-position="'left'"
          :value="automationGroup.alertNoCro"
          :disabled="getAutomationGroupRequest.fetching"
          @toggle-change="setAlertNoCro(!automationGroup.alertNoCro)"
        />
        <sancare-toggle
          v-if="enableWebpimsAccess"
          class="py-2 align-items-center justify-content-center"
          :label="'Alerter si pas de CRH'"
          :label-position="'left'"
          :value="automationGroup.alertNoCrh"
          :disabled="getAutomationGroupRequest.fetching"
          @toggle-change="setAlertNoCrh(!automationGroup.alertNoCrh)"
        />
        <div class="row py-2 no-gutters align-items-center justify-content-center">
          <div class="col-auto ">
            Conditions à remplir dans les
          </div>
          <div class="col-1 px-2">
            <input
              :value="automationGroup.waitDuration"
              :disabled="getAutomationGroupRequest.fetching"
              type="number"
              max="30"
              min="0"
              class="form-control"
              @change="event => setWaitDuration(event.srcElement.value)"
            >
          </div>
          <div class="col-auto">
            jours après la fin du séjour
          </div>
        </div>
        <div class="row py-2 no-gutters align-items-center justify-content-center font-italic text-secondary">
          Au delà de 90 jours, les séjours non-automatisés n'apparaissent plus dans l'automatisation
        </div>
      </div>
      <div
        v-if="automationType === AutomationTypeEnum.RULES"
        id="rules-group-actions"
        class="card-body"
      >
        <h2 class="mb-4">
          Action d'automatisation
        </h2>
        <div>
          <div class="align-items-center mb-3">
            <automation-action-editor
              @save-action="saveAction"
            />
          </div>
          <div class="row">
            <div class="col-6 offset-3">
              <div
                v-for="(action, idx) in automationGroup.actions"
                :key="`${idx}`"
                class="col-auto mx-1 mb-1 pl-2 pr-2 badge badge-primary"
              >
                <span>{{ actionLabels[action.action] }} : {{ action.value }}</span>
                <span
                  @click="removeAction(action)"
                >
                  <sancare-octicon
                    name="x"
                    :width="14"
                    :height="11"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col">
            <div
              v-if="requestError"
              class="text-danger"
            >
              {{ requestError }}
            </div>
            <div
              v-else-if="requestSuccess"
              class="text-success"
            >
              {{ requestSuccess }}
            </div>
          </div>
          <div class="col-auto ml-auto">
            <button
              id="butSave"
              v-tooltip="automationGroup.automated ? 'Modifier un groupe activé le désactive' : null"
              class="btn btn-primary"
              :disabled="addAutomationGroupRequest.fetching || updateAutomationGroupRequest.fetching"
              @click="saveAutomationGroup"
            >
              {{ automationGroup.automated ? "Enregistrer et désactiver" : "Enregistrer" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
