<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'

import JustificationChronicSummary from './JustificationChronicSummary.vue'

export default {
  components: {
    'justification-chronic-summary': JustificationChronicSummary,
    'sancare-octicon': SancareOcticon,
  },
  props: {
    chronicJustifications: { type: Object, required: true },
    mode: { type: String, required: true },
    suggestedChronicDas: { type: Object, required: true },
    rumId: { type: Number, required: true },
    readonly: { type: Boolean, required: true },
    standardModeDiagnosisGroup: { type: Object, required: true },
  },
  data() {
    return {
      selectedDiagnostic: null,
      showJustificationSummary: false,
      extendedWildcards: [],
      isAddingDiag: false
    }
  },
  watch: {
    chronicSuggestion() {
      this.refresh()
    }
  },
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      if(this.chronicJustifications.diagList.length === 1 && !this.isAlreadyCoded(this.chronicJustifications.diagList[0])){
        this.selectDiagnosis(this.chronicJustifications.diagList[0].reference)
      } else {
        this.selectedDiagnostic = null
      }
    },
    isWildcardExtended(wildcardRef) {
      return this.extendedWildcards.indexOf(wildcardRef) !== -1
    },
    toggleWildcard(wildcard) {
      if(this.extendedWildcards.indexOf(wildcard.reference) === -1){
        this.extendedWildcards.push(wildcard.reference)
      } else {
        this.extendedWildcards = _.without(this.extendedWildcards, wildcard.reference)
      }
    },
    showJustificationPopup() {
      this.showJustificationSummary = true
    },
    async addDiagnosis() {
      if(this.selectedDiagnostic === null || this.isAddingDiag) {
        return
      }
      this.isAddingDiag = true
      await this.$store.dispatch('addDiagnosis', {
        rumId: this.rumId,
        diagnosisType: 'DAS',
        reference: this.selectedDiagnostic.reference,
        justifications: null,
        mode: this.mode
      })
      this.isAddingDiag = false
    },
    selectDiagnosis(reference) {
      this.selectedDiagnostic = _.find(
        this.chronicJustifications.diagList, (diagnosis) => diagnosis.reference === reference
      ) || null
    },
    joinDiagosticRef(diagnostics, sep, cut = false) {
      const nbChar = 28
      const content = diagnostics.map((diagnostic) => diagnostic.reference).join(sep)
      if (!cut) {
        return content
      }
      let result = content.substring(0, nbChar)
      if(content.length > nbChar){
        result += '...'
      }
      return result
    },
    isExternalDiagnosis(diagnosis) {
      return ['external', 'unsure', 'prediction_unsure'].indexOf(diagnosis.codingOrigin) !== -1
    },
    isInitialDiagnosis(diagnosis) {
      return _.some(
        this.standardModeDiagnosisGroup.associatedDiags,
        (das) => this.isExternalDiagnosis(das) && das.associatedDiagnosis.reference === diagnosis.reference
      )
    },
    isCurrentDiagnosis(diagnosis) {
      return _.some(
        this.standardModeDiagnosisGroup.associatedDiags,
        (das) => !das.disabled && das.associatedDiagnosis.reference === diagnosis.reference
      )
    },
    isAlreadyCoded(diagnosis) {
      return this.isCurrentDiagnosis(diagnosis) && this.isInitialDiagnosis(diagnosis)
    }
  }
}
</script>

<template>
  <div>
    <div class="mb-2">
      <h2 v-if="!readonly">
        <span
          class="predicted-diagnosis-adder"
          @click="addDiagnosis"
        >
          <div
            v-if="isAddingDiag"
            class="loader loader-sm"
          />
          <sancare-octicon
            v-else
            v-tooltip="'Ajouter le diagnostic'"
            name="arrow-left"
            class="cp"
            :width="20"
            :height="20"
          />
        </span>
        <span
          v-if="selectedDiagnostic !== null"
        >
          {{ selectedDiagnostic.reference }}
        </span>
        <span v-else>Choisir un diagnostic</span>
      </h2>
    </div>
    <div class="row mx-0">
      <div
        v-for="chronicJustification in chronicJustifications.diagList"
        :key="chronicJustification.reference"
        v-tooltip="chronicJustification.description + (isAlreadyCoded(chronicJustification) ? ' (déjà codé)' : '')"
        class="col-6 mx-0 px-0"
        @click="isAlreadyCoded(chronicJustification) ? null : selectDiagnosis(chronicJustification.reference)"
      >
        <div
          class="card diagnosis py-1 mx-1"
          :class="{
            selected: !readonly && selectedDiagnostic !== null && chronicJustification.reference === selectedDiagnostic.reference,
          }"
        >
          <div class="row mx-0">
            <div class="col-9 px-1">
              <h2>
                {{ chronicJustification.reference }}
              </h2>
              <span class="diagnosis-level">
                Niv. {{ chronicJustification.level }}
              </span>
            </div>
            <div class="col-3 pt-2 px-0">
              <sancare-octicon
                v-if="chronicJustification.length > 0"
                name="stopwatch"
                :width="14"
                :height="14"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-tooltip="'Voir justification'">
      <v-dropdown
        :shown="showJustificationSummary"
        placement="left"
        class="popover-container"
        popover-class="justification-popover"
        @show="showJustificationPopup"
      >
        <div class="row">
          <span class="col-auto">
            <sancare-octicon
              name="stopwatch"
              :width="14"
              :height="14"
            />
          </span>
          <span class="col-auto">
            <span>DAS Chronique</span>
          </span>
          <span class="col-auto">
            <sancare-octicon
              name="note"
              :width="14"
              :height="14"
            />
          </span>
        </div>
        <template #popper>
          <justification-chronic-summary
            :chronic-justifications="chronicJustifications.stayList"
            :title="joinDiagosticRef(chronicJustifications.diagList, ', ')"
          />
        </template>
      </v-dropdown>
    </div>
  </div>
</template>
