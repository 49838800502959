<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

import { hasPermission, RoleEnum } from '@/misc/permissions/permissionsHelper'

import {
  chunkIsSelected,
  getChunkClasses,
  getChunkOrigin,
  tagAsFalseJustification,
  tagAsLegitJustification,
  toggleFalseJustificationTypes
} from '../chunkJustifications'

export default {
  props: {
    report: { type: Object, required: true },
    displayedRumIdx: { type: Number, required: true },
    selection: { type: Object, default: null },
    savedSearches: { type: Array, default: null },
    rumPredictedLabels: { type: Array, default: null },
    shouldScroll: { type: Boolean, required: true },
    isDocumentSource: { type: Boolean, required: false },
    enableFalseJustificationFeatures: { type: Boolean, default: true },
  },
  emits: ['disable-justification-scrolling'],
  data() {
    return {
      showFalseJustificationTypes: {},
      RoleEnum,
    }
  },
  computed: mapGetters(['userRole']),
  mounted() {
    if (this.shouldScroll) {
      this.$nextTick(this.scrollToChunk)
    }
  },
  beforeUpdate() {
    if (this.shouldScroll) {
      this.$nextTick(this.scrollToChunk)
    }
  },
  methods: {
    getChunkClasses(chunk, selection) {
      return getChunkClasses(chunk, selection, this.displayedRumIdx)
    },
    tagAsFalseJustification({ report, healthEntry, chunk, errorType }) {
      const origin = getChunkOrigin(chunk, this.rumPredictedLabels, this.savedSearches, this.displayedRumIdx)
      this.showFalseJustificationTypes[chunk.hash] = tagAsFalseJustification(report, healthEntry, chunk, errorType, origin, this.$store)
    },
    tagAsLegitJustification({ report, healthEntry, chunk }) {
      this.showFalseJustificationTypes[chunk.hash] = tagAsLegitJustification(report, healthEntry, chunk, this.$store)
    },
    toggleFalseJustificationTypes(chunk) {
      if (this.enableFalseJustificationFeatures) {
        toggleFalseJustificationTypes(chunk, this.showFalseJustificationTypes)
      }
    },
    scrollToChunk() {
      let selectedChunk = null
      let hash = null
      _.forEach(this.report.chunks, (chunk) => {
        if ((selectedChunk === null && chunk.isHighlighted) || chunkIsSelected(chunk, this.selection, this.displayedRumIdx)) {
          selectedChunk = chunk
          hash = parseInt(selectedChunk.hash)
        }
      })

      if (this.$refs[hash] && selectedChunk !== null) {
        const chunkPosition = window.pageYOffset + this.$refs[hash][0].getBoundingClientRect().top
        const margin = window.innerHeight/2
        scroll({ top: chunkPosition - margin, behavior: 'smooth' })
      }

      this.$emit('disable-justification-scrolling')

    },
    hasPermission,
  },
}
</script>

<template>
  <div :class="{'highlight-on-click' : isDocumentSource }">
    <div class="card-body">
      <div class="row justify-content-between">
        <h1 class="report-title col">
          {{ report.documentTitle }}
        </h1>
        <div class="col-auto">
          <a
            v-if="hasPermission(RoleEnum.ROLE_DOWNLOAD_SOURCEFILE, userRole) && report.generatedDataId"
            :href="'/api/document-source?generatedDataId=' + report.generatedDataId"
            class="report-link"
            target="_blank"
          >
            Voir document <i class="small external icon" />
          </a>
        </div>
      </div>
      <h2
        v-if="report.author"
        class="report-title"
      >
        Auteur : {{ report.author }}
      </h2>
      <h2
        v-if="report.creationDate"
        class="report-title"
      >
        Date : {{ report.creationDate ? report.creationDate.format('L') : '-' }}
      </h2>
    </div>
    <div class="card-body">
      <p
        v-if="report.chunks"
        class="report-content"
      >
        <span
          v-for="chunk in report.chunks"
          :key="chunk.hash"
          :ref="chunk.hash"
          :class="getChunkClasses(chunk, selection)"
          class="text-data-content"
          @click="toggleFalseJustificationTypes(chunk)"
        >{{ chunk.string }}<!--  Comments are here to avoid whitespace issues
        --><v-dropdown
            v-if="(chunk.isHighlighted || chunk.isFalseJustification) && !chunk.isNegative"
            :shown="showFalseJustificationTypes[chunk.hash]"
            placement="bottom-start"
            class="popover-container popover-container__invisible"
            @auto-hide="() => showFalseJustificationTypes[chunk.hash] = false"
          ><!--
        --><template #popper>
              <span
                v-if="!chunk.isFalseJustification"
              >
                Signaler comme :
                <div
                  v-for="signal in [['negation', 'Négation'], ['antecedent', 'Antécédent'], ['doubt', 'Passage incertain'], ['recurrence', 'Motif récurrent']]"
                  :key="signal[0]"
                >
                  <button
                    class="btn btn-link btn-sm"
                    @click="tagAsFalseJustification({ errorType: signal[0], report, healthEntry: null, chunk })"
                  >
                    {{ signal[1] }}
                  </button>
                </div>
              </span>
              <span
                v-else
              >
                <button
                  class="btn btn-link btn-sm"
                  @click="tagAsLegitJustification({ report, healthEntry: null, chunk })"
                >
                  Annuler le signalement
                </button>
              </span>
          </template><!--
        --></v-dropdown><!--
      --></span>
      </p>
      <p v-else>
        <span class="text-data-content">{{ report.content }}</span>
      </p>
    </div>
  </div>
</template>
<style>
.report-content {
  word-wrap: anywhere;
}
</style>
