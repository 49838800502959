import { AutomationModeEnum, AutomationTypeEnum } from '@/store/modules/automation/types'

export enum CountryEnum {
  CH = 'ch',
  FR = 'fr',
}

export enum PmsiTypeEnum {
  MCO = 'mco',
  SSR = 'ssr',
}
export const pmsiTypesLabels = {
  [PmsiTypeEnum.MCO]: 'MCO',
  [PmsiTypeEnum.SSR]: 'SMR',
}

export enum ModeEnum {
  AUTOMATION = 'automation',
  NEW_CODING = 'new-coding',
  OPTIMIZABLE = 'optimizable',
  QUALITY_STAYS = 'quality-stays',
}

export enum PrivacyEnum {
  PRIVATE_SEARCH = 'privateSearch',
  TEAM_SEARCH = 'teamSearch',
  PUBLIC_SEARCH = 'publicSearch',
  SANCARE_SEARCH = 'sancareSearch',
}

export type StayListColumn = {
  icon?: string
  name?: string
  isTranslated?: boolean
  label: string|null
  country: CountryEnum[]
  order: string
  mode: (ModeEnum|AutomationModeEnum)[]
  automationType?: AutomationTypeEnum[]
  isDefault: boolean
  invertedOrder: boolean
}

export type StayListColumns = { [key: string]: StayListColumn }

type Team = {
  id: number
  name: string
}

// to be completed
export type User = {
  id: number
  role: string
  sancareUser: boolean
  teamMode: boolean
  team: Team|null
  username: string
}
