<script>
import { mapState } from 'vuex'

import Graphic from './SancareGraphic.vue'

export default {
  components: {
    'sancare-graphic': Graphic,
  },
  props: {
    earlyLimit: { type: Object, required: true },
    lateLimit: { type: Object, required: true },
    chartUnit: { type: String, default: 'month' },
    labelSets: { type: Array, required: true },
    selectedMedicalUnits: { type: Array, default: null },
  },
  computed: {
    ...mapState({
      stats: (state) => state.stats.financialStats,
    }),
  },
  watch: {
    earlyLimit() {
      this.refresh()
    },
    lateLimit() {
      this.refresh()
    },
    chartUnit() {
      this.refresh()
    },
    selectedMedicalUnits() {
      this.refresh()
    }
  },
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.$store.dispatch('getFinancialStats', {
        earlyLimit: this.earlyLimit.format('YYYY-MM-DD'),
        lateLimit: this.lateLimit.format('YYYY-MM-DD'),
        unit: this.chartUnit,
        medicalUnits: this.selectedMedicalUnits,
      })
    },
  }
}
</script>

<template>
  <div class="stat">
    <h1 class="stat-title">
      Analyse financière
    </h1>
    <div class="row">
      <h2 class="col-12 stat-graph-title">
        Gains et pertes - Revalorisation
      </h2>
    </div>
    <div class="stat-content row justify-content-around">
      <div class="col-12 row align-items-stretch no-gutters">
        <sancare-graphic
          :label-sets="labelSets"
          :serie="stats"
          :serie-infos="{
            positiveRevaluation: { label: 'Gain', unit: '€', color: '#31E1E3' },
            negativeRevaluation: { label: 'Perte', unit: '€', color: '#E331A9' },
          }"
          :label-y="'€'"
          type="bar"
          class="col-12"
        />
      </div>
    </div>
  </div>
</template>
