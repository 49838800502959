<script>
import { DatePicker } from '@sancare/ui-frontend-commons'
import { mapState } from 'vuex'

export default {
  components: {
    'date-picker': DatePicker,
  },
  data() {
    return {
      test: 'revaluations',
      stayRssId: null,
      stayHPrim: '',
    }
  },
  computed: {
    ...mapState({
      coraRevaluationsRequest: (state) => state.devTools.debugCoraRevaluationsRequest,
      coraRevaluationsResponse: (state) => state.devTools.debugCoraRevaluationsResponse,

      coraEditionsRequest: (state) => state.devTools.debugCoraEditionsRequest,
      coraEditionsResponse: (state) => state.devTools.debugCoraEditionsResponse,

      coraStayRequest: (state) => state.devTools.debugCoraStayRequest,
      coraStayResponse: (state) => state.devTools.debugCoraStayResponse,

      coraImportRequest: (state) => state.devTools.debugCoraImportsRequest,
      coraImportResponse: (state) => state.devTools.debugCoraImportsResponse,
    }),
    earlyLimit: {
      get() {
        return this.$store.state.settings.earlyLimit
      },
      set(limit) {
        this.$store.commit('setEarlyLimit', limit)
      },
    },
    lateLimit: {
      get() {
        return this.$store.state.settings.lateLimit
      },
      set(limit) {
        this.$store.commit('setLateLimit', limit)
      },
    },
  },
  methods: {
    debugCoraRevaluations() {
      this.$store.dispatch('debugCoraRevaluations', this.earlyLimit)
    },
    debugCoraEditions() {
      this.$store.dispatch('debugCoraEditions', this.earlyLimit)
    },
    debugCoraStay() {
      this.$store.dispatch('debugCoraStay', this.stayRssId)
    },
    debugCoraImports() {
      this.$store.dispatch('debugCoraImports', this.stayHPrim)
    }
  }
}
</script>

<template>
  <div class="webpims-analysis-page">
    <div class="row m-1">
      <h1>Cora Analysis - {{ test }}</h1>
    </div>
    <div class="row m-1 align-items-center">
      <div class="col-auto">
        Tester
      </div>
      <div class="col-auto">
        <button
          class="btn btn-primary"
          @click="test = 'revaluations'"
        >
          /cora/revaluations
        </button>
      </div>
      <div class="col-auto">
        <button
          class="btn btn-primary"
          @click="test = 'editions'"
        >
          /cora/editions
        </button>
      </div>
      <div class="col-auto">
        <button
          class="btn btn-primary"
          @click="test = 'stay'"
        >
          /cora/{stayId}
        </button>
      </div>
      <div class="col-auto">
        <button
          class="btn btn-primary"
          @click="test = 'import'"
        >
          /cora (import)
        </button>
      </div>
    </div>
    <div v-if="test === 'revaluations'">
      <div class="row m-1 align-items-center">
        <div class="col-auto">
          A partir du
        </div>
        <div class="col-auto">
          <date-picker v-model="earlyLimit" />
        </div>
        <div class="col-auto">
          <button
            class="btn btn-link"
            @click="debugCoraRevaluations()"
          >
            Envoyer
          </button>
        </div>
      </div>
      <div class="row m-1">
        <div class="col-1">
          <strong>RSS ID</strong>
        </div>
        <div class="col-1">
          <strong>Administrative ID</strong>
        </div>
        <div class="col-1">
          <strong>Revalorisable</strong>
        </div>
      </div>
      <div
        v-for="stay in coraRevaluationsResponse"
        :key="`cora_revaluation_stay_${stay.rssId}`"
        class="row m-1"
      >
        <div class="col-1">
          {{ stay.rssId }}
        </div>
        <div class="col-1">
          {{ stay.administrativeLocalStayId }}
        </div>
        <div class="col-1">
          {{ stay.isRevaluable ? 'OUI' : 'NON' }}
        </div>
      </div>
    </div>
    <div v-else-if="test === 'editions'">
      <div class="row m-1 align-items-center">
        <div class="col-auto">
          A partir du
        </div>
        <div class="col-auto">
          <date-picker v-model="earlyLimit" />
        </div>
        <div class="col-auto">
          <button
            class="btn btn-link"
            @click="debugCoraEditions()"
          >
            Envoyer
          </button>
        </div>
      </div>
      <div class="row m-1">
        <div class="col-1">
          <strong>RSS ID</strong>
        </div>
        <div class="col-1">
          <strong>Administrative ID</strong>
        </div>
      </div>
      <div
        v-for="stay in coraEditionsResponse"
        :key="`cora_editions_stay_${stay.rssId}`"
        class="row m-1"
      >
        <div class="col-1">
          {{ stay.rssId }}
        </div>
        <div class="col-1">
          {{ stay.administrativeLocalStayId }}
        </div>
      </div>
    </div>
    <div v-else-if="test === 'stay'">
      <div class="row m-1 align-items-center">
        <div class="col-auto">
          RSS ID
        </div>
        <div class="col-auto">
          <input
            v-model="stayRssId"
            type="text"
            class="form-control"
          >
        </div>
        <div class="col-auto">
          <button
            class="btn btn-link"
            @click="debugCoraStay()"
          >
            Envoyer
          </button>
        </div>
      </div>
      <div class="row m-1">
        <div class="col">
          Séjour:
        </div>
      </div>
      <div class="row m-1">
        <div class="col">
          <textarea
            v-model="coraStayResponse"
            class="form-control"
            style="width: 100%;min-height:300px;resize:none"
          />
        </div>
      </div>
    </div>
    <div v-else-if="test === 'import'">
      <div class="row m-1">
        <div class="col-6">
          <div class="row">
            <div class="col">
              HPRIM :
            </div>
          </div>
          <div class="row">
            <div class="col">
              <textarea
                v-model="stayHPrim"
                style="width: 100%;height:300px;resize:none"
              />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            Response :
          </div>
          <div class="row">
            <p style="border:1px solid #C0C0C0; width: 100%; height: 300px;">
              {{ coraImportResponse }}
            </p>
          </div>
        </div>
      </div>
      <div class="row m-1">
        <button
          class="btn btn-link"
          @click="debugCoraImports"
        >
          Envoyer
        </button>
      </div>
    </div>
  </div>
</template>
