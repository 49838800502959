import Api from '@sancare/ui-frontend-commons/src/misc/api'

import { state } from '@/store/modules/diagnosis/ssr/state'
import { DiagnosisType } from '@/store/modules/diagnosis/types'
import { PayloadWithMut } from '@/store/types'

interface SsrDiagnosisPayload extends PayloadWithMut {
  urlParams : {
    rhsId: number
  }
}

interface AddSsrDiagnosisPayload extends SsrDiagnosisPayload {
  body: {
    newDiagnosisType: DiagnosisType
    diagnosis: string
  }
}

interface RemoveSsrDiagnosisPayload extends SsrDiagnosisPayload {
  urlParams : {
    rhsId: number
    associatedDiagnosisId: number
  }
  body: {
    currentDiagnosisType: DiagnosisType
  }
}

type UpdateSsrDiagnosisPayload = RemoveSsrDiagnosisPayload & AddSsrDiagnosisPayload

export const actions = {
  addSsrDiagnosis({ commit }, payload: AddSsrDiagnosisPayload) {
    return Api.request(
      state.requests.addSsrDiagnosis,
      {
        urlParams: payload.urlParams,
        body: payload.body,
        startMut: () => {
          commit('ssrStay/startRefreshingGroupingResult', null, { root: true })
        },
        successMut: (response) => {
          commit(
            'ssrStay/updateSsrDiagnosisSuccess',
            response,
            { root: true }
          )
          return null
        },
        errorMut: (response) => {
          const badTypeError = `The diagnosis ${payload.body.diagnosis} cannot be set to the given type ${
            payload.body.newDiagnosisType
          }`
          if (response.rawError?.includes(badTypeError)) {
            return { error: `Impossible d\'ajouter le diagnostic au type ${payload.body.newDiagnosisType}` }
          } else {
            return { error: 'Échec de l\'ajout du diagnostic' }
          }
        }
      },
      commit
    )
  },

  removeSsrDiagnosis({ commit }, payload: RemoveSsrDiagnosisPayload) {
    return Api.request(
      state.requests.removeSsrDiagnosis,
      {
        urlParams: payload.urlParams,
        startMut: () => {
          commit('ssrStay/startRefreshingGroupingResult', null, { root: true })
        },
        successMut: (response) => {
          commit(
            'ssrStay/updateSsrDiagnosisSuccess',
            response,
            { root: true }
          )
          return null
        },
        errorMut: () => {
          return { error: 'Échec de la suppression' }
        }
      },
      commit
    )
  },

  updateSsrDiagnosis({ commit }, payload: UpdateSsrDiagnosisPayload) {
    return Api.request(
      state.requests.updateSsrDiagnosis,
      {
        urlParams: payload.urlParams,
        body: payload.body,
        startMut: () => {
          commit('ssrStay/startRefreshingGroupingResult', null, { root: true })
        },
        successMut: (response) => {
          commit(
            'ssrStay/updateSsrDiagnosisSuccess',
            response,
            { root: true }
          )
          return null
        },
        errorMut: (response) => {
          const badTypeError = `The diagnosis ${payload.body.diagnosis} cannot be set to the given type ${
            payload.body.newDiagnosisType
          }`
          if (response.rawError?.includes(badTypeError)) {
            return { error: `Impossible d\'ajouter le diagnostic au type ${payload.body.newDiagnosisType}` }
          } else {
            return { error: 'Échec de l\'ajout du diagnostic' }
          }
        }
      },
      commit
    )
  }
}
