<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { mapGetters } from 'vuex'

import { hasPermission, RoleEnum } from '@/misc/permissions/permissionsHelper'
import router from '@/router'

import BugReport from './BugReport.vue'
import UserSettings from './UserSettings.vue'

export default {
  components: {
    'user-settings': UserSettings,
    'bug-report': BugReport,
    'sancare-octicon': SancareOcticon,
  },
  props: {
    userRole: { type: String, required: true },
  },
  data() {
    return {
      RoleEnum,
    }
  },
  computed: {
    path() {
      return this.$route.path
    },
    ...mapGetters(['settingsUrlQuery']),
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
        .then(() => {
          router.push({ path: '/login' })
        })
    },
    hasPermission,
  }
}
</script>

<template>
  <div>
    <div class="settings-buttons row">
      <router-link
        v-if="hasPermission(RoleEnum.ROLE_SANCARE_SEARCH_MANAGER, userRole)"
        v-tooltip="'Signalements'"
        :to="{
          path: '/false-justifications',
          query: { ...settingsUrlQuery }
        }"
        class="px-2"
      >
        <sancare-octicon
          name="megaphone"
          :width="25"
          :height="25"
        />
      </router-link>
      <bug-report />
      <router-link
        v-if="hasPermission(RoleEnum.ROLE_MAINTENANCE_VIEWER, userRole)"
        v-tooltip="'Maintenance'"
        :to="{
          path: '/maintenance',
          query:{ }
        }"
        class="px-2"
      >
        <sancare-octicon
          name="pulse"
          :width="25"
          :height="25"
        />
      </router-link>
      <router-link
        v-if="hasPermission(RoleEnum.ROLE_DASHBOARD_ACCESSOR, userRole)"
        v-tooltip="'Statistiques'"
        :to="{
          path: '/stats',
          query:{ ...settingsUrlQuery }
        }"
        class="px-2"
      >
        <sancare-octicon
          name="graph"
          :width="25"
          :height="25"
        />
      </router-link>
      <router-link
        v-if="hasPermission(RoleEnum.ROLE_USER_MANAGER, userRole) || hasPermission('ROLE_ADMIN_MANAGER', userRole) || hasPermission('ROLE_SETTING_UPDATER', userRole)"
        v-tooltip="'Zone admin'"
        to="/admin"
        class="px-2"
      >
        <sancare-octicon
          name="tools"
          :width="25"
          :height="25"
        />
      </router-link>
      <user-settings />
      <div
        v-tooltip="'Déconnexion'"
        class="px-2"
        @click="logout"
      >
        <sancare-octicon
          name="sign-out"
          :width="25"
          :height="25"
        />
      </div>
    </div>
  </div>
</template>
