<script>
import { mapGetters } from 'vuex'

import { hasPermission, RoleEnum } from '@/misc/permissions/permissionsHelper'
import router from '@/router'

import LoginModal from './login/LoginModal.vue'
import Settings from './settings/SancareSettings.vue'
import StayFinder from './stay-displayer/StayFinder.vue'

export default {
  components: {
    'sancare-settings': Settings,
    'stay-finder': StayFinder,
    'login-modal': LoginModal,
  },
  data() {
    return {
      ieMsgClosed: false
    }
  },
  computed: mapGetters(['isAuthenticated', 'sessionIsValid', 'userRole', 'username', 'appVersion']),
  watch: {
    sessionIsValid(sess) {
      if (sess) {
        this.$store.dispatch('getUserSettings')
        if (hasPermission(RoleEnum.ROLE_STAY_READER, this.userRole)) {
          this.loadMedicalUnitFilterData()
        }
      }
    },
    isAuthenticated(isAuth, wasAuthenticated) {
      if (isAuth && !wasAuthenticated) {
        // Default route when user is ROLE_DATA_PROVIDER is manage-api-key
        router.push({
          path: this.$store.state.route.query.redirect || (this.userRole === 'ROLE_DATA_PROVIDER' ? '/manage-api-key' : '/'),
        })
      } else if (!isAuth) {
        router.push({
          path: '/login',
          query: {
            redirect: this.$store.state.route.fullPath,
          }
        })
      }
    },
  },
  mounted() {
    if (this.isAuthenticated && this.sessionIsValid) {
      this.$store.dispatch('getUserSettings')
      if (hasPermission(RoleEnum.ROLE_STAY_READER, this.userRole)) {
        this.loadMedicalUnitFilterData()
      }
    } else if (this.isAuthenticated && !this.sessionIsValid) {
      this.$store.commit('sessionExpired')
    } else if (this.username && !this.isAuthenticated) {
      this.$store.dispatch('logout')
    }
  },
  methods: {
    isIE() {
      return window.navigator && /Trident|MSIE/i.test(window.navigator.userAgent)
    },
    isIESupported() {
      return (new Date()) < new Date(2022, 0, 15)
    },
    loadMedicalUnitFilterData() {
      this.$store.dispatch('medicalUnitFilter/fetchConstants', {
        url: '/api/stay-search-criteria/list?type=constant',
      })
      this.$store.dispatch('medicalUnitFilter/fetchDataPresence', {
        url: '/api/stay-search-criteria/presence',
      })

      this.$store.dispatch('medicalUnitFilter/fetchMedicalUnits', {
        url: '/api/stay-search-criteria/list?type=medicalUnit',
      }).then(() => {
        const { medicalUnits, facilities, divisions } = this.$store.state.medicalUnitFilter.remoteLists
        this.$store.commit('initMedicalUnits', { medicalUnits, facilities, divisions })
      })
    }
  }
}
</script>

<template>
  <div
    class="full-height"
    :class="isAuthenticated ? '' : 'unlogged-page'"
  >
    <div
      v-if="isIE() && !ieMsgClosed"
      id="ie-msg"
      class="alert alert-danger"
    >
      Attention, Internet Explorer {{ isIESupported() ? 'ne sera' : "n'est plus" }} plus supporté {{ isIESupported() ? 'à partir de' : 'depuis' }} Janvier 2022. Veuillez utiliser un autre navigateur (Mozilla Firefox, Google Chrome ou autre...) ou contacter votre support informatique.
      <button
        type="button"
        class="close"
        @click="ieMsgClosed = true"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <login-modal v-if="isAuthenticated && !sessionIsValid" />
    <stay-finder v-if="isAuthenticated" />
    <sancare-settings
      v-if="isAuthenticated"
      :user-role="userRole"
    />
    <div class="full-height">
      <router-view class="full-height-leaf" />
      <footer class="text-center font-italic">
        Sancare Report Viewer {{ appVersion }}
      </footer>
    </div>
  </div>
</template>

<style lang="less">
  #ie-msg {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9;
    text-align: center;
  }
</style>
