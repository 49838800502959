<script>
import _ from 'lodash'

import { ModeEnum, PrivacyEnum } from '../store/modules/settings/types'
import SancareCategory from './SancareCategory.vue'

export default {
  name: 'SancareCategoryList',
  components: {
    'sancare-category': SancareCategory,
  },
  props: {
    sancareCategories: { type: Array, required: true },
    mode: { type: String, required: true },
    selectedSearches: { type: Array, required: true },
    quickFilterResults: { type: Object, default: null },
    searchesPrivacy: { type: String, required: true },
  },
  emits: ['refresh-stats', 'select-search'],
  data() {
    return {
      showMore: false,
    }
  },
  computed: {
    PrivacyEnum() {
      return PrivacyEnum
    },
    ModeEnum() {
      return ModeEnum
    },
    displayedCategories() {
      return _.filter(this.sancareCategories, (cat) => {
        if (!_.isNull(this.quickFilterResults)) {
          return this.quickFilterResults[cat.id]
        }

        const hasSomeSearchWithStays = _.some(cat.searches, (search) => search.countTodoStay > 0)

        return hasSomeSearchWithStays || this.showMore
      })
    },
    hasHideableCategories() {
      return _.some(this.sancareCategories, (c) => c.countTodoStay === 0) && _.isNull(this.quickFilterResults)
    }
  },
  methods: {
    handleSelectSearch(search) {
      this.$emit('select-search', search)
    },
    refreshStats(searchIds) {
      this.$emit('refresh-stats', searchIds)
    }
  },
}
</script>

<template>
  <div
    v-if="sancareCategories.length"
    class="home-list-part"
  >
    <div class="row no-gutters">
      <h2
        v-if="searchesPrivacy === PrivacyEnum.PUBLIC_SEARCH"
        class="col home-list-part-title p-2"
      >
        Recherches publiques
      </h2>
      <h2
        v-else
        class="col home-list-part-title p-2"
      >
        {{ mode === ModeEnum.OPTIMIZABLE ? 'Recherches' : 'Listes de travail' }} Sancare
      </h2>
    </div>
    <div
      v-if="!displayedCategories.length"
      class="text-center"
    >
      Aucune recherche {{ hasHideableCategories ? "n'a de séjour correspondant" : "ne correspond au filtrage" }}
    </div>
    <sancare-category
      v-for="category in displayedCategories"
      :key="category.id"
      :category="category"
      :selected-searches="selectedSearches"
      :mode="mode"
      :searches-privacy="searchesPrivacy"
      :quick-filter-results="quickFilterResults ? quickFilterResults[category.id] : null"
      @select-search="handleSelectSearch"
      @refresh-stats="refreshStats"
    />
    <div
      v-if="hasHideableCategories"
      class="row no-gutters px-1"
    >
      <span
        class="col text-center btn btn-link"
        @click="showMore = !showMore"
      >
        {{ showMore ? 'Afficher moins' : 'Afficher plus' }}
      </span>
    </div>
  </div>
</template>
