import Api from '@sancare/ui-frontend-commons/src/misc/api'

import { state } from './state'

export const actions = {
  fetchStayActions({ commit }, stayId: number) {
    return Api.request(
      state.requests.fetchStayActions,
      {
        urlParams: { id: stayId },
        successMut: 'fetchStayActionsSuccess',
      },
      commit
    )
  },
}
