import Api from '@sancare/ui-frontend-commons/src/misc/api'

import { state } from '@/store/modules/stay/ssr/state'
import { DependenciesType } from '@/store/modules/stay/ssr/types'

export const actions = {
  fetchStay({ commit }, stayId: number) {
    return Api.request(
      state.requests.fetchStay,
      {
        urlParams: { id: stayId },
        successMut: 'fetchStaySuccess',
      },
      commit
    )
  },
  fetchRhs({ commit }, rhsId: number) {
    return Api.request(
      state.requests.fetchRhs,
      {
        urlParams: { id: rhsId },
        successMut: 'fetchRhsSuccess',
      },
      commit
    )
  },
  updateRhsDependency({ commit }, payload: { rhsId: number, dependencyType: DependenciesType, value: number }) {
    return Api.request(
      state.requests.updateRhsDependency,
      {
        urlParams: { id: payload.rhsId },
        body: {
          dependencyType: payload.dependencyType,
          value: payload.value
        },
        startMut: 'startRefreshingGroupingResult',
        successMut: 'setRhsDependencies',
        errorMut: (response) => (response),
      },
      commit
    )
  },
  updateStayStatus({ commit }, { stayId, status }) {
    return Api.request(
      state.requests.updateStayStatus,
      {
        body: { stayId, status },
        successMut: 'updateStayStatusSuccess'
      },
      commit
    )
  }
}
