import Api from '@/misc/api'

export default {
  state: {
    apiKeyExists: false,
    apiKey: null,
    checkApiKeyRequest: { fetching: false, error: null, ok: false },
    generateApiKeyRequest: { fetching: false, error: null, ok: false },
  },
  mutations: {
    checkApiKeyStart(state) {
      state.checkApiKeyRequest = { fetching: true, error: null, ok: false }
    },
    checkApiKeySuccess(state, res) {
      state.checkApiKeyRequest.fetching = false
      state.checkApiKeyRequest.ok = true
      state.apiKeyExists = res.apiKeyExists
    },
    checkApiKeyError(state, res) {
      state.checkApiKeyRequest.fetching = false
      state.checkApiKeyRequest.error = res.message
    },
    generateApiKeyStart(state) {
      state.generateApiKeyRequest = { fetching: true, error: null, ok: false }
    },
    generateApiKeySuccess(state, res) {
      state.generateApiKeyRequest.fetching = false
      state.generateApiKeyRequest.ok = true
      state.apiKey = res.apiKey
    },
    generateApiKeyError(state, res) {
      state.generateApiKeyRequest.fetching = false
      state.generateApiKeyRequest.error = res.message
    },
  },
  actions: {
    checkApiKey({ commit }) {
      Api.get(
        {
          url: '/api/api-key-existence',
          startMut: 'checkApiKeyStart',
          successMut: 'checkApiKeySuccess',
          errorMut: 'checkApiKeyError',
        },
        commit
      )
    },
    generateApiKey({ commit }) {
      Api.post(
        {
          url: '/api/api-key-generate',
          body: '',
          startMut: 'generateApiKeyStart',
          successMut: 'generateApiKeySuccess',
          errorMut: 'generateApiKeyError',
        },
        commit
      )
    }
  },
}
