<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { mapState } from 'vuex'

import { PmsiTypeEnum } from '@/store/modules/settings/types'

import AdvancedSearchCriteriaGroup from './AdvancedSearchCriteriaGroup.vue'

export default {
  components: {
    'advanced-search-criteria-group': AdvancedSearchCriteriaGroup,
    'sancare-octicon': SancareOcticon,
  },
  props: {
    disabled: { type: Boolean, required: true },
    mode: { type: String, required: true },
    pmsiType: {
      validator(value) {
        return Object.values(PmsiTypeEnum).includes(value)
      },
      default: PmsiTypeEnum.MCO
    },
  },
  emits: ['clear-message'],
  computed: {
    ...mapState({
      globalCriteriaGroup: (state) => _.isEmpty(state.stayList.currentSearch.globalCriteriaGroup) ? {} : state.stayList.currentSearch.globalCriteriaGroup,
      criteriaGroups: (state) => state.stayList.currentSearch.criteriaGroups,
      externalOperator: (state) => state.stayList.currentSearch.searchOperators === 'andOr' ? 'ET' : 'OU',
      internalOperator: (state) => state.stayList.currentSearch.searchOperators === 'andOr' ? 'OU' : 'ET',
    }),
  },
  mounted() {
    if (_.isEmpty(this.globalCriteriaGroup)) {
      this.$store.commit('initiateGlobalCriteriaGroup')
    }
  },
  methods: {
    clearMessage() {
      this.$emit('clear-message')
    },
    addCriteriaGroup() {
      this.clearMessage()
      if (!this.disabled) {
        this.$store.commit('addCriteriaGroup')
      }
    },
    deleteCriteriaGroup(id) {
      this.clearMessage()
      this.$store.commit('removeCriteriaGroup', id)
    }
  }
}
</script>

<template>
  <div class="advanced-search-criteria-groups">
    <div class="row">
      <div
        class="col-12 row align-items-stretch"
      >
        <advanced-search-criteria-group
          :internal-operator="internalOperator"
          :group="globalCriteriaGroup"
          :group-is-global="true"
          :mode="mode"
          :pmsi-type="pmsiType"
          class="col-12"
        />
      </div>
    </div>
    <div class="row">
      <div
        v-for="(group, idx) in criteriaGroups"
        :key="group.id"
        class="col-6 row align-items-stretch"
      >
        <advanced-search-criteria-group
          :class="idx % 2 !== 1 ? 'col-11' : 'col-12'"
          :internal-operator="internalOperator"
          :group="group"
          :group-is-global="false"
          :mode="mode"
          :pmsi-type="pmsiType"
          @delete-criteria-group="deleteCriteriaGroup"
          @clear-message="clearMessage"
        />
        <div
          v-if="idx % 2 !== 1"
          class="col-1 row no-gutters align-items-center"
        >
          {{ externalOperator }}
        </div>
      </div>
      <div class="col-4 row">
        <div class="col-10 row no-gutters align-items-stretch">
          <div
            class="col-12 automation-criteria-group automation-criteria-group-add"
            :class="{ disabled: disabled }"
            @click="addCriteriaGroup"
          >
            <sancare-octicon
              name="diff-added"
              :width="30"
            />
            <div>Ajouter un groupe de critères</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.automation-criteria-group-add {
  background: #F0F0F0;
  border-radius: 0.3rem;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #A0A0A0;
  cursor: pointer;
  border: solid 1px #E0E0E0;
  &:hover {
    border-color: #A0A0A0;
    color: #505050;
  }
  transition: border-color 0.15s, color 0.15s;
  &.disabled {
    cursor: default;
  }
  &.disabled:hover {
    border-color: #E0E0E0;
    color: #A0A0A0;
  }
}
</style>
