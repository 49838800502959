function formatQuery(query) {
  for (const prop of ['el', 'earlyLimit', 'll', 'lateLimit']) {
    if (query[prop] && typeof query[prop] !== 'string') {
      query[prop] = query[prop].format('DD-MM-YYYY')
    }
  }
  for (const prop of ['um', 'medicalUnits']) {
    if (query[prop] === null) {
      delete query[prop]
    }
  }

  return query
}

function getUrlQuery(query) {
  const formattedQuery = formatQuery({ ...query })
  const sp = new URLSearchParams(formattedQuery)

  return sp.toString()
}

export {
  formatQuery,
  getUrlQuery,
}
