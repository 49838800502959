import { ApiRequest } from '@sancare/ui-frontend-commons/src/misc/api-request'

import { ConnectorConfigState } from './types'

const baseUrl = '/api/connector-config/'

const state = new ConnectorConfigState()
state.requests = {
  getConnectorsConfigList: new ApiRequest('getConnectorsConfigList', 'GET', baseUrl),
  postConnectorConfig: new ApiRequest('postConnectorConfig', 'POST', baseUrl),
  putConnectorConfig: new ApiRequest('putConnectorConfig', 'PUT', `${baseUrl}{id}`),
  deleteConnectorConfig: new ApiRequest('deleteConnectorConfig', 'DELETE', `${baseUrl}{id}`),
  testWriteRight: new ApiRequest('testWriteRight', 'POST', `${baseUrl}test-write/`),
}

export { state }
