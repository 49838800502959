<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { mapGetters } from 'vuex'

import { PmsiTypeEnum } from '@/store/modules/settings/types'

import SavedSearchThumbnail from '../saved-searches/SavedSearchThumbnail.vue'

export default {
  name: 'OwnSearchList',
  components: {
    'sancare-octicon': SancareOcticon,
    'saved-search-thumbnail': SavedSearchThumbnail,
  },
  props: {
    searchList: { type: Array, required: true },
    mode: { type: String, required: true },
    pmsiType: {
      validator(value) {
        return Object.values(PmsiTypeEnum).includes(value)
      },
      required: true,
    },
    selectedSearches: { type: Array, required: true },
    title: { type: String, required: true },
    quickFilterResults: { type: Array, default: null },
    canAddOwnSearch: { type: Boolean, default: false },
  },
  emits: ['refresh-stats', 'select-search'],
  data() {
    return {
      expand: false,
    }
  },
  computed: {
    ...mapGetters(['settingsUrlQuery']),
    displayedSearches() {
      return _.map(
        _.filter(this.searchList, (s) => {
          if (!_.isNull(this.quickFilterResults)) {
            return this.quickFilterResults.indexOf(s.id) !== -1
          }

          return s.countTodoStay || this.expand
        }),
        (s) => ({
          ...s,
          stayListQuery: {
            mode: this.mode,
            revType: 'undetermined',
            searchId: [s.id],
            ...this.settingsUrlQuery
          },
        })
      )
    },
    hasHideableSearches() {
      return _.some(this.searchList, (s) => s.countTodoStay === 0) && _.isNull(this.quickFilterResults)
    },
    newSearchQuery() {
      return {
        mode: this.mode,
        revType: 'undetermined',
        sc: 1,
        ...this.settingsUrlQuery
      }
    },
  },
  methods: {
    refreshStats(searchIds) {
      this.$emit('refresh-stats', searchIds)
    }
  }
}
</script>

<template>
  <div class="own-search-list">
    <div class="home-list-part">
      <div class="row no-gutters align-items-center">
        <h2 class="col home-list-part-title p-2">
          {{ title }}
        </h2>
        <div
          v-if="canAddOwnSearch"
          class="col-auto btn sancare-btn"
        >
          <router-link :to="{name: 'staylist', params: {pmsiType}, query: newSearchQuery}">
            {{ `+ Créer une ${mode === 'optimizable' ? 'recherche' : 'liste de travail'}` }}
          </router-link>
        </div>
        <div class="col-auto">
          <span
            v-if="hasHideableSearches"
            :class="{
              'home-list-chevron--extend': !expand,
              'home-list-chevron--reduce': expand
            }"
            class="home-list-chevron ml-2"
            @click="expand = !expand"
          >
            <sancare-octicon
              :name="expand ? 'chevron-up' : 'chevron-down'"
              :width="20"
              :height="20"
            />
          </span>
        </div>
      </div>
      <div class="row no-gutters">
        <div
          v-for="search in displayedSearches"
          :key="search.id"
          class="col-3 p-2"
        >
          <saved-search-thumbnail
            :search="search"
            :selected="selectedSearches.indexOf(search.id) !== -1"
            :stay-list-query="search.stayListQuery"
            :mode="mode"
            @select-search="$emit('select-search', search)"
            @refresh-stats="refreshStats"
          />
        </div>
      </div>
    </div>
  </div>
</template>
