<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { mapState } from 'vuex'

import filtersData from '../filters/FiltersData'
import { getDocumentCategoryLabel } from '../stay-displayer/health-data/documentLabels'
import { documentCategories, textualHealthDataCategories } from '../stay-displayer/health-data/documentLabels.js'
import { getAutomationExplanation } from './automationGroupCriteriaExplanation'

export default {
  components: {
    'sancare-octicon': SancareOcticon,
  },
  props: {
    automationGroup: { type: Object, required: true },
    stay: { type: Object, required: true }
  },
  data() {
    return {
      criteriaData: filtersData,
      textualReportCategories: { ...documentCategories, ...textualHealthDataCategories },
    }
  },
  computed: {
    ...mapState({
      pmsiCountry: (state) => state.settings.pmsi.country,
    }),
    waitingForAnesthesia() {
      const hasAnesthesiaActActivity = _.includes(
        _.forEach(
          _.map(this.stay.rums, 'codedMedicalActs'), (codedActs) => {
            _.forEach(codedActs, (act) => {
              _.map(act.associatedAct, 'activity')
            })
          }
        ),
        4
      )

      let hasAnesthesiaActReference = false
      _.forEach(
        _.map(this.stay.rums, 'codedMedicalActs'), (codedActs) => {
          _.forEach(codedActs, (act) => {
            if (act.associatedAct.reference.match('ZZLP')) {
              hasAnesthesiaActReference = true
            }
          })
        }
      )

      return this.automationGroup.waitForAnesthesia && this.stay.automationModeGhm.reference.match('K')  && !hasAnesthesiaActActivity && !hasAnesthesiaActReference
    },
    waitingForAnapath() {
      const hasAnapathAct = _.includes(
        _.forEach(
          _.map(this.stay.rums, 'codedMedicalActs'), (codedActs) => {
            _.forEach(codedActs, (act) => {
              _.map(act.associatedAct, 'is_anapath')
            })
          }
        ),
        true
      )

      const hasAnapathReport = _.includes(
        _.map(this.stay.reports, 'documentTemplate'),
        'ANAPATH_REPORT'
      )

      return this.automationGroup.fulfillAnapathRequests && this.stay.hasAnapathRequest && !hasAnapathAct && !hasAnapathReport
    }
  },
  methods: {
    getCriteriaType(criteriaType) {
      switch (criteriaType) {
        case 'documentCategory':
          return 'Catégorie de document'
        case 'presentMedicalUnit':
          return 'Unité médicale présente'
        case 'absentMedicalUnit':
          return 'Unité médicale absente'
        case 'gender':
          return 'Sexe'
        case 'patientAge':
          return 'Age du patient'
        case 'stayDuration':
          return 'Durée de séjour'
        case 'admissionMode':
          return 'Mode d\'admission'
        case 'releaseMode':
          return 'Mode de sortie'
        case 'presentMedicalAct':
          return 'Acte CCAM présent'
        case 'absentMedicalAct':
          return 'Acte CCAM absent'
        case 'medicalActActivity':
          return 'Activité de l\'acte CCAM'
        case 'origin':
          return 'Provenance du patient'
        case 'destination':
          return 'Destination du patient'
        default:
          return criteriaType
      }
    },
    getAdmissionOrReleaseModeLabel(criteria, country) {
      return _.find(
        this.criteriaData[criteria.type]['options'], function(option) {
          return option.value === criteria.value && option.country === country
        }).label
    },
    getCriteriaValue(criteria) {

      switch (criteria.type) {
        case 'documentCategory':
          return getDocumentCategoryLabel(criteria.value)
        case 'admissionMode':
        case 'releaseMode':
        case 'origin':
        case 'destination':
          return this.getAdmissionOrReleaseModeLabel(criteria, this.pmsiCountry)
        case 'gender':
          return criteria.value === '1' ? 'Homme' : 'Femme'
        case 'patientAge':
          return `entre ${criteria.value.split('_')[0]} et ${criteria.value.split('_')[1]} ans`
        case 'stayDuration':
          return `de ${criteria.value.split('_')[0]} jours à ${criteria.value.split('_')[1]} jours`
        default:
          return criteria.value
      }
    },
    getAutomationMissingCriteriaExplanation(criteriaGroupId, criteria) {
      const explanation = getAutomationExplanation(this.stay, this.automationGroup)
      let response = 'x'
      if (!explanation.details[criteriaGroupId]) {
        return response
      }
      if (explanation.details[criteriaGroupId].match === true) {
        response = 'dash'
      }

      if (!explanation.details[criteriaGroupId].details[criteria.id]) {
        return response
      }

      if (explanation.details[criteriaGroupId].details[criteria.id].match === true) {
        response = 'check'
      }

      return response
    },
    displayableCriteriaList(criteriaList) {
      return  _.filter(criteriaList, (criteria) => (!criteria.type.startsWith('presentContent') && !criteria.type.startsWith('absentContent')))
    },
    hasTextualCriteria() {
      let hasTextualCriteria = false
      _.forEach(this.automationGroup.criteriaGroups, (criteriaGroup) => {
        _.forEach(criteriaGroup.criteriaList, (criteria) => {
          if (criteria.type.startsWith('presentContent') || criteria.type.startsWith('absentContent')) {
            hasTextualCriteria = true
          }
        })
      })

      return hasTextualCriteria
    }
  }
}

</script>

<template>
  <div class="py-3">
    <div class="stay-list-criteria card">
      <div class="card-body">
        <div class="text-center">
          <h2>Conditions de validation</h2>
          <h3>Nom du groupe : {{ automationGroup.name }}</h3>
          <h3>N° de RSS : {{ stay.rssId }} </h3>
        </div>
        <div
          v-for="(criteriaGroup, idx) in automationGroup.criteriaGroups"
          :key="criteriaGroup.id"
        >
          <div
            v-if="idx > 0 && displayableCriteriaList(criteriaGroup.criteriaList).length"
            class="m-2"
          >
            {{ automationGroup.searchOperators === 'andOr' ? 'ET' : 'OU' }}
          </div>
          <div
            v-if="displayableCriteriaList(criteriaGroup.criteriaList).length"
            class="stay-list-criteria card card-body"
          >
            <div
              v-for="(criteria, criteriaIdx) in displayableCriteriaList(criteriaGroup.criteriaList)"
              :key="criteria.id"
            >
              <div
                class="row align-items-start"
              >
                <div
                  class="col-2"
                >
                  <div
                    v-if="criteriaIdx > 0"
                  >
                    {{ automationGroup.searchOperators === 'andOr' ? 'OU' : 'ET' }}
                  </div>
                </div>
                <div
                  class="col"
                >
                  {{ getCriteriaType(criteria.type) }}
                </div>
                <div
                  class="col"
                >
                  {{ getCriteriaValue(criteria) }}
                </div>
                <div class="missing-criteria-check">
                  <sancare-octicon
                    :name="getAutomationMissingCriteriaExplanation(criteriaGroup.id, criteria)"
                    :width="20"
                    :height="18"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="waitingForAnesthesia"
        >
          <hr>
          <span>
            Un acte d'anesthésie est manquant
          </span>
        </div>
        <div
          v-if="waitingForAnapath"
        >
          <hr>
          <span>
            Une biopsie a été demandée, mais l'acte ou le compte-rendu correspondant est manquant
          </span>
        </div>
        <div
          v-if="stay.lastPredictionUpdate < stay.lastExternalPmsiUpdate"
        >
          <hr>
          <span
            class="m-2 p-2 badge-pill badge-secondary"
          >
            <sancare-octicon
              name="gear"
              :width="14"
              :height="14"
            />
            Prédictions en cours de mise à jour
          </span>
        </div>
        <div
          v-if="hasTextualCriteria()"
        >
          <hr>
          <span
            class="m-2 p-2 badge-pill"
          >
            <sancare-octicon
              name="pencil"
              :width="14"
              :height="14"
            />
            Le groupe d'automatisation contient des critères textuels pouvant bloquer l'automatisation
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
