<script>
import ChartButton from './ChartButton.vue'

export default {
  components: {
    'chart-button': ChartButton,
  },
  props: {
    selectedChart: { type: String, required: true },
    stats: { type : Object, required: true },
    chartInterval: { type: Number, required: true },
  },
  emits: ['set-chart']
}
</script>

<template>
  <div class="homepage-indicator align-items-center text-center">
    <chart-button
      :count="stats.countTodoGlobalStay"
      :max-count="stats.countTotalGlobalStay"
      :class="{ 'chart-button-selected': selectedChart === 'waitingStays' }"
      label="Dossiers à valider"
      bar-color="orange"
      class="col-4"
      @click="$emit('set-chart', 'waitingStays')"
    />
    <chart-button
      :count="stats.countTodoNewCodingTotalStay"
      :max-count="stats.countNewCodingTotalStay"
      :class="{ 'chart-button-selected': selectedChart === 'shortStays' }"
      label="Séjours en primo-codage"
      unit=""
      bar-color="green"
      class="col-4"
      @click="$emit('set-chart', 'shortStays')"
    />
    <chart-button
      :count="stats.countTodoOptimizableStay"
      :max-count="stats.countTotalOptimizableStay"
      :class="{ 'chart-button-selected': selectedChart === 'revaluableStays' }"
      label="Séjours optimisables"
      unit=""
      bar-color="yellow"
      class="col-4"
      @click="$emit('set-chart', 'revaluableStays')"
    />
  </div>
</template>
