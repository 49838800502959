<script>
import { capitalize } from '@sancare/ui-frontend-commons/src/misc/string-utils'
import Slider from 'vue-slider-component'

export default {
  components: {
    'vue-slider': Slider
  },
  props: {
    filterData: { type: Object, required: true },
  },
  emits: ['add-filter'],
  data() {
    const params = this.filterData.range
    const min = params.less ? `<${params.min}` : params.min
    const max = params.more ? `>${params.max}` : params.max

    return {
      target: 'global',
      severity: 'all',
      value: [min, max],
    }
  },
  computed: {
    rangeList() {
      const params = this.filterData.range
      const list = []
      let value = params.min

      if (params.less) {
        list.push(`<${params.min}`)
      }
      while (value <= params.max) {
        list.push(value)
        value += params.interval
      }
      if (params.more) {
        list.push(`>${params.max}`)
      }

      return (list)
    },
    rangeFormat() {
      return this.filterData.range.unit ? `{value}${this.filterData.range.unit}` : '{value}'
    }
  },
  watch: {
    target() {
      const params = this.filterData.range
      const min = params.less ? `<${params.min}` : params.min
      const max = params.more ? `>${params.max}` : params.max

      this.resetRange(min, max)
    }
  },
  methods: {
    setRange() {
      const name = this.filterData.name
      const params = this.filterData.range
      const type = `confidence${capitalize(this.target)}`
      const min = params.less ? `<${params.min}` : params.min
      const max = params.more ? `>${params.max}` : params.max
      let value = this.value

      if (value.length && (value[0] !== min || value[1] !== max)) {
        if (this.target === 'associated') {
          value = `${this.severity}_${value[0]}_${value[1]}`
        } else {
          value = `${value[0]}_${value[1]}`
        }
        this.$emit('add-filter', { name, type, value })
      }
    },
    resetRange(min, max) {
      this.value = [min, max]
    }
  },
}
</script>

<template>
  <div class="row">
    <div class="col-auto">
      <select
        v-model="target"
        class="form-control"
      >
        <option value="global">
          Global
        </option>
        <option value="principal">
          DP
        </option>
        <option value="associated">
          DAS
        </option>
      </select>
    </div>
    <div
      v-if="target !== 'associated'"
      class="col row align-items-center"
    >
      <div class="col-5">
        <vue-slider
          :key="filterData.name"
          v-model="value"
          :v-data="rangeList"
          :formatter="rangeFormat"
          :tooltip="'always'"
          range
        />
      </div>
      <div class="col-auto">
        <button
          class="btn btn-primary"
          type="button"
          @click="setRange"
        >
          Ok
        </button>
      </div>
    </div>
    <div
      v-else
      class="col row align-items-center"
    >
      <div class="col-auto">
        <select
          v-model="severity"
          class="form-control"
        >
          <option value="all">
            Toutes sévérités
          </option>
          <option value="max">
            Sévérités supérieures
          </option>
          <option value="1">
            Sévérités 1
          </option>
          <option value="2">
            Sévérités 2
          </option>
          <option value="3">
            Sévérités 3
          </option>
          <option value="4">
            Sévérités 4
          </option>
        </select>
      </div>
      <div class="col-5">
        <vue-slider
          v-model="value"
          :v-data="rangeList"
          :formatter="rangeFormat"
          range
        />
      </div>
      <div class="col-auto">
        <button
          class="col-auto btn btn-primary"
          type="button"
          @click="setRange"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
</template>
