import { HealthDataType } from '@sancare/ui-frontend-commons/src/types/health-data'

import { Report, TextualHealthEntry } from '@/stay-displayer/health-data/types'

export enum FalseJustificationType {
  ANT = 'antecedent',
  NEG = 'negation',
  DBT = 'doubt',
  REC = 'recurrence',
}

export enum FalseJustificationOriginType {
  BOTH = 'both',
  PREDICTED_LABEL = 'predictedLabel',
  SEARCH_CRITERIA = 'searchCriteria',
}

export type FalseJustification = {
  id: number
  type: FalseJustificationType
  hintStart: number
  hintEnd: number
  originType: FalseJustificationOriginType
  documentType: HealthDataType
  report: Report | null
  textualHealthEntry: TextualHealthEntry | null
}
