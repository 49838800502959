<script>
import { SancareCheckbox } from '@sancare/ui-frontend-commons'
import numeral from 'numeral'

import { PmsiTypeEnum } from '@/store/modules/settings/types'

const HOME_THUMBNAIL_MAX_LABEL_LENGTH = 64
const HOME_THUMBNAIL_MAX_COLOR = 9
const HOME_THUMBNAIL_DEFAULT_COLOR = 0

export default {
  name: 'HomeThumbnail',
  components: {
    'sancare-checkbox': SancareCheckbox,
  },
  props: {
    name: { type: String, default: '' },
    canSelect: { type: Boolean, default: false },
    canEdit: { type: Boolean, default: false },
    canRemove: { type: Boolean, default: false },

    count: { type: [String, Number], default: 0 },
    label: { type: String, default: null },
    footer: { type: String, default: '' },

    color: { type: [String, Number], default: 'uniform' },
    selected: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    stayListQuery: { type: Object, default: null },
    pmsiType: {
      validator(value) {
        return Object.values(PmsiTypeEnum).includes(value)
      },
      default: PmsiTypeEnum.MCO
    },
  },
  emits: ['select'],
  computed: {
    headerColor() {
      if (this.disabled) {
        return 'disabled'
      } else if (typeof(this.color) === 'string') {
        return this.color
      } else if (this.color === -1) {
        return HOME_THUMBNAIL_DEFAULT_COLOR
      } else {
        return this.color % HOME_THUMBNAIL_MAX_COLOR
      }
    },
    displayedCount() {
      if (typeof(this.count) === 'string') {
        return this.count
      } else {
        return numeral(this.count).format(this.count >= 1000 ? '0.0a' : '0a').toUpperCase()
      }
    },
    displayedLabel() {
      if (this.label.length > HOME_THUMBNAIL_MAX_LABEL_LENGTH) {
        return this.label.substr(0, HOME_THUMBNAIL_MAX_LABEL_LENGTH)
      } else {
        return this.label
      }
    }
  },
  methods: {
    select() {
      if (!this.disabled) {
        this.$emit('select')
      }
    },
    consultStayList() {
      this.$store.commit('resetStoredCriteria')

      if (this.stayListQuery) {
        const newRoute = {
          name: 'staylist',
          query: this.stayListQuery,
          params: { pmsiType: this.pmsiType },
        }

        this.$router.push(newRoute)
      }
    }
  }
}
</script>

<template>
  <div :class="`home-thumbnail home-thumbnail-color-${headerColor}`">
    <div
      class="row no-gutters align-items-center home-thumbnail-header"
      :name="`${name}_header`"
      @click="consultStayList()"
    >
      <div class="col-auto px-2 text-center home-thumbnail-count">
        {{ displayedCount }}
      </div>
      <div
        v-if="label"
        :title="label"
        class="col px-2 text-right home-thumbnail-label"
      >
        {{ displayedLabel }}
      </div>
      <slot name="info" />
    </div>
    <div class="row no-gutters align-items-center home-thumbnail-footer">
      <div class="col-2 mt-2 ml-1">
        <sancare-checkbox
          v-if="canSelect"
          :checked="selected"
          @change="select"
        />
      </div>
      <div class="col">
        {{ footer }}
      </div>
    </div>
  </div>
</template>
