<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'

export default {
  components: {
    'sancare-octicon': SancareOcticon,
  },
  props: {
    filter: { type: Object, required: true },
    canEdit: { type: Boolean, default: false },
  },
  emits: ['remove-criteria'],
  computed: {
    displayedContent() {
      return this.filter.content
    }
  }
}

</script>

<template>
  <div>
    <span
      v-if="filter.modifiers.includeAntecedents"
      v-tooltip="{content: 'Recherche également dans les antécédents', offset: [5, 5], placement: 'bottom'}"
    >
      <sancare-octicon
        name="eye"
        :width="14"
        :height="11"
      />
    </span>
    <span
      v-if="filter.modifiers.includeFamilyAntecedents"
      v-tooltip="{content: 'Recherche également dans les antécédents familiaux', offset: [5, 5], placement: 'bottom'}"
    >
      <sancare-octicon
        name="organization"
        :width="14"
        :height="11"
      />
    </span>
    <span v-if="filter.modifiers.wordDistance">
      [Intervalle: {{ filter.modifiers.wordDistance }}]
    </span>
    <span>{{ displayedContent }}</span>
    <span
      v-if="canEdit"
      class="criteria-remove"
      @click="$emit('remove-criteria', filter)"
    >
      <sancare-octicon
        name="x"
        :width="14"
        :height="11"
      />
    </span>
  </div>
</template>
