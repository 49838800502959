<script>
import { SancareModal, SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { mapState } from 'vuex'

export default {
  components: {
    'sancare-modal': SancareModal,
    'sancare-octicon': SancareOcticon,
  },
  props: {
    mode: { type: String, required: true },
    userColumns: { type: Array, required: true },
    selectableColumns: { type: Object, required: true }
  },
  emits: ['set-columns'],
  data() {
    return {
      showModal: false,
      enabledColumns: [],
    }
  },
  computed: {
    ...mapState({
      pmsiCountry: (state) => state.settings.pmsi.country,
    }),
    displayedSelectableColumnList: function() {
      const columnList = {}

      _.forEach(this.selectableColumns, (columnInfo, columnName) => {
        columnList[columnName] = columnInfo
      })

      return columnList
    },
  },
  watch: {
    showModal(showModal) {
      const target = document.getElementById('column-settings')
      if (!showModal || typeof target === 'undefined') {
        return
      }
      const observer = new MutationObserver(() => {
        const modalSettings = document.getElementById('column-modal-settings')
        if (!modalSettings) {
          return
        }
        modalSettings.scrollIntoView({ behavior: 'smooth' })
        observer.disconnect()
      })
      observer.observe(
        document.getElementById('column-settings'),
        { attributes: true, childList: true }
      )
    }
  },
  methods: {
    closeModal() {
      this.showModal = false
    },
    openModal() {
      this.showModal = true
      this.enabledColumns = [...this.userColumns]
    },
    isChecked(column){
      return this.enabledColumns.indexOf(column) !== -1
    },
    toggleColumn(column){
      if (this.isChecked(column)) {
        this.enabledColumns = [..._.filter(this.enabledColumns, (col) => col !== column)]
      } else {
        this.enabledColumns.push(column)
      }
    },
    setColumns() {
      this.$emit('set-columns', this.enabledColumns)
      this.closeModal()
    },
  }
}
</script>

<template>
  <div>
    <div @click="openModal">
      <sancare-octicon
        name="gear"
        :width="15"
        :height="15"
      />
    </div>
    <sancare-modal
      :open="showModal"
      :no-padding="true"
      @close-modal="closeModal"
    >
      <template #header>
        <div
          id="column-modal-settings"
        >
          <span class="row text-left">
            <span class="sancare-modal-title col">Paramètres de la liste de séjours</span>
            <span
              class="sancare-modal-close col-auto"
              @click="closeModal"
            >
              <sancare-octicon
                name="x"
                :width="20"
                :height="20"
              />
            </span>
          </span>
        </div>
      </template>
      <div class="text-left px-1 mt-2 py-1">
        <h2 class="row sancare-modal-subtitle mx-1 mb-4">
          Colonnes affichées
        </h2>
        <div class="row mx-1">
          <div
            v-for="(columnInfo, columnName) in displayedSelectableColumnList"
            :key="'enable_'+columnName+'_column'"
            class="row col-6 mx-0 px-0 my-1 align-items-center stay-list-settings-line"
            @click="toggleColumn(columnName)"
          >
            <input
              :checked="isChecked(columnName)"
              type="checkbox"
              class="col-auto mx-1 my-0"
            >
            <label class="col my-0 stay-list-settings-label">
              {{ columnInfo.name ?? (columnInfo.isTranslated ? $t(columnInfo.label) : columnInfo.label) }}
            </label>
          </div>
        </div>
        <div class="row mx-1 py-1 text-right">
          <span class="col" />
          <span class="col-auto">
            <button
              class="btn btn-push btn-primary btn-padded"
              @click="setColumns()"
            >
              Valider
            </button>
          </span>
        </div>
      </div>
    </sancare-modal>
  </div>
</template>
