<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'

import PredictedDiagnosis from '../PredictedDiagnosis.vue'

export default {
  components: {
    'predicted-diagnosis': PredictedDiagnosis,
    'sancare-octicon': SancareOcticon,
  },
  props: {
    appSettings: { type: Object, required: true },
    type: { type: String, required: true },
    rumId: { type: Number, required: true },
    automationModeDiagnosisGroup: { type: Object, required: true },
    healthData: { type: Object, required: true },
    selection: { type: Object, default: null },
    isLoading: { type: Boolean, default: false },
    displayedRumIdx: { type: Number, required: true },
    rumPredictedLabels: { type: Array, default: null },
  },
  emits: ['make-selection'],
  computed: {
    predictedDiagnoses() {
      return this.rumPredictedLabels.filter((dDiag) => dDiag.type === this.type)
    },
    currentDiagnosis() {
      if (!this.automationModeDiagnosisGroup[`current${this.type}`]) {
        return null
      }
      const currentReference = this.automationModeDiagnosisGroup[`current${this.type}`].reference
      const prediction = _.find(this.predictedDiagnoses, (dDiag) => dDiag.diagnosis.reference === currentReference)

      return {
        ...prediction,
        canShowRational: _.some(prediction.hints, (h) => h.confidence >= this.appSettings['standard_mode_hint_threshold']),
      }
    },
    displayedDiagnoses() {
      let currentReference = null
      if (this.automationModeDiagnosisGroup[`current${this.type}`]) {
        currentReference = this.automationModeDiagnosisGroup[`current${this.type}`].reference
      }

      return _.map(
        _.filter(
          _.orderBy(this.predictedDiagnoses, ['confidence', 'reference'], ['desc', 'asc']),
          (dDiag) => dDiag.diagnosis.reference !== currentReference
        ),
        (dDiag) => ({
          ...dDiag,
          canShowRational: _.some(dDiag.hints, (h) => h.confidence >= this.appSettings['standard_mode_hint_threshold']),
        })
      )
    },
  },
  methods: {
    makeSelection(selection) {
      this.$emit('make-selection', selection)
    },
  }
}
</script>

<template>
  <div :key="rumId">
    <div class="row no-gutters diagnosis-list-container">
      <div
        v-if="!currentDiagnosis"
        key="no-diagnosis"
        class="col-12 card diagnosis-card"
      >
        <div class="card-body">
          <h1 class="card-title">
            {{ type }}
          </h1>
        </div>
        <div class="card-body">
          Pas de {{ type }}
        </div>
      </div>
      <h1
        v-if="!currentDiagnosis"
        key="other-diagnoses-title"
        class="col-12 other-diagnoses-title flex-priority-1"
      >
        {{ predictedDiagnoses.length ? 'Taux de confiance des prédictions insuffisants' : 'Aucune prédiction' }}
      </h1>
      <div
        v-if="currentDiagnosis"
        class="col-12"
      >
        <predicted-diagnosis
          :can-show-rational="currentDiagnosis.canShowRational"
          :predicted-diagnosis="currentDiagnosis"
          :health-data="healthData"
          :is-loading="isLoading"
          :selected="selection != null && selection.diagType === type && selection.diagReference === currentDiagnosis.diagnosis.reference"
          :selection="selection"
          :displayed-rum-idx="displayedRumIdx"
          :rum-predicted-labels="rumPredictedLabels"
          class="card diagnosis-card"
          @make-selection="makeSelection"
        >
          <div class="card-body">
            <h1 class="card-title">
              {{ type }}
            </h1>
          </div>
          <div class="card-body diagnosis-title">
            <div class="row no-gutters align-items-center">
              <h2
                :title="currentDiagnosis.diagnosis.description"
                class="col mr-1"
              >
                {{ currentDiagnosis.diagnosis.reference }}
              </h2>
              <div class="col-1">
                <div
                  v-if="currentDiagnosis.canShowRational"
                  :class="{ selected: selection != null && selection.diagType === type && selection.diagReference === currentDiagnosis.diagnosis.reference }"
                  class="btn-select-diagnosis icon-block"
                  @click.stop="makeSelection({ diagType: type, diagReference: currentDiagnosis.diagnosis.reference, savedSearchIds: [] })"
                >
                  <sancare-octicon
                    name="note"
                    :width="18"
                    :height="18"
                  />
                </div>
              </div>
            </div>
            <div>
              <p>
                {{ currentDiagnosis.diagnosis.description }}
              </p>
            </div>
          </div>
        </predicted-diagnosis>
      </div>
      <div
        v-for="(predictedDiagnosis, idx) in displayedDiagnoses"
        :key="`${rumId}-${predictedDiagnosis.diagnosis.reference}`"
        class="col-6 mb-2"
        :class="{
          'pl-1': idx % 2,
          'pr-1': (idx - 1) % 2,
        }"
      >
        <predicted-diagnosis
          :can-show-rational="predictedDiagnosis.canShowRational"
          :predicted-diagnosis="predictedDiagnosis"
          :health-data="healthData"
          :is-loading="isLoading"
          :selected="selection != null && selection.diagType === type && selection.diagReference === predictedDiagnosis.diagnosis.reference"
          :displayed-rum-idx="displayedRumIdx"
          :rum-predicted-labels="rumPredictedLabels"
          class="card diagnosis-card small"
          @make-selection="makeSelection"
        >
          <div
            class="card-body diagnosis-title"
          >
            <div class="row no-gutters align-items-center">
              <h2
                :title="predictedDiagnosis.diagnosis.description"
                class="col mr-1"
              >
                {{ predictedDiagnosis.diagnosis.reference }}
              </h2>
              <div class="col-1">
                <div
                  v-if="predictedDiagnosis.canShowRational"
                  :class="{ selected: selection != null && selection.diagType === type && selection.diagReference === predictedDiagnosis.diagnosis.reference }"
                  class="btn-select-diagnosis icon-block"
                  @click.stop="makeSelection({ diagType: type, diagReference: predictedDiagnosis.diagnosis.reference, savedSearchIds: [] })"
                >
                  <sancare-octicon
                    name="note"
                    :width="18"
                    :height="18"
                  />
                </div>
              </div>
            </div>
          </div>
        </predicted-diagnosis>
      </div>
    </div>
  </div>
</template>
