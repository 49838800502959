<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import lodashFp from 'lodash/fp'
import { mapGetters, mapState } from 'vuex'

import { roleLabels } from '@/misc/constants'
import { hasPermission, RoleEnum } from '@/misc/permissions/permissionsHelper'

import UserAssignationModal from './UserAssignationModal.vue'
import UserEditModal from './UserEditModal.vue'

export default {
  components: {
    'user-edit-modal': UserEditModal,
    'user-assignation-modal': UserAssignationModal,
    'sancare-octicon': SancareOcticon,
  },
  data() {
    return {
      showEditor: false,
      showAssignation: false,
      showDeleteConfirm: false,
      userIdToDelete: null,
      editedUser: null,
      roleLabels,
      RoleEnum,
    }
  },
  computed: {
    ...mapState({
      userList: (state) => _.sortBy(state.admin.userList, (u) => u.id),
      teamList: (state) => {
        const teamList = _.flow([
          lodashFp.map((u) => u.team ? u.team : null),
          lodashFp.uniqBy('id'),
          _.compact, // remove false values
        ])(state.admin.userList)

        return _.orderBy(teamList, ['name'], ['asc'])
      },
      currentUserId: (state) => state.settings.currentUser.id,
      editableRoles: (state) => state.settings.currentUser.editableRoles,
      createUserOk: (state) => state.admin.createUserRequest.ok,
      updateUserOk: (state) => state.admin.updateUserRequest.ok,
      deleteUserOk: (state) => state.admin.deleteUserRequest.ok,
      enableUserOk: (state) => state.admin.enableUserRequest.ok,
    }),
    ...mapGetters(['userRole']),
  },
  watch: {
    createUserOk(ok) {
      if (ok) {
        this.closeEditor()
      }
    },
    updateUserOk(ok) {
      if (ok) {
        if (this.editedUser.id === this.currentUserId) {
          // reload self when updated
          this.$store.dispatch('getSelf')
        }
        this.closeEditor()
      }
    },
    deleteUserOk(ok) {
      if (ok) {
        this.userIdToDelete = null
        this.refresh()
      }
    },
    enableUserOk(ok) {
      if (!ok) {
        return
      }

      this.refresh()
    },
    userRole() {
      this.refresh()
    }
  },
  mounted() {
    this.refresh()
  },
  methods: {
    hasPermission,
    refresh() {
      this.$store.dispatch('getUserList')
    },
    openEditor(editedUser = null) {
      this.showEditor = true
      this.editedUser = editedUser
    },
    closeEditor() {
      this.showEditor = false
      if(this.editedUser) {
        this.$store.commit('resetUpdateUserRequest')
        this.editedUser = null
      } else {
        this.$store.commit('resetCreateUserRequest')
      }
    },
    openAssignation(editedUser) {
      this.showAssignation = true
      this.editedUser = editedUser
    },
    closeAssignation() {
      this.showAssignation = false
    },
    handleUserModal(payload) {
      this.$store.dispatch(this.editedUser ? 'updateUser' : 'createUser', payload)
    },
    deleteUser() {
      this.$store.dispatch('deleteUser', this.userIdToDelete)
    },
    enableUser(userId) {
      this.$store.dispatch('enableUser', userId)
    },
    canEditUser(user) {
      if (hasPermission(RoleEnum.ROLE_USER_MANAGER, this.userRole) && user.disabled === false && this.editableRoles.includes(user.role)) {
        return true
      }

      return false
    },
  },
}
</script>

<template>
  <div>
    <div class="settings-table">
      <div class="settings-table__header-row row">
        <div class="col-6 settings-table__header-cell">
          <h2>Utilisateurs</h2>
        </div>
      </div>
      <div class="settings-table__header-row row">
        <div class="col-2 settings-table__header-cell">
          Identifiant
        </div>
        <div class="col-3 settings-table__header-cell">
          Nom - Prénom
        </div>
        <div class="col-3 settings-table__header-cell">
          Email
        </div>
        <div class="col-2 settings-table__header-cell">
          Privilèges
        </div>
        <!-- TODO: Define and implement teams
          <div class="col-x settings-table__cell">Equipe</div>
        -->
        <div class="col-2 settings-table__header-cell" />
      </div>
      <div
        v-for="user in userList"
        :key="user.username"
        class="settings-table__data-row row"
        :class="{ disabled: user.disabled }"
      >
        <div class="col-2 settings-table__data-cell">
          {{ user.username }}
        </div>
        <div class="col-3 settings-table__data-cell">
          {{ user.lastName }} {{ user.firstName }}
        </div>
        <div class="col-3 settings-table__data-cell">
          {{ user.email }}
        </div>
        <div class="col-2 settings-table__data-cell">
          {{ roleLabels[user.role] }}
        </div>
        <!-- TODO: Define and implement teams
        <div class="col-x settings-table__data-cell">{{ user.team ? user.team.name : '' }}</div>
        -->
        <div class="col-2 settings-table__data-cell">
          <div
            v-if="canEditUser(user)"
            class="row no-gutters"
          >
            <div class="col-auto">
              <button
                v-tooltip="'Modifier l\'utilisateur'"
                class="btn btn-link"
                @click="openEditor(user)"
              >
                <sancare-octicon
                  name="pencil"
                  :width="15"
                  :height="15"
                />
              </button>
            </div>
            <div class="col-auto">
              <button
                v-tooltip="'Assignations FINESS'"
                class="btn btn-link"
                @click="openAssignation(user)"
              >
                <sancare-octicon
                  name="organization"
                  :width="15"
                  :height="15"
                />
              </button>
            </div>
            <v-dropdown
              v-if="user.id !== currentUserId"
              :shown="userIdToDelete === user.id"
              placement="right"
              class="col-auto"
              @hide="userIdToDelete = null"
            >
              <button
                v-tooltip="'Supprimer l\'utilisateur'"
                class="btn btn-link"
                @click="userIdToDelete = user.id"
              >
                <sancare-octicon
                  name="trash"
                  :width="15"
                  :height="15"
                />
              </button>
              <template #popper>
                <h4 class="title-light text-center">
                  Êtes-vous sûr ?
                </h4>
                <div class="text-center">
                  <button
                    class="btn btn-danger btn-padded"
                    @click="deleteUser()"
                  >
                    Supprimer
                  </button>
                </div>
              </template>
            </v-dropdown>
          </div>
          <div
            v-if="hasPermission(RoleEnum.ROLE_ADMIN_MANAGER, userRole)
              && hasPermission(RoleEnum.ROLE_SYSTEM_USER_MANAGER, userRole)
              && user.disabled === true"
            class="row no-gutters"
          >
            <button
              v-tooltip="'Activer l\'utilisateur'"
              class="btn btn-link"
              @click="enableUser(user.id)"
            >
              <sancare-octicon
                name="zap"
                :width="15"
                :height="15"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="settings-table__data-row row no-gutters">
      <div class="col mr-2 text-right">
        <button
          class="btn btn-push btn-primary btn-padded"
          @click="openEditor()"
        >
          Nouvel Utilisateur
        </button>
      </div>
    </div>
    <user-edit-modal
      :show-editor="showEditor"
      :edited-user="editedUser"
      @submit-form="handleUserModal"
      @close-modal="closeEditor"
    />
    <user-assignation-modal
      v-if="editedUser !== null"
      :show-modal="showAssignation"
      :user-id="editedUser.id"
      @close-modal="closeAssignation"
    />
  </div>
</template>
<style>
.settings-table div.disabled {
  opacity: 0.6;
}
</style>
