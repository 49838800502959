<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'

export default {
  components: {
    'sancare-octicon': SancareOcticon,
  },
  props: {
    isRevalo: { type: Boolean, default: true },
  }
}
</script>


<template>
  <svg
    v-if="isRevalo"
    width="18"
    height="18"
  >
    <image
      xlink:href="@/assets/icons/revalo-icon.svg"
      width="18"
      height="18"
    />    
  </svg>
  <sancare-octicon
    v-else
    name="search"
    :width="18"
    :height="18"
  />
</template>
