<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { mapState } from 'vuex'

import PredictionIcon from '@/common/PredictionIcon.vue'

import { CountryEnum, ModeEnum } from '../../../store/modules/settings/types'
import { extractChunksFromSuggestedDiagnosis, extractIndicesFromChunkObject } from '../diagnosis_justifications.js'
import JustificationSummary from '../JustificationSummary.vue'
import ManualDiagnosisButton from '../ManualDiagnosisButton'
import SuggestedDiagnosis from '../SuggestedDiagnosis.vue'
import SvgLink from '../SvgLink.vue'
import { computeSvgLink } from '../svgLinkCalculator.js'
import SvgSearchIcon from '../SvgSearchIcon.vue'

export default {
  components: {
    'suggested-diagnosis': SuggestedDiagnosis,
    'manual-diagnosis-button': ManualDiagnosisButton,
    'justification-summary': JustificationSummary,
    'svg-link': SvgLink,
    'sancare-octicon': SancareOcticon,
    'svg-search-icon': SvgSearchIcon,
    'prediction-icon': PredictionIcon,
  },
  props: {
    rumId: { type: Number, required: true },
    type: { type: String, required: true },
    suggestedDiagnoses: { type: Array, required: true },
    standardModeDiagnosisGroup: { type: Object, required: true },
    healthData: { type: Object, required: true },
    selection: { type: Object, default: null },
    isLoading: { type: Boolean, default: false },
    appSettings: { type: Object, required: true },
    displayedRumIdx: { type: Number, required: true },
    rumPredictedLabels: { type: Array, default: null },
    savedSearchIntersectionChunks: { type: Array, default: null },
    mode: { type: String, required: true }
  },
  emits: ['make-selection'],
  data() {
    return {
      width: 0,
      height: 0,
      startHeight: 0,
      endHeight: 0,
      updateSize: null,
      showMore: false,
      extendedSuggestions: [],
      showJustificationSummary: false,
      processingDiagnoses: [],
      CountryEnum,
    }
  },
  computed: {
    ...mapState({
      pmsiCountry: (state) => state.settings.pmsi.country,
    }),
    firstDiagnosis() {
      if (this.standardModeDiagnosisGroup[`first${this.type}`]) {
        return this.standardModeDiagnosisGroup[`first${this.type}`]
      }
      return null
    },
    currentDiagnosis() {
      if (this.standardModeDiagnosisGroup[`current${this.type}`]) {
        return {
          ...this.standardModeDiagnosisGroup[`current${this.type}`],
          origin: this.standardModeDiagnosisGroup[`current${this.type}Origin`],
        }
      }
      return null
    },
    displayedSuggestedDiagnoses() {
      const filteredSuggestedDiagnoses = _.filter(this.suggestedDiagnoses, (suggestion) => suggestion.isOverThreshold || suggestion.searches.length || this.showMore || suggestion.isAlwaysVisible)
      return _.orderBy(filteredSuggestedDiagnoses, ['isInitialDiag', 'searches.length', 'level', 'predictions.confidence'], ['desc', 'desc', 'desc', 'desc'])
    },
    svgLinks() {
      const svgLinks = []
      svgLinks.push(computeSvgLink(this.startHeight, this.endHeight, this.width))

      return svgLinks
    },
    codedSuggestion() {
      const currentDiagnosis = this.currentDiagnosis
      if(currentDiagnosis){
        const suggestion =  _.find(this.suggestedDiagnoses, (suggestion) => {
          if(!suggestion.isWildcard){
            return suggestion.reference === currentDiagnosis.reference
          }
          return _.some(suggestion.diagnoses, (diag) => diag.reference === currentDiagnosis.reference)
        })
        return suggestion || null
      }
      return null
    },
    suggestedDiagnosis() {
      const suggestion = this.codedSuggestion
      if(suggestion){
        return suggestion.diagnoses[0]
      }
      return null
    },
    chunks() {
      return extractChunksFromSuggestedDiagnosis(this.suggestedDiagnosis, this.savedSearchIntersectionChunks)
    },
  },
  mounted() {
    this.updateSize = () => {
      this.$nextTick(() => {
        const predictedDiagnosesRef = this.type === 'DP' ? this.$refs.predictedDPDiagnoses : this.$refs.predictedDRDiagnoses
        const manualDiagnosisRef = this.type === 'DP' ? this.$refs.manualDPDiagnosis : this.$refs.manualDRDiagnosis
        if (!predictedDiagnosesRef || !manualDiagnosisRef) {
          return
        }

        this.width = this.$el.offsetWidth - predictedDiagnosesRef.offsetWidth - manualDiagnosisRef.offsetWidth
        this.height = Math.max(predictedDiagnosesRef.offsetHeight, manualDiagnosisRef.offsetHeight)

        const elementRect = this.$el.getBoundingClientRect()

        if (this.hasMatch()) {
          const predictedTitles = this.type === 'DP' ? this.$refs.predictedDPTitle : this.$refs.predictedDRTitle
          const endRect = _.find(predictedTitles, (r) => r.dataset.reference === this.firstDiagnosis.reference).getBoundingClientRect()
          this.endHeight = endRect.top - elementRect.top + (endRect.bottom - endRect.top) / 2
        }

        const initialTitle = this.type === 'DP' ? this.$refs.initialDPTitle : this.$refs.initialDRTitle
        if (initialTitle) {
          const startRect = initialTitle.getBoundingClientRect()
          this.startHeight = startRect.top - elementRect.top + (startRect.bottom - startRect.top) / 2
        }
      })
    }

    window.addEventListener('resize', this.updateSize)
    this.updateSize()
  },
  updated() {
    this.updateSize()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateSize)
  },
  methods: {
    hasVisibleSuggestion() {
      return _.some(this.suggestedDiagnoses, (suggestion) => suggestion.isAlwaysVisible || (!suggestion.isAlwaysHidden && this.showMore))
    },
    hasHiddenSuggestion() {
      return _.some(this.suggestedDiagnoses, (suggestion) => !suggestion.isAlwaysVisible && !suggestion.isAlwaysHidden)
    },
    async addDiagnosis(suggestedDiagnosis) {
      if (this.processingDiagnoses.indexOf(suggestedDiagnosis.reference) !== -1) {
        return
      }
      this.processingDiagnoses.push(suggestedDiagnosis.reference)
      await this.$store.dispatch('addDiagnosis', {
        rumId: this.rumId,
        diagnosisType: this.type,
        reference: suggestedDiagnosis.reference,
        justifications: extractIndicesFromChunkObject(extractChunksFromSuggestedDiagnosis(suggestedDiagnosis, this.savedSearchIntersectionChunks)),
        mode: this.mode
      })
      _.remove(this.processingDiagnoses, (diag) => diag === suggestedDiagnosis.reference)
    },
    removeCurrentDiagnosis() {
      if (this.type === 'DP') {
        this.$store.dispatch('removeDP', { rumId: this.rumId, mode: this.mode })
      } else if (this.type === 'DR') {
        this.$store.dispatch('removeDR', { rumId: this.rumId, mode: this.mode })
      }
    },
    makeSelection(selection) {
      this.$emit('make-selection', selection)
    },
    switchToAssociatedDiagnosis(diagnosis) {
      this.$store.dispatch('switchDiagnosis', {
        rumId: this.rumId,
        origin: {
          diagnosisType: this.type,
          diagnosisReference: diagnosis.reference,
        },
        destination: 'DAS',
        mode: this.mode
      })
    },
    getSearchesIds(diagnosis) {
      return _.map(diagnosis.staySavedSearches, (ss) => ss.search.id)
    },
    stringifySuggestionSearches(suggestion, sep, cut = false) {
      const content = _.map([...suggestion.staySavedSearches], (savedSearch) => savedSearch.search.name).join(sep)
      if (cut) {
        return content.substring(0, 20)
      }
      return content
    },
    isSelectedSuggestion(suggestion) {
      return Boolean(
        !_.isNull(this.selection)
        && this.selection.labelType === this.type
        && this.selection.labelReference === suggestion.reference
        && _.isEqual(this.selection.savedSearchIds, _.map(suggestion.staySavedSearches, 'search.id').sort((a, b) => a - b))
      )
    },
    isExtendedSuggestion(suggestion) {
      return this.extendedSuggestions.indexOf(suggestion.reference) !== -1
    },
    toggleSuggestionDiagnoses(suggestion) {
      if (suggestion.isWildcard) {
        if (this.isExtendedSuggestion(suggestion)) {
          this.extendedSuggestions = _.filter(this.extendedSuggestions, (ref) => ref !== suggestion.reference)
        } else {
          this.extendedSuggestions.push(suggestion.reference)
        }
      }
    },
    readOnly() {
      return this.rumId === 0
    },
    hasMatch() {
      const refFirstDiag = this.standardModeDiagnosisGroup[`first${this.type}`] ? this.standardModeDiagnosisGroup[`first${this.type}`].reference : null
      const refCurrentDiag = this.standardModeDiagnosisGroup[`current${this.type}`] ? this.standardModeDiagnosisGroup[`current${this.type}`].reference : null

      const predictedTitles = this.type === 'DP' ? this.$refs.predictedDPTitle : this.$refs.predictedDRTitle

      if (refFirstDiag === null || refCurrentDiag !== refFirstDiag || !predictedTitles) {
        return false
      }

      return _.some(this.suggestedDiagnoses, (suggestedDiag) => refFirstDiag.match(suggestedDiag.reference)) && _.some(predictedTitles, (r) => r.dataset.reference === this.firstDiagnosis.reference)
    },
    hasRevaloSearch(suggestion) {
      return _.some(suggestion.searches, (search) => search.search.type === ModeEnum.OPTIMIZABLE)
    },
    handleHealthDataSelection(mainContent) {
      this.$store.commit('shouldScroll', true)
      this.$store.commit('setMainContent', mainContent)
      if (!this.selected) {
        this.$emit('make-selection', {
          labelType: this.type,
          labelReference: this.suggestedDiagnosis.reference,
          savedSearchIds: _.map(this.suggestedDiagnosis.staySavedSearches, (ss) =>ss.search.id),
        })
      }
      this.showJustificationSummary = !this.showJustificationSummary
    }
  },
}
</script>

<template>
  <div class="row no-gutters align-items-start">
    <div class="col-5">
      <div
        :ref="type === 'DP' ? 'manualDPDiagnosis' : 'manualDRDiagnosis'"
        class="card diagnosis-card highlighted"
      >
        <div class="card-body">
          <h1 class="card-title">
            {{ $t('diagnosis.'+type) }} <small>- Codage en cours</small>
          </h1>
        </div>
        <div
          v-if="firstDiagnosis && currentDiagnosis && currentDiagnosis.reference === firstDiagnosis.reference"
          class="card-body"
        >
          <div class="row no-gutters align-items-center">
            <div
              :ref="type === 'DP' ? 'initialDPTitle' : 'initialDRTitle'"
              :data-reference="firstDiagnosis.reference"
              class="col"
            >
              <h2 :title="firstDiagnosis.description">
                {{ firstDiagnosis.reference }}
              </h2>
              <div class="row no-gutters">
                {{ firstDiagnosis.description }}
              </div>
              <div
                v-if="type === 'DP' && !readOnly()"
                class="row no-gutters"
              >
                <div
                  class="badge badge-primary"
                  @click="switchToAssociatedDiagnosis(firstDiagnosis)"
                >
                  <sancare-octicon
                    name="arrow-down"
                    :width="10"
                    :height="10"
                  />
                  <span class="cp">
                    {{ $t('diagnosis.DAS') }}
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="type === 'DR' && !readOnly()"
              title="Supprimer le diagnostic"
              class="col-auto predicted-diagnosis-remover"
              @click.stop="removeCurrentDiagnosis()"
            >
              <sancare-octicon
                name="x"
                :width="18"
                :height="18"
              />
            </div>
          </div>
        </div>
        <div
          v-else-if="firstDiagnosis && (!currentDiagnosis || firstDiagnosis.reference !== currentDiagnosis.reference)"
          class="card-body"
        >
          <s :title="firstDiagnosis.description">
            {{ firstDiagnosis.reference }}
          </s> (initial)
          <h2 v-if="!currentDiagnosis">
            Pas de {{ $t('diagnosis.'+type) }}
          </h2>
        </div>
        <div
          v-if="currentDiagnosis && (!firstDiagnosis || firstDiagnosis.reference !== currentDiagnosis.reference)"
          class="card-body"
        >
          <div class="row no-gutters align-items-center">
            <div class="col">
              <div
                :ref="type === 'DP' ? 'initialDPTitle' : 'initialDRTitle'"
                :data-reference="currentDiagnosis.reference"
                class="row"
                :title="currentDiagnosis.description"
              >
                <h2 class="col-auto">
                  {{ currentDiagnosis.reference }}
                </h2>
                <div
                  v-if="codedSuggestion"
                  title="Voir les justifications"
                  class="col-auto show-justifications"
                  @click.stop="showJustificationSummary = true"
                >
                  <v-dropdown
                    :shown="showJustificationSummary"
                    placement="left"
                    class="popover-container"
                    popover-class="justification-popover"
                  >
                    <slot />
                    <template #popper>
                      <justification-summary
                        :is-loading="isLoading"
                        :chunks="suggestedDiagnosis.canShowRational ? chunks : null"
                        :health-data="healthData"
                        :saved-searches="suggestedDiagnosis.staySavedSearches"
                        :no-value-label="suggestedDiagnosis.hasRational ? 'Justification non disponible en raison des paramètres' : 'Pas de justification'"
                        :displayed-rum-idx="displayedRumIdx"
                        :rum-predicted-labels="rumPredictedLabels"
                        :selection="selection"
                        :readonly="readOnly()"
                        @select-health-data="handleHealthDataSelection"
                      />
                    </template>
                  </v-dropdown>
                  <div
                    v-if="codedSuggestion.diagnoses[0] && codedSuggestion.diagnoses[0].canShowRational && !codedSuggestion.isWildcard && !readOnly()"
                    :class="{ selected: isSelectedSuggestion(codedSuggestion.diagnoses[0]) }"
                    class="btn-note icon-block"
                  >
                    <sancare-octicon
                      name="note"
                      :width="18"
                      :height="18"
                    />
                  </div>
                </div>
              </div>
              <div>
                {{ currentDiagnosis.description }}
              </div>
              <div
                v-if="!readOnly()"
                class="badge badge-primary"
                @click="switchToAssociatedDiagnosis(currentDiagnosis)"
              >
                <sancare-octicon
                  name="arrow-down"
                  :width="10"
                  :height="10"
                />
                <span class="cp">
                  {{ $t('diagnosis.DAS') }}
                </span>
              </div>
            </div>
            <div
              v-if="['prediction_default', 'prediction_manual', 'prediction_unsure'].indexOf(currentDiagnosis.origin) !== -1 && !readOnly()"
              title="Retirer le diagnotic"
              class="col-auto predicted-diagnosis-remover"
              @click.stop="removeCurrentDiagnosis()"
            >
              <sancare-octicon
                name="arrow-right"
                :width="18"
                :height="18"
              />
            </div>
            <div
              v-else-if="!readOnly()"
              title="Supprimer le diagnotic"
              class="col-auto predicted-diagnosis-remover"
              @click.stop="removeCurrentDiagnosis()"
            >
              <sancare-octicon
                name="x"
                :width="18"
                :height="18"
              />
            </div>
          </div>
        </div>
        <div
          v-if="!firstDiagnosis && !currentDiagnosis"
          class="card-body"
        >
          <h2>
            Pas de {{ $t('diagnosis.'+type) }}
          </h2>
        </div>
      </div>
      <div class="row no-gutters">
        <manual-diagnosis-button
          :type="type"
          :read-only="readOnly()"
          :can-add-uncommon-diagnoses="pmsiCountry === CountryEnum.FR"
          @add-diagnosis="ref => { addDiagnosis({ reference: ref }); }"
        />
      </div>
    </div>
    <svg-link
      :has-match="hasMatch()"
      :svg-height="height"
      :svg-width="width"
      :svg-links="svgLinks"
    />
    <div
      :ref="type === 'DP' ? 'predictedDPDiagnoses' : 'predictedDRDiagnoses'"
      class="card diagnosis-card col-5"
    >
      <div class="card-body">
        <h1 class="card-title">
          {{ $t('diagnosis.'+type) }} <small>- Suggestion Sancare</small>
        </h1>
      </div>
      <div>
        <div
          v-for="(suggestion, suggestionIdx) in displayedSuggestedDiagnoses"
          :key="`suggestion_${type}_${suggestion.reference}`"
        >
          <div class="row card-body">
            <suggested-diagnosis
              :app-settings="appSettings"
              :type="type"
              :is-loading="isLoading"
              :is-wildcard="suggestion.isWildcard"
              :selected="selection !== null && selection.labelType === type && selection.labelReference === suggestion.reference"
              :selection="selection"
              :suggested-diagnosis="suggestion.diagnoses[0]"
              :saved-search-intersection-chunks="savedSearchIntersectionChunks"
              :health-data="healthData"
              :displayed-rum-idx="displayedRumIdx"
              :rum-predicted-labels="rumPredictedLabels"
              class="col-12"
              @make-selection="makeSelection"
            >
              <div
                class="row no-gutters px-1 align-items-center diagnosis-item"
                @click="toggleSuggestionDiagnoses(suggestion)"
              >
                <div class="col-2">
                  <div
                    v-if="suggestion.isWildcard"
                    title="Voir les diagnostics correspondants"
                    class="predicted-diagnosis-adder"
                  >
                    <sancare-octicon
                      :name="isExtendedSuggestion(suggestion) ? 'diff-removed' : 'diff-added'"
                      :width="18"
                      :height="18"
                    />
                  </div>
                  <div
                    v-else-if="(!currentDiagnosis || currentDiagnosis.reference !== suggestion.reference) && !readOnly()"
                    title="Ajouter le diagnostic"
                    class="predicted-diagnosis-adder"
                    @click.stop="addDiagnosis(suggestion.diagnoses[0])"
                  >
                    <div
                      v-if="processingDiagnoses.indexOf(suggestion.diagnoses[0].reference) !== -1"
                      class="loader loader-sm"
                    />
                    <sancare-octicon
                      v-else
                      name="arrow-left"
                      :width="20"
                      :height="20"
                    />
                  </div>
                </div>
                <div class="col-9 row no-gutters align-items-center">
                  <div
                    :ref="type === 'DP' ? 'predictedDPTitle' : 'predictedDRTitle'"
                    :data-reference="suggestion.reference"
                    :title="suggestion.description"
                    class="col-5"
                  >
                    <h2 class="diagnosis-reference">
                      {{ suggestion.reference }}
                    </h2>
                  </div>
                  <div class="col-6">
                    <div
                      v-if="suggestion.searches.length"
                      v-tooltip="stringifySuggestionSearches(suggestion.diagnoses[0], ',\n')"
                      class="row no-gutters align-items-center"
                    >
                      <div class="col-3">
                        <svg-search-icon
                          :is-revalo="hasRevaloSearch(suggestion)"
                        />
                      </div>
                      <div class="col-8 ml-1 diagnosis-searches">
                        {{ stringifySuggestionSearches(suggestion.diagnoses[0], ', ', true) }}
                      </div>
                    </div>
                    <div
                      v-if="suggestion.predictions.length"
                      class="row no-gutters align-items-center"
                    >
                      <div class="col-3">
                        <prediction-icon />
                      </div>
                      <div
                        v-if="suggestion.predictions.length === 1"
                        class="col-8 ml-1 diagnosis-confidence"
                      >
                        {{ suggestion.confidence.label }}
                      </div>
                      <div
                        v-else
                        class="col-8 ml-1 diagnosis-prediction-count"
                      >
                        {{ suggestion.predictions.length }} détection{{ suggestion.predictions.length > 1 ? 's' : '' }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-1">
                  <div
                    v-if="suggestion.diagnoses[0] && suggestion.diagnoses[0].canShowRational && !suggestion.isWildcard"
                    :class="{ selected: isSelectedSuggestion(suggestion.diagnoses[0]) }"
                    class="btn-select-diagnosis icon-block"
                    @click.stop="makeSelection({ labelType: type, labelReference: suggestion.reference, savedSearchIds: getSearchesIds(suggestion.diagnoses[0]) })"
                  >
                    <sancare-octicon
                      name="note"
                      :width="18"
                      :height="18"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="suggestionIdx === 0"
                class="row no-gutters align-items-center"
              >
                <p>
                  {{ suggestion.description }}
                </p>
              </div>
            </suggested-diagnosis>
          </div>
          <div v-show="suggestion.isWildcard && isExtendedSuggestion(suggestion) && suggestion.diagnoses.length > 1">
            <div
              v-for="diagnosis in suggestion.diagnoses"
              :key="`suggested_diagnosis_${type}_${diagnosis.reference}`"
              class="row card-body"
            >
              <suggested-diagnosis
                :app-settings="appSettings"
                :type="type"
                :is-loading="isLoading"
                :is-wildcard="false"
                :selected="selection !== null && selection.labelType === type && selection.labelReference === diagnosis.reference"
                :selection="selection"
                :suggested-diagnosis="diagnosis"
                :saved-search-intersection-chunks="savedSearchIntersectionChunks"
                :health-data="healthData"
                :displayed-rum-idx="displayedRumIdx"
                :rum-predicted-labels="rumPredictedLabels"
                class="col-12"
                @make-selection="makeSelection"
              >
                <div class="row no-gutters align-items-center diagnosis-item">
                  <!-- @click="/toggleDiagCategory(sDiag)" -->
                  <div class="col-2">
                    <div
                      v-if="(!currentDiagnosis || currentDiagnosis.reference !== diagnosis.reference) && !readOnly()"
                      title="Ajouter le diagnostic"
                      class="predicted-diagnosis-adder"
                      @click.stop="addDiagnosis(diagnosis)"
                    >
                      <div
                        v-if="processingDiagnoses.indexOf(diagnosis.reference) !== -1"
                        class="loader loader-sm"
                      />
                      <sancare-octicon
                        v-else
                        name="arrow-left"
                        :width="20"
                        :height="20"
                      />
                    </div>
                  </div>
                  <div class="col-9 row no-gutters align-items-center">
                    <div
                      :ref="type === 'DP' ? 'predictedDPTitle' : 'predictedDRTitle'"
                      :data-reference="diagnosis.reference"
                      :title="diagnosis.description"
                      class="col-5"
                    >
                      <h2 class="diagnosis-reference">
                        {{ diagnosis.reference }}
                      </h2>
                    </div>
                    <div class="col-6">
                      <div
                        v-if="diagnosis.staySavedSearches.length"
                        v-tooltip="stringifySuggestionSearches(diagnosis, ',\n')"
                        class="row no-gutters align-items-center"
                      >
                        <div class="col-3">
                          <svg-search-icon
                            :is-revalo="hasRevaloSearch(suggestion)"
                          />
                        </div>
                        <div class="col-8 ml-1 diagnosis-searches">
                          {{ stringifySuggestionSearches(diagnosis, ', ', true) }}
                        </div>
                      </div>
                      <div
                        v-if="diagnosis.detection"
                        class="row no-gutters align-items-center"
                      >
                        <div class="col-3">
                          <prediction-icon />
                        </div>
                        <div
                          v-if="diagnosis.detection"
                          class="col-8 ml-1 diagnosis-confidence"
                        >
                          {{ Math.round(diagnosis.detection.confidence * 100) }}%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-1">
                    <div
                      v-if="diagnosis.canShowRational"
                      :class="{ selected: isSelectedSuggestion(diagnosis) }"
                      class="btn-select-diagnosis icon-block"
                      @click.stop="makeSelection({ labelType: type, labelReference: diagnosis.reference, savedSearchIds: getSearchesIds(diagnosis) })"
                    >
                      <sancare-octicon
                        name="note"
                        :width="18"
                        :height="18"
                      />
                    </div>
                  </div>
                </div>
              </suggested-diagnosis>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!suggestedDiagnoses.length"
        class="card-body text"
      >
        Aucun diagnostic detecté
      </div>
      <div
        v-else-if="!hasVisibleSuggestion()"
        class="card-body text-center"
      >
        Aucun diagnostic pertinent
      </div>
      <div
        v-if="hasHiddenSuggestion()"
        class="col-12 mb-1 py-0 text-center"
      >
        <span
          class="btn-more-diagnosis"
          @click="showMore = !showMore"
        >
          {{ showMore ? 'Afficher moins' : 'Afficher plus' }}
        </span>
      </div>
    </div>
  </div>
</template>
