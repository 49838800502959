import { computed } from 'vue'
import { useRoute } from 'vue-router'

import router from '@/router'
import { redirectToStayDisplayer } from '@/stay-list/stay-list-table/helpers/line/lineHelper'
import { useStore } from '@/store'
import { ModeEnum } from '@/store/modules/settings/types'

export default function useStayRedirection(stayDisplayerPath: string, mode: ModeEnum) {
  const store = useStore()
  const route = useRoute()

  const settingsUrlQuery = computed(() => store.getters.settingsUrlQuery)
  const viewStay = (event: MouseEvent, forceNewWindow: boolean | null = null) =>
    redirectToStayDisplayer(event, forceNewWindow, router, {
      routeFullPath: route.fullPath,
      newRoute: {
        path: stayDisplayerPath,
        query: {
          ...settingsUrlQuery.value,
          mode: mode,
        },
      }
    })

  return {
    viewStay
  }
}