<script>
import { SancareToggle } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex'

import { hasPermission, RoleEnum, savedSearchPermissions } from '@/misc/permissions/permissionsHelper'
import router from '@/router'
import { doesSearchIdExist, saveCurrentSavedSearch } from '@/saved-searches/helpers'
import { DEFAULT_SEARCH_ID } from '@/saved-searches/types'
import StayListCriteriaParameters from '@/stay-list/StayListCriteriaParameters.vue'
import { ModeEnum, PmsiTypeEnum, PrivacyEnum } from '@/store/modules/settings/types'

import AdvancedSearchCriteriaGroups from '../filters/AdvancedSearchCriteriaGroups.vue'
import ShowActiveSearchesModal from '../saved-searches/ShowActiveSearchesModal.vue'

export default {
  components: {
    'advanced-search-criteria-groups': AdvancedSearchCriteriaGroups,
    'show-active-searches-modal': ShowActiveSearchesModal,
    'sancare-toggle': SancareToggle,
    'stay-list-criteria-parameters': StayListCriteriaParameters,
  },
  props: {
    mode: { type: String, required: true },
    pmsiType: {
      validator(value) {
        return Object.values(PmsiTypeEnum).includes(value)
      },
      required: true,
    },
  },
  data() {
    return {
      requestError: null,
      requestSuccess: null,
      savedSearchPermissions,
    }
  },
  computed: {
    PrivacyEnum() {
      return PrivacyEnum
    },
    ModeEnum() {
      return ModeEnum
    },
    ...mapGetters(['shortHandStayListParams']),
    ...mapState({
      addSavedSearchRequest: (state) => state.savedSearches.addSavedSearchRequest,
      editSavedSearchRequest: (state) => state.savedSearches.editSavedSearchRequest,
      params: (state) => state.stayList.stayListParams,
      isSearchCreation: (state) => state.stayList.stayListParams.searchCreation,
      isSearchEdition: (state) => doesSearchIdExist(state.stayList.stayListParams.searchEdition),
      isAdmin: (state) => state.login.userRole === 'ROLE_SANCARE_DEV',
      isSearchEditor: (state) => hasPermission(RoleEnum.ROLE_SANCARE_SEARCH_MANAGER, state.login.userRole),
      currentSearch: (state) => state.stayList.currentSearch,
      currentSearchEdited: (state) => state.stayList.currentSearchEdited,
      currentUser: (state) => state.settings.currentUser,
      currentUsername: (state) => state.login.username,
      fetchSavedSearchesRequest: (state) => state.savedSearches.fetchSavedSearchesRequest,
      activeSearches(state) {
        let searchIds = state.stayList.activeSearches
        const searchesList = state.savedSearches.savedSearches

        searchIds = Array.isArray(searchIds) ? searchIds : [searchIds]
        searchIds = _.map(searchIds, (searchId) => parseInt(searchId))

        const activeSearches = _.filter(searchesList, (search) => searchIds.indexOf(search.id) !== -1)

        return activeSearches
      },
      editionInProgress: (state) => state.stayList.editionInProgress,
    }),
    isSancareUser() {
      return this.$store.state.settings.currentUser.sancareUser
    },
    activeSearchIds() {
      return this.activeSearches.map((s) => s.id)
    },
    isOwner() {
      return this.currentSearch.user === null || this.currentSearch.user.username === this.currentUsername
    }
  },
  watch: {
    addSavedSearchRequest(req) {
      if (req.error) {
        this.requestSuccess = null
        this.requestError = req.error
      } else if (req.ok) {
        this.requestError = null
        this.requestSuccess = 'Recherche enregistrée'
      }
    },
    editSavedSearchRequest(req) {
      if (req.error) {
        this.requestSuccess= null
        this.requestError = req.error
      } else if (req.ok) {
        this.requestError = null
        this.requestSuccess = 'Modifications enregistrées'
      }
    },
  },
  mounted: function() {
    this.$store.dispatch('initStayList')
  },
  methods: {
    clearMessage() {
      this.requestSuccess = null
      this.requestError = null
    },
    switchOperators() {
      this.clearMessage()
      this.$store.commit(
        'updateOperators',
        this.currentSearch.searchOperators === 'orAnd' ? 'andOr' : 'orAnd'
      )
      this.$store.dispatch('refreshStayList', { resetStaySelection: true })
    },
    setParameter(type, value) {
      this.$store.commit('setStayListParam', { type, value })
      this.onEdit()
      this.$store.dispatch('refreshStayList', { resetStaySelection: type !== 'pageSize' })
      this.$store.dispatch('refreshStayListUrl', {})
    },
    setSearchName(name) {
      this.$store.commit('setSearchName', name)
      this.onEdit()
    },
    setSearchPrivacy(privacy) {
      this.$store.commit('setSearchPrivacy', privacy)
      this.onEdit()
    },
    setSearchCategory(category) {
      if (!this.userCanEditSearchCategory) {
        return
      }

      this.$store.commit('setSearchCategory', category)
      this.onEdit()
    },
    onEdit() {
      this.requestError = null
      this.requestSuccess = null
    },
    clearCriteria() {
      const refreshUrl = this.currentSearch.criteriaGroups.length > 0 || this.currentSearch.globalCriteriaGroup.criteriaList.length > 0

      this.$store.commit('clearCurrentSearchCriteria')
      this.requestError = null

      this.$store.dispatch('refreshStayList', { resetStaySelection: true })
      if (!refreshUrl) {
        return
      }
      this.$store.dispatch('refreshStayListUrl', {})
    },
    resetEdition() {
      this.onEdit()
      this.$store.dispatch('resetEdition', {})
    },
    loadSavedSearch(search) {
      if (search) {
        this.$store.commit('loadSavedSearch', search)
      } else {
        this.$store.commit('newSavedSearch')
      }
      this.setParameter('searchEdition', DEFAULT_SEARCH_ID)
    },
    editSelectedSearch(search) {
      this.$store.commit('loadSavedSearch', search)
      const stayListQuery = { ...this.shortHandStayListParams, edit: search.id, searchId: undefined }
      const newRoute = {
        name: 'staylist',
        query: stayListQuery,
        params: { pmsiType: this.pmsiType },
      }

      this.$store.commit('setStayListParam', { type: 'searchEdition', value: search.id })

      router.push(newRoute)
    },
    async saveSavedSearch() {
      if (!this.isSearchCreation && !this.isSearchEdition) {
        this.requestError = 'Aucune recherche en cours d\'édition'
        return
      }

      if (!this.currentSearch.name || this.currentSearch.name === '') {
        this.requestError = 'Nom de la recherche invalide'
        return
      }

      if (!this.currentSearch.privacy || this.currentSearch.privacy === '') {
        this.requestError = 'Niveau de partage incorrect'
        return
      }

      if (!this.currentSearch.savedSearchCategory.name || this.currentSearch.savedSearchCategory.name === '') {
        this.searchError = 'Nom de la catégorie invalide'
        return
      }

      await saveCurrentSavedSearch()

      this.$store.commit('setInitialCriteriaToCurrentSearch')
      this.$store.commit('loadSavedSearch', this.currentSearch)
      this.$store.commit('setEditionInProgress', false)
    },
    canDisplayActiveSearchesProperties() {
      return _.isEmpty(this.activeSearches) === false && this.isSearchEdition === false
    },
    getSearchNameOrCategory() {
      if (this.activeSearches.length === 1) {
        return this.activeSearches[0].name
      } else {
        return this.activeSearches[0].savedSearchCategory.name
      }
    },
    userCanEditSearchCategory() {
      return this.isSearchEditor || this.isOwner || this.currentSearch.privacy === PrivacyEnum.PUBLIC_SEARCH
    },
  }
}
</script>

<template>
  <div
    id="advanced-criteria-builder"
    class="stay-list-criteria card"
    :class="{ 'opposite-mode': params.improveRecallMode === true }"
  >
    <div class="card-body">
      <h2
        v-if="params.improveRecallMode === true"
        class="recall-optimisation-mode-title"
      >
        Mode optimisation du rappel
      </h2>
      <div
        v-if="canDisplayActiveSearchesProperties()"
      >
        {{ getSearchNameOrCategory() }}
      </div>
      <stay-list-criteria-parameters
        :mode="mode"
        @edit="onEdit"
        @set-parameter="setParameter"
      />
      <hr v-if="isSearchCreation || isSearchEdition">
      <div v-if="isSearchCreation || isSearchEdition">
        <h2 class="mb-2">
          {{ isSearchCreation ? 'Création' : 'Modification' }} d'une {{ mode === 'optimizable' ? 'recherche' : 'liste de travail' }} avancée
        </h2>
        <div
          v-if="fetchSavedSearchesRequest.fetching"
          class="loader loader-center"
        />
        <template v-else>
          <div
            class="d-flex justify-content-between"
          >
            <div
              class="flex-sm-grow-1 col"
            >
              Nom
            </div>
            <div
              class="flex-sm-grow-1 col"
            >
              Partage
            </div>
            <div
              v-if="currentSearch.privacy === PrivacyEnum.SANCARE_SEARCH || isSearchEditor"
              class="flex-sm-grow-1 col"
            >
              Catégorie
            </div>
          </div>
          <div
            class="my-1 d-flex justify-content-between"
          >
            <div
              class="flex-sm-grow-1 col"
            >
              <input
                :value="currentSearch.name"
                type="text"
                class="form-control"
                @change="event => setSearchName(event.srcElement.value)"
              >
            </div>
            <div
              class="flex-sm-grow-1 col"
            >
              <select
                :value="currentSearch.privacy"
                :disabled="!savedSearchPermissions.canEditPrivacy(currentSearch, currentUser)"
                class="custom-select"
                @change="event => setSearchPrivacy(event.srcElement.value)"
              >
                <option
                  value="privateSearch"
                >
                  Privée
                </option>
                <option
                  v-if="currentSearch.type !== ModeEnum.QUALITY_STAYS && isSancareUser"
                  value="teamSearch"
                >
                  Equipe
                </option>
                <option
                  value="publicSearch"
                >
                  Publique
                </option>
                <option
                  v-if="currentSearch.privacy === PrivacyEnum.SANCARE_SEARCH || isAdmin"
                  :disabled="!isAdmin"
                  value="sancareSearch"
                >
                  Sancare
                </option>
              </select>
            </div>
            <div
              v-if="currentSearch.privacy === PrivacyEnum.SANCARE_SEARCH || isSearchEditor"
              class="flex-sm-grow-1 col"
            >
              <input
                :value="currentSearch.savedSearchCategory.name"
                :disabled="!userCanEditSearchCategory()"
                type="text"
                class="form-control"
                @change="event => setSearchCategory(event.srcElement.value)"
              >
            </div>
          </div>
        </template>
      </div>
      <hr>
      <div>
        <h2
          class="mb-2"
        >
          Conditions
        </h2>
        <sancare-toggle
          class="pt-2 pb-4 align-items-center"
          :label="'Inverser les opérateurs &quot;ET&quot;/&quot;OU&quot; dans les conditions'"
          :label-position="'right'"
          :value="currentSearch.searchOperators === 'orAnd'"
          :disabled="fetchSavedSearchesRequest.fetching"
          @toggle-change="switchOperators"
        />
        <advanced-search-criteria-groups
          :disabled="fetchSavedSearchesRequest.fetching"
          :mode="mode"
          :pmsi-type="pmsiType"
        />
      </div>
      <div
        class="row no-gutters align-items-center"
      >
        <div
          class="col"
        >
          <div
            v-if="requestError"
            class="text-danger"
          >
            {{ requestError }}
          </div>
          <div
            v-else-if="requestSuccess"
            class="text-success"
          >
            {{ requestSuccess }}
          </div>
        </div>
        <div
          class="col-auto"
        >
          <button
            v-if="editionInProgress && (isSearchEdition || isSearchCreation)"
            :disabled="!editionInProgress || !currentSearch.name || !currentSearch.privacy"
            class="btn btn-link"
            @click="saveSavedSearch"
          >
            Enregistrer la {{ mode === 'optimizable' ? 'recherche' : 'liste de travail' }}
          </button>
        </div>
        <div
          class="col-auto"
        >
          <button
            v-if="currentSearchEdited && (isSearchCreation || isSearchEdition)"
            class="btn btn-link"
            @click="resetEdition"
          >
            Annuler les modifications
          </button>
          <button
            v-else-if="currentSearch.criteriaGroups.length || currentSearch.globalCriteriaGroup.criteriaList.length"
            class="btn btn-link"
            @click="clearCriteria"
          >
            Retirer les critères
          </button>
        </div>
        <div
          v-if="activeSearches.length"
          class="col-auto"
        >
          <show-active-searches-modal
            :active-search-ids="activeSearchIds"
            :pmsi-type="pmsiType"
            @load-saved-search="s => { editSelectedSearch(s) }"
          >
            <template #button>
              <button class="btn btn-link">
                {{ (activeSearches.length > 1) ? 'Recherches sélectionnées' : 'Recherche sélectionnée' }}
              </button>
            </template>
          </show-active-searches-modal>
        </div>
      </div>
    </div>
  </div>
</template>
