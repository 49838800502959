import Api from '@sancare/ui-frontend-commons/src/misc/api'

import { PmsiTypeEnum } from '@/store/modules/settings/types'
import { PayloadWithMut } from '@/store/types'

import { state } from './state'

interface MedicalActPayload extends PayloadWithMut {
  urlParams : {
    pmsiType: PmsiTypeEnum
    summaryUnitId: number
  }
}

interface AddActPayload extends MedicalActPayload {
  body: {
    reference: string
    executionDate?: string
    activity?: number
    laterality?: string
  }
}
interface RemoveActPayload extends MedicalActPayload {
  id: number
}

export const actions = {
  addMedicalAct({ commit }, payload: AddActPayload) {
    return Api.request(state.requests.addMedicalAct, {
      body: payload.body,
      urlParams: payload.urlParams,
      startMut: () => payload.startMut?.name ? commit(payload.startMut.name, null, { root: true }) : null,
      successMut: (response) => commit(payload.successMut.name, response, { root: true })
    }, commit)
  },
  removeMedicalAct({ commit }, payload: RemoveActPayload) {
    return Api.request(state.requests.removeMedicalAct, {
      urlParams: { ...payload.urlParams, id: String(payload.id) },
      startMut: () => payload.startMut?.name ? commit(payload.startMut.name, null, { root: true }) : null,
      successMut: (response) => commit(payload.successMut.name, response, { root: true })
    }, commit)
  }
}
