import _ from 'lodash'
import { RouteLocationRaw, Router } from 'vue-router'

import { PrivacyEnum, StayListColumns, User } from '@/store/modules/settings/types'
import { ExternalPmsiStatusEnum, SeenStatusEnum, StayNotificationEnum, StayStatus } from '@/store/modules/stay/types'
import { StaySavedSearch } from '@/store/modules/stay-saved-search/types'

export const ExternalPmsiStatusLabel: { [key in ExternalPmsiStatusEnum]: string } = {
  [ExternalPmsiStatusEnum.NORMAL]: 'À coder',
  [ExternalPmsiStatusEnum.VALIDATED]: 'Validé',
  [ExternalPmsiStatusEnum.INVOICED]: 'À émettre',
}

export function filterMatchingStaySavedSearches(staySavedSearches: StaySavedSearch[], user: User) {
  return staySavedSearches
    .filter((s) =>
      // We need to filter empty searches because of a DQL limitation. See https://app.clickup.com/t/86bz5qvqa
      Boolean(s.search)
      && (
        s.search.privacy === PrivacyEnum.SANCARE_SEARCH
        || s.search.privacy === PrivacyEnum.PUBLIC_SEARCH
        || (s.search.privacy === PrivacyEnum.PRIVATE_SEARCH && s.search.user.username === user.username)
        || (user.teamMode && s.search.privacy === PrivacyEnum.TEAM_SEARCH && s.search.user.team?.id === user.team?.id)
      )
      && s.search.disabled === false)
    .map((s) => s.search)
}

export function getStaySeenStatus(stayStatuses = [], username): StayStatus {
  const status = stayStatuses.find((s) => s.user.username === username && s.statusValue !== SeenStatusEnum.UNSEEN)

  if (!status) {
    return { notification: StayNotificationEnum.NOT_READ, statusValue: SeenStatusEnum.UNSEEN }
  }

  return status
}

export function redirectToStayDisplayer(
  event: MouseEvent,
  forceNewWindow: boolean | null,
  router: Router, 
  { routeFullPath, newRoute }: { routeFullPath: string, newRoute: RouteLocationRaw }
) {
  window.sessionStorage.setItem('stayListRoute', routeFullPath)

  if (forceNewWindow || event.ctrlKey || event.metaKey || event.shiftKey) {
    window.open(router.resolve(newRoute).href, '_blank')
  } else {
    router.push(newRoute)
  }
}

export function getColumnList(list, availableColumns: StayListColumns): StayListColumns {
  const columnList = {}
  _.forEach(availableColumns, (columnInfo, columnName) => {
    if(list.indexOf(columnName) > -1){
      columnList[columnName] = columnInfo
    }
  })
  return columnList
}