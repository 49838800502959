<script>
import { ExportButton, SancareToggle } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex'

import LoadingMark from '@/misc/LoadingMark.vue'
import { hasPermission, RoleEnum } from '@/misc/permissions/permissionsHelper'
import router from '@/router'

import { AutomationModeEnum } from '../store/modules/automation/types'
import { ModeEnum, PmsiTypeEnum } from '../store/modules/settings/types'
import availableColumns from './stay-list-table/mco/StayListColumns'

export default {
  components: {
    ExportButton,
    SancareToggle,
    LoadingMark
  },
  props: {
    pmsiType: {
      validator(value) {
        return Object.values(PmsiTypeEnum).includes(value)
      },
      default: PmsiTypeEnum.MCO
    },
    stayList: { type: Array, required: true },
    request: { type: Object, required: true },
    mode: { type: String, required: true },
    params: { type: Object, required: true },
  },
  emits: ['refresh-staylist', 'set-stay-list-parameter'],
  data() {
    return {
      availableColumns: availableColumns,
      ModeEnum,
      stayPerPageOptions: [10, 20, 30, 50, 80, 100, 150],
      exportError: null,
      isExporting: null,
    }
  },
  computed:{
    ...mapGetters(['flatSancareSettings']),
    ...mapState({
      userSettings: (state) => state.settings.userSettings,
      isStayReader: (state) => hasPermission(RoleEnum.ROLE_STAY_READER, state.login.userRole),
      selectedStays: (state) => state.stayList.selectedStays,
      automationMode: (state) => state.route.query ? state.route.query.automationMode : AutomationModeEnum.AUTOMATABLE,
      automationGroupId: (state) => state.route.query ? state.route.query.automationGroupId : null,
      columnList(state) {
        const userColumns = state.settings.userSettings.stayListColumns
        const columnList = {}

        _.forEach(this.availableColumns, (columnInfo, columnName) => {
          if (columnInfo.mode && columnInfo.mode.includes(this.mode)) {
            if (userColumns && userColumns.length) {
              if (userColumns.indexOf(columnName) !== -1) {
                columnList[columnName] = columnInfo
              }
            } else if (columnInfo.isDefault) {
              columnList[columnName] = columnInfo
            }
          }
        })

        return columnList
      },
      areListActionsShowed() {
        return this.pmsiType === PmsiTypeEnum.MCO
      }
    }),
  },
  mounted() {
    this.$store.commit('resetStaySelection')
  },
  methods: {
    setColumns(columns) {
      const settings = { ...this.userSettings }
      settings.stayListColumns = columns
      this.$store.dispatch('setUserSettings', settings)
    },
    isSelectedStay(stay) {
      return _.findIndex(this.selectedStays, (s) => s.id === stay.id) !== -1
    },
    toggleSelectedStay(stay) {
      this.$store.dispatch('toggleSelectedStay', stay)
    },
    startReadingList() {
      if (!this.selectedStays.length) {
        _.forEach(this.stayList, (stay) => {
          if (!this.isSelectedStay(stay)) {
            this.toggleSelectedStay(stay)
          }
        })
      }
      window.sessionStorage.setItem('stayListRoute', this.$route.fullPath)

      this.$store.dispatch('createReadingList').then(() => {
        const stay = this.$store.getters.readingListCurrentStay
        if (stay) {
          router.push({
            path: `/id/${stay.id}`,
            query: {
              readingList: 1,
              mode: this.mode,
            }
          })
        }
      })
    },
    validateAutomatables() {
      if (!this.selectedStays.length) {
        this.$store.dispatch('validateAutomatableList', { automationGroupId: this.automationGroupId, mode: this.automationMode }).then(() => {
          this.$emit('refresh-staylist')
        })
      } else {
        this.$store.dispatch('validateAutomatableSelection', { automationGroupId: this.automationGroupId, selection: this.selectedStays }).then(() => {
          this.$emit('refresh-staylist')
        })
      }
    },
    async exportStays() {
      this.exportError = null
      this.isExporting = true
      const exportUrl = new URL('/api/export/search-csv', window.location.href)
      const requestData = {
        mode: this.mode,
        request: this.request,
        columnList: Object.keys(this.columnList),
      }

      try {
        const requestOptions = {
          method: 'POST',
          credentials: 'same-origin',
          headers: new Headers({
            'Cache-Control': 'no-cache, no-store, must-revalidate, max-age=0',
            expires: '0',
            pragma: 'no-cache',
            'X-Requested-With': 'XMLHttpRequest',
          }),
          body: JSON.stringify(requestData),
        }

        const response = await fetch(exportUrl, requestOptions)
        const fileName = this.getFileNameFromResponse(response)
        const downloadUrl = window.URL.createObjectURL(await response.blob())
        this.downloadFile(downloadUrl, fileName)
      } catch {
        this.exportError = 'Une erreur est survenue lors de l\'export.'
      } finally {
        this.showSearchSettings = false
        this.isExporting = false
      }
    },

    getFileNameFromResponse(response) {
      const contentDispositionHeader = response.headers.get('Content-Disposition')
      const matchFileName = contentDispositionHeader.match(/filename="?([^"]+)"?/)
      return matchFileName && matchFileName[1] ? matchFileName[1] : 'stay_list_export.xlsx'
    },

    downloadFile(downloadUrl, fileName) {
      const link = document.createElement('a')
      link.href = downloadUrl
      link.download = fileName
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(downloadUrl)
    }
  }
}
</script>

<template>
  <div class="pl-1 pr-3">
    <div class="row justify-content-between">
      <div class="d-flex justify-content-start align-items-md-center pl-2">
        <div
          v-if="areListActionsShowed"
          class="btn-group pl-1"
        >
          <div
            v-if="mode === ModeEnum.AUTOMATION && automationGroupId && !flatSancareSettings['demo_mode']"
            class="btn sancare-btn"
            :class="!stayList.length ? 'disabled' : ''"
          >
            <v-dropdown>
              <span>Valider</span>
              <template #popper>
                <div class="title-light text-center mb-2">
                  <h4>
                    Êtes-vous sûr(e) ?
                  </h4>
                  <span>Cette action est irréversible</span>
                </div>
                <div class="text-center">
                  <button
                    class="btn btn-danger btn-padded"
                    @click="validateAutomatables"
                  >
                    Confirmer
                  </button>
                </div>
              </template>
            </v-dropdown>
          </div>
          <div class="btn sancare-btn btn-fixed-width">
            <export-button
              @confirm="exportStays()"
            >
              <template #button>
                <div>
                  <loading-mark
                    v-if="isExporting"
                    :finished="!isExporting"
                    class="spinner ml-2"
                    color="blue"
                  />
                  <span v-else>Exporter</span>
                </div>
              </template>
            </export-button>
          </div>
          <span
            class="btn sancare-btn btn-fixed-width"
            @click="startReadingList"
          >
            Parcourir
          </span>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center border-info">
        <sancare-toggle
          class="mr-3"
          label="Afficher les séjours vus"
          :value="params.showSeen"
          @toggle-change="$emit('set-stay-list-parameter', 'showSeen', !params.showSeen)"
        />
        <div class="d-flex align-items-center">
          <div class="flex-shrink-0 mr-2">
            Résultats par page
          </div>
          <select
            :value="params.pageSize"
            class="form-control sancare-select"
            @change="e => $emit('set-stay-list-parameter', 'pageSize', Number(e.target.value))"
          >
            <option
              v-for="option in stayPerPageOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div
      v-if="exportError"
      class="row error-block mt-2 pl-3"
    >
      {{ exportError }}
    </div>
  </div>
</template>

<style lang="less" scoped>
@import '~@sancare/ui-frontend-commons/src/assets/styles/variables.less';

.error-block {
  color: @sancare-dark-red;
  line-height: 1.2em;
}

.btn-fixed-width {
  width: 80px;
  display: flex;
  justify-content: center;
}
</style>