<script>
import { SancareModal, SancareOcticon } from '@sancare/ui-frontend-commons'
import { mapState } from 'vuex'

import FinessInput from './FinessInput.vue'

export default {
  components: {
    'sancare-modal': SancareModal,
    'sancare-octicon': SancareOcticon,
    'finess-input': FinessInput,
  },
  props: {
    showModal: { type: Boolean, required: true },
    userId: { type: Number, required: true },
  },
  emits: ['close-modal'],
  computed: {
    editedUser() {
      return this.$store.getters.getUserById(this.userId)
    },
    ...mapState({
      legalFinessList: (state) => state.admin.legalFinessList,
      geoFinessList: (state) => state.admin.geoFinessList,
    }),
  },
}
</script>

<template>
  <sancare-modal
    :open="showModal"
    @close-modal="$emit('close-modal');"
  >
    <template #header>
      <div
        class="row"
      >
        <div class="sancare-modal-title col">
          Assignation de l'utilisateur «{{ editedUser.username }}»
        </div>
        <div
          class="sancare-modal-close col-6"
          @click="$emit('close-modal');"
        >
          <sancare-octicon
            name="x"
            :width="20"
            :height="20"
          />
        </div>
      </div>
    </template>

    <finess-input
      type="legal"
      :user-id="editedUser.id"
      :user-finess-list="editedUser.legalEntities"
      :completion-finess-list="legalFinessList"
    />
    <finess-input
      type="geo"
      :user-id="editedUser.id"
      :user-finess-list="editedUser.geographicEntities"
      :completion-finess-list="geoFinessList"
    />
  </sancare-modal>
</template>
