import medicalUnitFilterStore from '@sancare/ui-frontend-commons/src/store/modules/filters/medical-unit-filter'
import { createStore, Store, StoreOptions } from 'vuex'

import { RootState } from '@/store/types'

import adminStore from './admin/store'
import automationStore from './automation/store'
import devToolsStore from './dev-tools/store'
import falseJustificationsStore from './false-justifications/store'
import homeStore from './home/store'
import loginStore from './login/store'
import maintenanceStore from './maintenance/store'
import manageApiKeyStore from './manage-api-key/store'
import savedSearchesStore from './saved-searches/store'
import settingsStore from './settings/store'
import statsStore from './stats/store'
import stayDisplayerStore from './stay-displayer/store'
import stayListStore from './stay-list/store'
import connectorConfigStore from './store/modules/connector-config'
import ssrDiagnosisStore from './store/modules/diagnosis/ssr'
import medicalActStore from './store/modules/medical-act'
import mcoStayStore from './store/modules/stay/mco'
import ssrStayStore from './store/modules/stay/ssr'

export const modules = {
  medicalAct: medicalActStore,
  connectorConfig: connectorConfigStore,
  maintenance: maintenanceStore,
  home: homeStore,
  stayDisplayer: stayDisplayerStore,
  stayList: stayListStore,
  mcoStay: mcoStayStore,
  ssrStay: ssrStayStore,
  settings: settingsStore,
  login: loginStore,
  admin: adminStore,
  savedSearches: savedSearchesStore,
  stats: statsStore,
  manageApiKey: manageApiKeyStore,
  devTools: devToolsStore,
  automation: automationStore,
  falseJustifications: falseJustificationsStore,
  ssrDiagnosis: ssrDiagnosisStore,
  medicalUnitFilter: medicalUnitFilterStore,
}

const store = createStore({
  modules,
  strict: process.env.NODE_ENV !== 'production',
} as StoreOptions<RootState>)

if (module.hot) {
  module.hot.accept([
    './store/modules/diagnosis/ssr',
    './store/modules/medical-act',
    './store/modules/connector-config',
    './maintenance/store',
    './home/store',
    './stay-displayer/store',
    './stay-list/store',
    './store/modules/stay/mco',
    './store/modules/stay/ssr',
    './settings/store',
    './login/store',
    './admin/store',
    './saved-searches/store',
    './stats/store',
    './manage-api-key/store',
    './automation/store',
    './false-justifications/store',
    '@sancare/ui-frontend-commons/src/store/modules/filters/medical-unit-filter'
  ], () => {
    store.hotUpdate({
      modules: {
        medicalAct: medicalActStore,
        connectorConfig: connectorConfigStore,
        home: homeStore,
        stayDisplayer: stayDisplayerStore,
        stayList: stayListStore,
        mcoStay: mcoStayStore,
        ssrStay: ssrStayStore,
        settings: settingsStore,
        login: loginStore,
        admin: adminStore,
        savedSearches: savedSearchesStore,
        stats: statsStore,
        manageApiKey: manageApiKeyStore,
        automation: automationStore,
        ssrDiagnosis: ssrDiagnosisStore,
        medicalUnitFilter: medicalUnitFilterStore,
      },
    })
  })
}

export function useStore(): Store<RootState> {
  return store
}

export default store
