<script>
import JustificationChronicSummary from './JustificationChronicSummary.vue'

export default {
  components: {
    'justification-chronic-summary': JustificationChronicSummary,
  },
  props: {
    chronicSuggestion: { type: Object, required: true },
    chronicJustifications: { type: Array, required: true },
  },
  data() {
    return {
      showJustificationSummary: false,
    }
  },
  methods: {
    toggleRationals() {
      this.showJustificationSummary = !this.showJustificationSummary
    },
  }
}
</script>

<template>
  <div
    :class="{
      selected: showJustificationSummary,
      'diagnosis-title': true,
    }"
    @click="toggleRationals"
  >
    <v-dropdown
      :shown="showJustificationSummary"
      placement="left"
      class="popover-container"
      popover-class="justification-popover"
    >
      <slot />
      <template #popper>
        <justification-chronic-summary
          :title="chronicSuggestion.diagnosis.reference"
          :chronic-justifications="chronicJustifications"
        />
      </template>
    </v-dropdown>
  </div>
</template>
