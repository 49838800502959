import { setRequest } from '@sancare/ui-frontend-commons/src/misc/api'

import { convertMcoStayActionIn } from '@/store/modules/stay/mco/dto'
import { McoStayState, StayActionDtoIn } from '@/store/modules/stay/mco/types'

export const mutations = {
  fetchStayActionsSuccess(state: McoStayState, actions: StayActionDtoIn[]) {
    state.currentActions = actions.map((action) => convertMcoStayActionIn(action))
  },
  setRequest
}
