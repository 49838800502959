<script>
import { mapState } from 'vuex'

import { PredictedLabelType } from '@/store/modules/diagnosis/types'

import { getDocumentCategoryLabel, getHealthDataLabel } from '../stay-displayer/health-data/documentLabels.js'
import SearchThumbnail from './SearchThumbnail.vue'

export default {
  name: 'FalseJustificationCard',
  components: {
    SearchThumbnail,
  },
  props: {
    falseJustification: { type: Object, required: true },
  },
  data() {
    return {
      nbCarChunkWrap: 150,
      falseJustificationTypes: {
        antecedent: 'Antécédent',
        doubt: 'Passage incertain',
        negation: 'Négation',
        recurrence: 'Motif récurrent'
      },
      PredictedLabelType,
    }
  },
  computed: {
    ...mapState({
      earlyLimit: (state) => state.settings.earlyLimit,
      lateLimit: (state) => state.settings.lateLimit
    })
  },
  methods: {
    displayedDocumentDescription() {
      if(this.falseJustification.report === null){
        return getHealthDataLabel(this.falseJustification.textualHealthEntry.type)
      }
      let description = this.falseJustification.report.documentTitle ? this.falseJustification.report.documentTitle : '[ Sans Titre ]'
      const categoryLabel = getDocumentCategoryLabel(this.falseJustification.report.documentTemplate)
      description += categoryLabel ? ` [ ${categoryLabel} ]` : ` [ ${this.falseJustification.report.documentTemplate} ]`
      return description
    },
    getChunkBefore() {
      return this.getDocument().content.slice(this.falseJustification.hintStart-this.nbCarChunkWrap, this.falseJustification.hintStart)
    },
    getChunk() {
      return this.getDocument().content.slice(this.falseJustification.hintStart, this.falseJustification.hintEnd)
    },
    getChunkAfter() {
      return this.getDocument().content.slice(this.falseJustification.hintEnd, this.falseJustification.hintEnd+this.nbCarChunkWrap)
    },
    getDocument() {
      return this.falseJustification.report === null ? this.falseJustification.textualHealthEntry : this.falseJustification.report
    },
    getStayId() {
      return this.getDocument().stay.id
    }
  }
}
</script>

<template>
  <div class="row mt-2 false-justification-card">
    <div class="col-2 general-stay-data card">
      N° ADMINISTRATIF
      <div class="pl-3 pb-3">
        <router-link
          :to="'/id/'+getStayId()"
        >
          {{ getDocument().stay.administrativeLocalStayId }}
        </router-link>
      </div>
      N° RSS
      <div class="pl-3 pb-3">
        <router-link
          :to="'/id/'+getStayId()"
        >
          {{ getDocument().stay.rssId }}
        </router-link>
      </div>
      TYPE
      <div class="pl-3">
        {{ falseJustificationTypes[falseJustification.type] }}
      </div>
    </div>
    <div class="col-6">
      <button class="btn btn-sm btn-report-select">
        {{ displayedDocumentDescription() }}
      </button>
      <div class="card report">
        <span>{{ getChunkBefore() }}
          <span class="highlighted">{{ getChunk() }}</span>
          {{ getChunkAfter() }}</span>
      </div>
    </div>
    <div class="col-4">
      Recherches:
      <div class="row mb-2">
        <search-thumbnail
          v-for="savedSearch in falseJustification.savedSearches"
          :key="savedSearch.id"
          :search="savedSearch"
        />
      </div>
      Predictions:
      <div class="row">
        <div
          v-for="predictedLabel in falseJustification.predictedLabels"
          :key="predictedLabel.id"
          class="col-12"
          :class="{
            'principal-diagnosis-group': predictedLabel.type === PredictedLabelType.DP,
            'related-diagnosis-group': predictedLabel.type === PredictedLabelType.DR,
            'associated-diagnosis-group': predictedLabel.type === PredictedLabelType.DAS,
            'medical-act-group': predictedLabel.type === PredictedLabelType.MEDICAL_ACTS
          }"
        >
          <div class="card diagnosis-card py-1">
            <div class="card-body">
              <div class="row card-body">
                <div class="col-12 diagnosis-title">
                  <div class="row no-gutters px-1 align-items-center diagnosis-item">
                    {{ predictedLabel.type }} - {{ predictedLabel.diagnosis?.reference ?? predictedLabel.medicalAct.reference }}
                  </div>
                  <div class="row no-gutters align-items-center">
                    <p>{{ predictedLabel.diagnosis?.description ?? predictedLabel.medicalAct.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
