import { ExpiredPasswordForm, LoginForm, UnloggedPage } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { createRouter, createWebHistory } from 'vue-router'
import { sync } from 'vuex-router-sync/dist/vuex-router-sync.esm-bundler'

import EventViewer from '@/maintenance/EventViewer.vue'
import { hasPermission } from '@/misc/permissions/permissionsHelper'
import SsrPage from '@/stay-displayer/ssr/SsrPage.vue'
import { PmsiTypeEnum } from '@/store/modules/settings/types'

import AdminPage from './admin/AdminPage.vue'
import AutomationGroupEditor from './automation/AutomationGroupEditor.vue'
import AutomationGroupHistory from './automation/AutomationGroupHistory.vue'
import AutomationStayList from './automation/StayListPage.vue'
import CoraAnalysis from './dev-tools/CoraAnalysis.vue'
import WebPimsAnalysis from './dev-tools/WebPimsAnalysis.vue'
import FalseJustificationPage from './false-justifications/FalseJustificationPage.vue'
import HomePage from './home/HomePage.vue'
import ResetPasswordForm from './login/ResetPasswordForm.vue'
import EventObserver from './maintenance/EventObserver.vue'
import MaintenanceHome from './maintenance/MaintenanceHome.vue'
import StayHistory from './maintenance/StayHistory.vue'
import ManageApiKeyPage from './manage-api-key/ManageApiKeyPage.vue'
import StatsPage from './stats/StatsPage.vue'
import RhsPage from './stay-displayer/ssr/rhs/RhsPage.vue'
import StayDisplayerPage from './stay-displayer/StayDisplayerPage.vue'
import StayListPage from './stay-list/StayListPage.vue'
import store from './store'

const pmsiPrefix = '/:pmsiType(mco|ssr)?'
function getPmsiType(route) {
  return route.params.pmsiType !== '' ? route.params.pmsiType : PmsiTypeEnum.MCO
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      component: UnloggedPage,
      children: [
        {
          path: '',
          component: LoginForm,
          meta: { requiresAuth: false, unloggedPage: true },
        },
        {
          // This route is used in the emails sent by the backend,
          // avoid changing it or it will cause lots of problems
          path: 'reset-password/:link',
          component: ResetPasswordForm,
          meta: { requiresAuth: false, unloggedPage: true, requiresMailer: false },
        },
        {
          // This route is used by users to change their password without to be connected (eg: expired password)
          path: 'expired-password',
          component: ExpiredPasswordForm,
          meta: { requiresAuth: false, unloggedPage: true },
        },
      ],
    },
    {
      name: 'home',
      path: pmsiPrefix,
      component: HomePage,
      props: (route) => ({ pmsiType: getPmsiType(route) }),
      meta: { requiresAuth: true }
    },
    {
      name: 'staylist',
      path: `${pmsiPrefix}/stay-list`,
      component: StayListPage,
      props: (route) => ({ pmsiType: getPmsiType(route) }),
      meta: { requiresAuth: true },
    },
    {
      name: 'admin',
      path: '/admin',
      component: AdminPage,
      meta: { requiresAuth: true, requireSomeRoles: ['ROLE_USER_MANAGER', 'ROLE_ADMIN_MANAGER', 'ROLE_SETTING_UPDATER'] },
    },
    {
      name: 'maintenance-home',
      path: '/maintenance',
      component: MaintenanceHome,
      meta: { requiresAuth: true, requireSomeRoles: ['ROLE_MAINTENANCE_VIEWER'] },
    },
    {
      name: 'maintenance-event-observer',
      path: '/maintenance/event-observer',
      component: EventObserver,
      meta: { requiresAuth: true, requireSomeRoles: ['ROLE_MAINTENANCE_VIEWER'] },
    },
    {
      name: 'maintenance-event-visualizer',
      path: '/maintenance/event-observer/detail',
      component: EventViewer,
      meta: { requiresAuth: true, requireSomeRoles: ['ROLE_MAINTENANCE_VIEWER'] },
    },
    {
      name: 'maintenance-stay-history-search',
      path: '/maintenance/stay-history',
      component: StayHistory,
      meta: { requiresAuth: true, requireSomeRoles: ['ROLE_MAINTENANCE_VIEWER'] },
    },
    {
      name: 'maintenance-stay-history-detail',
      path: '/maintenance/stay-history/:stayRssId',
      component: StayHistory,
      meta: { requiresAuth: true, requireSomeRoles: ['ROLE_MAINTENANCE_VIEWER'] },
    },
    {
      name: 'stats',
      path: '/stats',
      component: StatsPage,
      meta: { requiresAuth: true }
    },
    {
      path: '/automation/edit-automation-group',
      component: AutomationGroupEditor,
      meta: { requiresAuth: true }
    },
    {
      path: '/automation/:automationGroupId/history',
      component: AutomationGroupHistory,
      meta: { requiresAuth: true }
    },
    {
      path: '/automation/automation-stay-list',
      component: AutomationStayList,
      meta: { requiresAuth: true }
    },
    {
      path: '/manage-api-key',
      component: ManageApiKeyPage,
      meta: { requiresAuth: true },
    },
    {
      path: '/webpims-analysis',
      component: WebPimsAnalysis,
      meta: { requiresAuth: true, requireSomeRoles: ['ROLE_DATA_PROVIDER'] },
    },
    {
      path: '/cora-analysis',
      component: CoraAnalysis,
      meta: { requiresAuth: true, requireSomeRoles: ['ROLE_DATA_PROVIDER'] },
    },
    {
      name: 'falseJustifications',
      path: '/false-justifications',
      component: FalseJustificationPage,
      meta: { requiresAuth: true, requireSomeRoles: ['ROLE_SANCARE_SEARCH_MANAGER'] },
    },
    {
      name: 'stay',
      path: '/:stayId',
      component: StayDisplayerPage,
      meta: { requiresAuth: true }
    },
    {
      path: '/:idType(administrativelocalstayid|administrativeid|id|sancareid|rssid|legacy)/:stayId',
      component: StayDisplayerPage,
      meta: { requiresAuth: true }
    },
    {
      name: 'ssr-stay',
      path: '/ssr/:ssrId(\\d+)',
      component: SsrPage,
      meta: { requiresAuth: true }
    },
    {
      name: 'rhs',
      path: '/ssr/:ssrId/rhs/:rhsId',
      component: RhsPage,
      meta: { requiresAuth: true }
    }
    // TODO: { path: '*', component: NotFoundComponent },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.path.includes('/ssr') && store.getters.pmsiType !== PmsiTypeEnum.SSR) {
    store.commit('setPmsiType', PmsiTypeEnum.SSR)
  } else if (!to.path.includes('/ssr') && store.getters.pmsiType !== PmsiTypeEnum.MCO) {
    store.commit('setPmsiType', PmsiTypeEnum.MCO)
  }
  if (to.query.mode) {
    store.commit('setPmsiMode', to.query.mode)
  } else {
    store.commit('initPmsiMode')
  }

  if (to.path === '/stay-list'){
    store.commit('setStayListRoute', to)
  }

  // Pages forbidden to logged-in users
  if (to.matched.some((record) => record.meta.unloggedPage) && store.getters.isAuthenticated) {
    return next({ path: to.query.redirect || '/' })
  }

  // Pages forbidden if the mailer is disabled
  if (window.mailerDisable) {
    if (to.matched.some((record) => record.meta.requiresMailer)) {
      return next({ path: '/' })
    }
  }

  // Pages forbidden to unlogged users
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      const nextPage = { path: '/login' }
      if (to.fullPath !== '/') {
        nextPage.query = { redirect: to.fullPath }
      }
      return next(nextPage)
    }
  }

  // Pages forbidden to non-admins
  let permissionDenied = false
  _.forEach(to.matched, (route) => {
    if (route.meta.requireSomeRoles) {
      permissionDenied = permissionDenied || _.every(route.meta.requireSomeRoles, (role) => !hasPermission(role, store.getters.userRole))
    }
    if (route.meta.requireAllRoles) {
      permissionDenied = permissionDenied || _.some(route.meta.requireSomeRoles, (role) => !hasPermission(role, store.getters.userRole))
    }
  })
  if (permissionDenied) {
    return next({ path: '/' })
  }

  return next()
})

export function useRouter(callSync) {
  // FIXME awful trick in order to prevent calling sync() in tests
  if (callSync) {
    sync(store, router)
  }

  return router
}

export default router
