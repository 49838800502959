export const statusLabels = {
  new: 'Nouveau',
  review: 'À revoir',
  unvalidated: 'Non-validé',
  validated: 'Validé',
}

const roleLabels = {
  ROLE_SANCARE_DEV: 'Développeur Sancare',
  ROLE_DIM: 'DIM',
  ROLE_TIM_MANAGER: 'Cadre TIM',
  ROLE_TIM: 'TIM',
  ROLE_DIM_IT: 'Informaticien DIM',
  ROLE_DAF: 'DAF',
  ROLE_DSI: 'DSI',
  ROLE_SANCARE_EDITOR: 'Editeur Sancare',
  ROLE_SANCARE_VIEWER: 'Utilisateur Sancare',
  ROLE_SANCARE_ADMIN: 'Admin Sancare',
  ROLE_DATA_PROVIDER: 'Data Provider',
}

export {
  roleLabels
}
