<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { mapGetters, mapState } from 'vuex'

import { savedSearchPermissions } from '@/misc/permissions/permissionsHelper'

import SavedSearchDetails from '../saved-searches/SavedSearchDetails.vue'

const SAVED_SEARCH_THUMBNAIL_MAX_COLOR = 9

export default {
  name: 'SearchThumbnail',
  components: {
    'saved-search-details': SavedSearchDetails,
    'sancare-octicon': SancareOcticon,
  },
  props: {
    search: { type: Object, required: true },
  },
  data() {
    return {
      savedSearchPermissions
    }
  },
  computed: {
    ...mapGetters(['flatSancareSettings']),
    ...mapState({
      currentUser: (state) => state.settings.currentUser,
      earlyLimit: (state) => state.settings.earlyLimit,
      lateLimit: (state) => state.settings.lateLimit
    })
  },
  methods: {
    displayedColor() {
      if (this.search.disabled) {
        return 'disabled'
      }
      return this.search.savedSearchCategory.id % SAVED_SEARCH_THUMBNAIL_MAX_COLOR
    }
  },
}
</script>

<template>
  <div
    :class="'col-6 saved-search-thumbnail saved-search-thumbnail-color-'+displayedColor()"
  >
    <router-link
      :to="{
        name: 'staylist',
        query:{ mode: search.type, revType: 'undetermined', edit: search.id, el: earlyLimit.format('DD-MM-YYYY'), ll: lateLimit.format('DD-MM-YYYY') },
        params: { pmsiType: search.pmsiType }
      }"
      class="row saved-search-thumbnail-header"
    >
      <div class="col-9 saved-search-thumbnail-name pt-1">
        {{ search.name }}
      </div>
      <v-dropdown
        v-if="savedSearchPermissions.canShowDetails(search, currentUser, flatSancareSettings['hide_sancare_searches_details'])"
        :triggers="['hover']"
        placement="bottom"
        class="col-3 popover-container"
        popover-class="saved-search-thumbnail-criteria-popover"
      >
        <span class="saved-search-thumbnail-criteria-icon">
          <sancare-octicon
            name="info"
            :width="18"
            :height="18"
          />
        </span>
        <template #popper>
          <div class="saved-search-thumbnail-criteria-list">
            <saved-search-details
              :saved-search="search"
              :can-remove-criteria="false"
            />
          </div>
        </template>
      </v-dropdown>
    </router-link>
  </div>
</template>
