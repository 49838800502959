<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'

import { documentCategories, textualHealthDataCategories } from '../stay-displayer/health-data/documentLabels.js'
import JustificationSummary from './diagnosis-lists/JustificationSummary.vue'

export default {
  components: {
    'justification-summary': JustificationSummary,
    'sancare-octicon': SancareOcticon,
  },
  props: {
    keywordValue: { type: String, required: true },
    documentType: { type: String, default: null },
    isHighlighted: { type: Boolean, default: false },
    keywordIndex: { type: String, required: true },
    isLoading: { type: Boolean, default: false },
    healthData: { type: Object, required: true },
    selection: { type: Object, default: null },
    chunks: { type: Object, default: null },
    savedSearches: { type: Array, default: null },
    displayedRumIdx: { type: Number, required: true },
    rumPredictedLabels: { type: Array, default: null },
  },
  emits: ['make-selection'],
  data() {
    return {
      showRationals: false,
      textualReportCategories: { ...documentCategories, ...textualHealthDataCategories },
    }
  },
  computed: {
    keywordText() {
      return this.keywordValue.substring(this.keywordValue.indexOf('\x1F') + 1)
    },
    keywordModifiers() {
      const modifiers = {}
      const strModif = this.keywordValue.substring(0, this.keywordValue.indexOf('\x1F'))
      if (strModif.indexOf('a') !== -1) {
        modifiers.includeAntecedents = true
      }
      if (strModif.indexOf('f') !== -1) {
        modifiers.includeFamilyAntecedents = true
      }
      return modifiers
    },
  },
  methods: {
    toggleRationals() {
      this.showRationals = !this.showRationals
    },
    selectKeyword() {
      this.$emit('make-selection', { labelType: null, labelReference: null, savedSearchIds: [this.keywordIndex] })
    },
    handleHealthDataSelection(mainContent) {
      this.$store.commit('shouldScroll', true)
      this.$store.commit('setMainContent', mainContent)

      if (!this.isHighlighted) {
        this.selectKeyword()
      }

      this.toggleRationals()
    },
  },
}
</script>

<template>
  <div
    :class="{
      active: isHighlighted,
      selected: showRationals
    }"
    class="btn btn-sm btn-report-select"
    @click.stop="toggleRationals"
  >
    <div class="row align-items-center no-gutters">
      <div class="col-auto">
        <span
          v-if="keywordModifiers.includeAntecedents"
          v-tooltip="{
            content: 'Recherche également dans les antécédents',
            offset: [5, 5],
            placement: 'bottom'
          }"
        >
          <sancare-octicon
            name="eye"
            :width="12"
            :height="18"
          />
        </span>
        <span
          v-if="keywordModifiers.includeFamilyAntecedents"
          v-tooltip="{
            content: 'Recherche également dans les antécédents familiaux',
            offset: [5, 5],
            placement: 'bottom'
          }"
        >
          <sancare-octicon
            name="organization"
            :width="12"
            :height="18"
          />
        </span>
        <span
          v-if="documentType"
          v-tooltip="{
            content: 'Recherche uniquement dans: ' + textualReportCategories[documentType],
            offset: [5, 5],
            placement: 'bottom'
          }"
        >
          <sancare-octicon
            name="repo"
            :width="12"
            :height="18"
          />
        </span>
      </div>
      <span class="col-auto mx-2">{{ keywordText }}</span>
      <span
        class="col-auto"
        @click.stop="selectKeyword"
      >
        <sancare-octicon
          name="note"
          :width="18"
          :height="18"
        />
      </span>
    </div>
    <v-dropdown
      :shown="showRationals"
      placement="right"
      class="popover-container"
      popover-class="justification-popover"
    >
      <slot />
      <template #popper>
        <justification-summary
          :is-loading="isLoading"
          :chunks="chunks"
          :health-data="healthData"
          :displayed-rum-idx="displayedRumIdx"
          :saved-searches="savedSearches"
          :rum-predicted-labels="rumPredictedLabels"
          :selection="selection"
          no-value-label="Mot non trouvé"
          @select-health-data="handleHealthDataSelection"
        />
      </template>
    </v-dropdown>
  </div>
</template>
