<script>
import { InputAutoCompletion } from '@sancare/ui-frontend-commons'
import {
  useAutoCompletionDebouncedWatcher
} from '@sancare/ui-frontend-commons/src/misc/composables/useAutoCompletionDebouncedWatcher'
import { ref, watchEffect } from 'vue'
export default {
  components: {
    'input-auto-completion': InputAutoCompletion,
  },
  props: {
    settingName: { type: String, required: true },
  },
  emits: ['select-finess'],
  data() {
    return {
      showCompletion: false,
      finessInput: '',
      label: this.settingName === 'restricted_legal_finess' ? 'FINESS juridique' : 'FINESS géographique',
      searchFinessRequestName: this.settingName === 'restricted_legal_finess' ? 'searchLegalFiness' : 'searchGeoFiness',
      clearFinessListRequestName: this.settingName === 'restricted_legal_finess' ? 'searchLegalFinessClear' : 'searchGeoFinessClear',
    }
  },
  computed: {
    completionFinessList() {
      return this.settingName === 'restricted_legal_finess' ? this.$store.state.admin.legalFinessList : this.$store.state.admin.geoFinessList
    },
    searchFinessRequest() {
      return this.settingName === 'restricted_legal_finess' ? this.$store.state.admin.searchLegalFiness : this.$store.state.admin.searchGeoFiness
    }
  },
  mounted() {
    const finessInputRef = ref(this.finessInput)
    const completionFinessListRef = ref(this.completionFinessList)

    useAutoCompletionDebouncedWatcher(
      finessInputRef,
      completionFinessListRef,
      (search, signal) => this.fetchFinessData(search, signal),
      () => this.clearFinessData()
    )

    watchEffect(() => {
      finessInputRef.value = this.finessInput
    })
  },
  methods: {
    openCompletion() {
      this.showCompletion = true
    },
    closeCompletion() {
      this.showCompletion = false
    },
    selectFiness(completionItem) {
      this.showCompletion = false
      this.$emit('select-finess', {
        finess: completionItem.reference,
        shortDescription: completionItem.label,
        settingName: this.settingName
      })
      this.finessInput = ''
    },
    fetchFinessData(search, signal) {
      this.$store.dispatch(this.searchFinessRequestName, { search, signal })
    },
    clearFinessData() {
      this.$store.commit(this.clearFinessListRequestName)
    },
  },
}
</script>

<template>
  <div>
    <input
      v-model="finessInput"
      v-on-click-outside="closeCompletion"
      class="form-control"
      type="text"
      placeholder="Nom ou FINESS de l'établissement"
      @click="openCompletion"
      @keydown.esc="closeCompletion"
    >
    <input-auto-completion
      v-show="showCompletion && finessInput !== ''"
      :completion-list="completionFinessList"
      :is-loading="searchFinessRequest.fetching"
      :title-prop="'reference'"
      :description-prop="'label'"
      @select-item="selectFiness"
    />
  </div>
</template>
