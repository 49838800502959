import _ from 'lodash'

const buildRssDisplay = (rums) => {
  const rss = {
    id: 0,
    rumStart: null,
    rumEnd: null,
    automationModeDiagnosisGroup: {
      associatedDiags: [],
      currentDP: null,
      currentDPLastUpdate: null,
      currentDR: null,
      currentDRLastUpdate: null,
    },
    standardModeDiagnosisGroup: {
      associatedDiags: [],
      firstDP: null,
      firstDPLastUpdate: null,
      firstDR: null,
      firstDRLastUpdate: null,
      currentDP: null,
      currentDPOrigin: null,
      currentDPLastUpdate: null,
      currentDR: null,
      currentDROrigin: null,
      currentDRLastUpdate: null,
    },
    medicalUnit: null,
    predictedLabels: [],
    displayablePredictedLabels: [],
    codedMedicalActs: [],
    automationModeMajorRumId: null,
    standardModeMajorRumId: null,
  }

  rss.rumStart = rums[0].rumStart
  _.forEach(rums, (rum) => {
    if (rum.automationModeDiagnosisGroup) {
      rss.automationModeDiagnosisGroup.associatedDiags.push(rum.automationModeDiagnosisGroup.associatedDiags)
    }

    if (rum.standardModeDiagnosisGroup) {
      rss.standardModeDiagnosisGroup.associatedDiags.push(rum.standardModeDiagnosisGroup.associatedDiags)
    }
    rss.predictedLabels.push(rum.predictedLabels)
    rss.displayablePredictedLabels.push(rum.displayablePredictedLabels)
    rss.codedMedicalActs.push(rum.codedMedicalActs)

    if (rum.standardModeMajorRum === true) {
      rss.standardModeMajorRumId = rum.id
      rss.medicalUnit = rum.medicalUnit

      if (rum.standardModeDiagnosisGroup) {
        rss.standardModeDiagnosisGroup.firstDP = rum.standardModeDiagnosisGroup.firstDP
        rss.standardModeDiagnosisGroup.firstDPLastUpdate = rum.standardModeDiagnosisGroup.firstDPLastUpdate
        rss.standardModeDiagnosisGroup.firstDR = rum.standardModeDiagnosisGroup.firstDR
        rss.standardModeDiagnosisGroup.firstDRLastUpdate = rum.standardModeDiagnosisGroup.firstDRLastUpdate
        rss.standardModeDiagnosisGroup.currentDP = rum.standardModeDiagnosisGroup.currentDP
        rss.standardModeDiagnosisGroup.currentDPLastUpdate = rum.standardModeDiagnosisGroup.currentDPLastUpdate
        rss.standardModeDiagnosisGroup.currentDP = rum.standardModeDiagnosisGroup.currentDP
        rss.standardModeDiagnosisGroup.currentDPLastUpdate = rum.standardModeDiagnosisGroup.currentDPLastUpdate
        rss.standardModeDiagnosisGroup.currentDPOrigin = rum.standardModeDiagnosisGroup.currentDPOrigin
        rss.standardModeDiagnosisGroup.currentDR = rum.standardModeDiagnosisGroup.currentDR
        rss.standardModeDiagnosisGroup.currentDROrigin = rum.standardModeDiagnosisGroup.currentDROrigin
        rss.standardModeDiagnosisGroup.currentDRLastUpdate = rum.standardModeDiagnosisGroup.currentDRLastUpdate
      }
    } else {
      const additionalAssociatedDiag = {
        associatedDiagnosis: {}
      }
      if (rum.standardModeDiagnosisGroup.currentDR) {
        additionalAssociatedDiag.associatedDiagnosis = rum.standardModeDiagnosisGroup.currentDR
        rss.standardModeDiagnosisGroup.associatedDiags.push(additionalAssociatedDiag)
      }

      if (rum.standardModeDiagnosisGroup.currentDP) {
        additionalAssociatedDiag.associatedDiagnosis = rum.standardModeDiagnosisGroup.currentDP
        rss.standardModeDiagnosisGroup.associatedDiags.push(additionalAssociatedDiag)
      }
    }

    if (rum.automationModeMajorRum === true) {
      rss.automationModeMajorRumId = rum.id
      rss.medicalUnit = rum.medicalUnit
      if (rum.automationModeDiagnosisGroup) {
        rss.automationModeDiagnosisGroup.currentDP = rum.automationModeDiagnosisGroup.currentDP
        rss.automationModeDiagnosisGroup.currentDPLastUpdate = rum.automationModeDiagnosisGroup.currentDPLastUpdate
        rss.automationModeDiagnosisGroup.currentDR = rum.automationModeDiagnosisGroup.currentDR
        rss.automationModeDiagnosisGroup.currentDRLastUpdate = rum.automationModeDiagnosisGroup.currentDRLastUpdate
      }
    }
  })

  rss.rumEnd = _.last(rums).rumEnd

  rss.automationModeDiagnosisGroup.associatedDiags = _.uniq(_.flatten(rss.automationModeDiagnosisGroup.associatedDiags))
  rss.standardModeDiagnosisGroup.associatedDiags = _.uniq(_.flatten(rss.standardModeDiagnosisGroup.associatedDiags))
  rss.predictedLabels = _.uniq(_.flatten(rss.predictedLabels))
  rss.displayablePredictedLabels = _.uniq(_.flatten(rss.displayablePredictedLabels))
  rss.codedMedicalActs = _.uniq(_.flatten(rss.codedMedicalActs))

  return rss
}

export {
  buildRssDisplay,
}
