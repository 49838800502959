import { ApiRequest } from '@sancare/ui-frontend-commons/src/misc/api-request'

import { DiagnosisState } from '@/store/modules/diagnosis/types'

const baseUrl = '/api/rhs/{rhsId}/diagnosis'

const state = new DiagnosisState()
state.requests = {
  addSsrDiagnosis: new ApiRequest('addSsrDiagnosis', 'POST', baseUrl),
  removeSsrDiagnosis: new ApiRequest('removeSsrDiagnosis', 'DELETE', `${baseUrl}/{associatedDiagnosisId}`),
  updateSsrDiagnosis: new ApiRequest('updateSsrDiagnosis', 'PUT', `${baseUrl}/{associatedDiagnosisId}`),
}

export { state }
