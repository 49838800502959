export default {
  standardMode: {
    label: 'Mode classique',
    settings: [
      {
        name: 'standard_mode_dp_threshold',
        label: 'Seuil de probabilité minimum d\'affichage des DP revalorisants',
        type: 'slider',
      },
      {
        name: 'standard_mode_dp_financial_threshold',
        label: 'Seuil financier minimum de revalorisation par DP',
        type: 'slider',
      },
      {
        name: 'standard_mode_dr_threshold',
        label: 'Affichage des DR',
        type: 'slider',
      },
      {
        name: 'standard_mode_das_threshold',
        label: 'Affichage des DAS',
        type: 'slider',
      },
      {
        name: 'new_coding_das_threshold',
        label: 'Taux de confiance minimal pour proposition de DAS en primo-codage',
        type: 'slider',
      },
      {
        name: 'standard_mode_hint_threshold',
        label: 'Affichage des justifications',
        type: 'slider',
      },
      {
        name: 'new_coding_stays_show_low_das',
        label: 'Affichage des DAS de niveau 1 (Primo-codage)',
        type: 'bool',
      },
      {
        name: 'new_coding_stays_show_excluded_das',
        label: 'Affichage des DAS exclus (Primo-codage)',
        type: 'bool',
      },
      {
        name: 'optimizable_stays_show_low_das',
        label: 'Affichage des DAS de niveau 1',
        type: 'bool',
      },
      {
        name: 'optimizable_stays_show_excluded_das',
        label: 'Affichage des DAS exclus (revalo)',
        type: 'bool',
      },
      {
        name: 'optimizable_stays_show_coded_das_found_by_ai',
        label: 'Affichage des DAS déjà codés trouvés par l\'IA',
        type: 'bool',
      },
      {
        name: 'ai_confidence_external_flag_threshold',
        label: 'Taux de confiance minimum des diagnostics prédits pour notifier le connecteur externe',
        type: 'slider',
      },
    ],
  },
  automation: {
    label: 'Mode automatisation',
    settings: [
      {
        name: 'automation_session_financial_risk',
        label: 'Risque financier de l\'automatisation - CMD 28 (Séances)',
        type: 'slider',
      },
      {
        name: 'automation_non_session_financial_risk',
        label: 'Risque financier de l\'automatisation - 1-2j',
        type: 'slider',
      },
      {
        name: 'automation_non_ambu_financial_risk',
        label: 'Risque financier de l\'automatisation - 0j hors ambulatoire',
        type: 'slider',
      },
      {
        name: 'automation_ambu_financial_risk',
        label: 'Risque financier de l\'automatisation - 0j ambulatoire',
        type: 'slider',
      },
      {
        name: 'automation_mode_dr_threshold',
        label: 'Affichage des DR',
        type: 'slider',
      },
      {
        name: 'related_diagnosis',
        label: 'Taux de confiance des diagnostics reliés requis',
        type: 'slider',
      },
    ]
  }
}
