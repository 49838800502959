<script>
import { mapState } from 'vuex'

import { labelSets } from '@/misc/graphicDataFormatter.js'
import { formatQuery, getUrlQuery } from '@/misc/string_utils'
import router from '@/router'

import AutomatableStaysStats from './AutomatableStaysStats.vue'
import FinancialStats from './FinancialStats.vue'
import GlobalStats from './GlobalStats.vue'
import NewCodingStaysStats from './NewCodingStaysStats.vue'
import OptimizableStaysStats from './OptimizableStaysStats.vue'
import StatsSettings from './StatsSettings.vue'

export default {
  components: {
    'stats-settings': StatsSettings,
    'global-stats': GlobalStats,
    'financial-stats': FinancialStats,
    'automatable-stays-stats': AutomatableStaysStats,
    'new-coding-stays-stats': NewCodingStaysStats,
    'optimizable-stays-stats': OptimizableStaysStats,
  },
  data() {
    return {
      chartUnit: 'month',
    }
  },
  computed: {
    ...mapState({
      globalStats: (state) => state.stats.globalStats,
      earlyLimit: (state) => state.settings.earlyLimit,
      lateLimit: (state) => state.settings.lateLimit,
      selectedMedicalUnits: (state) => state.settings.selectedMedicalUnits,
    }),
    labelSets() {
      return labelSets(this.globalStats, this.chartUnit)
    }
  },
  methods: {
    setEarlyLimit(lim) {
      this.$store.commit('setEarlyLimit', lim)
      router.replace({ path: this.$store.state.route.path, query: { ...this.$store.state.route.query, el: lim.format('DD-MM-YYYY') } })
    },
    setLateLimit(lim) {
      this.$store.commit('setLateLimit', lim)
      router.replace({ path: this.$store.state.route.path, query: { ...this.$store.state.route.query, ll: lim.format('DD-MM-YYYY') } })
    },
    setChartUnit(unit) {
      this.chartUnit = unit
    },
    exportStats() {
      const urlArgs = {
        earlyLimit: this.earlyLimit,
        lateLimit: this.lateLimit,
        chartUnit: this.chartUnit,
        medicalUnits: this.selectedMedicalUnits,
      }
      const url = `/api/stats/export-csv?${getUrlQuery(urlArgs)}`

      window.open(url, '_blank')
    },
    setSelectedMedicalUnits(medicalUnitList) {
      this.$store.commit('setSelectedMedicalUnits', medicalUnitList)
      router.replace({ path: this.$store.state.route.path, query: formatQuery({ ...this.$store.state.route.query, um: medicalUnitList }) })
    },
  },
}
</script>

<template>
  <div class="container">
    <stats-settings
      :early-limit="earlyLimit"
      :late-limit="lateLimit"
      :chart-unit="chartUnit"
      @set-early-limit="setEarlyLimit"
      @set-late-limit="setLateLimit"
      @set-chart-unit="setChartUnit"
      @set-selected-medical-units="setSelectedMedicalUnits"
    />
    <div class="d-flex flex-row-reverse">
      <button
        class="btn btn-start-rss-pipeline"
        @click="exportStats()"
      >
        Exporter les métriques au format CSV
      </button>
    </div>
    <global-stats
      :early-limit="earlyLimit"
      :late-limit="lateLimit"
      :chart-unit="chartUnit"
      :label-sets="labelSets"
      :selected-medical-units="selectedMedicalUnits"
    />
    <financial-stats
      :early-limit="earlyLimit"
      :late-limit="lateLimit"
      :chart-unit="chartUnit"
      :label-sets="labelSets"
      :selected-medical-units="selectedMedicalUnits"
    />
    <automatable-stays-stats
      :early-limit="earlyLimit"
      :late-limit="lateLimit"
      :chart-unit="chartUnit"
      :selected-medical-units="selectedMedicalUnits"
      :label-sets="labelSets"
    />
    <new-coding-stays-stats
      :early-limit="earlyLimit"
      :late-limit="lateLimit"
      :chart-unit="chartUnit"
      :selected-medical-units="selectedMedicalUnits"
      :label-sets="labelSets"
    />
    <optimizable-stays-stats
      :early-limit="earlyLimit"
      :late-limit="lateLimit"
      :chart-unit="chartUnit"
      :selected-medical-units="selectedMedicalUnits"
      :label-sets="labelSets"
    />
  </div>
</template>
