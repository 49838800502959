<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { mapGetters } from 'vuex'

import { ModeEnum, PmsiTypeEnum, PrivacyEnum } from '@/store/modules/settings/types'

import SavedSearchThumbnail from '../saved-searches/SavedSearchThumbnail.vue'
import HomeThumbnail from './HomeThumbnail.vue'

export default  {
  name: 'SancareCategory',
  components: {
    'sancare-octicon': SancareOcticon,
    'saved-search-thumbnail': SavedSearchThumbnail,
    'home-thumbnail': HomeThumbnail,
  },
  props: {
    category: { type: Object, required: true },
    selectedSearches: { type: Array, required: true },
    mode: { type: String, required: true },
    quickFilterResults: { type: Array, default: null },
    searchesPrivacy: { type: String, required: true },
    pmsiType: {
      validator(value) {
        return Object.values(PmsiTypeEnum).includes(value)
      },
      default: PmsiTypeEnum.MCO
    },
  },
  emits: ['refresh-stats', 'select-search'],
  data() {
    return {
      expand: false,
    }
  },
  computed: {
    PrivacyEnum() {
      return PrivacyEnum
    },
    ModeEnum() {
      return ModeEnum
    },
    ...mapGetters(['settingsUrlQuery', 'flatSancareSettings']),
    isSancareUser() {
      return this.$store.state.settings.currentUser.sancareUser
    },
    displayedSearches() {
      return _.map(
        _.filter(this.category.searches, (s) => {
          if (!_.isNull(this.quickFilterResults)) {
            return this.quickFilterResults.indexOf(s.id) !== -1
          }

          return s.countTodoStay || this.expand
        }),
        (s) => ({
          ...s,
          stayListQuery: {
            mode: this.mode,
            revType: 'undetermined',
            searchId: [s.id],
            ...this.settingsUrlQuery
          },
        })
      )
    },
    categoryStayListQuery() {
      return {
        mode: this.mode,
        revType: 'undetermined',
        searchId: _.map(this.category.searches, 'id'),
        ...this.settingsUrlQuery
      }
    },
    hasHideableSearches() {
      return _.some(this.category.searches, (s) => s.countTodoStay === 0) && _.isNull(this.quickFilterResults)
    },
  },
  methods: {
    refreshStats(searchIds) {
      this.$emit('refresh-stats', searchIds)
    }
  }
}
</script>

<template>
  <div
    v-if="categoryStayListQuery.searchId.length > 0"
    class="homepage-sancare-category home-list-part"
  >
    <div class="row no-gutters">
      <h2 class="col pl-2 home-list-part-title p-2">
        {{ category.name || 'Autres' }}
      </h2>
      <div class="col-1 text-right">
        <span
          v-if="hasHideableSearches"
          :class="{
            'home-list-chevron--extend': !expand,
            'home-list-chevron--reduce': expand,
          }"
          class="home-list-chevron"
          @click="expand = !expand"
        >
          <sancare-octicon
            :name="expand ? 'chevron-up' : 'chevron-down'"
            :width="20"
            :height="20"
          />
        </span>
      </div>
    </div>
    <div class="row no-gutters">
      <div
        v-if="searchesPrivacy === PrivacyEnum.SANCARE_SEARCH && mode !== ModeEnum.QUALITY_STAYS"
        class="col-3 p-2"
      >
        <home-thumbnail
          :name="`category_${category.name}`"
          :count="category.countTodoStay"
          :label="'Voir tous les séjours de la catégorie'"
          :color="category.id"
          :stay-list-query="categoryStayListQuery"
          :pmsi-type="pmsiType"
        />
      </div>
      <div
        v-for="search in displayedSearches"
        :key="search.id"
        class="col-3 p-2"
      >
        <saved-search-thumbnail
          :search="search"
          :selected="selectedSearches.indexOf(search.id) !== -1"
          :stay-list-query="search.stayListQuery"
          @select-search="$emit('select-search', search)"
          @refresh-stats="refreshStats"
        />
      </div>
    </div>
  </div>
</template>
