import { ApiRequest } from '@sancare/ui-frontend-commons/src/misc/api-request'

import { McoStayState } from '@/store/modules/stay/mco/types'

const baseUrl = '/api/stays'

export const state = new McoStayState()
// to be completed
state.requests = {
  fetchStayActions: new ApiRequest('fetchStayActions', 'GET', `${baseUrl}/{id}/actions`),
}
