<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'

import router from '@/router'

export default {
  components: {
    'sancare-octicon': SancareOcticon,
  },
  props: {
    stay: { type: Object, required: true },
  },
  methods: {
    disableAutomation() {
      this.$store.dispatch('disableAutomation', { stayId: this.stay.id })
      this.$store.commit('resetReadingList')
      router.replace({
        query: { mode: 'new-coding' }
      })
    },
  },
}
</script>

<template>
  <div
    class="btn-divided btn-divided-mco btn-divided--warning row no-gutters align-items-center"
  >
    <div class="col py-2 px-3">
      Passer en mode manuel
    </div>
    <div
      class="col-auto py-2 px-3 text-center btn-divided__action"
      @click="disableAutomation"
    >
      <sancare-octicon name="arrow-right" />
    </div>
  </div>
</template>
