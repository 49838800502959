import { getDefaultEarlyLimit } from '@sancare/ui-frontend-commons/src/misc/date-utils'
import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'

import Api from '@/misc/api'

const initialUrl = new URL(location.href)
let initialEarlyLimit = null
let initialLateLimit = null
if (initialUrl.searchParams.get('el') && dayjs(initialUrl.searchParams.get('el'), 'DD-MM-YYYY').isValid()) {
  initialEarlyLimit = dayjs(initialUrl.searchParams.get('el'), 'DD-MM-YYYY')
  sessionStorage.setItem('earlyLimit', initialEarlyLimit.format())
} else if (sessionStorage.getItem('earlyLimit') && dayjs(sessionStorage.getItem('earlyLimit')).isValid()) {
  initialEarlyLimit = dayjs(sessionStorage.getItem('earlyLimit'))
} else {
  sessionStorage.removeItem('earlyLimit')
  initialEarlyLimit = getDefaultEarlyLimit()
}
if (initialUrl.searchParams.get('ll') && dayjs(initialUrl.searchParams.get('ll'), 'DD-MM-YYYY').isValid()) {
  initialLateLimit = dayjs(initialUrl.searchParams.get('ll'), 'DD-MM-YYYY')
  sessionStorage.setItem('lateLimit', initialLateLimit.format())
} else if (sessionStorage.getItem('lateLimit') && dayjs(sessionStorage.getItem('lateLimit')).isValid()) {
  initialLateLimit = dayjs(sessionStorage.getItem('lateLimit'))
} else {
  sessionStorage.removeItem('lateLimit')
  initialLateLimit = dayjs()
}

export default {
  state: {
    falseJustifications: [],
    getFalseJustificationsRequest: { fetching: false, error: null, ok: false },

    earlyLimit: initialEarlyLimit,
    lateLimit: initialLateLimit,
  },
  mutations: {
    getFalseJustificationsStart(state) {
      state.getFalseJustificationsRequest = { fetching: true, error: null, ok: false }
    },
    getFalseJustificationsSuccess(state, falseJustifications) {
      state.getFalseJustificationsRequest = { fetching: false, error: null, ok: true }
      state.falseJustifications = falseJustifications
    },
    getFalseJustificationsError(state, res) {
      state.getFalseJustificationsRequest = { fetching: false, error: res.message, ok: false }
    }
  },
  actions: {
    getFalseJustifications({ commit }) {
      return Api.get(
        {
          url: '/api/false-justification/',
          startMut: 'getFalseJustificationsStart',
          successMut: 'getFalseJustificationsSuccess',
          errorMut: 'getFalseJustificationsError',
        },
        commit
      )
    }
  }
}
