<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { mapState } from 'vuex'

export default {
  components: {
    'sancare-octicon': SancareOcticon,
  },
  data() {
    return {
      isCopied: false,
    }
  },
  computed: mapState({
    apiKeyExists: (state) => state.manageApiKey.apiKeyExists,
    apiKey: (state) => state.manageApiKey.apiKey,
    checkApiKeyFetching: (state) => state.manageApiKey.checkApiKeyRequest.fetching,
    generateApiKeyFetching: (state) => state.manageApiKey.generateApiKeyRequest.fetching,
  }),
  mounted() {
    this.$store.dispatch('checkApiKey')
  },
  methods: {
    generateApiKey() {
      this.$store.dispatch('generateApiKey')
    },
    copyApiKey() {
      document.getElementById('api-key-input').select()
      document.execCommand('copy')
      this.isCopied = true
      window.setTimeout(() => (this.isCopied = false), 2000)
    },
  },
}
</script>

<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-5 card card-manage-api-key">
        <div class="card-body">
          <h1 class="text-center mb-3">
            Gestion des clés API
          </h1>
          <div v-if="!checkApiKeyFetching">
            <div class="row">
              <div class="col-3 text-center">
                <span v-if="apiKeyExists">
                  <sancare-octicon
                    name="key"
                    :width="100"
                    :height="100"
                  />
                </span>
                <span v-else>
                  <sancare-octicon
                    name="lock"
                    :width="125"
                    :height="125"
                  />
                </span>
              </div>
              <div
                v-if="!apiKey && apiKeyExists"
                class="col-9"
              >
                <div class="mb-2">
                  Une clé d'accès à l'API existe déjà.
                </div>
                <div class="alert alert-warning">
                  Si vous l'avez perdu, il est possible d'en générer une nouvelle, mais il faudra mettre à jour l'ensemble des scripts et services utilisant cette clé.
                </div>
                <div class="text-right">
                  <button
                    :class="{ disabled: apiKey }"
                    class="btn btn-danger"
                    @click="generateApiKey"
                  >
                    Régénérer une clé API
                  </button>
                </div>
              </div>
              <div
                v-else-if="!apiKey && !apiKeyExists"
                class="col-9 text-center"
              >
                <div class="mb-2">
                  Vous n'avez pas encore généré de clé d'accès à l'API
                </div>
                <button
                  class="btn btn-success"
                  @click="generateApiKey"
                >
                  Génerer une clé API
                </button>
              </div>
              <div
                v-else
                class="col-9"
              >
                <div class="row">
                  <div class="col">
                    Voici la clé API:
                  </div>
                </div>
                <div class="row">
                  <pre class="col api-key-code">{{ apiKey }}</pre>
                  <div class="mt-3 col-auto">
                    <span
                      v-tooltip="{
                        content: 'Copié !',
                        trigger: 'manual',
                        show: isCopied,
                        placement: 'right'
                      }"
                      @click="copyApiKey"
                    >
                      <sancare-octicon
                        name="clippy"
                        :width="10"
                        :height="10"
                      />
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col alert alert-danger">
                    Gardez-la précieusement et ne la perdez pas, celle-ci ne pourra pas être affichée à nouveau. Traitez-la avec les mêmes précautions de sécurité qu'un mot de passe.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <hr>
            <div>Pour utiliser une clé API, envoyez-la dans le header HTTP <code>Authorization</code> pour toute requête à destination de l'API, avec le format suivant :</div>
            <pre class="api-key-code">Bearer &lt;valeur de la clé&gt;</pre>
          </div>
        </div>
      </div>
    </div>
    <input
      id="api-key-input"
      v-model="apiKey"
      type="text"
      style="position: absolute; top: -50px; left: 0;"
    >
  </div>
</template>
