<script>
import { DatePicker, SancareMultiselect } from '@sancare/ui-frontend-commons'
import { mapState } from 'vuex'

export default {
  components: {
    'date-picker': DatePicker,
    'sancare-multiselect': SancareMultiselect,
  },
  props: {
    earlyLimit: { type: Object, required: true },
    lateLimit: { type: Object, required: true },
    chartUnit: { type: String, default: undefined },
    areIntervalsDisplayed: { type: Boolean, default: true }
  },
  emits: ['set-early-limit', 'set-late-limit', 'set-selected-medical-units', 'set-chart-unit'],
  computed: {
    ...mapState({
      medicalUnits: (state) => state.settings.medicalUnits,
      selectedMedicalUnits: (state) => state.settings.selectedMedicalUnits || [],
      medicalUnitsRequest: (state) => state.medicalUnitFilter.medicalUnitsRequest,
    }),
  },
  methods: {
    setEarlyLimit(limit) {
      this.$emit('set-early-limit', limit)
    },
    setLateLimit(limit) {
      this.$emit('set-late-limit', limit)
    },
    updateMedicalUnits(medicalUnitList) {
      this.$emit('set-selected-medical-units', medicalUnitList)
    },
  }
}
</script>

<template>
  <div class="row m-1 align-items-center">
    <div class="col-auto row no-gutters align-items-center">
      <div class="col-auto px-2">
        Période : du
      </div>
      <div class="col-auto px-2">
        <date-picker
          :model-value="earlyLimit"
          @update:model-value="setEarlyLimit"
        />
      </div>
      <div class="col-auto px-1">
        au
      </div>
      <div class="col-auto px-2">
        <date-picker
          :model-value="lateLimit"
          @update:model-value="setLateLimit"
        />
      </div>
    </div>
    <div class="col-auto">
      <sancare-multiselect
        :title="'Unités médicales'"
        :selected-items="selectedMedicalUnits"
        :items="medicalUnits"
        :is-ready="!medicalUnitsRequest.fetching"
        :none-label="'Toutes'"
        :all-label="'Toutes'"
        @update-selection="updateMedicalUnits"
      />
    </div>
    <div
      v-if="areIntervalsDisplayed"
      class="col-auto ml-auto row intervals"
    >
      <div class="col" />
      <div class="col-auto row align-items-center">
        <div class="col-auto">
          Intervalles :
        </div>
        <div class="col-auto pt-2">
          <label class="row no-gutters align-items-center">
            <input
              id="week_interval"
              type="radio"
              :checked="chartUnit === 'week'"
              class="mr-1"
              @input="$emit('set-chart-unit', 'week')"
            >
            Semaines
          </label>
        </div>
        <div class="col-auto pt-2">
          <label class="row no-gutters align-items-center">
            <input
              id="month_interval"
              type="radio"
              :checked="chartUnit === 'month'"
              class="mr-1"
              @input="$emit('set-chart-unit', 'month')"
            >
            Mois
          </label>
        </div>
        <div class="col-auto pt-2">
          <label class="row no-gutters align-items-center">
            <input
              id="year_interval"
              type="radio"
              :checked="chartUnit === 'year'"
              class="mr-1"
              @input="$emit('set-chart-unit', 'year')"
            >
            Années
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
