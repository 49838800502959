import _ from 'lodash'

import Api from '@/misc/api'
import { getUrlQuery } from '@/misc/string_utils'

export default {
  state: {
    savedSearches: [],
    savedSearchesCategories: [],
    savedSearchesGroups: [],

    fetchSavedSearchesRequest: { fetching: false, error: null, ok: false },
    addSavedSearchRequest: { fetching: false, error: null, ok: false },
    editSavedSearchRequest: { fetching: false, error: null, ok: false },
    removeSavedSearchRequest: { fetching: false, error: null, ok: false },
    enableSavedSearchRequest: { fetching: false, error: null, ok: false },
    disableSavedSearchRequest: { fetching: false, error: null, ok: false },
    enableAutomationSavedSearchRequest: { fetching: false, error: null, ok: false },
    validateTeamSearchRequest: { fetching: false, error: null, ok: false },
    toBeRemovedTeamSearchRequest: { fetching: false, error: null, ok: false },

    fetchSavedSearchesCategoriesRequest: { fetching: false, error: null, ok: false },

    fetchSavedSearchesGroupsRequest: { fetching: false, error: null, ok: false },
    addSavedSearchesGroupRequest: { fetching: false, error: null, ok: false },
    fetchSavedSearchesGroupRequest: { fetching: false, error: null, ok: false },
    editSavedSearchesGroupRequest: { fetching: false, error: null, ok: false },
    removeSavedSearchesGroupRequest: { fetching: false, error: null, ok: false },
  },
  mutations: {
    fetchSavedSearchesStart(state) {
      state.fetchSavedSearchesRequest = { fetching: true, error: null, ok: false }
    },
    // mutate all searches
    fetchSavedSearchesSuccess(state, response) {
      state.fetchSavedSearchesRequest = { fetching: false, error: null, ok: true }
      state.savedSearches = response.searches.map((search) => {
        const stats = response.stats.find((stat) => stat.id === search.id) ?? { countStay: 0, countTodoStay: 0 }
        return { ...search, ...stats }
      })
    },
    // replace only fetched searches
    fetchSomeSavedSearchesSuccess(state, response) {
      state.fetchSavedSearchesRequest = { fetching: false, error: null, ok: true }

      const updatedSearches = state.savedSearches.map((storeSearch) => {
        const responseSearch = response.searches.find((savedSearch) => savedSearch.id === storeSearch.id)
        const stats = response.stats.find((stat) => stat.id === storeSearch.id)
          ?? { countStay: storeSearch.countStay, countTodoStay: storeSearch.countTodoStay }
        return responseSearch ? { ...responseSearch, ...stats } : storeSearch
      })

      const newSearches = response.searches.filter((search) => {
        return !updatedSearches.some((savedSearch) => savedSearch.id === search.id)
      }).map((newSearch) => {
        const stats = response.stats.find((stat) => stat.id === newSearch.id)
          ?? { countStay: 0, countTodoStay: 0 }
        return { ...newSearch, ...stats }
      })

      state.savedSearches = updatedSearches.concat(newSearches)
    },
    fetchSavedSearchesError(state, res) {
      state.fetchSavedSearchesRequest = { fetching: false, error: res.message, ok: false }
      state.savedSearches = []
    },
    addSavedSearchStart(state) {
      state.addSavedSearchRequest = { fetching: true, error: null, ok: false }
    },
    addSavedSearchSuccess(state, savedSearch) {
      state.addSavedSearchRequest = { fetching: false, error: null, ok: true }
      state.savedSearches.push(savedSearch)
      return savedSearch
    },
    addSavedSearchError(state, res) {
      state.addSavedSearchRequest = { fetching: false, error: res.message, ok: false }
    },
    fetchSavedSearchStart(state) {
      state.fetchSavedSearchRequest = { fetching: true, error: null, ok: false }
    },
    fetchSavedSearchSuccess(state, search) {
      state.fetchSavedSearchRequest = { fetching: false, error: null, ok: true }
      state.savedSearches = _.map(state.savedSearches, (s) => s.id === search.id ? { ...s, ...search } : s)
      return search
    },
    fetchSavedSearchError(state, res) {
      state.fetchSavedSearchRequest = { fetching: false, error: res.message, ok: false }
    },
    editSavedSearchStart(state) {
      state.editSavedSearchRequest = { fetching: true, error: null, ok: false }
    },
    editSavedSearchSuccess(state, search) {
      state.editSavedSearchRequest = { fetching: false, error: null, ok: true }
      state.savedSearches = _.map(state.savedSearches, (s) => s.id === search.id ? { ...s, ...search } : s)
      return search
    },
    editSavedSearchError(state, res) {
      state.editSavedSearchRequest = { fetching: false, error: res.message, ok: false }
    },
    removeSavedSearchStart(state) {
      state.removeSavedSearchRequest = { fetching: true, error: null, ok: false }
    },
    removeSavedSearchSuccess(state, searchId) {
      state.removeSavedSearchRequest = { fetching: false, error: false, ok: true }
      state.savedSearches = _.filter(state.savedSearches, (s) => s.id !== searchId)
    },
    removeSavedSearchError(state, res) {
      state.removeSavedSearchRequest = { fetching: false, error: res.message, ok: false }
    },
    enableSavedSearchStart(state) {
      state.enableSavedSearchRequest = { fetching: true, error: null, ok: false }
    },
    enableSavedSearchSuccess(state, search) {
      state.enableSavedSearchRequest = { fetching: false, error: false, ok: true }
      state.savedSearches = _.map(state.savedSearches, (s) => s.id === search.id ? { ...s, ...search } : s)
    },
    enableSavedSearchError(state, res) {
      state.enableSavedSearchRequest = { fetching: false, error: res.message, ok: false }
    },
    disableSavedSearchStart(state) {
      state.disableSavedSearchRequest = { fetching: false, error: null, ok: false }
    },
    disableSavedSearchSuccess(state, search) {
      state.disableSavedSearchRequest = { fetching: false, error: false, ok: true }
      state.savedSearches = _.map(state.savedSearches, (s) => s.id === search.id ? { ...s, ...search } : s)
    },
    disableSavedSearchError(state, res) {
      state.disableSavedSearchRequest = { fetching: false, error: res.message, ok: false }
    },

    fetchSavedSearchesCategoriesStart(state) {
      state.fetchSavedSearchesRequest = { fetching: true, error: null, ok: false }
    },
    fetchSavedSearchesCategoriesSuccess(state, categories) {
      state.fetchSavedSearchesCategoriesRequest = { fetching: false, error: null, ok: true }

      const updatedSavedSearchesCategories = state.savedSearchesCategories.map((storeCategory) =>
        categories.find((category) => category.id === storeCategory.id) || storeCategory)
      const newSavedSearchesCategories = categories.filter(
        (category) => !updatedSavedSearchesCategories.some((storeCategory) => storeCategory.id === category.id)
      )

      state.savedSearchesCategories = updatedSavedSearchesCategories.concat(newSavedSearchesCategories)
    },
    fetchSavedSearchesCategoriesError(state, res) {
      state.fetchSavedSearchesRequest = { fetching: false, error: res.message, ok: false }
      state.savedSearchesCategories = []
    },
    fetchSavedSearchesGroupsStart(state) {
      state.fetchSavedSearchesGroupsRequest = { fetching: true, error: null, ok: false }
    },
    // mutate all groups
    fetchSavedSearchesGroupsSuccess(state, response) {
      state.fetchSavedSearchesGroupsRequest = { fetching: false, error: null, ok: true }
      state.savedSearchesGroups = _.map(response.searchesGroups, (group) => {
        let stats = _.find(response.stats, (stat) => stat.id === group.id)
        if (!stats) {
          stats = { countStay: 0, countTodoStay: 0 }
        }
        return { ...group, ...stats }
      })
    },
    // replace only fetched groups
    fetchSomeSavedSearchesGroupsSuccess(state, response) {
      state.fetchSavedSearchesGroupsRequest = { fetching: false, error: null, ok: true }
      state.savedSearchesGroups = _.map(state.savedSearchesGroups, (storeGroup) => {
        const responseGroup = _.find(response.searchesGroups, (group) => group.id === storeGroup.id)
        let stats = _.find(response.stats, (stat) => stat.id === storeGroup.id)
        if (!stats) {
          stats = { countStay: 0, countTodoStay: 0 }
        }
        return responseGroup ? { ...responseGroup, ...stats } : storeGroup
      })
    },
    fetchSavedSearchesGroupsError(state, res) {
      state.fetchSavedSearchesGroupsRequest = { fetching: false, error: res.message, ok: false }
      state.savedSearchesGroups = []
    },
    addSavedSearchesGroupStart(state) {
      state.addSavedSearchesGroupRequest = { fetching: true, error: null, ok: false }
    },
    addSavedSearchesGroupSuccess(state, { searchesGroup, stats }) {
      state.addSavedSearchesGroupRequest = { fetching: false, error: null, ok: true }
      state.savedSearchesGroups.push({ ...searchesGroup, ...stats[0] })
    },
    addSavedSearchesGroupError(state, res) {
      state.addSavedSearchesGroupRequest = { fetching: false, error: res.message, ok: false }
    },
    editSavedSearchesGroupStart(state) {
      state.editSavedSearchesGroupRequest = { fetching: true, error: null, ok: false }
    },
    editSavedSearchesGroupSuccess(state, searchesGroup) {
      state.editSavedSearchesGroupRequest = { fetching: false, error: null, ok: true }
      state.savedSearchesGroups = _.map(state.savedSearchesGroups, (group) => group.id === searchesGroup.id ? searchesGroup : group)
    },
    editSavedSearchesGroupError(state, res) {
      state.editSavedSearchesGroupRequest = { fetching: false, error: res.message, ok: false }
    },
    removeSavedSearchesGroupStart(state) {
      state.removeSavedSearchesGroupRequest = { fetching: true, error: null, ok: false }
    },
    removeSavedSearchesGroupSuccess(state, groupId) {
      state.removeSavedSearchesGroupRequest = { fetching: false, error: false, ok: true }
      state.savedSearchesGroups = _.filter(state.savedSearchesGroups, (group) => group.id !== groupId)
    },
    removeSavedSearchesGroupError(state, res) {
      state.removeSavedSearchesGroupRequest = { fetching: false, error: res.message, ok: false }
    },
    enableAutomationSavedSearchStart(state) {
      state.enableAutomationSavedSearchRequest = { fetching: true, error: null, ok: false }
    },
    enableAutomationSavedSearchSuccess(state, search) {
      state.enableAutomationSavedSearchRequest = { fetching: false, error: null, ok: true }
      state.savedSearches = _.map(state.savedSearches, (s) => s.id === search.id ? { ...s, ...search } : s)
    },
    enableAutomationSavedSearchError(state, res) {
      state.enableAutomationSavedSearchRequest = { fetching: true, error: res.message, ok: false }
    },
    validateTeamSearchStart(state) {
      state.validateTeamSearchRequest = { fetching: true, error: null, ok: false }
    },
    validateTeamSearchSuccess(state, search) {
      state.validateTeamSearchRequest = { fetching: false, error: null, ok: true }
      state.savedSearches = _.map(state.savedSearches, (s) => s.id === search.id ? { ...s, ...search } : s)
    },
    validateTeamSearchError(state, res) {
      state.validateTeamSearchRequest = { fetching: true, error: res.message, ok: false }
    },
    toBeRemovedTeamSearchStart(state) {
      state.toBeRemovedTeamSearchRequest = { fetching: true, error: null, ok: false }
    },
    toBeRemovedTeamSearchSuccess(state, search) {
      state.toBeRemovedTeamSearchRequest = { fetching: false, error: null, ok: true }
      state.savedSearches = _.map(state.savedSearches, (s) => s.id === search.id ? { ...s, ...search } : s)
    },
    toBeRemovedTeamSearchError(state, res) {
      state.toBeRemovedTeamSearchRequest = { fetching: true, error: res.message, ok: false }
    },
  },
  actions: {
    fetchSavedSearches({ commit }, urlArgs) {
      let urlParams = ''
      let successMut = 'fetchSavedSearchesSuccess'
      if (urlArgs) {
        urlParams = `?${getUrlQuery(urlArgs)}`
        if (urlArgs.searchIds || urlArgs.searchMode) {
          successMut = 'fetchSomeSavedSearchesSuccess'
        }
      }
      return Api.get(
        {
          url: `/api/saved-search/searches${urlParams}`,
          startMut: 'fetchSavedSearchesStart',
          successMut,
          errorMut: 'fetchSavedSearchesError',
        },
        commit
      )
    },
    addSavedSearch({ commit }, search) {
      return Api.post(
        {
          url: '/api/saved-search/add-search',
          body: search,
          startMut: 'addSavedSearchStart',
          successMut(s) {
            commit('addSavedSearchSuccess', s)
            // call to mutation 'switchToEditSavedSearch' located in the stay-list store.js
            commit('switchToEditSavedSearch', s)
          },

          errorMut: 'addSavedSearchError',
        },
        commit
      )
    },
    fetchSavedSearch({ commit }, searchId) {
      return Api.get(
        {
          url: `/api/saved-search/search-${searchId}`,
          startMut: 'fetchSavedSearchStart',
          successMut: 'fetchSavedSearchSuccess',
          errorMut: 'fetchSavedSearchError',
        },
        commit
      )
    },
    editSavedSearch({ commit }, search) {
      return Api.put(
        {
          url: `/api/saved-search/search-${search.id}`,
          body: search,
          startMut: 'editSavedSearchStart',
          successMut: 'editSavedSearchSuccess',
          errorMut: 'editSavedSearchError',
        },
        commit
      )
    },
    removeSavedSearch({ commit }, searchId) {
      return Api.del(
        {
          url: `/api/saved-search/search-${searchId}`,
          body: '',
          startMut: 'removeSavedSearchStart',
          successMut: () => commit('removeSavedSearchSuccess', searchId),
          errorMut: 'removeSavedSearchError',
        },
        commit
      )
    },
    fetchSavedSearchesCategories({ commit }, urlArgs) {
      let urlParams = ''
      if (urlArgs) {
        urlParams = `?${getUrlQuery(urlArgs)}`
      }

      return Api.get(
        {
          url: `/api/saved-search/categories${urlParams}`,
          startMut: 'fetchSavedSearchesCategoriesStart',
          successMut: 'fetchSavedSearchesCategoriesSuccess',
          errorMut: 'fetchSavedSearchesCategoriesError',
        },
        commit
      )
    },
    fetchSavedSearchesGroups({ commit }, urlArgs) {
      let urlParams = ''
      let successMut = 'fetchSavedSearchesGroupsSuccess'
      if (urlArgs) {
        urlParams = `?${getUrlQuery(urlArgs)}`
        if (urlArgs.searchIds) {
          successMut = 'fetchSomeSavedSearchesGroupsSuccess'
        }
      }

      return Api.get(
        {
          url: `/api/saved-search/groups${urlParams}`,
          startMut: 'fetchSavedSearchesGroupsStart',
          successMut,
          errorMut: 'fetchSavedSearchesGroupsError',
        },
        commit
      )
    },
    addSavedSearchesGroup({ commit }, group) {
      return Api.post(
        {
          url: '/api/saved-search/add-group',
          body: group,
          startMut: 'addSavedSearchesGroupStart',
          successMut: 'addSavedSearchesGroupSuccess',
          errorMut: 'addSavedSearchesGroupError',
        },
        commit
      )
    },
    editSavedSearchesGroup({ commit }, group) {
      return Api.put(
        {
          url: `/api/saved-search/group-${group.id}`,
          body: group,
          startMut: 'editSavedSearchesGroupStart',
          successMut: 'editSavedSearchesGroupSuccess',
          errorMut: 'editSavedSearchesGroupError',
        },
        commit
      )
    },
    removeSavedSearchesGroup({ commit }, groupId) {
      return Api.del(
        {
          url: `/api/saved-search/group-${groupId}`,
          startMut: 'removeSavedSearchesGroupStart',
          successMut: () => commit('removeSavedSearchesGroupSuccess', groupId),
          errorMut: 'removeSavedSearchesGroupError',
        },
        commit
      )
    },
    enableSavedSearch({ commit }, searchId) {
      return Api.put(
        {
          url: `/api/saved-search/${searchId}/enable`,
          startMut: 'enableSavedSearchStart',
          successMut: 'enableSavedSearchSuccess',
          errorMut: 'enableSavedSearchError'
        },
        commit
      )
    },
    disableSavedSearch({ commit }, searchId) {
      return Api.put(
        {
          url: `/api/saved-search/${searchId}/disable`,
          startMut: 'disableSavedSearchStart',
          successMut: 'disableSavedSearchSuccess',
          errorMut: 'disableSavedSearchError'
        },
        commit
      )
    },
    validateTeamSearch({ commit }, { searchId }) {
      return Api.put(
        {
          url: `/api/saved-search/${searchId}/validate-team-search`,
          startMut: 'validateTeamSearchStart',
          successMut: 'validateTeamSearchSuccess',
          errorMut: 'validateTeamSearchError',
        },
        commit
      )
    },
    toBeRemovedTeamSearch({ commit }, { searchId, toBeRemoved }) {
      return Api.put(
        {
          url: `/api/saved-search/${searchId}/toberemoved-team-search`,
          body: { toBeRemoved },
          startMut: 'toBeRemovedTeamSearchStart',
          successMut: 'toBeRemovedTeamSearchSuccess',
          errorMut: 'toBeRemovedTeamSearchError',
        },
        commit
      )
    }
  },
}
