<script>

import { mapState } from 'vuex'

import FalseJustificationCard from './FalseJustificationCard.vue'

export default {
  name: 'FalseJustificationPage',
  components: {
    'false-justification-card': FalseJustificationCard,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      falseJustifications: (state) => state.falseJustifications.falseJustifications,
      earlyLimit: (state) => state.settings.earlyLimit,
      lateLimit: (state) => state.settings.lateLimit
    })
  },
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.$store.dispatch('getFalseJustifications')
    }
  },
}
</script>

<template>
  <div class="container">
    <h1>Liste des signalements ({{ falseJustifications.length }})</h1>
    <false-justification-card
      v-for="falseJustification in falseJustifications"
      :key="falseJustification.id"
      :false-justification="falseJustification"
    />
  </div>
</template>
