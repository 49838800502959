import { McoStay } from '@/store/modules/stay/mco/types'

// TODO: Use new raw and displayed MCO stay types
export function refreshActs(originalStay: McoStay, newStay: McoStay) {
  for (const rum of newStay.rums) {
    const originalRum = originalStay.rums.find((r) => r.id === rum.id)
    originalRum.codedMedicalActs = [...rum.codedMedicalActs]
  }
}

export function refreshGroupingResult(originalStay: McoStay, newStay: McoStay) {
  originalStay.firstStandardModeValue = newStay.firstStandardModeValue
  originalStay.standardModeValue = newStay.standardModeValue
  originalStay.automationModeValue = newStay.automationModeValue
  originalStay.firstStandardModeGhm = { ...newStay.firstStandardModeGhm }
  originalStay.standardModeGhm = { ...newStay.standardModeGhm }
  originalStay.automationModeGhm = { ...newStay.automationModeGhm }
  originalStay.firstStandardModeDrg = { ...newStay.firstStandardModeDrg }
  originalStay.standardModeDrg = { ...newStay.standardModeDrg }
  // originalStay.automationModeDrg = {...newStay.automationModeDrg}
}
