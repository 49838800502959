<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { mapState } from 'vuex'

import LoadingMark from '@/misc/LoadingMark.vue'

export default {
  components: {
    'sancare-octicon': SancareOcticon,
    'loading-mark': LoadingMark,
  },
  props: {
    stay: { type: Object, required: true },
    mode: { type: String, required: true },
  },
  computed: {
    ...mapState({
      updateStayStatusRequest: (state) => state.stayDisplayer.updateStayStatusRequest,
    }),
    buttonLabel() {
      return this.stay.status === 'review' ? 'Annuler à revoir' : 'Marquer à revoir'
    },
  },
  methods: {
    reviewStay() {
      if (this.updateStayStatusRequest.fetching) {
        return
      }
      this.$store.dispatch(
        'updateStayStatus',
        {
          stayId: this.stay.id,
          status: 'review',
          mode:  this.mode,
        }
      )
    },
    unReviewStay() {
      if (this.updateStayStatusRequest.fetching) {
        return
      }
      this.$store.dispatch(
        'updateStayStatus',
        {
          stayId: this.stay.id,
          status: 'unvalidated',
          mode: this.mode,
        }
      )
    },
  }
}
</script>

<template>
  <div
    :class="{ 'btn-divided--info': stay.status !== 'review' }"
    class="btn-divided btn-divided-mco"
  >
    <div class="col py-2 px-3">
      {{ buttonLabel }}
    </div>
    <div
      class="col-auto py-2 px-3 btn-divided__action"
      @click="stay.status === 'review' ? unReviewStay() : reviewStay()"
    >
      <sancare-octicon
        v-if="!updateStayStatusRequest.fetching"
        name="arrow-right"
      />
      <loading-mark
        v-else-if="updateStayStatusRequest.fetching"
        :finished="!updateStayStatusRequest.fetching"
        :color="'blue'"
      />
    </div>
  </div>
</template>
