<script>
import { mapState } from 'vuex'

import VolumeGraphics from './VolumeGraphics.vue'

export default {
  components: {
    'volume-graphics': VolumeGraphics,
  },
  props: {
    earlyLimit: { type: Object, required: true },
    lateLimit: { type: Object, required: true },
    chartUnit: { type: String, default: 'month' },
    labelSets: { type: Array, required: true },
  },
  computed: {
    ...mapState({
      stats(state) {
        return state.stats.automatableStats
      },
    }),
    statsPercent() {
      return this.stats.map((s) => {
        if (s.countStay <= 0) {
          return {
            prctValidatedStay: 0,
            prctValidatedSessionStay: 0,
            prctValidatedLongerStay: 0,
            prctValidatedAmbuStay: 0,
            prctValidatedOtherZeroDayStay: 0,
          }
        }
        return {
          prctValidatedStay: ((s.countAutomatedStay / s.countStay) * 100.0).toFixed(2),
          prctValidatedSessionStay: ((s.countAutomatedSessionStay / s.countStay) * 100.0).toFixed(2),
          prctValidatedLongerStay: ((s.countAutomatedLongerStay / s.countStay) * 100.0).toFixed(2),
          prctValidatedAmbuStay: ((s.countAutomatedAmbuStay / s.countStay) * 100.0).toFixed(2),
          prctValidatedOtherZeroDayStay: ((s.countAutomatedOtherZeroDayStay / s.countStay) * 100.0).toFixed(2),
        }
      })
    },
  },
  watch: {
    earlyLimit() {
      this.refresh()
    },
    lateLimit() {
      this.refresh()
    },
    chartUnit() {
      this.refresh()
    },
  },
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.$store.dispatch('getAutomatableStaysStats', {
        earlyLimit: this.earlyLimit.format('YYYY-MM-DD'),
        lateLimit: this.lateLimit.format('YYYY-MM-DD'),
        unit: this.chartUnit
      })
    },
  }
}
</script>

<template>
  <div class="stat">
    <h1 class="stat-title">
      Automatisation
    </h1>
    <volume-graphics
      :label-sets="labelSets"
      :stats="stats"
      :stats-percent="statsPercent"
      :prct-infos="{
        prctValidatedSessionStay: { label: 'Automatisés (séances)', unit: '%' },
        prctValidatedAmbuStay: { label: 'Automatisés (ambulatoire)', unit: '%' },
        prctValidatedOtherZeroDayStay: { label: 'Automatisés (0 jour hors ambulatoire et séances)', unit: '%' },
        prctValidatedLongerStay: { label: 'Automatisés (1-2 jours)', unit: '%' },
      }"
      :stats-infos="{
        countAutomatableStay: { label: 'Automatisables', unit: 'séjour(s)' },
        countAutomatedSessionStay: { label: 'Automatisés (séances)', unit: 'séjour(s)' },
        countAutomatedLongerStay: { label: 'Automatisés (1-2 jours)', unit: 'séjour(s)' },
        countAutomatedAmbuStay: { label: 'Automatisés (ambulatoire)', unit: 'séjour(s)' },
        countAutomatedOtherZeroDayStay: { label: 'Automatisés (0 jour hors ambulatoire et séances)', unit: 'séjour(s)' },
        countAutomatedStay: { label: 'Automatisés (total)', unit: 'séjour(s)' },
      }"
    />
  </div>
</template>
