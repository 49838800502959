<script setup>
import { computed } from 'vue'

import useCoreGroupingCostProps from '@/stay-displayer/grouping/grouping-cost-props'
import GroupingCost from '@/stay-displayer/grouping/GroupingCost.vue'

const props = defineProps({
  ...useCoreGroupingCostProps(),
  gmt: {
    type: String,
    default: null
  }
})

const isGmtSet = computed(() => props.gmt !== null && props.gmt !== '')
const gmtContent = computed(() => isGmtSet.value ? `GMT : ${props.gmt}` : '')
</script>

<template>
  <grouping-cost
    v-tooltip.left="gmtContent"
    :class="{ 'grouping-cost-context' : isGmtSet }"
    :cost="cost"
    :size="size"
  />
</template>

<style scoped>
.grouping-cost-context {
  cursor: context-menu;
}
</style>