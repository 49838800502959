<script>
import { mapState } from 'vuex'

import VolumeGraphics from './VolumeGraphics.vue'

export default {
  components: {
    'volume-graphics': VolumeGraphics,
  },
  props: {
    earlyLimit: { type: Object, required: true },
    lateLimit: { type: Object, required: true },
    chartUnit: { type: String, default: 'month' },
    selectedMedicalUnits: { type: Array, default: null },
    labelSets: { type: Array, required: true },
  },
  computed: {
    ...mapState({
      stats: (state) => state.stats.globalStats,
      statsPercent(state) {
        return state.stats.globalStats.map((s) => {
          return {
            prctAutomatableStay: s.countStay > 0 ? ((s.countAutomatableStay / s.countStay) * 100.0).toFixed(2) : 0,
            prctShortStay: s.countStay > 0 ? ((s.countNewCodingStay / s.countStay) * 100.0).toFixed(2) : 0,
            prctLongStay: s.countStay > 0 ? ((s.countOptimizableStay / s.countStay) * 100.0).toFixed(2) : 0,
          }
        })
      },
    }),
  },
  watch: {
    earlyLimit() {
      this.refresh()
    },
    lateLimit() {
      this.refresh()
    },
    chartUnit() {
      this.refresh()
    },
    selectedMedicalUnits() {
      this.refresh()
    }
  },
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.$store.dispatch('getGlobalStats', {
        earlyLimit: this.earlyLimit.format('YYYY-MM-DD'),
        lateLimit: this.lateLimit.format('YYYY-MM-DD'),
        unit: this.chartUnit,
        medicalUnits: this.selectedMedicalUnits,
      })
    },
  }
}
</script>

<template>
  <div class="stat">
    <h1 class="stat-title">
      Statistiques Globales
    </h1>
    <volume-graphics
      :label-sets="labelSets"
      :stats="stats"
      :stats-percent="statsPercent"
      :prct-infos="{
        prctAutomatableStay: { label: 'Automatisation', unit: '%' },
        prctShortStay: { label: 'Primo-codage', unit: '%' },
        prctLongStay: { label: 'Séjours revalorisables', unit: '%' }
      }"
      :stats-infos="{
        countStay: { label: 'Total', unit: 'séjour(s)' },
        countAutomatableStay: { label: 'Automatisation', unit: 'séjour(s)' },
        countNewCodingStay: { label: 'Primo-codage', unit: 'séjour(s)' },
        countOptimizableStay: { label: 'Séjours optimisables', unit: 'séjour(s)' }
      }"
    />
  </div>
</template>
