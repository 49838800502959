<script>
import JustificationSummary from './JustificationSummary.vue'

export default {
  components: {
    'justification-summary': JustificationSummary,
  },
  props: {
    canShowRational: { type: Boolean, required: true },
    isLoading: { type: Boolean, default: false },
    selected: { type: Boolean, required: true },
    selection: { type: Object, default: null },
    predictedDiagnosis: { type: Object, required: true },
    healthData: { type: Object, required: true },
    displayedRumIdx: { type: Number, required: true },
    rumPredictedLabels: { type: Array, default: null },
  },
  emits: ['make-selection'],
  data() {
    return {
      showRationals: false,
    }
  },
  computed: {
    hasRational() {
      return this.predictedDiagnosis.hints.length > 0
    },
  },
  methods: {
    handleHealthDataSelection(mainContent) {
      this.$store.commit('shouldScroll', true)
      this.$store.commit('setMainContent', mainContent)
      if (!this.selected) {
        this.$emit('make-selection', { labelType: this.predictedDiagnosis.type, labelReference: this.predictedDiagnosis.diagnosis.reference, savedSearchIds: [] })
      }
      this.showRationals = !this.showRationals
    },
  }
}
</script>

<template>
  <div
    :class="{ selected: showRationals }"
    @click="showRationals = !showRationals"
  >
    <v-dropdown
      :shown="showRationals"
      placement="left"
      class="popover-container"
      popover-class="justification-popover"
    >
      <slot />
      <template #popper>
        <justification-summary
          :is-loading="isLoading"
          :chunks="canShowRational ? predictedDiagnosis.chunks : null"
          :health-data="healthData"
          :no-value-label="hasRational ? 'Justification non disponible en raison des paramètres' : 'Pas de justification'"
          :displayed-rum-idx="displayedRumIdx"
          :rum-predicted-labels="rumPredictedLabels"
          :selection="selection"
          @select-health-data="handleHealthDataSelection"
        />
      </template>
    </v-dropdown>
  </div>
</template>
