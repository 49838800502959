<script>

import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import _ from 'lodash'
import { mapState } from 'vuex'

import AutomationGroupDetails from './AutomationGroupDetails.vue'

export default {
  components: {
    'automation-group-details': AutomationGroupDetails,
  },
  data() {
    return {
      selectedVersion: null,
    }
  },
  computed: {
    ...mapState({
      automationGroup: (state) => state.automation.editedAutomationGroup,
      automationGroupVersions: (state) => state.automation.automationGroupVersions,
      getAutomationGroupRequest: (state) => state.automation.getAutomationGroupRequest,
      getAutomationGroupVersionsRequest: (state) => state.automation.getAutomationGroupVersionsRequest,
      automationGroupId: (state) => state.route.params.automationGroupId,
    }),
    automatedGroupDataToBeShown() {
      if(this.selectedVersion === null) {
        return this.automationGroup
      }
      return this.getVersion(this.selectedVersion).state
    },
  },
  mounted() {
    this.$store.dispatch('getAutomationGroup', this.automationGroupId)
    this.$store.dispatch('getAutomationGroupVersions', this.automationGroupId)
  },
  methods: {
    getVersiontitle(versionId) {
      return versionId === null ? 'Version courante' : `Version antérieure au ${dayjs(this.getVersion(versionId).updatedAt).format('DD/MM/YYYY HH:mm')}`
    },
    getVersion(id) {
      return _.find(this.automationGroupVersions, { id: id })
    },
  },
}

</script>

<template>
  <div class="container">
    <div class="stay-list-criteria card">
      <div class="card-body">
        <h1 class="mb-4">
          Historique des versions
        </h1>
        <div class="row">
          <div class="col-3">
            <div class="list-group">
              <a
                href="#"
                class="list-group-item list-group-item-action"
                :class="{active: selectedVersion === null}"
                @click.prevent="selectedVersion = null"
              >
                <h6>Version courante</h6>
              </a>
              <a
                v-for="version in automationGroupVersions"
                :key="version.id"
                href="#"
                class="list-group-item list-group-item-action"
                :class="{active: version.id === selectedVersion}"
                @click.prevent="selectedVersion = version.id"
              >
                <h6>{{ getVersiontitle(version.id) }}</h6>
                <p>Modifiée par {{ version.user.username }}</p>
              </a>
            </div>
          </div>
          <div class="col-9">
            <div class="card px-3">
              <h2 class="mb-3">
                {{ getVersiontitle(selectedVersion) }}
              </h2>
              <div>
                Nom du groupe: <span class="badge badge-primary">{{ automatedGroupDataToBeShown.name }}</span>
              </div>
              <automation-group-details :full-automation-group="automatedGroupDataToBeShown" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
