import Api from '@sancare/ui-frontend-commons/src/misc/api'

import { state } from './state'
import { ConnectorConfig } from './types'

export const actions = {
  getConnectorsConfigList({ commit }) {
    return Api.request(state.requests.getConnectorsConfigList, {
      successMut: (response) => {
        commit('getConnectorsConfigListSuccess', response)
      }
    }, commit)
  },
  submitConnectorConfig({ commit }, payload: { connectorIndex: number, connectorData: ConnectorConfig }) {
    const apiRequest = payload.connectorData.id
      ? state.requests.putConnectorConfig
      : state.requests.postConnectorConfig

    const options = {
      body: payload.connectorData,
      successMut: (response) => {
        commit('submitConnectorConfigSuccess', { connectorIndex: payload.connectorIndex, connectorData: response })
        return response
      }
    }

    if(payload.connectorData.id) {
      options['urlParams'] = { id: String(payload.connectorData.id) }
    }

    return Api.request(apiRequest, options, commit)
  },
  deleteConnectorConfig({ commit }, payload: { connectorId: number, connectorIndex:number }) {
    return Api.request(state.requests.deleteConnectorConfig, {
      urlParams: { id: String(payload.connectorId) },
      successMut: () => {
        commit('deleteConnectorConfigSuccess', payload.connectorIndex)
      }
    }, commit)
  },
  testWriteRight({ commit }, path: string) {
    return Api.request(state.requests.testWriteRight, {
      body: { path },
      successMut: (response) => (response)
    }, commit)
  }
}
