// NOTE: when adding a new category, don't forget to also add it in SearchFieldTypeEnum.php
const documentCategories = {
  MEDICAL_HISTORY: 'Antécédents',
  BIOLOGICAL_TEST_RESULT_COMMENT: 'Commentaire de résultats biologiques',
  NURSE_REPORT: 'Dossier infirmier',
  TARGET_NURSE_REPORT: 'Transmissions ciblées',
  HOSPITAL_REPORT: 'Compte-rendu hospitalier',
  SURGERY_REPORT: 'Compte-rendu opératoire',
  ANESTHESIA_REPORT: 'Compte-rendu d\'anesthésie',
  CONSULTATION_REPORT: 'Compte-rendu de consultation',
  EXAMINATION_REPORT: 'Compte-rendu d\'examen',
  MEDICAL_REPORT: 'Autres intervenants',
  DOCTOR_REPORT: 'Observations médicales',
  ANAPATH_REPORT: 'Compte-rendu d\'examen anatomo-pathologique',
  EMERGENCY_ROOM_REPORT: 'Urgences',
  RCP_REPORT: 'Compte-rendu de réunion de concertation pluridisciplinaire'
}

const textualHealthDataCategories = {
  medicalHistory: 'Antécédents (docs regroupés)',
  nurseReport: 'Dossiers infirmiers (docs regroupés)',
  labResultComment: 'Commentaires de résultats (docs regroupés)',
  targetNurseReport: 'Transmissions ciblées (docs regroupés)',
  surgeryLabel: 'Libellés de chirurgie (docs regroupés)',
}

const healthDataLabels = {
  constant: 'Constantes',
  labResult: 'Biologie',
  medicalHistory: 'Antécédents',
  namedEntity: 'Entités nommées',
  nurseReport: 'Dossiers infirmiers',
  targetNurseReport: 'Transmissions ciblées',
  labResultComment: 'Commentaires de résultats',
  surgeryLabel: 'Libellés de chirurgie',
}

const getDocumentCategoryLabel = (categoryType) => {
  if (documentCategories[categoryType]) {
    return documentCategories[categoryType]
  }
  return 'Autres documents'
}

const getHealthDataLabel = (healthDataType) => {
  if (healthDataLabels[healthDataType]) {
    return healthDataLabels[healthDataType]
  }
  return healthDataType
}

export {
  getDocumentCategoryLabel,
  getHealthDataLabel,
  documentCategories,
  textualHealthDataCategories,
  healthDataLabels,
}
