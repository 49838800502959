import { setRequest } from '@sancare/ui-frontend-commons/src/misc/api'

import { formatCodedMedicalActs, formatMainContent, selectMainContent } from '@/misc/stay/helper'
import { formatRhs, formatSsrStay } from '@/misc/stay/ssr/formatter'
import { CodingSelection } from '@/stay-displayer/types'
import { RawCodedMedicalAct } from '@/store/modules/medical-act/types'
import {
  Dependencies,
  RawRhs,
  RawSsrStay,
  RhsDependencyElement,
  RhsRefreshedGroupingResult,
  SsrDiagnosisGroup,
  SsrStayState
} from '@/store/modules/stay/ssr/types'

export const mutations = {
  fetchStaySuccess(state: SsrStayState, stay: RawSsrStay) {
    state.rawStay = stay
    state.currentStay = formatSsrStay(stay)

    const mainContent = selectMainContent(stay)
    state.mainContent = formatMainContent(mainContent)
  },
  fetchRhsSuccess(state: SsrStayState, responseRhs: RawRhs) {
    let rhsIdx = state.rawStay.rhsList.findIndex((rhs) => rhs.id === responseRhs.id)
    if (rhsIdx > -1) {
      state.rawStay.rhsList[rhsIdx] = responseRhs
    }
    rhsIdx = state.rawStay.rhsList.findIndex((rhs) => rhs.id === responseRhs.id)
    if (rhsIdx > -1) {
      state.currentStay.rhsList[rhsIdx] = formatRhs(responseRhs)
    }
    state.rawRhs = responseRhs
    state.currentRhs = formatRhs(responseRhs)
  },
  setCurrentRhs(state: SsrStayState, rhsId: number) {
    const rawRhs = state.rawStay.rhsList.find((rhs) => rhs.id === rhsId)
    if (!rawRhs) {
      return
    }
    state.rawRhs = rawRhs
    state.currentRhs = formatRhs(rawRhs)
  },
  startRefreshingGroupingResult(state: SsrStayState) {
    state.isGroupingResultRefreshing = true
  },
  endRefreshingGroupingResult(state: SsrStayState) {
    state.isGroupingResultRefreshing = false
  },
  setRhsDependency(state: SsrStayState, dependency: RhsDependencyElement) {
    state.currentRhs = {
      ...state.currentRhs,
      [dependency.dependencyType]: dependency.value
    }
  },
  setRhsDependencies(state: SsrStayState, lightRhs: RhsRefreshedGroupingResult & Dependencies) {
    state.currentRhs = {
      ...state.currentRhs,
      depBehaviour: lightRhs.depBehaviour,
      depContinence: lightRhs.depContinence,
      depDressing: lightRhs.depDressing,
      depFeeding: lightRhs.depFeeding,
      depMoving: lightRhs.depMoving,
      depSocial: lightRhs.depSocial,
    }
    this.commit('ssrStay/setCurrentGroupingResult', lightRhs)
  },
  setCurrentGroupingResult(state: SsrStayState, lightRhs: RhsRefreshedGroupingResult) {
    state.currentRhs.currentModeDiagnosisGroup.groupingResult = lightRhs.currentModeDiagnosisGroup.groupingResult
    state.currentStay.standardModeGroupingResult = lightRhs.stay.standardModeGroupingResult
    this.commit('ssrStay/endRefreshingGroupingResult', lightRhs)
  },
  setMainContent(state: SsrStayState, { type, targetId = null, drugEventPrestationType = null }) {
    state.mainContent = formatMainContent({ type, drugEventPrestationType, targetId })
  },
  setJustificationSelection(state: SsrStayState, codingSelection: CodingSelection) {
    state.justificationSelection = codingSelection
  },
  setJustificationShouldScroll(state: SsrStayState, shouldScroll: boolean) {
    state.justificationShouldScroll = shouldScroll
  },
  // todo create TextProcessingStay type
  setCurrentStay(state: SsrStayState, withTextProcessingStay: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
    state.currentStay = formatSsrStay(withTextProcessingStay)
  },
  updateStayStatusSuccess(state: SsrStayState, stay: RawSsrStay) {
    // TODO: Update searches ?
    refreshStateStay(state, stay)
  },
  updateMedicalActsSuccess(state: SsrStayState, lightRhs: RhsRefreshedGroupingResult & { codedMedicalActs: RawCodedMedicalAct[] }) {
    state.currentRhs.codedMedicalActs = formatCodedMedicalActs([...lightRhs.codedMedicalActs])
    this.commit('ssrStay/setCurrentGroupingResult', lightRhs)
  },
  updateSsrDiagnosisSuccess(state: SsrStayState, lightRhs: RhsRefreshedGroupingResult & { currentModeDiagnosisGroup: SsrDiagnosisGroup }) {
    state.currentRhs.currentModeDiagnosisGroup = lightRhs.currentModeDiagnosisGroup
    this.commit('ssrStay/setCurrentGroupingResult', lightRhs)
  },
  setRequest
}

function refreshStateStay(state: SsrStayState, stay: RawSsrStay) {
  state.rawStay = stay
  state.currentStay = formatSsrStay(stay)
}
