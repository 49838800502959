<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { mapState } from 'vuex'

import { extractChunksFromSuggestedDiagnosis } from './diagnosis_justifications.js'
import SuggestedChronicDiagnosisCard from './SuggestedChronicDiagnosisCard.vue'
import SuggestedDiagnosisCard from './SuggestedDiagnosisCard.vue'

export default {
  components: {
    'suggested-diagnosis-card': SuggestedDiagnosisCard,
    'suggested-chronic-diagnosis-card': SuggestedChronicDiagnosisCard,
    'sancare-octicon': SancareOcticon,
  },
  props: {
    appSettings: { type: Object, required: true },
    suggestedDiagnoses: { type: Array, required: true },
    suggestedChronicDas: { type: Object, required: true },
    rumId: { type: Number, required: true },
    standardModeDiagnosisGroup: { type: Object, required: true },
    healthData: { type: Object, required: true },
    selection: { type: Object, default: null },
    isLoading: { type: Boolean, default: false },
    mode: { type: String, required: true },
    savedSearchIntersectionChunks: { type: Array, default: null },
    staySavedSearches: { type: Array, default: null },
    displayedRumIdx: { type: Number, required: true },
    rumPredictedLabels: { type: Array, default: null },
    type: { type: String, default: null },
    readonly: { type: Boolean, required: true },
  },
  emits: ['switch-display-mode', 'show-more-diags', 'make-selection'],
  data() {
    return {
      showMore: false,
    }
  },
  computed: {
    ...mapState({
      pmsiCountry: (state) => state.settings.pmsi.country,
    }),
    orderedSuggestedDiagnoses() {
      const orderedSuggestions = _.map(this.suggestedDiagnoses, (suggestion) => {
        suggestion.diagnoses = _.orderBy(suggestion.diagnoses, ['excluded', 'level', 'predictions.confidence'], ['asc', 'desc', 'desc'])

        return suggestion
      })

      return _.orderBy(orderedSuggestions, ['isExcluded', 'level.max', 'confidence.max'], ['asc', 'desc', 'desc'])
    },
    displayedSuggestedDiagnoses() {
      return _.filter(this.orderedSuggestedDiagnoses, (suggestion) => {
        return suggestion.isAlwaysVisible || (!suggestion.isAlwaysHidden && this.showMore)
      })
    },
    // group diagnoses by chunks set
    justificationGroups() {
      if(this.isLoading) {
        return []
      }
      const justificationGroups = []

      for(const suggestedDiagnosis of this.displayedSuggestedDiagnoses){
        for(const diagnosis of suggestedDiagnosis.diagnoses){
          const chunks = extractChunksFromSuggestedDiagnosis(diagnosis, this.savedSearchIntersectionChunks)
          let hasMatched = false
          diagnosis.wildcard = suggestedDiagnosis.isWildcard ? suggestedDiagnosis.reference : null

          // group by chunks set
          for(const justificationGroup of justificationGroups){
            if(_.isEqual(chunks, justificationGroup.chunks)){
              hasMatched = true
              justificationGroup.diagnoses.push(diagnosis)
              justificationGroup.searchNames = _.uniq([...justificationGroup.searchNames, ..._.map(diagnosis.staySavedSearches, 'search.name')])
              justificationGroup.searchIds = _.uniq([...justificationGroup.searchNames, ..._.map(diagnosis.staySavedSearches, 'search.id')])
              justificationGroup.staySavedSearches = [...diagnosis.staySavedSearches, ..._.filter(diagnosis.staySavedSearches, (staySavedSearch) => {
                return justificationGroup.searchIds.indexOf(staySavedSearch.search.id) === -1
              })]

              // wildcard
              if(diagnosis.wildcard !== null){
                if(!_.find(justificationGroup.wildcards, (wildcard) => wildcard.reference === suggestedDiagnosis.reference)){
                  justificationGroup.wildcards.push({
                    reference: suggestedDiagnosis.reference,
                    level: suggestedDiagnosis.level
                  })
                }
              }
            }
          }
          if(!hasMatched){
            const justificationGroup = {
              chunks: chunks,
              diagnoses: [diagnosis],
              staySavedSearches: diagnosis.staySavedSearches,
              searchNames: _.map(diagnosis.staySavedSearches, 'search.name'),
              searchIds: _.map(diagnosis.staySavedSearches, 'search.id'),
              wildcards: []
            }
            // wildcard
            if(diagnosis.wildcard !== null){
              justificationGroup.wildcards.push({
                reference: suggestedDiagnosis.reference,
                level: suggestedDiagnosis.level
              })
            }
            justificationGroups.push(justificationGroup)
          }
        }
      }
      return justificationGroups
    },
    chronicJustificationGroups() {
      const propList = { diag: ['reference', 'level'], stay: ['id', 'rssId', 'stayDuration', 'stayEnd'] }
      // Group by justification
      const baseGroupByList = _.groupBy(
        this.suggestedChronicDas.chronicDasJustification,
        (justif) => {
          return _.uniq(justif.map((stay) => stay.id)).sort().join()
        }
      )
      // Index diagnosis info by reference
      const diagListByReference = _.keyBy(
        this.suggestedChronicDas.chronicDasList
          .map((chronicDas) => chronicDas.diagnosis || {})
          .filter((chronicDasDiag) => chronicDasDiag),
        'reference'
      )
      const chronicJustificationGroups = []
      // Split and uniq justification by DAS (for card) and stay (for justification popover)
      for(const baseGroupByKey in baseGroupByList) {
        if (!Object.prototype.hasOwnProperty.call(baseGroupByList, baseGroupByKey)) {
          continue
        }
        const baseGroupBy = _.union(...baseGroupByList[baseGroupByKey])
        const chronicJustificationGroup = { stayList: [], diagList: [] }
        chronicJustificationGroup.stayList = _.uniqBy(baseGroupBy, 'id').map((stay) => _.pick(stay, propList.stay))
        chronicJustificationGroup.diagList = _.uniqBy(baseGroupBy, 'reference')
          .map((diag) => {
            return {
              ..._.pick(diag, propList.diag),
              ...diagListByReference[diag.reference]
            }
          })
        
        chronicJustificationGroups.push(chronicJustificationGroup)
      }

      return chronicJustificationGroups
    },
  },
  methods: {
    switchDisplayMode() {
      this.$emit('switch-display-mode')
    },
    hasVisibleSuggestion() {
      return _.some(this.suggestedDiagnoses, (suggestion) => suggestion.isAlwaysVisible || (!suggestion.isAlwaysHidden && this.showMore))
    },
    showMoreDiags() {
      this.showMore = !this.showMore
      this.$emit('show-more-diags')
    }
  }
}
</script>

<template>
  <div>
    <div class="card-body">
      <h1 class="card-title">
        {{ $t('diagnosis.'+type) }} <small>- Suggestion Sancare</small>
        <span
          v-tooltip="'Afficher en mode liste'"
          class="float-right"
          @click="switchDisplayMode()"
        >
          <sancare-octicon
            name="list-unordered"
            class="cp"
            :width="30"
            :height="30"
          />
        </span>
      </h1>
    </div>
    <div
      v-if="isLoading"
      class="loader loader-lg loader-center"
    />
    <div
      v-else-if="!justificationGroups.length && !suggestedChronicDas.chronicDasList.length"
      class="card-body text"
    >
      Aucun diagnostic detecté
    </div>
    <div
      v-else-if="!hasVisibleSuggestion() && !suggestedChronicDas.chronicDasList.length"
      class="card-body text-center"
    >
      Aucun diagnostic pertinent
    </div>
    <suggested-diagnosis-card
      v-for="(justificationGroup, idx) in justificationGroups"
      :key="idx"
      :suggestion-group="justificationGroup"
      :rum-id="rumId"
      :displayed-rum-idx="displayedRumIdx"
      :health-data="healthData"
      :mode="mode"
      :saved-search-intersection-chunks="savedSearchIntersectionChunks"
      :rum-predicted-labels="rumPredictedLabels"
      :selection="selection"
      :readonly="readonly"
      :standard-mode-diagnosis-group="standardModeDiagnosisGroup"
      class="card justification-group mx-2 px-1 pb-1 mb-1"
      @make-selection="(suggestion) => $emit('make-selection', suggestion)"
    />
    <suggested-chronic-diagnosis-card
      v-for="(chronicJustificationGroup, idx) in chronicJustificationGroups"
      :key="`chronic-das-card-${idx}`"
      :chronic-justifications="chronicJustificationGroup"
      :mode="mode"
      :suggested-chronic-das="suggestedChronicDas"
      :rum-id="rumId"
      :selection="selection"
      :readonly="readonly"
      :standard-mode-diagnosis-group="standardModeDiagnosisGroup"
      class="card justification-group mx-2 px-1 pb-1 mb-1"
      @make-selection="(suggestion) => $emit('make-selection', suggestion)"
    />
    <div class="col-12 my-1 py-0 text-center">
      <span
        class="btn-more-diagnosis"
        @click="showMoreDiags"
      >
        {{ showMore ? 'Afficher moins' : 'Afficher plus' }}
      </span>
    </div>
  </div>
</template>

<style lang="less">
.card.justification-group {
  background-color: lighten(#22a2fe, 10%);
}
.justification-group {
  border-color: white;
}
</style>
