import { getDefaultEarlyLimit } from '@sancare/ui-frontend-commons/src/misc/date-utils'
import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import _ from 'lodash'

import Api from '@/misc/api'
import { getAppSettings, getSancareSettings } from '@/misc/getSettings'
import { CountryEnum, ModeEnum, PmsiTypeEnum } from '@/store/modules/settings/types'

const appSettingNameList = [
  'automation_mode_dr_threshold',
  'standard_mode_dp_threshold',
  'standard_mode_dr_threshold',
  'standard_mode_das_threshold',
  'new_coding_das_threshold',
  'standard_mode_hint_threshold',
  'standard_mode_dp_financial_threshold',
  'automation_session_financial_risk',
  'automation_non_session_financial_risk',
  'automation_ambu_financial_risk',
  'automation_non_ambu_financial_risk',
  'optimizable_stays_show_low_das',
  'optimizable_stays_show_excluded_das',
  'optimizable_stays_show_coded_das_found_by_ai',
  'new_coding_stays_show_low_das',
  'new_coding_stays_show_excluded_das',
  'related_diagnosis',
  'ai_confidence_external_flag_threshold',
]
const sancareSettingNameList = [
  'enable_ssr',
  'enable_pseudonymization',
  'enable_data_downgrading',
  'enable_manual_acts_external_export',
  'enable_comments_external_export',
  'demo_mode',
  'hide_sancare_searches_details',
  'session_automation',
  'non_session_automation',
  'rules_automation',
  'renewal_automation',
  'restricted_legal_finess',
  'restricted_geo_finess',
]
const initialUrl = new URL(location.href)
const sp = new URLSearchParams(initialUrl.search)
let initialEarlyLimit = null
let initialLateLimit = null
let initialMedicalUnits = null
if (sp.get('el') && dayjs(sp.get('el'), 'DD-MM-YYYY').isValid()) {
  initialEarlyLimit = dayjs(sp.get('el'), 'DD-MM-YYYY')
  sessionStorage.setItem('earlyLimit', initialEarlyLimit.format())
} else if (sessionStorage.getItem('earlyLimit') && dayjs(sessionStorage.getItem('earlyLimit')).isValid()) {
  initialEarlyLimit = dayjs(sessionStorage.getItem('earlyLimit'))
} else {
  sessionStorage.removeItem('earlyLimit')
  initialEarlyLimit = getDefaultEarlyLimit()
}
if (sp.get('ll') && dayjs(sp.get('ll'), 'DD-MM-YYYY').isValid()) {
  initialLateLimit = dayjs(sp.get('ll'), 'DD-MM-YYYY')
  sessionStorage.setItem('lateLimit', initialLateLimit.format())
} else if (sessionStorage.getItem('lateLimit') && dayjs(sessionStorage.getItem('lateLimit')).isValid()) {
  initialLateLimit = dayjs(sessionStorage.getItem('lateLimit'))
} else {
  sessionStorage.removeItem('lateLimit')
  initialLateLimit = dayjs()
}
initialMedicalUnits = sp.getAll('um')
if (initialMedicalUnits.length > 0) {
  sessionStorage.setItem('medicalUnits', JSON.stringify(initialMedicalUnits))
} else if (sessionStorage.getItem('medicalUnits')) {
  initialMedicalUnits = JSON.parse(sessionStorage.getItem('medicalUnits'))
} else {
  sessionStorage.removeItem('medicalUnits')
  initialMedicalUnits = null
}

export default {
  state: {
    appVersion: window.appVersion,
    currentUser: {
      id: null,
      role: null,
      editableRoles: [],
      teamMode: null,
      team: {
        id: null,
      },
    },
    userSettings: {},
    noSearchWarning: dayjs.unix(localStorage.getItem('noSearchWarning') || 0),
    appSettings: _.mapValues(window.appSettings, (settingCategory) => _.pick(settingCategory, appSettingNameList)),
    sancareSettings: _.mapValues(window.appSettings, (settingCategory) => _.pick(settingCategory, sancareSettingNameList)),

    connectorSettings: window.connectorSettings,
    pmsi: {
      country: window.pmsiCountry,
      mcoEnabled: window.mcoEnabled,
      pmsiType: PmsiTypeEnum.MCO,
      mode: ModeEnum.OPTIMIZABLE,
    },

    earlyLimit: initialEarlyLimit,
    lateLimit: initialLateLimit,
    medicalUnits: [],
    selectedMedicalUnits: Array.isArray(initialMedicalUnits) ? initialMedicalUnits.map((um) => Number(um)) : initialMedicalUnits,

    getSelfRequest: { fetching: false, error: null, ok: false },
    updateSelfRequest: { fetching: false, error: null, ok: false },
    setAppSettingsRequest: { fetching: false, error: null, ok: false },
    getUserSettingsRequest: { fetching: false, error: null, ok: false },
    setUserSettingsRequest: { fetching: false, error: null, ok: false },
    sendBugReportRequest: { fetching: false, error: null, ok: false },
    setSancareSettingsRequest: { fetching: false, error: null, ok: false },
  },
  getters: {
    appVersion: (state) => state.appVersion,
    enableWebpimsAccess: (state) => state.connectorSettings.webpimsEnabled,
    enableCoraAccess: (state) => state.connectorSettings.coraEnabled,
    enableMedstatAccess: (state) => state.connectorSettings.medstatEnabled,
    // for now medstat compatible only
    aiFlagEnabled: (state) => state.connectorSettings.medstatEnabled,
    flatAppSettings(state) {
      return getAppSettings(state)
    },
    flatSancareSettings(state) {
      return getSancareSettings(state)
    },
    earlyLimit(state) {
      return state.earlyLimit
    },
    lateLimit(state) {
      return state.lateLimit
    },
    settingsUrlQuery(state) {
      const formattedQuery = {
        el: state.earlyLimit.format('DD-MM-YYYY'),
        ll: state.lateLimit.format('DD-MM-YYYY'),
      }
      if (state.selectedMedicalUnits !== null) {
        formattedQuery.um = state.selectedMedicalUnits
      }

      return formattedQuery
    },
    pmsiType: (state) => state.pmsi.pmsiType,
  },
  mutations: {
    getSelfStart(state) {
      state.getSelfRequest = { fetching: true, error: null, ok: false }
    },
    getSelfSuccess(state, user) {
      state.getSelfRequest.fetching = false
      state.getSelfRequest.ok = true
      state.currentUser = user
    },
    getSelfError(state, res) {
      state.getSelfRequest.fetching = false
      state.getSelfRequest.error = res.message
    },
    updateSelfStart(state) {
      state.updateSelfRequest = { fetching: true, error: null, ok: false }
    },
    updateSelfSuccess(state, user) {
      state.updateSelfRequest = { fetching: false, error: null, ok: true }
      state.currentUser = user
    },
    updateSelfError(state, res) {
      state.updateSelfRequest = { fetching: false, error: res.message, ok: false }
    },
    resetUpdateSelf(state) {
      state.updateSelfRequest = { fetching: false, error: null, ok: false }
    },
    setAppSettingsStart(state) {
      state.setAppSettingsRequest = { fetching: true, error: null, ok: false }
    },
    setAppSettingsSuccess(state, newSettings) {
      state.setAppSettingsRequest = { fetching: false, error: null, ok: true }
      state.appSettings = _.mapValues(newSettings, (settingCategory) => _.pick(settingCategory, appSettingNameList))
    },
    setSancareSettingsStart(state) {
      state.setSancareSettingsRequest = { fetching: true, error: null, ok: false }
    },
    setSancareSettingsSuccess(state, newSettings) {
      state.setSancareSettingsRequest = { fetching: false, error: null, ok: true }
      state.sancareSettings = _.mapValues(newSettings, (settingCategory) => _.pick(settingCategory, sancareSettingNameList))
    },
    setSancareSettingsError(state, res) {
      state.setSancareSettingsRequest = { fetching: false, error: res.message, ok: false }
    },
    setAppSettingsError(state, res) {
      state.setAppSettingsRequest = { fetching: false, error: res.message, ok: false }
    },
    setNoSearchWarning(state, noSearchWarning) {
      localStorage.setItem('noSearchWarning', noSearchWarning.unix())
      state.noSearchWarning = noSearchWarning
    },
    getUserSettingsStart(state) {
      state.getUserSettingsRequest = { fetching: true, error: null, ok: false }
    },
    getUserSettingsSuccess(state, userSettings) {
      state.getUserSettingsRequest = { fetching: false, error: null, ok: true }
      state.userSettings = { ...state.userSettings }

      _.forEach(userSettings, (setting) => {
        state.userSettings[setting.name] = setting.value
      })
    },
    getUserSettingsError(state, res) {
      state.getUserSettingsRequest = { fetching: false, error: res.message, ok: false }
    },
    setUserSettingsStart(state) {
      state.setUserSettingsRequest = { fetching: true, error: null, ok: false }
    },
    setUserSettingsSuccess(state, userSettings) {
      state.setUserSettingsRequest = { fetching: false, error: null, ok: true }
      state.userSettings = { ...state.userSettings }

      _.forEach(userSettings, (setting) => {
        state.userSettings[setting.name] = setting.value
      })
    },
    setUserSettingsError(state, res) {
      state.setUserSettingsRequest = { fetching: false, error: res.message, ok: false }
    },
    sendBugReportStart(state) {
      state.sendBugReportRequest = { fetching: true, error: null, ok: false }
    },
    sendBugReportSuccess(state) {
      state.sendBugReportRequest = { fetching: false, error: null, ok: true }
    },
    sendBugReportError(state, res) {
      state.sendBugReportRequest = { fetching: false, error: res.message, ok: false }
    },
    setEarlyLimit(state, lim) {
      state.earlyLimit = lim
      sessionStorage.setItem('earlyLimit', lim.format())
    },
    setLateLimit(state, lim) {
      state.lateLimit = lim
      sessionStorage.setItem('lateLimit', lim.format())
    },
    initMedicalUnits(state, { medicalUnits, facilities, divisions }) {
      state.medicalUnits = _.sortBy(facilities.map((facility) => {
        return {
          value: facility.id,
          label: facility.name === '' ? (`Etablissement inconnu${facility.geographicFiness ? (` (${facility.geographicFiness})`) : ''}`) : facility.name,
          children: _.sortBy(divisions
            .filter((division) => division.facility.id === facility.id)
            .map((division) => ({
              value: division.id,
              label: division.title === '' ? 'Pôle inconnu' : division.title,
              children: _.sortBy(medicalUnits
                .filter((unit) => unit.facilityDivision.id === division.id)
                .map((unit) => ({ value: unit.id, label: unit.reference + (unit.title ? ` - ${unit.title}` : '')  })), ['reference'])
            })), ['title'])
        }
      }), ['name'])
    },
    setSelectedMedicalUnits(state, medicalUnitList) {
      if (medicalUnitList && medicalUnitList.length === 0) {
        medicalUnitList = null
      }
      state.selectedMedicalUnits = medicalUnitList
      sessionStorage.setItem('medicalUnits', JSON.stringify(medicalUnitList))
    },
    setPmsiType(state, pmsiType) {
      state.pmsi.pmsiType = pmsiType
    },
    initPmsiMode(state) {
      if (state.pmsi.pmsiType === PmsiTypeEnum.SSR || state.pmsi.country === CountryEnum.CH) {
        state.pmsi.mode = ModeEnum.QUALITY_STAYS
      } else {
        state.pmsi.mode = ModeEnum.OPTIMIZABLE
      }
    },
    setPmsiMode(state, mode) {
      state.pmsi.mode = mode
    }
  },
  actions: {
    getSelf({ commit }) {
      return Api.get(
        {
          url: '/api/user/me',
          startMut: 'getSelfStart',
          successMut: 'getSelfSuccess',
          errorMut: 'getSelfError',
        },
        commit
      )
    },
    updateSelf({ commit }, data) {
      return Api.put(
        {
          url: '/api/user/me',
          body: data,
          startMut: 'updateSelfStart',
          successMut: 'updateSelfSuccess',
          errorMut: 'updateSelfError',
        },
        commit
      )
    },
    setAppSettings({ commit }, settings) {
      return Api.put(
        {
          url: '/api/settings/app-settings',
          body: { ...settings },
          startMut: 'setAppSettingsStart',
          successMut: 'setAppSettingsSuccess',
          errorMut: 'setAppSettingsError',
        },
        commit
      )
    },
    setSancareSettings({ commit }, settings) {
      return Api.put(
        {
          url: '/api/settings/sancare-settings',
          body: { ...settings },
          startMut: 'setSancareSettingsStart',
          successMut: 'setSancareSettingsSuccess',
          errorMut: 'setSancareSettingsError',
        },
        commit
      )
    },
    getUserSettings({ commit }) {
      return Api.get(
        {
          url: '/api/settings/user-settings',
          startMut: 'getUserSettingsStart',
          successMut: 'getUserSettingsSuccess',
          errorMut: 'getUserSettingsError',
        },
        commit
      )
    },
    setUserSettings({ commit }, userSettings) {
      return Api.post(
        {
          url: '/api/settings/user-settings',
          body: userSettings,
          startMut: 'setUserSettingsStart',
          successMut: 'setUserSettingsSuccess',
          errorMut: 'setUserSettingsError',
        },
        commit
      )
    },
    sendBugReport({ commit }, bugInfo) {
      return Api.post(
        {
          url: '/api/settings/bug-report',
          body: bugInfo,
          startMut: 'sendBugReportStart',
          successMut: 'sendBugReportSuccess',
          errorMut: 'sendBugReportError',
        },
        commit
      )
    },
  },
}
