import { DEFAULT_SEARCH_ID, ListedSavedSearch } from '@/saved-searches/types'
import store from '@/store'

export function isSavedSearchBeingCreated(savedSearch: ListedSavedSearch): boolean {
  return !doesSearchIdExist(savedSearch.id)
}

export function doesSearchIdExist(id: number): boolean {
  return id !== DEFAULT_SEARCH_ID
}

export async function saveCurrentSavedSearch() {
  const currentSearch = store.state.stayList.currentSearch

  if (isSavedSearchBeingCreated(currentSearch)) {
    await store.dispatch('addSavedSearch', currentSearch)

    if (store.state.savedSearches.addSavedSearchRequest.ok && doesSearchIdExist(store.state.stayList.stayListParams.searchCopyFrom)) {
      store.commit('setStayListParam', { type: 'searchCopyFrom', value: DEFAULT_SEARCH_ID })
      await store.dispatch('refreshStayListUrl', {})
    }

    return
  }

  await store.dispatch('editSavedSearch', currentSearch)
}