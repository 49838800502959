<script>
import dayjs from 'dayjs'
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex'

import { ModeEnum, PmsiTypeEnum, PrivacyEnum } from '@/store/modules/settings/types'

import GenericThumbnailList from './GenericThumbnailList.vue'
import HomePageQuickFilter from './HomePageQuickFilter.vue'
import OwnSearchGroupList from './OwnSearchGroupList.vue'
import OwnSearchList from './OwnSearchList.vue'
import SancareCategoryList from './SancareCategoryList.vue'
import TeamSearchList from './TeamSearchList.vue'

export default {
  name: 'HomeList',
  components: {
    'generic-thumbnail-list': GenericThumbnailList,
    'home-page-quick-filter': HomePageQuickFilter,
    'own-search-list': OwnSearchList,
    'own-search-group-list': OwnSearchGroupList,
    'sancare-category-list': SancareCategoryList,
    'team-search-list': TeamSearchList,
  },
  props: {
    mode: { type: String, required: true },
    privateSearches: { type: Array, required: true },
    teamSearches: { type: Array, required: true },
    publicSearches: { type: Array, required: true },
    userSearchGroups: { type: Array, required: true },
    sancareCategories: { type: Array, required: true },
    earlyLimit: { type: Object, required: true },
    lateLimit: { type: Object, required: true },
    pmsiType: {
      validator(value) {
        return Object.values(PmsiTypeEnum).includes(value)
      },
      default: PmsiTypeEnum.MCO
    },
  },
  emits: ['refresh-stats'],
  data() {
    return {
      selectedSearches: [],
      quickFilterResults: {
        privateSearches: null,
        userSearchGroups: null,
        teamSearches: null,
        publicSearches: null,
        sancareCategories: null,
        earlylimit: null,
        latelimit: null,
      },
      ModeEnum,
      PmsiTypeEnum,
    }
  },
  computed: {
    PrivacyEnum() {
      return PrivacyEnum
    },
    ...mapGetters(['settingsUrlQuery']),
    ...mapState({
      noSearchWarning: (state) => state.settings.noSearchWarning,
      userSettings: (state) => state.settings.userSettings,
      savedSearchesRequest: (state) => state.savedSearches.fetchSavedSearchesRequest,
    }),
    selectedSearchLabel() {
      if (this.selectedSearches.length <= 1) {
        return `Utiliser la ${this.mode === ModeEnum.OPTIMIZABLE ? 'recherche' : 'liste'} selectionnée`
      }

      return `Utiliser les ${this.mode === ModeEnum.OPTIMIZABLE ? 'recherches' : 'liste'} selectionnées`
    },
    selectedSearchQuery() {
      return {
        mode: this.mode,
        searchId: _.map(this.selectedSearches, 'id'),
        ...this.settingsUrlQuery
      }
    },
  },
  methods: {
    onQuickFilterUpdate(quickFilterResults) {
      this.selectedSearches = []
      this.quickFilterResults = quickFilterResults
    },
    disableQuickFilter() {
      this.selectedSearches = []
      this.quickFilterResults = {
        privateSearches: null,
        userSearchGroups: null,
        teamSearches: null,
        publicSearches: null,
        sancareCategories: null,
        earlylimit: null,
        latelimit: null,
      }
    },
    dismissSearchWarning() {
      this.$store.commit('setNoSearchWarning', dayjs().add(1, 'day'))
    },
    disableSearchWarning() {
      const settings = { ...this.userSettings }
      _.assign(settings, { dismissedMenus: ['searchWarning'] })
      this.$store.dispatch('setUserSettings', settings)
    },
    handleSelectSearch(search) {
      if (_.find(this.selectedSearches, (s) => s.id === search.id)) {
        this.selectedSearches = _.filter(this.selectedSearches, (s) => s.id !== search.id)
      } else {
        this.selectedSearches = _.concat(this.selectedSearches, search)
      }
    },
    refreshStats(searchIds) {
      this.$emit('refresh-stats', searchIds)
    }
  },
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-md-3 col text-right">
        <router-link
          v-if="selectedSearches.length !== 0"
          class="btn btn-link"
          name="apply-selected-searches"
          :to="{ name: 'staylist', query: selectedSearchQuery, params: {pmsiType: pmsiType} }"
        >
          {{ selectedSearchLabel }}
        </router-link>
      </div>
    </div>
    <generic-thumbnail-list
      :mode="mode"
      :pmsi-type="pmsiType"
    />
    <home-page-quick-filter
      :key="mode"
      :mode="mode"
      :private-searches="privateSearches"
      :team-searches="teamSearches"
      :public-searches="publicSearches"
      :user-search-groups="userSearchGroups"
      :sancare-categories="sancareCategories"
      class="col"
      @quick-filter-update="onQuickFilterUpdate"
      @quick-filter-disable="disableQuickFilter"
    />
    <own-search-list
      :search-list="privateSearches"
      :mode="mode"
      :pmsi-type="pmsiType"
      :early-limit="earlyLimit"
      :late-limit="lateLimit"
      :selected-searches="selectedSearches"
      :quick-filter-results="quickFilterResults.privateSearches"
      :title="`Mes ${[ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS].includes(mode) ? 'recherches' : 'listes de travail' }`"
      :can-add-own-search="true"
      @select-search="handleSelectSearch"
      @refresh-stats="refreshStats"
    />
    <!-- SSR not supported for now -->
    <own-search-group-list
      v-if="mode === ModeEnum.OPTIMIZABLE && pmsiType === PmsiTypeEnum.MCO"
      :search-groups="userSearchGroups"
      :mode="mode"
      :selected-searches="selectedSearches"
      :quick-filter-results="quickFilterResults.userSearchGroups"
      :pmsi-type="pmsiType"
      title="Mes groupes de recherches"
      @refresh-stats="refreshStats"
    />
    <team-search-list
      v-if="teamSearches.length"
      :team-searches="teamSearches"
      :mode="mode"
      :pmsi-type="pmsiType"
      :selected-searches="selectedSearches"
      :quick-filter-results="quickFilterResults.teamSearches"
      title="Recherches de mon équipe"
      @select-search="handleSelectSearch"
      @refresh-stats="refreshStats"
    />
    <sancare-category-list
      :sancare-categories="publicSearches"
      :mode="mode"
      :selected-searches="selectedSearches"
      :quick-filter-results="quickFilterResults.publicSearches"
      :searches-privacy="PrivacyEnum.PUBLIC_SEARCH"
      @select-search="handleSelectSearch"
      @refresh-stats="refreshStats"
    />
    <sancare-category-list
      :sancare-categories="sancareCategories"
      :mode="mode"
      :selected-searches="selectedSearches"
      :quick-filter-results="quickFilterResults.sancareCategories"
      :searches-privacy="PrivacyEnum.SANCARE_SEARCH"
      @select-search="handleSelectSearch"
      @refresh-stats="refreshStats"
    />
    <div v-if="savedSearchesRequest.fetching">
      <div class="loader loader-lg loader-center" />
    </div>
  </div>
</template>
