<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'

import PredictedDiagnosis from '../PredictedDiagnosis.vue'
import svgLink from '../SvgLink.vue'
import { computeSvgLink } from '../svgLinkCalculator'

export default {
  components: {
    'predicted-diagnosis': PredictedDiagnosis,
    'svg-link': svgLink,
    'sancare-octicon': SancareOcticon,

  },
  props: {
    appSettings: { type: Object, required: true },
    rumId: { type: Number, required: true },
    type: { type: String, required: true },
    automationModeDiagnosisGroup: { type: Object, required: true },
    standardModeDiagnosisGroup: { type: Object, required: true },
    healthData: { type: Object, required: true },
    selection: { type: Object, default: null },
    isLoading: { type: Boolean, default: false },
    displayedRumIdx: { type: Number, required: true },
    rumPredictedLabels: { type: Array, default: null },
  },
  emits: ['make-selection'],
  data() {
    return {
      width: 0,
      height: 0,
      startHeight: 0,
      endHeight: 0,
      updateSize: null,
      showMore: false,
    }
  },
  computed: {
    predictedDiagnoses() {
      return this.rumPredictedLabels.filter((dDiag) => dDiag.type === this.type)
    },
    currentDiagnosis() {
      // Retrieves the diagnosis that could be applied in automation mode
      if (!this.automationModeDiagnosisGroup[`current${this.type}`]) {
        return null
      }
      const currentReference = this.automationModeDiagnosisGroup[`current${this.type}`].reference
      const prediction = _.find(this.predictedDiagnoses, (dDiag) => dDiag.diagnosis.reference === currentReference)

      return {
        ...prediction,
        canShowRational: _.some(prediction.hints, (h) => h.confidence >= this.appSettings['standard_mode_hint_threshold']),
      }
    },
    displayedDiagnoses() {
      // Retrieves all the predicted diagnoses except the favorite one (current automation diagnosis)
      let currentReference = null
      if (this.automationModeDiagnosisGroup[`current${this.type}`]) {
        currentReference = this.automationModeDiagnosisGroup[`current${this.type}`].reference
      }
      let existingReference = null
      if (this.standardModeDiagnosisGroup[`first${this.type}`]) {
        existingReference = this.standardModeDiagnosisGroup[`first${this.type}`].reference
      }

      // Creates a list with all the diagnoses that are not the main suggested diagnosis ('currentReference')
      // Orders those diagnoses by confidence and then reference
      // Computes two more info for each diagnosis :
      // - canShowRational : the suggested diagnosis must by displayable, with sufficient hint confidence (higher than standard mode threshold)
      // - isInAccordion : the diagnosis will be displayed only when showMore is active. It depends on whether the predicted diagnosis is the same as the one coded
      return _.map(
        _.filter(
          _.orderBy(this.predictedDiagnoses, ['confidence', 'reference'], ['desc', 'asc']),
          (dDiag) => dDiag.diagnosis.reference !== currentReference && !dDiag.diagnosis.isAlwaysHidden && (this.showMore || !dDiag.diagnosis.isInAccordion)
        ),
        (dDiag) => ({
          ...dDiag,
          canShowRational: _.some(dDiag.hints, (h) => h.confidence >= this.appSettings['standard_mode_hint_threshold']),
          isInAccordion: dDiag.diagnosis.reference !== existingReference, // FIXME
        })
      )
    },
    hasMatch() {
      if (!this.standardModeDiagnosisGroup[`first${this.type}`]) {
        return false
      }
      const standardModeReference = this.standardModeDiagnosisGroup[`first${this.type}`].reference

      return _.some(this.predictedDiagnoses, (dDiag) => dDiag.diagnosis.reference === standardModeReference)
    },
    svgLinks() {
      const svgLinks = []
      svgLinks.push(computeSvgLink(this.startHeight, this.endHeight, this.width))
      return svgLinks
    }
  },
  mounted() {
    this.updateSize = () => {
      this.$nextTick(() => {
        if (!this.$refs.sancareDiagnoses || !this.$refs.initialDiagnosis) {
          return
        }

        this.width = this.$el.offsetWidth - this.$refs.sancareDiagnoses.offsetWidth - this.$refs.initialDiagnosis.offsetWidth
        this.height = Math.max(this.$refs.sancareDiagnoses.offsetHeight, this.$refs.initialDiagnosis.offsetHeight)

        const elementRect = this.$el.getBoundingClientRect()
        if (this.hasMatch) {
          let endRect
          const firstDiagRef = this.standardModeDiagnosisGroup[`first${this.type}`] ? this.standardModeDiagnosisGroup[`first${this.type}`].reference : null
          if (this.$refs.firstPredictedDiagnosisTitle && this.$refs.firstPredictedDiagnosisTitle.dataset.reference === firstDiagRef) {
            endRect = this.$refs.firstPredictedDiagnosisTitle.getBoundingClientRect()
          } else {
            endRect = _.find(this.$refs.otherPredictedDiagnosisTitle, (r) => r.dataset.reference === firstDiagRef).getBoundingClientRect()
          }
          this.endHeight = endRect.top - elementRect.top + (endRect.bottom - endRect.top) / 2
        }
        const startRect = this.$refs.initialDiagnosisTitle.getBoundingClientRect()
        this.startHeight = startRect.top - elementRect.top + (startRect.bottom - startRect.top) / 2
      })
    }

    window.addEventListener('resize', this.updateSize)
    this.updateSize()
  },
  updated() {
    this.updateSize()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateSize)
  },
  methods: {
    makeSelection(selection) {
      this.$emit('make-selection', selection)
    },
  }
}
</script>

<template>
  <div class="row no-gutters align-items-start">
    <!-- initial diagnosis -->
    <div class="col-5">
      <div
        ref="initialDiagnosis"
        class="card diagnosis-card highlighted"
      >
        <div class="card-body">
          <h1 class="card-title">
            {{ type }} <small>- Établissement</small>
          </h1>
        </div>
        <div class="card-body">
          <div v-if="standardModeDiagnosisGroup[`first${type}`]">
            <h2
              ref="initialDiagnosisTitle"
              :title="standardModeDiagnosisGroup[`first${type}`].description"
            >
              {{ standardModeDiagnosisGroup[`first${type}`].reference }}
            </h2>
            <div>{{ standardModeDiagnosisGroup[`first${type}`].description }}</div>
          </div>
          <h2
            v-else
            ref="initialDiagnosisTitle"
          >
            Pas de {{ type }}
          </h2>
        </div>
      </div>
    </div>
    <svg-link
      :has-match="hasMatch"
      :svg-height="height"
      :svg-width="width"
      :svg-links="svgLinks"
    />
    <!-- predicted/manual diagnoses -->
    <div class="col-5">
      <div ref="sancareDiagnoses">
        <div class="card diagnosis-card">
          <div class="card-body">
            <h1 class="card-title">
              {{ type }} <small>- Sancare</small>
            </h1>
          </div>
          <div
            v-if="currentDiagnosis"
            class="card-body diagnosis-title"
          >
            <predicted-diagnosis
              :can-show-rational="currentDiagnosis.canShowRational"
              :is-loading="isLoading"
              :selected="selection !== null && selection.diagType === type && selection.diagReference === currentDiagnosis.diagnosis.reference"
              :selection="selection"
              :predicted-diagnosis="currentDiagnosis"
              :health-data="healthData"
              :displayed-rum-idx="displayedRumIdx"
              :rum-predicted-labels="rumPredictedLabels"
              @make-selection="makeSelection"
            >
              <div class="row no-gutters">
                <div class="col row no-gutters align-items-center">
                  <h2
                    ref="firstPredictedDiagnosisTitle"
                    :data-reference="currentDiagnosis.diagnosis.reference"
                    :title="currentDiagnosis.diagnosis.description"
                    class="col"
                  >
                    {{ currentDiagnosis.diagnosis.reference }}
                  </h2>
                  <div class="col-2">
                    <div
                      :class="{ selected: selection !== null && selection.diagType === type && selection.diagReference === currentDiagnosis.diagnosis.reference }"
                      class="btn-select-diagnosis icon-block"
                      @click.stop="makeSelection({ diagType: type, diagReference: currentDiagnosis.diagnosis.reference, savedSearchIds: [] })"
                    >
                      <sancare-octicon
                        name="note"
                        :width="18"
                        :height="18"
                      />
                    </div>
                  </div>
                  <p>{{ currentDiagnosis.diagnosis.description }}</p>
                </div>
              </div>
            </predicted-diagnosis>
          </div>
          <div
            v-else
            class="card-body diagnosis-title"
          >
            <h2>Pas de {{ type }}</h2>
          </div>
        </div>
        <div
          v-if="displayedDiagnoses.length"
          class="card diagnosis-card"
        >
          <div
            key="card-header"
            class="card-body"
          >
            <div>Autres suggestions :</div>
          </div>
          <predicted-diagnosis
            v-for="predictedDiagnosis in displayedDiagnoses"
            :key="predictedDiagnosis.diagnosis.reference"
            :can-show-rational="predictedDiagnosis.canShowRational"
            :is-loading="isLoading"
            :selected="selection !== null && selection.diagType === type && selection.diagReference === predictedDiagnosis.diagnosis.reference"
            :selection="selection"
            :predicted-diagnosis="predictedDiagnosis"
            :health-data="healthData"
            :displayed-rum-idx="displayedRumIdx"
            :rum-predicted-labels="rumPredictedLabels"
            class="animated-height"
            @make-selection="makeSelection"
          >
            <div class="card-body diagnosis-title">
              <div class="row no-gutters align-items-center">
                <div class="col">
                  <div class="row no-gutters align-items-center">
                    <h2
                      ref="otherPredictedDiagnosisTitle"
                      :data-reference="predictedDiagnosis.diagnosis.reference"
                      :title="predictedDiagnosis.diagnosis.description"
                      class="col"
                    >
                      {{ predictedDiagnosis.diagnosis.reference }}
                    </h2>
                    <div class="col-2">
                      <div
                        :class="{ selected: selection !== null && selection.diagType === type && selection.diagReference === predictedDiagnosis.diagnosis.reference }"
                        class="btn-select-diagnosis icon-block"
                        @click.stop="makeSelection({ diagType: type, diagReference: predictedDiagnosis.diagnosis.reference, savedSearchIds: [] })"
                      >
                        <sancare-octicon
                          name="note"
                          :width="18"
                          :height="18"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </predicted-diagnosis>
          <div
            v-if="displayedDiagnoses.find(d => d.isInAccordion)"
            key="show-more"
            class="col-12 mb-1 py-0 text-center"
          >
            <span
              class="btn-more-diagnosis"
              @click="showMore = !showMore"
            >
              Afficher {{ showMore ? 'moins' : 'plus' }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
