import { useI18n } from 'vue-i18n'

import { FilterData, isLocalizedOption, LocalizedOption } from '@/store/modules/filters/types'

export function useFilterOptions() {
  const { t } = useI18n()

  const getOptionValue = (option: string | LocalizedOption): string =>
    isLocalizedOption(option) ? option.label : option

  const displayedOption = (filter: FilterData, option: string | LocalizedOption): string => {
    const optionLabel = getOptionValue(option)

    if (filter.isTranslated) {
      return t(filter.labels[optionLabel])
    }

    return filter.labels[optionLabel] ?? optionLabel
  }

  return {
    getOptionValue,
    displayedOption
  }
}