import _ from 'lodash'

import { ModeEnum, PmsiTypeEnum } from '../store/modules/settings/types'
import{ StayStatusEnum } from '../store/modules/stay/types'

const DEFAULT_STAYLIST_MODE = ModeEnum.OPTIMIZABLE
const DEFAULT_STAYLIST_REVALUATION_TYPE = 'ia_or_search_das'
const DEFAULT_STAYLIST_PAGE_SIZE = 20
const DEFAULT_STAYLIST_PAGE = 1
const DEFAULT_STAYLIST_ORDER = 'id'
export const DEFAULT_SEARCH_CATEGORY_NAME = 'Divers'
const emptyCriteriaGroup = {
  reference: 'tmp1',
  criteriaList: []
}
const emptySavedSearch = {
  criteriaGroups: [],
  searchOperators: 'andOr',
  globalCriteriaGroup: {
    reference: 'tmpGlobal',
    criteriaList: []
  },
  id: -1,
  isAdvancedSearch: false,
  name: null,
  privacy: 'privateSearch',
  pmsiType: PmsiTypeEnum.MCO,
  savedSearchCategory: { id: null, name: DEFAULT_SEARCH_CATEGORY_NAME },
  type: DEFAULT_STAYLIST_MODE,
  user: null
}
const emptyStoredCriteria = {
  criteriaGroups: [],
  searchOperators: 'andOr',
  globalCriteriaGroup: {
    reference: 'tmpGlobal',
    criteriaList: []
  },
  isAdvancedSearch: false,
  name: null,
  savedSearchCategory: { id: null, name: DEFAULT_SEARCH_CATEGORY_NAME },
}

export const DEFAULTS = () => {
  return {
    PMSI_TYPE: PmsiTypeEnum.MCO,
    STAYLIST_MODE: ModeEnum.OPTIMIZABLE,
    STAYLIST_REVALUATION_TYPE: 'ia_or_search_das',
    STAYLIST_PAGE_SIZE: 20,
    STAYLIST_PAGE: 1,
    STAYLIST_ORDER: 'id',
    STAYLIST_SEEN: true,
    STAYLIST_STATUS: [StayStatusEnum.NEW, StayStatusEnum.REVIEW, StayStatusEnum.UNVALIDATED]
  }
}

export const emptyObj = () => {
  return {
    criteriaGroup: emptyCriteriaGroup,
    savedSearch: emptySavedSearch,
    storedCriteria: emptyStoredCriteria
  }
}
export const defaultState = () => {
  return {
    initialized: {
      stayList: false,
      stayListParams: false,
      stayListCriteria: false
    },
    stayListRoute: null,
    stayListParams: {
      currentPage: DEFAULT_STAYLIST_PAGE,
      order: DEFAULT_STAYLIST_ORDER,
      reverseOrder: false,
      pageSize: DEFAULT_STAYLIST_PAGE_SIZE,
      showSeen: DEFAULTS().STAYLIST_SEEN,
      showStatus: DEFAULTS().STAYLIST_STATUS,
      mode: DEFAULT_STAYLIST_MODE,
      pmsiType: DEFAULTS().PMSI_TYPE,
      searchCreation: false,
      searchEdition: -1,
      searchCopyFrom: -1,
      revaluationType: DEFAULT_STAYLIST_REVALUATION_TYPE,
      showOnlyNewCoding: false,
      improveRecallMode: false
    },
    // @TODO: Is those two props the same ? Can we merge them ?
    currentSearchEdited: false,
    editionInProgress: false,
    currentSearch: _.cloneDeep(emptySavedSearch),
    initialCriteria: {
      globalCriteriaGroup: { criteriaList: [] },
      criteriaGroups: []
    },
    activeSearches: [],
    storedCriteria: _.cloneDeep(emptyStoredCriteria),

    stayList: {
      items: [],
      pageCount: 0, // Was set to 1 in init (Why ??)
      totalCount: 0,
      precision: null,
      recall: null,
      selected: null,
      totalMatching: null,
      expected: null
    },
    readingList: {
      currentIndex: null,
      stayList: [],
      status: null
    },
    selectedStays: [],

    fetchStayListRequest: { fetching: false, error: null, ok: false },
    createReadingListRequest: { fetching: false, error: null, ok: false },
    getCurrentReadingListRequest: { fetching: false, error: null, ok: false },
    validateAutomatableListRequest: { fetching: false, error: null, ok: false },
    validateAutomatableSelectionRequest: { fetching: false, error: null, ok: false }
  }
}
