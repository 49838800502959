<script>
import _ from 'lodash'
import Slider from 'vue-slider-component'
import { mapState } from 'vuex'

const OFFSET_CONSTANT_VALUE_RANGE = 10

export default {
  components: {
    'vue-slider' : Slider,
  },
  props: {
    filterData: { type: Object, required: true },
    isLegacy: { type: Boolean, default: true },
  },
  emits: ['add-filter'],
  data() {
    return {
      constantId: '',
      inputMode: 'slider',
      value: [0, 100],
    }
  },
  computed: mapState({
    list: (state) => state.medicalUnitFilter.remoteLists['constants'],
    range(state) {
      if (!this.constantId || this.constantId === '') {
        return { min:0, max:100, list:[0, 100] }
      }

      const constant = _.find(state.medicalUnitFilter.remoteLists['constants'], (elem) => {
        return elem.id === this.constantId
      })

      const rawMin = parseFloat(constant.min)
      const rawMax = parseFloat(constant.max)
      let min = rawMin - OFFSET_CONSTANT_VALUE_RANGE * rawMin / 100
      let max = rawMax + OFFSET_CONSTANT_VALUE_RANGE * rawMax / 100

      min = parseInt(min + 0.5)
      max = parseInt(max + 0.5)

      min -= min % 10
      max += 10 - (max % 10)

      if (min < 0 && rawMin >= 0) {
        min = 0
      }

      const diff = max - min
      let step = 1

      if (diff > 1000) {
        step = 50
      } else if (diff > 100) {
        step = 5
      } else if (diff < 1 && diff > 0.1) {
        step = 0.05
      } else if (diff < 1 && diff > 0.01) {
        step = 0.005
      }

      let i = min
      const list = []

      if (min !== 0 && rawMin >= 0) {
        list.push(`<${min}`)
      }
      while (i < max) {
        list.push(i)
        i+= step
      }
      list.push(max)
      list.push(`>${max}`)


      if (min !== 0 && rawMin >= 0) {
        this.value = [`<${min}`, `>${max}`]

        return { min: `<${min}`, max: `>${max}`, list }
      } else {
        this.value = [min, `>${max}`]

        return { min, max: `>${max}`, list }
      }
    }
  }),
  methods: {
    removeChar(target) {
      this.value[target] = this.value[target].replace(/\D+/g, '')
    },
    addFilter() {
      const name = this.filterData.name
      const type = 'healthConstant'
      const value = `${this.constantId}\x1F${this.value[0]}\x1F${this.value[1]}`

      if (this.value[0] !== this.range.min || this.value[1] !== this.range.max) {
        this.$emit('add-filter', { name, type, value })
      }
    },
    toggleInputMode() {
      this.inputMode = this.inputMode === 'slider' ? 'manual' : 'slider'
    }
  },
}
</script>

<template>
  <div>
    <div class="row">
      <div
        class="col-auto"
        :class="isLegacy ? ['col-auto'] : ['col-12']"
      >
        <select
          v-model="constantId"
          class="form-control"
        >
          <option
            value=""
            disabled
            selected
          >
            ---
          </option>
          <option
            v-for="constant in list"
            :key="'constant_'+constant.id"
            :value="constant.id"
          >
            {{ constant.description }}
          </option>
        </select>
      </div>
      <div
        v-if="constantId !== ''"
        :class="isLegacy ? ['col-4'] : ['col-12']"
      >
        <vue-slider
          v-if="inputMode === 'slider'"
          v-model="value"
          :v-data="range.list"
          :tooltip="'always'"
          range
        />
        <div
          v-else
          class="row no-gutters align-items-center"
        >
          <div class="col-2 text-center">
            de
          </div>
          <div class="col">
            <input
              v-model="value[0]"
              type="text"
              class="form-control"
            >
          </div>
          <div class="col-2 text-center">
            à
          </div>
          <div class="col">
            <input
              v-model="value[1]"
              type="text"
              class="col form-control"
            >
          </div>
        </div>
      </div>
      <div class="col-auto">
        <button
          v-if="constantId !== ''"
          class="col-auto btn btn-primary"
          type="button"
          @click="addFilter"
        >
          Ok
        </button>
      </div>
    </div>
    <div
      v-if="constantId != ''"
      class="row"
    >
      <div class="col">
        <span
          class="btn btn-link"
          @click="toggleInputMode()"
        >
          {{ inputMode === 'slider' ? 'Mode manuel' : 'Mode normal' }}
        </span>
      </div>
    </div>
  </div>
</template>
