<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { mapState } from 'vuex'

export default {
  components: {
    'sancare-octicon': SancareOcticon,
  },
  data() {
    return {
      facilityName: window.facilityName,
      mailerDisabled: window.mailerDisable,
      userName: '',
      userEmail: '',
      userAgent: navigator.userAgent,
      bugDescription: '',
      showBugModal: false,
      reportTip: `Ce canal d'échange n'est pas sécurisé :
Par conséquent nous vous demandons de pas inclure dans vos messages d'informations personnelles ou identifiantes (numéro de séjour, identité, diagnostic, ...) , notamment concernant des patients (même pour un exemple de dysfonctionnement).
Conformément à la loi Informatique et Libertés, vous disposez d'un droit d'accès ou de modification aux informations personnelles vous concernant.`,
    }
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.settings.currentUser,
      sendBugReportRequest: (state) => state.settings.sendBugReportRequest,
    }),
    bugPage() {
      return this.$route.fullPath
    },
    mailTo() {
      const subject = `Rapport de bug ${this.userName} [${this.facilityName}]`
      const bugInfo = {
        Server: this.facilityName,
        Url: this.$route.fullPath,
        User: this.userName,
        'User Agent': this.userAgent,
      }

      let body = `${this.reportTip}\n`
      body += '\nDescription du bug:\n\n\n\n'
      body += '---------------------------\n'
      _.forEach(bugInfo, (info, name) => {
        body += `${name}: ${info}\n`
      })
      body += '---------------------------\n'

      return `mailto:support@sancare.fr?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
    },
  },
  watch: {
    currentUser(user) {
      this.userName = user.username
      this.userEmail = user.email
    },
    'sendBugReportRequest.ok': function(ok) {
      if (ok){
        this.closeModal()
      }
    }
  },
  mounted() {
    this.$store.dispatch('getSelf')
  },
  methods: {
    handleSubmitBug() {
      this.$store.dispatch('sendBugReport', {
        fullUrl: this.$route.fullPath,
        user: this.userName,
        userAgent: this.userAgent,
        message: this.bugDescription
      })
    },
    closeModal() {
      this.showBugModal = false
      this.bugDescription = ''
    },
    openModal() {
      this.$store.dispatch('getSelf')
      this.showBugModal = true
    },
  },
}
</script>

<template>
  <div>
    <a
      v-if="mailerDisabled"
      v-tooltip="'Signaler un bug'"
      :href="mailTo"
      class="px-2"
    >
      <sancare-octicon
        name="bug"
        :width="25"
        :height="25"
      />
    </a>
    <div
      v-else
      v-tooltip="'Signaler un bug'"
      class="px-2"
      @click="openModal()"
    >
      <sancare-octicon
        name="bug"
        :width="25"
        :height="25"
      />
    </div>
    <transition name="sancare-modal">
      <div
        v-if="showBugModal"
        class="sancare-modal-mask row no-gutters justify-content-center align-items-start"
        @click.stop="closeModal()"
      >
        <div
          class="sancare-modal-overlay col-10 col-md-7 col-lg-5 col-xl-4"
          @click.stop
        >
          <div class="sancare-modal-header">
            <span class="row">
              <span
                class="sancare-modal-icon col-1 col-md-1 col-lg-1 col-xl-1"
              >
                <sancare-octicon
                  name="bug"
                  :width="18"
                  :height="26"
                />
              </span>
              <span class="sancare-modal-title col-9 col-md-9 col-lg-9 col-xl-9">Signaler un bug</span>
              <span
                class="sancare-modal-close col-2 col-md-2 col-lg-2 col-xl-2"
                @click="closeModal()"
              >
                <sancare-octicon
                  name="x"
                  :width="20"
                  :height="20"
                />
              </span>
            </span>
          </div>
          <div class="sancare-modal-body">
            <form
              class="row"
              @submit.prevent="handleSubmitBug"
            >
              <div class="form-group col-6">
                <label
                  for="bug-user-email"
                  name="bug-user-email"
                  class="small-label"
                >
                  Utilisateur
                </label>
                <input
                  id="bug-user-email"
                  v-model="userName"
                  :disabled="currentUser.username"
                  class="form-control"
                >
              </div>
              <div class="form-group col-6">
                <label
                  for="bug-user-email"
                  name="bug-user-email"
                  class="small-label"
                >
                  Email
                </label>
                <input
                  id="bug-user-email"
                  v-model="userEmail"
                  :disabled="currentUser.email"
                  class="form-control"
                >
              </div>
              <!-- eslint-disable-next-line vue/max-attributes-per-line,vue/singleline-html-element-content-newline -- We have to put everything on a single line to avoid whitespace issues -->
              <div class="alert alert-warning small-label" style="white-space: pre-wrap">{{ reportTip }}</div>
              <div class="form-group col-12">
                <label
                  for="bug-description"
                  name="bug-description"
                  class="small-label"
                >
                  Description du bug:
                </label>
                <textarea
                  id="bug-description"
                  v-model="bugDescription"
                  class="form-control sancare-bug-description"
                />
              </div>
              <div
                v-if="sendBugReportRequest.error"
                class="col-12 text-center text-danger"
              >
                {{ sendBugReportRequest.error }}
              </div>
              <div class="col-12 text-right">
                <button
                  class="btn btn-push btn-primary btn-padded"
                  type="submit"
                >
                  Envoyer
                </button>
              </div>
            </form>
          </div>
          <div class="sancare-modal-footer" />
        </div>
      </div>
    </transition>
  </div>
</template>
