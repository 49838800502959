import { AbstractState } from '@sancare/ui-frontend-commons/src/store/types'

import { PmsiTypeEnum } from '@/store/modules/settings/types'

export enum ConnectorType {
  CORA = 'cora',
  MEDSTAT = 'medstat',
  WEBPIMS = 'webpims',
  CAPITOOLS = 'capitools',
  EVENEMENTS_PMSI = 'evenements_pmsi',
}

export const connectorsTypesLabels = {
  [ConnectorType.CORA]: 'Cora',
  [ConnectorType.MEDSTAT]: 'Medstat',
  [ConnectorType.WEBPIMS]: 'Webpims',
  [ConnectorType.CAPITOOLS]: 'Capitools',
  [ConnectorType.EVENEMENTS_PMSI]: 'Evènements PMSI',
}

export const fileConnectors = [
  ConnectorType.CORA,
  ConnectorType.CAPITOOLS,
  ConnectorType.EVENEMENTS_PMSI,
]

export const httpConnectors = [
  ConnectorType.MEDSTAT,
  ConnectorType.WEBPIMS,
]

export enum ConnectorFlowType {
  AUTO = 'auto_validation',
  MANUAL = 'manual_validation',
  REVALUABLE = 'revaluable_state_update',
}

export const connectorFlowVersions = {
  [ConnectorType.CORA]: ['2.2', '2.4'],
  [ConnectorType.WEBPIMS]: ['2.0', '2.1'],
  [ConnectorType.MEDSTAT]: ['1.1'],
  [ConnectorType.CAPITOOLS]: ['2.4'],
  [ConnectorType.EVENEMENTS_PMSI]: ['2.2', '2.4'],
}

export const connectorsConnexionInputs = {
  host: httpConnectors,
  isSecureSsl: httpConnectors,
  apiKey: [ConnectorType.MEDSTAT],
  username: [ConnectorType.WEBPIMS],
  password: [ConnectorType.WEBPIMS],
  pmsiType: [ConnectorType.CORA],
}

export type ConnectorConfig = {
  id: number
  connectorType: ConnectorType|null
  host: string
  apiKey: string
  apiKeyPart: string
  username: string
  password: string
  isAllFiness: boolean
  isSecureSsl: boolean
  legalFinessList: Finess[]
  geoFinessList: Finess[]
  flows: ConnectorFlow[]
}

export type Finess = {
  finess: string
  shortDescription: string
}

export const emptyConnector = {
  id: null,
  connectorType: null,
  host: '',
  apiKey: null,
  apiKeyPart: null,
  username: null,
  password: null,
  isSecureSsl: false,
  isAllFiness: true,
  legalFinessList: [],
  geoFinessList: [],
  flows: [],
}

export type ConnectorFlow = {
  id: number
  type: ConnectorFlowType
  path: string
  formatVersion: string
  pmsiType: PmsiTypeEnum
}

export class ConnectorConfigState extends AbstractState {
  connectorsConfigList: ConnectorConfig[]
  flowsWriteRights: []
}
