import dayjs from 'dayjs'

function labelSets(stats, chartUnit) {
  const monthLabel = [
    'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
    'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
  ]
  let labels = []

  switch(chartUnit) {
    case 'day':
    case 'week':
      labels = stats.map((stat) => {
        return dayjs(stat.earlyTimestamp).format('DD/MM/YY')
      })
      break
    case 'month':
      labels = stats.map((stat) => {
        const date = dayjs(stat.earlyTimestamp)

        return `${monthLabel[date.month()]} ${date.year()}`
      })
      break
    case 'year':
      labels = stats.map((stat) => {
        return dayjs(stat.earlyTimestamp).year()
      })
      break
  }

  return labels
}

export {
  labelSets,
}
