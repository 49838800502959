<script>
import { mapState } from 'vuex'

import { hasPermission, RoleEnum } from '@/misc/permissions/permissionsHelper'

import AppSettingsManager from './AppSettingsManager.vue'
import ConnectorsManager from './ConnectorsManager.vue'
import UserManager from './UserManager.vue'

export default {
  components: {
    'user-manager': UserManager,
    'app-settings-manager': AppSettingsManager,
    'connectors-manager': ConnectorsManager,
  },
  data() {
    return {
      tab: 'userManager',
    }
  },
  computed: mapState({
    canManageUsers: (state) => hasPermission(RoleEnum.ROLE_USER_MANAGER, state.login.userRole) || hasPermission(RoleEnum.ROLE_ADMIN_MANAGER, state.login.userRole),
    canManageSettings: (state) => hasPermission(RoleEnum.ROLE_SETTING_UPDATER, state.login.userRole),
    canManageSancareSettings: (state) => hasPermission(RoleEnum.ROLE_SANCARE_SETTING_UPDATER, state.login.userRole),
    canManageConnectors: (state) => hasPermission(RoleEnum.ROLE_SANCARE_SETTING_UPDATER, state.login.userRole),
  }),
  mounted() {
    if (this.canManageUsers) {
      this.tab = 'userManager'
    } else if (this.canManageSettings) {
      this.tab = 'settingManager'
    }
  },
}
</script>

<template>
  <div class="admin-page">
    <div class="container">
      <ul class="row no-gutters nav nav-tabs">
        <li
          v-if="canManageUsers"
          class="col nav-item text-center home-list-tab"
        >
          <span
            :class="{ 'active': tab === 'userManager' }"
            class="nav-link"
            @click="tab = 'userManager'"
          >
            Utilisateurs
          </span>
        </li>
        <li
          v-if="canManageSettings"
          class="col nav-item text-center home-list-tab"
        >
          <span
            :class="{ 'active': tab === 'settingManager' }"
            class="nav-link"
            @click="tab = 'settingManager'"
          >
            Paramètres
          </span>
        </li>
        <li
          v-if="canManageSancareSettings"
          class="col nav-item text-center home-list-tab"
        >
          <span
            :class="{ 'active': tab === 'sancareSettingManager' }"
            class="nav-link"
            @click="tab = 'sancareSettingManager'"
          >
            Paramètres Administrateur
          </span>
        </li>
        <li
          v-if="canManageConnectors"
          class="col nav-item text-center home-list-tab"
        >
          <span
            :class="{ 'active': tab === 'connectorsManager' }"
            class="nav-link"
            @click="tab = 'connectorsManager'"
          >
            Connecteurs
          </span>
        </li>
      </ul>
      <div class="home-list">
        <user-manager
          v-if="canManageUsers"
          v-show="tab === 'userManager'"
        />
        <app-settings-manager
          v-if="canManageSettings"
          v-show="tab === 'settingManager'"
          :sancare-only="false"
        />
        <app-settings-manager
          v-if="canManageSancareSettings"
          v-show="tab === 'sancareSettingManager'"
          :sancare-only="true"
        />
        <connectors-manager
          v-if="canManageConnectors"
          v-show="tab === 'connectorsManager'"
        />
      </div>
    </div>
  </div>
</template>
