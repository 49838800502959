<script>
import { SancareModal, SancareOcticon } from '@sancare/ui-frontend-commons'
import { mapGetters, mapState } from 'vuex'

import { PmsiTypeEnum } from '@/store/modules/settings/types'

import SavedSearchDetails from './SavedSearchDetails.vue'
import SavedSearchThumbnail from './SavedSearchThumbnail.vue'

export default {
  components: {
    'sancare-modal': SancareModal,
    'saved-search-details': SavedSearchDetails,
    'saved-search-thumbnail': SavedSearchThumbnail,
    'sancare-octicon': SancareOcticon,
  },
  props: {
    activeSearchIds: { type: Array, required: true },
    pmsiType: {
      validator(value) {
        return Object.values(PmsiTypeEnum).includes(value)
      },
      default: PmsiTypeEnum.MCO
    },
  },
  emits: ['load-saved-search'],
  data() {
    return {
      showSavedFilters: false,
      forceShowLoader: false,
    }
  },
  computed: {
    title() {
      return this.activeSearchIds.length <= 1 ? 'Recherche sélectionnée' : 'Recherches sélectionnées'
    },
    ...mapGetters(['username', 'flatSancareSettings']),
    ...mapState({
      savedSearchesRequest: (state) => state.savedSearches.fetchSavedSearchesRequest,
      earlyLimit: (state) => state.settings.earlyLimit,
      lateLimit: (state) => state.settings.lateLimit,
      allSavedSearches: (state) => state.savedSearches.savedSearches,
      isSancareUser() {
        return this.$store.state.settings.currentUser.sancareUser
      },
    }),
    activeSearches() {
      return this.allSavedSearches.filter((s) => this.activeSearchIds.indexOf(s.id) > -1)
    },
  },
  methods: {
    closeModal() {
      this.showSavedFilters = false
    },
    async openModal() {
      this.showSavedFilters = true
      this.forceShowLoader = true
      // load search conditions if allowed and not already done
      const searchIds = this.activeSearches.filter((s) => !s.criteriaGroups && (s.privacy !== 'sancareSearch' || this.isSancareUser || this.flatSancareSettings['hide_sancare_searches_details'] === false)).map((s) => s.id)
      if (searchIds.length > 0) {
        await this.$store.dispatch('fetchSavedSearches', { earlyLimit: this.earlyLimit, lateLimit: this.lateLimit, pmsiType: this.pmsiType, searchIds })
      }
      window.setTimeout(() => this.forceShowLoader = false, 500)
    },
    loadSavedSearch(search) {
      this.closeModal()
      this.$emit('load-saved-search', search)
    },
  }
}
</script>

<template>
  <div>
    <div @click="openModal()">
      <slot name="button" />
    </div>
    <sancare-modal
      :open="showSavedFilters"
      :no-padding="true"
      @close-modal="closeModal"
    >
      <template #header>
        <div>
          <div class="row">
            <div class="sancare-modal-icon col-1">
              <sancare-octicon
                name="search"
                :width="20"
                :height="20"
              />
            </div>
            <div class="sancare-modal-title col">
              {{ title }}
            </div>
            <div
              class="sancare-modal-close col-auto"
              @click="closeModal"
            >
              <sancare-octicon
                name="x"
                :width="20"
                :height="20"
              />
            </div>
          </div>
        </div>
      </template>
      <div>
        <div v-if="savedSearchesRequest.fetching || forceShowLoader">
          <div class="loader loader-lg loader-center" />
        </div>
        <div v-if="savedSearchesRequest.error && !forceShowLoader">
          <p>{{ savedSearchesRequest.error }}</p>
        </div>
        <div v-if="activeSearches.length && !forceShowLoader">
          <div
            v-for="(search, searchIdx) in activeSearches"
            :key="'active_search_'+search.id"
            class="row p-2 no-gutters align-items-center"
          >
            <hr v-if="searchIdx">
            <div class="col-3 p-2">
              <saved-search-thumbnail
                :search="search"
                :select-enabled="false"
                :show-criteria-enabled="false"
                :show-settings-enabled="false"
              />
            </div>
            <div class="col pl-2">
              <saved-search-details
                v-if="search.criteriaGroups"
                :saved-search="search"
                :can-remove-criteria="false"
              />
              <div v-else>
                Recherche Sancare
              </div>
            </div>
            <div class="col-1 text-center">
              <span
                v-if="search.criteriaGroups"
                class="saved-search-icon m-1 cp"
                @click="loadSavedSearch(search)"
              >
                <sancare-octicon
                  name="search"
                  :width="20"
                  :height="20"
                />
              </span>
            </div>
          </div>
        </div>
        <div v-if="activeSearches.length === 0 && !savedSearchesRequest.fetching && !savedSearchesRequest.error && !forceShowLoader">
          <div class="text-center">
            <div>
              <sancare-octicon
                name="telescope"
                :width="60"
                :height="60"
              />
            </div>
            <p>Aucune recherche active</p>
          </div>
        </div>
      </div>
    </sancare-modal>
  </div>
</template>
