<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { mapGetters } from 'vuex'

import router from '@/router'

import { AutomationModeEnum, AutomationTypeEnum } from '../store/modules/automation/types'
import AutomationGroupDetails from './AutomationGroupDetails.vue'

export default {
  components: {
    'automation-group-details': AutomationGroupDetails,
    'sancare-octicon': SancareOcticon,
  },
  props: {
    earlyLimit: { type: Object, required: true },
    lateLimit: { type: Object, required: true },
    statsGroup: { type: Object, required: true },
    fullGroup: { type: Object, required: true },
    ghmCollidingGroupNames: { type: Array, required: true },
    demoMode: { type: Boolean, required: true },
  },
  emits: ['remove-automation-group'],
  data() {
    return {
      automatedGroup: this.fullGroup.automated,
      removeGroupConfirm: false,
      automationConfirm: false,
      criteriaLoadedOnce: false,
      AutomationTypeEnum,
      AutomationModeEnum,
    }
  },
  computed: {
    ...mapGetters(['shortHandAutomStayListParams']),
  },
  methods: {
    joinCollidingGroupNames(collidingGroups) {
      return collidingGroups.map((group) => {
        return group.name
      }).join(', ')
    },
    toggleAutomation(groupId) {
      if (this.automatedGroup) {
        this.automatedGroup = false
        this.$store.dispatch('disableAutomationGroup', groupId)
      } else {
        this.automatedGroup = true
        this.$store.dispatch('enableAutomationGroup', groupId)
      }
      this.automationConfirm = false
    },
    removeAutomationGroup(groupId) {
      this.$emit('remove-automation-group', groupId)
    },
    gotoGroupStayList(event, automationGroupId, automationMode, forceNewWindow) {
      this.$store.commit('setAutomStayListParam', { type: 'automationGroupId', value: automationGroupId })
      this.$store.commit('setAutomStayListParam', { type: 'automationMode', value: automationMode })
      const newRoute = {
        path: '/automation/automation-stay-list',
        query: {
          ...this.shortHandAutomStayListParams,
          automationGroupId,
          automationMode,
          el: this.earlyLimit.format('DD-MM-YYYY'),
          ll: this.lateLimit.format('DD-MM-YYYY'),
        }
      }
      if (forceNewWindow || event.ctrlKey || event.metaKey || event.shiftKey) {
        window.open(router.resolve(newRoute).href, '_blank')
      } else {
        router.push(newRoute)
      }
    },
    showGroupCriteria() {
      if (!this.criteriaLoadedOnce) {
        this.criteriaLoadedOnce = true
        this.$store.dispatch('getAutomationGroup', this.fullGroup.id)
      }
    }
  }
}
</script>

<template>
  <div class="automation-group-thumbnail hover-parent">
    <div class="automation-group-thumbnail__banner">
      <div class="row automation-group-thumbnail__banner-title">
        <div class="col-8">
          {{ statsGroup.name }}
        </div>
        <div class="row ml-auto col-auto">
          <v-dropdown
            :triggers="['hover']"
            placement="left"
            class="mx-1 popover-container"
            popover-class="saved-search-thumbnail-criteria-popover"
            @show="showGroupCriteria"
          >
            <span>
              <sancare-octicon
                name="info"
                :width="18"
                :height="18"
              />
            </span>
            <template #popper>
              <automation-group-details
                v-if="fullGroup.criteriaGroups"
                :full-automation-group="fullGroup"
              />
              <div
                v-else
                class="loader loader-center"
              />
            </template>
          </v-dropdown>
          <v-dropdown
            v-if="ghmCollidingGroupNames.length"
            :triggers="['hover']"
            placement="top"
            class="mx-1"
            popover-class="criteria-popover"
          >
            <span>
              <sancare-octicon
                name="alert"
                :width="18"
                :height="18"
              />
            </span>
            <slot />
            <template #popper>
              <div style="width: 30rem;">
                Les critères du groupe contiennent des GHM en commun avec le(s) groupe(s) suivant(s):
                <div style="text-align:center; font-weight: bold">
                  "{{ joinCollidingGroupNames(ghmCollidingGroupNames) }}"
                </div>
                <hr>
                Rappel:<br>
                <small>
                  Si un séjour est dans au moins un groupe activé dont les critères sont satisfaits, il est considéré comme automatisable. Et ce, même si un autre groupe dont il fait partie n'a pas ses critères satisfaits.
                </small>
              </div>
            </template>
          </v-dropdown>
          <v-dropdown
            placement="right"
            class="px-1 mr-2 popover-container"
            popover-class="saved-search-thumbnail-settings-popover"
            @hide="removeGroupConfirm = false"
          >
            <div
              v-tooltip="'Plus d\'infos'"
            >
              <sancare-octicon
                name="kebab-horizontal"
                :width="20"
              />
            </div>
            <template #popper>
              <div>
                <router-link
                  class="btn btn-link"
                  :to="`/automation/edit-automation-group?groupId=${statsGroup.id}&mode=automation`"
                >
                  Modifier
                </router-link>
              </div>
              <div
                v-if="!demoMode"
              >
                <v-dropdown
                  :shown="automationConfirm"
                >
                  <div
                    class="btn btn-link"
                    @click="automationConfirm = true"
                  >
                    {{ automatedGroup ? 'Ne pas automatiser' : 'Automatiser' }}
                  </div>
                  <template #popper>
                    <h4 class="title-light text-center">
                      Êtes-vous sûr(e) ?
                    </h4>
                    <div class="text-center">
                      <button
                        class="btn btn-danger btn-padded"
                        @click="toggleAutomation(statsGroup.id)"
                      >
                        Confirmer
                      </button>
                    </div>
                  </template>
                </v-dropdown>
              </div>
              <div
                v-if="demoMode"
                v-tooltip="'Les groupes ne peuvent pas être automatisés quand le mode démo est activé'"
                class="home-thumbnail-icon-disabled home-thumbnail-label ml-1 pl-2"
              >
                {{ automatedGroup ? 'Ne pas automatiser' : 'Automatiser' }}
              </div>
              <div
                v-if="!removeGroupConfirm"
                class="btn btn-link text-danger"
                @click="removeGroupConfirm = true"
              >
                Supprimer
              </div>
              <div
                v-else
                class="btn btn-link text-danger"
                @click="removeAutomationGroup(statsGroup.id)"
              >
                Confirmer
              </div>
              <div>
                <router-link
                  class="btn btn-link"
                  :to="`/automation/${statsGroup.id}/history?mode=automation`"
                >
                  Voir l'historique des versions
                </router-link>
              </div>
            </template>
          </v-dropdown>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-auto automation-group-thumbnail__banner-count">
          {{ statsGroup.totalCount || 0 }}
        </div>
        <div>
          Séjours correspondants
        </div>
        <div class="ml-auto px-3">
          <span
            v-tooltip="`Automatisation : ${automatedGroup ? 'Activée' : 'Désactivée'}`"
            :class="`automation-group-thumbnail__${automatedGroup ? 'enabled' : 'disabled'}`"
          >
            <sancare-octicon
              name="checklist"
              :width="20"
              :height="20"
            />
          </span>
        </div>
      </div>
    </div>

    <div class="automation-group-thumbnail__body">
      <div class="row no-gutters align-items-center automation-group-thumbnail__body-line--low-priority">
        <div class="col-2 text-right automation-group-thumbnail__number">
          {{ automatedGroup ? statsGroup.automatedCount : statsGroup.automatableCount || 0 }}
        </div>
        <div class="col-8 text-right">
          Séjours {{ automatedGroup ? 'validés' : 'automatisables' }}
        </div>
        <div class="col-1 text-right">
          <span
            class="hover-child cp"
            @click="gotoGroupStayList($event, statsGroup.id, AutomationModeEnum.AUTOMATABLE, false)"
            @click.middle="gotoGroupStayList($event, statsGroup.id, AutomationModeEnum.AUTOMATABLE, true)"
          >
            <sancare-octicon
              name="arrow-right"
              :width="15"
            />
          </span>
        </div>
      </div>
      <hr v-if="fullGroup.type === AutomationTypeEnum.AI">
      <div
        v-if="fullGroup.type === AutomationTypeEnum.AI"
        class="row no-gutters align-items-center automation-group-thumbnail__body-line--average-priority"
      >
        <div class="col-2 text-right automation-group-thumbnail__number">
          {{ statsGroup.waitingCount || 0 }}
        </div>
        <div class="col-8 text-right">
          Séjours en attente d'information
        </div>
        <div class="col-1 text-right">
          <span
            class="hover-child cp"
            @click="gotoGroupStayList($event, statsGroup.id, AutomationModeEnum.WAITING, false)"
            @click.middle="gotoGroupStayList($event, statsGroup.id, AutomationModeEnum.WAITING, true)"
          >
            <sancare-octicon
              name="arrow-right"
              :width="15"
            />
          </span>
        </div>
      </div>
      <hr v-if="fullGroup.type === AutomationTypeEnum.AI">
      <div
        v-if="fullGroup.type === AutomationTypeEnum.AI"
        class="row no-gutters align-items-center automation-group-thumbnail__body-line--high-priority"
      >
        <div class="col-2 text-right automation-group-thumbnail__number">
          {{ statsGroup.expiredCount || 0 }}
        </div>
        <div class="col-8 text-right">
          Séjours à faire manuellement
        </div>
        <div class="col-1 text-right">
          <span
            class="hover-child cp"
            @click="gotoGroupStayList($event, statsGroup.id, AutomationModeEnum.EXPIRED, false)"
            @click.middle="gotoGroupStayList($event, statsGroup.id, AutomationModeEnum.EXPIRED, true)"
          >
            <sancare-octicon
              name="arrow-right"
              :width="15"
            />
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="false"
      class="automation-group-thumbnail__footer text-center"
    >
      <div>Contrôle Qualité</div>
      <small>(233 Séjours)</small>
    </div>
  </div>
</template>
