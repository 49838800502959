import { setRequest } from '@sancare/ui-frontend-commons/src/misc/api'

import { ConnectorConfig, ConnectorConfigState, emptyConnector } from '@/store/modules/connector-config/types'

export const mutations = {
  addEmptyConnector(state: ConnectorConfigState) {
    state.connectorsConfigList.push(emptyConnector)
  },
  getConnectorsConfigListSuccess(state: ConnectorConfigState, connectorsConfigList: ConnectorConfig[]) {
    state.connectorsConfigList = connectorsConfigList
  },
  submitConnectorConfigSuccess(state: ConnectorConfigState, data: { connectorIndex: number, connectorData: ConnectorConfig }) {
    const connectorsConfigList = [...state.connectorsConfigList]
    connectorsConfigList[data.connectorIndex] = data.connectorData
    state.connectorsConfigList = connectorsConfigList
    
  },
  deleteConnectorConfigSuccess(state: ConnectorConfigState, connectorIndex: number) {    
    const connectorsConfigList = [...state.connectorsConfigList]
    connectorsConfigList.splice(connectorIndex, 1)
    state.connectorsConfigList = connectorsConfigList
  },
  setRequest
}
