<script>
import { DatePicker } from '@sancare/ui-frontend-commons'
import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import { capitalize } from '@sancare/ui-frontend-commons/src/misc/string-utils'
import _ from 'lodash'

export default {
  components: {
    'date-picker': DatePicker
  },
  props: {
    filterData: { type: Object, required: true },
    isLegacy: { type: Boolean, default: true },
  },
  emits: ['add-filter'],
  data() {
    const subFiltersValues = {}

    if (!this.filterData.subFilters) {
      return {
        date: this.filterData.default ? this.filterData.default : dayjs(),
        subFiltersValues: subFiltersValues,
      }
    }

    this.filterData.subFilters.forEach((filter) => {
      if (filter.default) {
        subFiltersValues[filter.name] = filter.default

        return
      }
      subFiltersValues[filter.name] = filter.options ? filter.options[0] : ''
    })

    return {
      date: dayjs(),
      subFiltersValues: subFiltersValues,
    }
  },
  computed: {
    value() {
      return dayjs.isDayjs(this.date) ? this.date : dayjs()
    }
  },
  methods: {
    setDate(date) {
      this.date = date
    },
    addFilter() {
      const name = this.filterData.name
      let type = this.filterData.format

      if (_.isEmpty(this.date)) {
        this.date = dayjs().format('YYYY-MM-DD')

        return
      }
      const value = this.date.format('YYYY-MM-DD')
      type = type.replace('{filterType}', capitalize(this.filterData.name))
      if (this.filterData.subFilters) {
        this.filterData.subFilters.forEach((filter) => {
          type = type.replace(`{${filter.name}}`, capitalize(this.subFiltersValues[filter.name]))
        })
      }
      type = type.charAt(0).toLowerCase() + type.slice(1)
      this.$emit('add-filter', { name, type, value })
    },
  },
}
</script>

<template>
  <div class="row align-items-center">
    <div
      v-if="filterData && filterData.subFilters && filterData.subFilters.length"
      class="input-group"
      :class="isLegacy ? ['col-4'] : ['col-12']"
    >
      <select
        v-for="filter in filterData.subFilters"
        :key="filter.name"
        v-model="subFiltersValues[filter.name]"
        class="custom-select form-control"
      >
        <option
          v-for="opt in filter.options"
          :key="opt"
          :value="opt"
        >
          {{ filter.labels[opt] ? filter.labels[opt] : opt }}
        </option>
      </select>
    </div>
    <div
      class="input-group"
      :class="isLegacy ? ['col-8'] : ['col-12']"
    >
      <date-picker
        class="col-6"
        :model-value="value"
        @update:model-value="setDate"
      />
      <div class="offset-1">
        <button
          class="btn btn-primary"
          type="button"
          @click="addFilter"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
</template>
