import Api from '@/misc/api'

export default {
  state: {

    debugCoraRevaluationsRequest: { fetching: false, error: null, ok: false },
    debugCoraRevaluationsResponse: {},

    debugCoraEditionsRequest: { fetching: false, error: null, ok: false },
    debugCoraEditionsResponse: {},

    debugCoraStayRequest: { fetching: false, error: null, ok: false },
    debugCoraStayResponse: {},

    debugCoraImportsRequest: { fetching: false, error: null, ok: false },
    debugCoraImportsResponse: {},

    debugWebpimsImportRequest: { fetching: false, error: null, ok: false },
    debugWebpimsImportResponse: {},
  },
  mutations: {
    debugCoraRevaluationsStart(state) {
      state.debugCoraRevaluationsRequest = { fetching: true, error: null, ok: false }
      state.debugCoraRevaluationsResponse = null
    },
    debugCoraRevaluationsSuccess(state, res) {
      state.debugCoraRevaluationsRequest = { fetching: false, error: null, ok: true }
      state.debugCoraRevaluationsResponse = res
    },
    debugCoraRevaluationsError(state, res) {
      state.debugCoraRevaluationsRequest = { fetching: false, error: res.message, ok: true }
      state.debugCoraRevaluationsResponse = res.message
    },

    debugCoraEditionsStart(state) {
      state.debugCoraEditionsRequest = { fetching: true, error: null, ok: false }
      state.debugCoraEditionsResponse = null
    },
    debugCoraEditionsSuccess(state, res) {
      state.debugCoraEditionsRequest = { fetching: false, error: null, ok: true }
      state.debugCoraEditionsResponse = res
    },
    debugCoraEditionsError(state, res) {
      state.debugCoraEditionsRequest = { fetching: false, error: res.message, ok: true }
      state.debugCoraEditionsResponse = res.message
    },

    debugCoraStayStart(state) {
      state.debugCoraStayRequest = { fetching: true, error: null, ok: false }
      state.debugCoraStayResponse = null
    },
    debugCoraStaySuccess(state, res) {
      state.debugCoraStayRequest = { fetching: false, error: null, ok: true }
      state.debugCoraStayResponse = res
    },
    debugCoraStayError(state, res) {
      state.debugCoraStayRequest = { fetching: false, error: res.message, ok: true }
      state.debugCoraStayResponse = res.message
    },

    debugCoraImportsStart(state) {
      state.debugCoraImportsRequest = { fetching: true, error: null, ok: false }
      state.debugCoraImportsResponse = null
    },
    debugCoraImportsSuccess(state, res) {
      state.debugCoraImportsRequest = { fetching: false, error: null, ok: true }
      state.debugCoraImportsResponse = res
    },
    debugCoraImportsError(state, res) {
      state.debugCoraImportsRequest = { fetching: false, error: res.message, ok: true }
      state.debugCoraImportsResponse = res.message
    },

    debugWebpimsImportStart(state) {
      state.debugWebpimsImportRequest = { fetching: true, error: null, ok: false }
      state.debugWebpimsImportResponse = null
    },
    debugWebpimsImportSuccess(state, res) {
      state.debugWebpimsImportRequest = { fetching: false, error: null, ok: true }
      state.debugWebpimsImportResponse = res
    },
    debugWebpimsImportError(state, res) {
      state.debugWebpimsImportRequest = { fetching: false, error: res.message, ok: true }
      state.debugWebpimsImportResponse = res.message
    },
  },
  actions: {
    debugCoraRevaluations({ commit }, earlyLimit) {
      return Api.get(
        {
          url: `/api/export/cora/revaluations?earlyLimit=${encodeURIComponent(earlyLimit.format())}`,
          startMut: 'debugCoraRevaluationsStart',
          successMut: 'debugCoraRevaluationsSuccess',
          errorMut: 'debugCoraRevaluationsError',
        },
        commit
      )
    },
    debugCoraEditions({ commit }, earlyLimit) {
      return Api.get(
        {
          url: `/api/export/cora/editions?earlyLimit=${encodeURIComponent(earlyLimit.format())}`,
          startMut: 'debugCoraEditionsStart',
          successMut: 'debugCoraEditionsSuccess',
          errorMut: 'debugCoraEditionsError',
        },
        commit
      )
    },
    debugCoraStay({ commit }, stayRssId) {
      // FIXME: Find a better way to receive XML format with api.js
      const xmlhttp = new XMLHttpRequest()

      xmlhttp.onreadystatechange = function() {
        if (xmlhttp.readyState === 4) {
          commit('debugCoraStaySuccess', xmlhttp.response)
        }
      }

      xmlhttp.open('GET', `/api/export/cora/${encodeURIComponent(stayRssId)}`, true)
      xmlhttp.send()
      commit('debugCoraStayStart')

    },
    debugCoraImports({ commit }, stayHPrim) {
      // FIXME: Find a better way to send XML format with api.js
      const xmlhttp = new XMLHttpRequest()

      xmlhttp.onreadystatechange = function() {
        if (xmlhttp.readyState === 4) {
          commit('debugCoraImportsSuccess', xmlhttp.response)
        }
      }

      xmlhttp.open('POST', 'api/import/cora', true)
      xmlhttp.send(stayHPrim.trim())
      commit('debugCoraImportsStart')
    },
    debugWebpimsImport({ commit }, content) {
      return Api.post(
        {
          url: '/api/import/webpims',
          body: content,
          startMut: 'debugWebpimsImportStart',
          successMut: 'debugWebpimsImportSuccess',
          errorMut: 'debugWebpimsImportError',
        },
        commit
      )
    },
  }
}
