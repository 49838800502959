<script>
import _ from 'lodash'

import { PmsiTypeEnum } from '@/store/modules/settings/types'

import OwnSearchList from './OwnSearchList.vue'

export default {
  name: 'TeamSearchList',
  components: {
    'own-search-list': OwnSearchList,
  },
  props: {
    teamSearches: { type: Array, required: true },
    mode: { type: String, required: true },
    pmsiType: {
      validator(value) {
        return Object.values(PmsiTypeEnum).includes(value)
      },
      required: true,
    },

    selectedSearches: { type: Array, required: true },
    quickFilterResults: { type: Array, default: null },
    title: { type: String, required: true },
  },
  emits: ['refresh-stats', 'select-search'],
  computed: {
    hasTeamSearches() {
      return _.some(this.teamSearches, 'searches')
    },
    displayedTeamSearches() {
      return _.filter(this.teamSearches, (subList) => subList.searches.length)
    }
  },
  methods: {
    handleSelectSearch(search) {
      this.$emit('select-search', search)
    },
    refreshStats(searchIds) {
      this.$emit('refresh-stats', searchIds)
    }
  },
}
</script>

<template>
  <div
    v-if="hasTeamSearches"
    class="home-list-part"
  >
    <div class="row no-gutters">
      <h2 class="col home-list-part-title p-2">
        {{ title }}
      </h2>
    </div>
    <own-search-list
      v-for="subList in displayedTeamSearches"
      :key="subList.title"
      :search-list="subList.searches"
      :mode="mode"
      :pmsi-type="pmsiType"
      :selected-searches="selectedSearches"
      :quick-filter-results="quickFilterResults"
      :title="subList.title"
      @select-search="handleSelectSearch"
      @refresh-stats="refreshStats"
    />
  </div>
</template>
