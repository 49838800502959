<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import numeral from 'numeral'
import { mapState } from 'vuex'

import { PmsiTypeEnum } from '@/store/modules/settings/types'

const SAVED_SEARCH_GROUP_THUMBNAIL_MAX_LABEL_LENGTH = 64
const SAVED_SEARCH_GROUP_THUMBNAIL_MAX_COLOR = 9

export default {
  components: {
    'sancare-octicon': SancareOcticon,
  },
  props: {
    searchesGroup: { type: Object, required: true },
    pmsiType: {
      validator(value) {
        return Object.values(PmsiTypeEnum).includes(value)
      },
      default: PmsiTypeEnum.MCO
    },
  },
  data() {
    return {
      showGroupSearches: false,
      showRemoveConfirm: false,
    }
  },
  computed: {
    displayedColor() {
      return this.searchesGroup.id % SAVED_SEARCH_GROUP_THUMBNAIL_MAX_COLOR
    },
    displayedCount() {
      return numeral(this.searchesGroup.countTodoStay).format(this.searchesGroup.countTodoStay >= 1000 ? '0.0a' : '0a').toUpperCase()
    },
    displayedLabel() {
      if (this.searchesGroup && this.searchesGroup.name.length > SAVED_SEARCH_GROUP_THUMBNAIL_MAX_LABEL_LENGTH) {
        return this.searchesGroup.name.substr(0, SAVED_SEARCH_GROUP_THUMBNAIL_MAX_LABEL_LENGTH)
      } else {
        return this.searchesGroup.name
      }
    },
    ...mapState({
      username: (state) => state.login.username,
    }),
  },
  methods: {
    removeGroup() {
      this.$store.dispatch('removeSavedSearchesGroup', this.searchesGroup.id)
    }
  }
}
</script>

<template>
  <div :class="`saved-search-thumbnail saved-search-thumbnail-color-${displayedColor}`">
    <router-link
      class="row no-gutters align-items-center saved-search-thumbnail-header"
      :to="{ name: 'staylist', query: searchesGroup.stayListQuery, params: {pmsiType: pmsiType} }"
    >
      <div class="col-auto px-2 text-center saved-search-thumbnail-count">
        {{ displayedCount }}
      </div>
      <div
        :title="searchesGroup.name"
        class="col px-2 text-right saved-search-thumbnail-name"
      >
        {{ displayedLabel }}
      </div>
      <div class="col-auto mx-1">
        <v-dropdown
          :shown="showGroupSearches"
          placement="bottom"
          class="popover-container"
          popover-class="saved-search-thumbnail-criteria-popover"
        >
          <span
            class="saved-search-thumbnail-criteria-icon"
            @mouseover="showGroupSearches = true"
            @mouseout="showGroupSearches = false"
          >
            <sancare-octicon
              name="info"
              :width="18"
              :height="18"
            />
          </span>
          <template #popper>
            <div class="text-center">
              {{ searchesGroup.savedSearches.length ? 'Recherches' : 'Recherche' }}
            </div>
            <hr>
            <div
              v-for="search in searchesGroup.savedSearches"
              :key="`searches_group_${searchesGroup.id}_search_${search.id}`"
              class="row no-gutters"
            >
              <div class="col">
                <span v-if="search.disabled"><del>{{ search.name }}</del></span>
                <span v-else>{{ search.name }}</span>
              </div>
            </div>
          </template>
        </v-dropdown>
      </div>
    </router-link>
    <div class="row no-gutters px-1 align-items-center saved-search-thumbnail-footer">
      <div class="col" />
      <div
        class="col-auto px-1"
      >
        <v-dropdown
          v-if="searchesGroup.user.username === username"
          placement="right"
          class="popover-container"
          popover-class="saved-search-thumbnail-settings-popover"
          @hide="showRemoveConfirm = false"
        >
          <span class="saved-search-thumbnail-settings-icon">
            <sancare-octicon
              name="kebab-horizontal"
              :width="18"
              :height="18"
            />
          </span>
          <template #popper>
            <div
              v-if="!showRemoveConfirm"
            >
              <button
                class="btn btn-link text-danger"
                @click="showRemoveConfirm = true"
              >
                Supprimer
              </button>
            </div>
            <div
              v-if="showRemoveConfirm"
            >
              <button
                class="btn btn-link text-danger"
                @click="removeGroup()"
              >
                Confirmer
              </button>
            </div>
          </template>
        </v-dropdown>
      </div>
    </div>
  </div>
</template>
