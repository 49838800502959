import { actions } from '@/store/modules/stay/ssr/actions'
import { getters } from '@/store/modules/stay/ssr/getters'
import { mutations } from '@/store/modules/stay/ssr/mutations'
import { state } from '@/store/modules/stay/ssr/state'

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}