<script>
import { mapGetters, mapState } from 'vuex'

import HomeThumbnail from '@/home/HomeThumbnail.vue'
import { ModeEnum, PmsiTypeEnum } from '@/store/modules/settings/types'

export default {
  name: 'GenericThumbnailList',
  components: {
    'home-thumbnail': HomeThumbnail,
  },
  props: {
    mode: { type: String, required: true },
    pmsiType: {
      validator(value) {
        return Object.values(PmsiTypeEnum).includes(value)
      },
      default: PmsiTypeEnum.MCO
    },
  },
  computed: {
    ...mapGetters(['settingsUrlQuery']),
    ...mapState({
      buttons(state) {
        const stats = state.home.homeStats
        const buttons = []

        const queryParams = {
          mode: this.mode,
          ...this.settingsUrlQuery
        }

        switch (this.mode) {
          case ModeEnum.NEW_CODING:
            buttons.push({
              name: 'newCodingShortStays',
              count: stats.countTodoNewCodingTotalStay,
              label: 'Tous les séjours',
              stayListQuery: { ...queryParams, newCoding: true },
            })
            break
          case ModeEnum.QUALITY_STAYS:
            buttons.push({
              name: 'allQualityStays',
              count: stats.countTodoQualityStay,
              label: 'Tous les séjours',
              stayListQuery: queryParams,
            })
            buttons.push({
              name: 'newCodingQualityStays',
              count: stats.countTodoNewCodingQualityStay,
              label: 'Séjours sans codage établissement',
              stayListQuery: { ...queryParams, newCoding: true },
            })
            break
          case ModeEnum.OPTIMIZABLE:
            switch (this.pmsiType) {
              case (PmsiTypeEnum.SSR):
                buttons.push({
                  name: 'AllStaysRevaluable',
                  // TODO: display the stat when it is ready
                  count: '-',
                  label: 'Séjours optimisables',
                  stayListQuery: { ...queryParams, revType: '' },
                })

                buttons.push({
                  name: 'MedicalStaysRevaluable',
                  // TODO: display the stat when it is ready
                  count: '-',
                  label: 'Séjours optimisables (Médical)',
                  stayListQuery: { ...queryParams, revType: '' },
                })
                buttons.push({
                  name: 'RehabilitationStaysRevaluable',
                  // TODO: display the stat when it is ready
                  count: '-',
                  label: 'Séjours optimisables (Rééducation)',
                  stayListQuery: { ...queryParams, revType: '' },
                })
                buttons.push({
                  name: 'DependencyStaysRevaluable',
                  // TODO: display the stat when it is ready
                  count: '-',
                  label: 'Séjours optimisables (Dépendances)',
                  stayListQuery: { ...queryParams, revType: '' },
                })
                break
              case (PmsiTypeEnum.MCO):
              default:
                buttons.push({
                  name: 'AllDasRevaluables',
                  count: stats.countTodoDasRevaluableStay,
                  label: 'Séjours optimisables (DAS)',
                  stayListQuery: { ...queryParams, revType: 'ia_or_search_das' },
                })
                buttons.push({
                  name: 'AllChronicDasRevaluables',
                  // TODO: display the stat when it is ready
                  count: '-',
                  label: 'Séjours optimisables (DAS chronique)',
                  stayListQuery: { ...queryParams, revType: 'chronic_das' },
                })
                buttons.push({
                  name: 'AllDasIaRevaluables',
                  count: stats.countTodoDasIaRevaluableStay,
                  label: 'Séjours optimisables par IA (DAS)',
                  stayListQuery: { ...queryParams, revType: 'ia_das' },
                })
                buttons.push({
                  name: 'AllDpIaRevaluables',
                  count: stats.countTodoDpIaRevaluableStay,
                  label: 'Séjours optimisables par IA (DP)',
                  stayListQuery: { ...queryParams, revType: 'ia_dp' },
                })
            }
            break
        }

        return buttons
      },
    })
  }
}
</script>

<template>
  <div class="row no-gutters home-list-part border-0">
    <div
      v-for="button in buttons"
      :key="button.name"
      class="col-3 p-2"
    >
      <home-thumbnail
        :count="button.count"
        :label="button.label"
        :name="button.name"
        :stay-list-query="button.stayListQuery"
        :pmsi-type="pmsiType"
      />
    </div>
  </div>
</template>
