import dayjs from 'dayjs'

import { RawPatient } from '@/store/modules/patient/types'
import { PrivacyEnum } from '@/store/modules/settings/types'
import { SeenStatusEnum } from '@/store/modules/stay/types'
import { Search } from '@/store/modules/stay-saved-search/types'

export function formatPatientName(patient: RawPatient): string {
  return `${patient.lastName || patient.maidenName} ${patient.firstName}`
}

export function formatOptionalDate(rawDate: string|null): string {
  return rawDate ? formatDate(rawDate) : ''
}

export function formatDate(rawDate: string): string {
  return dayjs(rawDate).format('L')
}

export function formatGroupingCost(groupingResultExists: boolean, rawCost: number): string {
  return groupingResultExists ? formatCost(rawCost) : '0,00'
}

export function formatCost(rawCost: number): string {
  return rawCost.toLocaleString('fr-FR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export function formatConfidence(rawConfidence: number): number {
  return Math.floor(rawConfidence * 100.0)
}

export function formatSearches(searches: Search[]): string[] {
  return searches.map((search) => {
    if (search.privacy === PrivacyEnum.TEAM_SEARCH) {
      return `[Équ.] ${search.name}`
    }
    if (search.privacy === PrivacyEnum.PRIVATE_SEARCH) {
      return `[Priv.] ${search.name}`
    }
    return search.name
  })
}

export function formatSeenStatus(status: SeenStatusEnum): string {
  return status === SeenStatusEnum.UNSEEN ? 'Non' : 'Oui'
}