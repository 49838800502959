<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import Slider from 'vue-slider-component'
import { mapGetters } from 'vuex'

import SancareSettingData from '../settings/sancareSettingsData'
import SettingData from '../settings/settingData.js'
import AppFinessInput from './AppFinessInput.vue'

export default {
  components: {
    'vue-slider': Slider,
    'sancare-octicon': SancareOcticon,
    'app-finess-input': AppFinessInput,
  },
  props: {
    sancareOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      setAppSettingsAction: this.sancareOnly ? 'setSancareSettings' : 'setAppSettings',
      settingData: this.sancareOnly ? SancareSettingData : SettingData,
      sliderSettings: {},
      boolSettings: {},
      finessSettings: {},
      hasEdition: false,
      error: null,
      success: false,
      showWarning: false,
      warningMessage: '',
    }
  },
  computed: {
    ...mapGetters(['aiFlagEnabled']),
    appSettings() {
      return this.sancareOnly ? this.$store.state.settings.sancareSettings : this.$store.state.settings.appSettings
    },
    setAppSettingsRequest() {
      return this.sancareOnly ? this.$store.state.settings.setSancareSettingsRequest : this.$store.state.settings.setAppSettingsRequest
    },
    displayableSettings() {
      const result = {}
      Object.keys(this.settingData).forEach((mode) => {
        result[mode] = {
          label: this.settingData[mode].label,
          settings: this.settingData[mode].settings.filter((setting) => {
            if (setting.name !== 'ai_confidence_external_flag_threshold') {
              return true
            }
            return this.aiFlagEnabled
          })
        }
      })
      return result
    }
  },
  watch: {
    setAppSettingsRequest(req) {
      if (req.ok) {
        this.hasEdition = false
        this.success = true
        this.error = null
        this.showWarning = false
        this.warning = ''
      } else if (req.error) {
        this.error = req.error
      }
    },
  },
  mounted() {
    this.updateLocalSettings(this.appSettings)
  },
  methods: {
    updateLocalSettings(appSettings) {
      _.forEach(this.settingData, (category) => {
        _.forEach(category.settings, (setting) => {
          const settingName = setting.name
          switch (setting.type) {
            case 'bool':
              this.boolSettings[settingName] = appSettings.boolSettings[settingName].value !== null ? appSettings.boolSettings[settingName].value : appSettings.boolSettings[settingName].default
              break
            case 'slider':
              this.sliderSettings[settingName] = appSettings.sliderSettings[settingName].index !== null ? appSettings.sliderSettings[settingName].index : appSettings.sliderSettings[settingName].default
              break
            case 'finess':
              this.finessSettings[settingName] = [...appSettings.arraySettings[settingName]]
              break
          }
        })
      })
    },
    getMarks(settingName, value) {
      if (settingName === 'standard_mode_dp_financial_threshold') {
        const options = [10, 30, 50, 100, 300, 500, 1000]
        return { label: `${options[value]}€` }
      } else if (settingName.endsWith('_financial_risk')) {
        if (value === 0) {
          return { label: 'Faible' }
        } else if (value === 11) {
          return { label: 'Élevé' }
        }
      } else if (settingName === 'related_diagnosis') {
        switch (value) {
          case 0:
            return { label: '0%' }
          case 1:
            return { label: '5%' }
          case 2:
            return { label: '20%' }
          case 3:
            return { label: '40%' }
          case 4:
            return { label: '60%' }
          case 5:
            return { label: '80%' }
          case 6:
            return { label: '95%' }
          case 7:
            return { label: '100%' }
          default:
            return { label: '' }
        }
      } else if (settingName === 'ai_confidence_external_flag_threshold') {
        switch (value) {
          case 0:
            return { label: '60%' }
          case 2:
            return { label: '70%' }
          case 4:
            return { label: '80%' }
          case 6:
            return { label: '90%' }
          default:
            return { label: '' }
        }
      }else if (settingName === 'standard_mode_dp_threshold') {
        switch (value) {
          case 0:
            return { label: '10%' }
          case 2:
            return { label: '30%' }
          case 4:
            return { label: '50%' }
          case 6:
            return { label: '70%' }
          case 8:
            return { label: '90%' }
          default:
            return { label: '' }
        }
      } else if (settingName === 'new_coding_das_threshold') {
        return { label : `${value*10}%` }
      } else {
        if (value === 0) {
          return { label: 'Quantité' }
        } else if (value === 1) {
          return { label: 'Équilibré' }
        } else {
          return { label: 'Qualité' }
        }
      }
      return { label: '' }
    },
    onEdit(settingName) {
      this.hasEdition = true
      this.error = null
      this.success = false

      // show warning when editing automation settings
      if (this.settingData.automation && this.settingData.automation.settings.find((elem) => elem.name === settingName)) {
        this.warningMessage = 'La modification des paramètres d\'automatisation entraîne la désactivation de tous les groupes d\'automatisation'
        this.showWarning = true
      }
    },
    clearEdition() {
      this.updateLocalSettings(this.appSettings)
      this.hasEdition = false
    },
    saveEdition() {
      const editedSettings = {
        sliderSettings: [],
        boolSettings: [],
        arraySettings: [],
      }

      _.forEach(this.appSettings.sliderSettings, (setting, name) => {
        const settingValue = setting.index === null ? setting.default : setting.index
        if (this.sliderSettings[name] !== settingValue) {
          editedSettings.sliderSettings.push({ name: name, index: this.sliderSettings[name] })
        }
      })

      _.forEach(this.appSettings.boolSettings, (setting, name) => {
        const settingValue = setting.value === null ? setting.default : setting.value
        if (this.boolSettings[name] !== settingValue) {
          editedSettings.boolSettings.push({ name: name, value: this.boolSettings[name] })
        }
      })

      _.forEach(this.finessSettings, (_, name) => {
        editedSettings.arraySettings.push({ name: name, value: this.finessSettings[name].map((elem) => elem.finess) })
      })

      this.error = null
      this.warningMessage = ''
      this.showWarning = false
      this.$store.dispatch(this.setAppSettingsAction, editedSettings).then(() => {
        this.updateLocalSettings(this.appSettings)
      })
    },
    removeAppFiness(finess, settingName) {
      this.finessSettings[settingName] = this.finessSettings[settingName].filter((elem) => elem.finess !== finess)
      this.onEdit(settingName)
    },
    addAppFiness({ finess, shortDescription, settingName }) {
      if (!this.finessSettings[settingName].find((elem) => elem.finess === finess)) {
        this.finessSettings[settingName].push({ finess, shortDescription })
        this.onEdit(settingName)
      }
    },
  }
}
</script>

<template>
  <div>
    <div class="settings-table">
      <div
        v-for="(modeSetting, mode) in displayableSettings"
        :key="mode"
      >
        <div class="settings-table__header-row row">
          <div class="col-auto settings-table row mx-3">
            <h2>{{ modeSetting.label }}</h2>

            <small
              v-if="showWarning && ([`Paramètres de l'automatisation`, `Mode automatisation`].includes(modeSetting.label))"
              class="col text-right"
            >
              <sancare-octicon
                name="alert"
                :width="18"
                :height="18"
              />
              {{ warningMessage }}
            </small>
          </div>
        </div>
        <div
          v-for="setting in modeSetting.settings"
          :key="setting.name"
          class="settings-table__data-row row"
        >
          <div class="col-7 settings-table__data-cell">
            {{ setting.label }}
            <div
              v-if="setting.type === 'finess'"
            >
              <div
                v-for="finess in finessSettings[setting.name]"
                :key="finess['finess']"
                class="badge-primary badge col-auto mx-1 mb-1 pl-2 pr-2"
              >
                <span>{{ finess['shortDescription'] }} ({{ finess['finess'] }})</span>
                <span
                  class="criteria-remove"
                  @click="removeAppFiness(finess['finess'], setting.name)"
                >
                  <sancare-octicon
                    name="x"
                    :width="14"
                    :height="11"
                  />
                </span>
              </div>
            </div>
          </div>
          <div class="col settings-table__data-cell text-right">
            <div v-if="setting.type === 'slider' && appSettings.sliderSettings[setting.name]">
              <vue-slider
                v-model="sliderSettings[setting.name]"
                :name="setting.name"
                :process="false"
                :v-data="Array.from(appSettings.sliderSettings[setting.name].values.keys())"
                :marks="(value) => getMarks(setting.name, value)"
                :tooltip="'none'"
                class="mr-3 mb-3"
                @change="onEdit(setting.name)"
              />
            </div>
            <div v-else-if="setting.type === 'bool'">
              <label class="col-auto toggle-switch">
                <input
                  v-model="boolSettings[setting.name]"
                  type="checkbox"
                  :name="setting.name"
                  @change="onEdit(setting.name)"
                >
                <div />
              </label>
            </div>
            <div v-else-if="setting.type === 'finess' && finessSettings[setting.name]">
              <app-finess-input
                :setting-name="setting.name"
                @select-finess="addAppFiness"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="settings-table__data-row row align-items-center">
        <div class="col">
          <div
            v-if="error"
            class="text-danger"
          >
            {{ error }}
          </div>
          <div
            v-if="setAppSettingsRequest.fetching"
            class="row"
          >
            <div class="loader loader-sm mt-0 mx-2" />
            <span
              v-if="showWarning"
            >
              Calcul des automatisations en cours...
            </span>
          </div>
          <div
            v-else-if="success"
            class="text-success"
          >
            Les modifications ont bien été enregistrées
          </div>
        </div>
        <div class="col-auto">
          <button
            :disabled="!hasEdition || setAppSettingsRequest.fetching"
            name="clearForm"
            class="btn btn-push btn-primary btn-padded"
            @click="clearEdition()"
          >
            Annuler les modifications
          </button>
        </div>
        <div class="col-auto text-right">
          <button
            :disabled="!hasEdition || setAppSettingsRequest.fetching"
            name="submitForm"
            class="btn btn-push btn-primary btn-padded"
            @click="saveEdition()"
          >
            Enregistrer les modifications
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
