<script>
import _ from 'lodash'
import { mapState } from 'vuex'

import { CountryEnum } from '../store/modules/settings/types'
import SingleSelectedItemList from './diagnosis-lists/automation-demo-mode/SingleSelectedItemList.vue'

export default {
  components: {
    'single-selected-item-list': SingleSelectedItemList,
  },
  props: {
    appSettings: { type: Object, required: true },
    rumId: { type: Number, required: true },
    standardModeDiagnosisGroup: { type: Object, required: true },
    automationModeDiagnosisGroup: { type: Object, required: true },
    selection: { type: Object, default: null },
    isLoading: { type: Boolean, default: false },
    healthData: { type: Object, required: true },
    savedSearchIntersectionChunks: { type: Array, default: null },
    staySavedSearches: { type: Array, default: null },
    displayedRumIdx: { type: Number, required: true },
    rumPredictedLabels: { type: Array, default: null },
  },
  emits: ['make-selection'],
  data() {
    return {
      CountryEnum,
    }
  },
  computed: {
    ...mapState({
      pmsiCountry: (state) => state.settings.pmsi.country,
    }),
  },
  methods: {
    showRelatedDiags() {
      // FIXME - Sometimes automation mode diagnosis group is not set
      return this.pmsiCountry === CountryEnum.CH || _.get(this, 'standardModeDiagnosisGroup.currentDR') || _.get(this, 'automationModeDiagnosisGroup.currentDR')
        || (this.standardModeDiagnosisGroup && this.standardModeDiagnosisGroup.currentDP && this.standardModeDiagnosisGroup.currentDP.reference[0] === 'Z')
        || (this.automationModeDiagnosisGroup && this.automationModeDiagnosisGroup.currentDP && this.automationModeDiagnosisGroup.currentDP.reference[0] === 'Z')
    },
  }
}
</script>

<template>
  <div class="card-container">
    <single-selected-item-list
      :app-settings="appSettings"
      :rum-id="rumId"
      :automation-mode-diagnosis-group="automationModeDiagnosisGroup"
      :standard-mode-diagnosis-group="standardModeDiagnosisGroup"
      :selection="selection"
      :is-loading="isLoading"
      :health-data="healthData"
      :displayed-rum-idx="displayedRumIdx"
      :rum-predicted-labels="rumPredictedLabels"
      type="DP"
      class="principal-diagnosis-group"
      @make-selection="(selection) => $emit('make-selection', selection)"
    />
    <single-selected-item-list
      v-if="showRelatedDiags()"
      :app-settings="appSettings"
      :rum-id="rumId"
      :automation-mode-diagnosis-group="automationModeDiagnosisGroup"
      :standard-mode-diagnosis-group="standardModeDiagnosisGroup"
      :selection="selection"
      :is-loading="isLoading"
      :health-data="healthData"
      :displayed-rum-idx="displayedRumIdx"
      :rum-predicted-labels="rumPredictedLabels"
      type="DR"
      class="related-diagnosis-group"
      @make-selection="(selection) => $emit('make-selection', selection)"
    />
  </div>
</template>
