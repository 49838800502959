<script>
import { SancareSticky } from '@sancare/ui-frontend-commons'
import _ from 'lodash'

import { ModeEnum } from '../store/modules/settings/types'

export default {
  components: {
    'sancare-sticky': SancareSticky,
  },
  props: {
    mode: { type: String, required: true },
    rums: { type: Array, required: true },
    displayedRumId: { type: Number, required: true },
  },
  emits: ['select-rum'],
  computed: {
    majorRum() {
      let majorRum = null

      _.forEach(this.rums, (rum) => {
        if (this.mode !== ModeEnum.AUTOMATION && rum.standardModeMajorRum) {
          majorRum = rum
        } else if (this.mode === ModeEnum.AUTOMATION && rum.automationModeMajorRum) {
          majorRum = rum
        }
      })

      return majorRum ? majorRum : { id: -1 }
    },
    rssRum() {
      return _.last(this.rums)
    }
  },
  methods: {
    selectRum(rumId) {
      this.$emit('select-rum', rumId)
    }
  },
}
</script>

<template>
  <sancare-sticky class="rum-selector-container">
    <div
      v-if="rums.length > 1"
      :class="{active: rssRum.id === displayedRumId}"
      class="rum-selector center aligned"
      @click="selectRum(rssRum.id)"
    >
      <div>RSS</div>
    </div>
    <div
      v-for="(rum, idx) in rums"
      :key="rum.id"
    >
      <div
        v-if="rum.id > 0"
        :class="{active: rum.id === displayedRumId}"
        class="rum-selector center aligned"
        @click="selectRum(rum.id)"
      >
        <div class="number">
          {{ idx + 1 }}{{ rum.id === majorRum.id ? '*' : '' }}
        </div>
        <div>RUM</div>
      </div>
    </div>
  </sancare-sticky>
</template>
