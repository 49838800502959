<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'

import { ModeEnum } from '@/store/modules/settings/types'

import { extractChunksFromSuggestedDiagnosis, extractIndicesFromChunkObject } from './diagnosis_justifications'
import justificationSummary from './JustificationSummary.vue'
import SvgSearchIcon from './SvgSearchIcon.vue'

export default {
  components: {
    'sancare-octicon': SancareOcticon,
    'justification-summary': justificationSummary,
    'svg-search-icon': SvgSearchIcon,
  },
  props: {
    suggestionGroup: { type: Object, required: true },
    rumId: { type: Number, required: true },
    healthData: { type: Object, required: true },
    displayedRumIdx: { type: Number, required: true },
    mode: { type: String, required: true },
    savedSearchIntersectionChunks: { type: Array, default: null },
    rumPredictedLabels: { type: Array, default: null },
    selection: { type: Object, default: null },
    readonly: { type: Boolean, required: true },
    standardModeDiagnosisGroup: { type: Object, required: true },
  },
  emits: ['make-selection'],
  data() {
    return {
      selectedDiagnostic: null,
      showJustificationSummary: false,
      extendedWildcards: [],
      isAddingDiag: false,
      showMoreDiags: false,
      showDiagsLimit: 2,
    }
  },
  computed: {
    hasContent() {
      return Boolean(
        this.suggestionGroup.chunks && (
          _.some(this.suggestionGroup.chunks.reports)
              || _.some(this.suggestionGroup.chunks.textualHealthEntries)
              || _.some(this.suggestionGroup.chunks.labResults)
              || _.some(this.suggestionGroup.chunks.categoricalLabResults)
              || _.some(this.suggestionGroup.chunks.drugEvents)
              || _.some(this.suggestionGroup.chunks.healthConstants)
        )
      )
    },
    noWildcardDiags() {
      return _.filter(this.suggestionGroup.diagnoses, (diagnosis) => diagnosis.wildcard === null)
    },
    displayedDiagnoses() {
      let n = this.displayedWildcards.length
      return _.filter(this.suggestionGroup.diagnoses, (diagnosis) => {
        if (diagnosis.wildcard !== null && this.isWildcardExtended(diagnosis.wildcard)) {
          return true
        }
        if (diagnosis.wildcard === null && (n < this.showDiagsLimit || this.showMoreDiags)) {
          n++
          return true
        }
        return false
      })
    },
    displayedWildcards() {
      return this.showMoreDiags ? this.suggestionGroup.wildcards : this.suggestionGroup.wildcards.slice(0, this.showDiagsLimit)
    },
    canShowMoreDiags() {
      return this.suggestionGroup.wildcards.length + this.noWildcardDiags.length > this.showDiagsLimit
    }
  },
  methods: {
    isWildcardExtended(wildcardRef) {
      return this.extendedWildcards.indexOf(wildcardRef) !== -1
    },
    toggleWildcard(wildcard) {
      if(this.extendedWildcards.indexOf(wildcard.reference) === -1){
        this.extendedWildcards.push(wildcard.reference)
      } else {
        this.extendedWildcards = _.without(this.extendedWildcards, wildcard.reference)
      }
    },
    showJustificationPopup() {
      this.showJustificationSummary = true
    },
    async addDiagnosis(diag) {
      if(this.isAddingDiag) {
        return
      }
      this.isAddingDiag = true
      this.selectedDiagnostic = diag.reference
      await this.$store.dispatch('addDiagnosis', {
        rumId: this.rumId,
        diagnosisType: 'DAS',
        reference: diag.reference,
        justifications: extractIndicesFromChunkObject(extractChunksFromSuggestedDiagnosis(diag, this.savedSearchIntersectionChunks)),
        mode: this.mode,
      })
      this.isAddingDiag = false
    },
    joinSearchesName(searches, sep, cut = false) {
      const nbChar = 28
      const content = searches.join(sep)
      if (cut) {
        let result = content.substring(0, nbChar)
        if(content.length > nbChar){
          result += '...'
        }
        return result
      }
      return content
    },
    handleHealthDataSelection(mainContent) {
      this.$store.commit('shouldScroll', true)
      this.$store.commit('setMainContent', mainContent)
      if (!this.selected) {
        this.$emit('make-selection', {
          labelType: 'DAS',
          labelReference: this.suggestionGroup.diagnoses[0].reference,
          savedSearchIds: this.suggestionGroup.searchIds,
        })
      }
      this.showJustificationSummary = !this.showJustificationSummary
    },
    hasRevaloSearch(suggestionGroup) {
      return _.some(suggestionGroup.staySavedSearches, (sss) => sss.search.type === ModeEnum.OPTIMIZABLE)
    },
    isExternalDiagnosis(diagnosis) {
      return ['external', 'unsure', 'prediction_unsure'].indexOf(diagnosis.codingOrigin) !== -1
    },
    isInitialDiagnosis(diagnosis) {
      return _.some(
        this.standardModeDiagnosisGroup.associatedDiags,
        (das) => this.isExternalDiagnosis(das) && das.associatedDiagnosis.reference === diagnosis.reference
      )
    },
    isCurrentDiagnosis(diagnosis) {
      return _.some(
        this.standardModeDiagnosisGroup.associatedDiags,
        (das) => !das.disabled && das.associatedDiagnosis.reference === diagnosis.reference
      )
    },
    isAlreadyCoded(diagnosis) {
      return this.isCurrentDiagnosis(diagnosis) || this.isInitialDiagnosis(diagnosis)
    },
    canShowRational() {
      return _.some(this.suggestionGroup.diagnoses, (diagnosis) => {
        return diagnosis.canShowRational
      })
    }
  }
}
</script>

<template>
  <div>
    <div class="row mx-0">
      <div
        v-for="wildcard in displayedWildcards"
        :key="wildcard.reference"
        v-tooltip="'Diagnostic commençant par '+wildcard.reference.replace('*', '')"
        class="col-6 mx-0 px-0"
        @click="toggleWildcard(wildcard)"
      >
        <div class="card diagnosis py-1 mx-1 mb-05">
          <div class="row mx-0">
            <div class="col-3 pt-2 px-0 pl-1">
              <sancare-octicon
                :name="isWildcardExtended(wildcard.reference) ? 'diff-removed' : 'diff-added'"
                :width="14"
                :height="14"
                class="wildcard-extender"
              />
            </div>
            <div class="col-9 px-1">
              <h2>
                {{ wildcard.reference }}
              </h2>
              <span class="diagnosis-level">Niv. {{ wildcard.level.label }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="diagnosis in displayedDiagnoses"
        :key="diagnosis.reference"
        v-tooltip="diagnosis.description + (isAlreadyCoded(diagnosis) ? ' (déjà codé)' : '')"
        class="col-6 mx-0 px-0"
        @click="isAlreadyCoded(diagnosis) || diagnosis.isExcluded ? null : addDiagnosis(diagnosis)"
      >
        <div
          v-if="isAddingDiag && selectedDiagnostic === diagnosis.reference"
          class="loader loader-sm"
        />
        <div
          v-else
          class="card diagnosis py-1 mx-1 mb-05"
          :class="{
            disabled: isAlreadyCoded(diagnosis) || diagnosis.isExcluded,
            selectable: !readonly && !isAlreadyCoded(diagnosis) && !diagnosis.isExcluded
          }"
        >
          <div class="row mx-0">
            <div class="col-9 px-1">
              <h2
                :class="{ excluded: diagnosis.excluded }"
              >
                {{ diagnosis.reference }}
              </h2>
              <span
                v-if="!diagnosis.excluded"
                class="diagnosis-level"
              >Niv. {{ diagnosis.level }}</span>
              <span
                v-else
                class="diagnosis-level"
              >
                Exclu
              </span>
            </div>
            <div class="col-3 pt-2 px-0">
              <span v-if="suggestionGroup.searchNames.length === 0">
                {{ Math.round(diagnosis.prediction.confidence*100) }}%
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 my-1 py-0 text-center">
        <span
          v-if="!showMoreDiags && canShowMoreDiags"
          class="btn-more-diagnosis"
          @click="showMoreDiags = !showMoreDiags"
        >Afficher plus
        </span>
        <span
          v-if="showMoreDiags"
          class="btn-more-diagnosis"
          @click="showMoreDiags = !showMoreDiags"
        >Afficher moins
        </span>
      </div>
    </div>
    <div
      v-tooltip="joinSearchesName(suggestionGroup.searchNames, ', ', false)"
    >
      <v-dropdown
        :shown="showJustificationSummary"
        placement="left"
        class="popover-container"
        popover-class="justification-popover"
        @show="showJustificationPopup"
      >
        <div class="row">
          <span
            class="col-auto"
          >
            <span
              v-if="suggestionGroup.searchNames.length > 0"
            >
              <svg-search-icon
                :is-revalo="hasRevaloSearch(suggestionGroup)"
              />
            </span>
            <sancare-octicon
              v-else
              name="arrow-right"
              :width="14"
              :height="14"
            />
          </span>
          <span
            class="col-auto"
          >
            {{ suggestionGroup.searchNames.length > 0 ? joinSearchesName(suggestionGroup.searchNames, ', ', true) : 'Voir justification' }}
          </span>
          <span
            v-if="canShowRational()"
            class="col-auto ml-auto"
          >
            <sancare-octicon
              name="note"
              :width="14"
              :height="14"
            />
          </span>
        </div>
        <template #popper>
          <justification-summary
            :is-loading="false"
            :chunks="canShowRational() ? suggestionGroup.chunks : null"
            :health-data="healthData"
            :no-value-label="canShowRational() ? 'Justification non disponible en raison des paramètres' : 'Pas de justification'"
            :displayed-rum-idx="displayedRumIdx"
            :rum-predicted-labels="rumPredictedLabels"
            :selection="selection"
            :saved-searches="suggestionGroup.staySavedSearches"
            :readonly="readonly"
            @select-health-data="handleHealthDataSelection"
          />
        </template>
      </v-dropdown>
    </div>
  </div>
</template>

<style lang="less" scoped>
.mb-05 {
  margin-bottom: .5rem;
}
</style>
