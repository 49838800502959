/**
 * keep in sync with StudyVoter.php
 */
import { isSavedSearchBeingCreated } from '@/saved-searches/helpers'
import { ListedSavedSearch } from '@/saved-searches/types'
import { PrivacyEnum, User } from '@/store/modules/settings/types'

import { hasPermission, RoleEnum } from './permissionsHelper'

function isOwner(savedSearch: ListedSavedSearch, user: User): boolean {
  return savedSearch.user?.id === user.id
}

export default {
  canEdit: (savedSearch: ListedSavedSearch, user: User): boolean => {
    if (PrivacyEnum.SANCARE_SEARCH === savedSearch.privacy) {
      return false
    }
    if (PrivacyEnum.PRIVATE_SEARCH === savedSearch.privacy) {
      return isOwner(savedSearch, user)
    }
    if (PrivacyEnum.TEAM_SEARCH === savedSearch.privacy) {
      return user.team !== null && savedSearch.user.team?.id === user.team.id
    }
    if (PrivacyEnum.PUBLIC_SEARCH === savedSearch.privacy) {
      return hasPermission(RoleEnum.ROLE_FACILITY_SEARCH_MANAGER, user.role)
    }
    return false
  },
  canEditPrivacy: (savedSearch: ListedSavedSearch, user: User): boolean => {
    return isOwner(savedSearch, user) || isSavedSearchBeingCreated(savedSearch)
  },
  canDisable: (savedSearch: ListedSavedSearch, user: User): boolean => {
    if (PrivacyEnum.PRIVATE_SEARCH === savedSearch.privacy) {
      return false
    }
    return hasPermission(RoleEnum.ROLE_SANCARE_SEARCH_MANAGER, user.role) || hasPermission(RoleEnum.ROLE_FACILITY_SEARCH_MANAGER, user.role)
  },
  canShowDetails: (savedSearch: ListedSavedSearch, user: User, hideSancareSearchesDetails: boolean): boolean => {
    return PrivacyEnum.SANCARE_SEARCH !== savedSearch.privacy
            || user.sancareUser
            || !hideSancareSearchesDetails
  },
}
