import { CountryEnum, ModeEnum, StayListColumns } from '@/store/modules/settings/types'

/**
 * General columns filtering
 */
export function filterAvailableColumns(
  columns: StayListColumns,
  { pmsiCountry, mode }: { pmsiCountry: CountryEnum, mode: ModeEnum }):
  StayListColumns {
  return filterStayListColumns(
    columns,
    (columnKey) =>
      columns[columnKey].country?.includes(pmsiCountry)
      && columns[columnKey].mode?.includes(mode)
  )
}

export function filterStayListColumns(
  columns: StayListColumns,
  predicate: (columnKey: string) => boolean
): StayListColumns {
  return Object.keys(columns)
    .filter((columnKey) => predicate(columnKey))
    .reduce((columnsObj, key) => {
      columnsObj[key] = columns[key]
      return columnsObj
    }, {})
}