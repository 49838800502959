<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      content: '',
    }
  },
  computed: {
    ...mapState({
      request: (state) => state.devTools.debugWebpimsImportRequest,
      response: (state) => state.devTools.debugWebpimsImportResponse,
    }),
  },
  methods: {
    send() {
      this.$store.dispatch('debugWebpimsImport', JSON.parse(this.content))
    }
  }
}
</script>

<template>
  <div class="webpims-analysis-page">
    <div class="row m-1">
      <h1>WebPims Analysis</h1>
    </div>
    <div class="row m-1">
      <div class="col-6">
        <div class="row">
          <div class="col">
            JSON :
          </div>
        </div>
        <div class="row">
          <div class="col">
            <textarea
              v-model="content"
              style="width: 100%;height:300px;resize:none"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          Response :
        </div>
        <div class="row">
          <p style="border:1px solid #C0C0C0; width: 100%; height: 300px;">
            {{ response }}
          </p>
        </div>
      </div>
    </div>
    <div class="row m-1">
      <button
        class="btn btn-link"
        @click="send"
      >
        Envoyer
      </button>
    </div>
  </div>
</template>
