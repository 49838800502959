<script>
import numeral from 'numeral'

export default {
  props: {
    count: { type: Number, default: 0 },
    maxCount: { type: Number, default: null },
    unit: { type: String, default: null },
    label: { type: String, default: null },
    barColor: { type: String, default: null },
  },
  methods: {
    formatedNumber(count) {
      return numeral(count).format(count >= 1000 ? '0.0a' : '0a').toUpperCase()
    }
  }
}
</script>

<template>
  <div class="chart-button px-1">
    <div class="chart-button-values">
      <span class="chart-button-count">{{ formatedNumber(count) }}</span>
      <span
        v-if="maxCount !== null"
        class="chart-button-count-max"
      >
        / {{ formatedNumber(maxCount) }}
      </span>
    </div>
    <span class="chart-button-label">{{ label }}</span>
    <span
      v-if="barColor"
      :style="{
        backgroundColor: barColor ? barColor : undefined,
      }"
      class="chart-button-bar"
    />
  </div>
</template>
