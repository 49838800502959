<script>
import {
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import _ from 'lodash'
import { Bar, Line } from 'vue-chartjs'

Chart.register(Title, Tooltip, Legend, LineElement, BarElement, LinearScale, PointElement, CategoryScale)

export default {
  components: {
    BarChart: Bar,
    LineChart: Line,
  },
  props: {
    type: { type: String, default: 'bar' },
    title: { type: String, default: undefined },
    labelSets: { type: Array, required: true },
    labelRotation: { type: Number, default: undefined },
    serie: { type: Array, required: true },
    serieInfos: { type: Object, required: true },
    minY: { type: Number, default: undefined },
    stepY: { type: Number, default: undefined },
    maxY:{ type: Number, default: undefined },
    labelY: { type: String, default: undefined },
  },
  data() {
    return {
      chart: null,
      hiddenDataSets: [],
      chartOptions: {
        maintainAspectRatio:false,
        responsive: true,
        plugins: {
          title: { display: false },
          legend:{ display: false },
          tooltip: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: function(context) {
                const label = context.dataset.label
                let value = context.parsed.y
                let unit = context.dataset.unit

                value = typeof(value) === 'undefined' ? 0 : value
                unit = typeof(unit) === 'undefined' ? '' : unit

                return `${label}: ${value} ${unit}`
              }
            }
          }
        },
        hover: { mode: 'nearest', intersect: true },
        scales: {
          x: {
            grid: {
              display: false
            },
            ticks: Object.assign(
              { padding: 10 },
              _.isNumber(this.labelRotation)
                && {
                  maxRotation: this.labelRotation,
                  minRotation: this.labelRotation
                }
            )
          },
          y: {
            grid: {
              display: true
            },
            ticks: {
              min: this.minY,
              max: this.maxY,
              stepSize: this.stepY,
            },
          },
        }
      }
    }
  },
  computed: {
    dataSets() {
      const colorSet = ['#E331A9', '#0E3C6A', '#318BE3', '#31E1E3', '#AC31E3', '#FD6565']
      const dataSets = []

      _.map(this.serieInfos, (info, name) => {
        dataSets.push({
          name: name,
          label: info.label,
          data: _.map(this.serie, (stat) => stat[name]),
          fill: this.type !== 'line',
          borderWidth: this.type === 'line' ? 1 : undefined,
          borderColor: info.color ?? colorSet[dataSets.length % colorSet.length],
          backgroundColor: info.color ?? colorSet[dataSets.length % colorSet.length],
          unit: info.unit,
          hidden: this.hiddenDataSets.indexOf(info.label) !== -1
        })
      })

      return dataSets
    },
    chartDatasets() {
      return {
        labels: this.labelSets,
        datasets: this.dataSets,
      }
    },
  },
  methods: {
    toggleDataSets(dataSet) {
      if (this.hiddenDataSets.indexOf(dataSet.label) === -1) {
        this.hiddenDataSets.push(dataSet.label)
      } else {
        this.hiddenDataSets = _.filter(this.hiddenDataSets, (name) => name !== dataSet.label)
      }
    },
    getLegendBoxStyle(dataset) {
      const style = []

      const color = this.hiddenDataSets.indexOf(dataset.label) !== -1 ? 'transparent' : dataset.backgroundColor

      style.push('display: inline-block')
      style.push(`background-color:${color}`)
      style.push('border: 1px solid #C0C0C0')
      style.push('width:15px')
      style.push('height: 15px')

      return style.join(';')
    },
    getLegendLabStyle(dataset) {
      const style = []

      style.push('display: inline-block')
      style.push('margin-left: 10px;')
      if (dataset.hidden) {
        style.push('text-decoration: line-through;')
      }

      return style.join(';')
    },
  },
}
</script>

<template>
  <div class="stat-graph-container">
    <div
      v-if="title"
      class="row"
    >
      <div class="col-12 stat-graph-title">
        {{ title }}
      </div>
    </div>
    <div
      v-if="labelY"
      class="row p-1 no-gutters stat-graph-label"
    >
      ({{ labelY }})
    </div>
    <div class="stat-graph-canvas">
      <BarChart
        v-if="'bar' === type"
        :chart-options="chartOptions"
        :chart-data="chartDatasets"
        :styles="{ height: '210px'}"
      />
      <LineChart
        v-else-if="'line' === type"
        :chart-options="chartOptions"
        :chart-data="chartDatasets"
        :styles="{ height: '210px'}"
      />
    </div>
    <div>
      <div
        v-for="(dataset, idx) in dataSets"
        :key="'stat_datasets_'+idx+'_'+dataset.name"
        class="row px-2 no-gutters align-items-center"
        style="cursor: pointer;"
        @click="toggleDataSets(dataset)"
      >
        <span :style="getLegendBoxStyle(dataset)" />
        <span :style="getLegendLabStyle(dataset)">{{ dataset.label }}</span>
      </div>
    </div>
  </div>
</template>
