<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { mapState } from 'vuex'

import PredictionIcon from '@/common/PredictionIcon.vue'
import { ModeEnum } from '@/store/modules/settings/types'

import { extractChunksFromSuggestedDiagnosis, extractIndicesFromChunkObject } from './diagnosis_justifications.js'
import SuggestedChronicDiagnosis from './SuggestedChronicDiagnosis.vue'
import SuggestedDiagnosis from './SuggestedDiagnosis.vue'
import SvgSearchIcon from './SvgSearchIcon.vue'

export default {
  components: {
    'suggested-diagnosis': SuggestedDiagnosis,
    'suggested-chronic-diagnosis': SuggestedChronicDiagnosis,
    'sancare-octicon': SancareOcticon,
    'svg-search-icon': SvgSearchIcon,
    'prediction-icon': PredictionIcon,
  },
  props: {
    appSettings: { type: Object, required: true },
    suggestedDiagnoses: { type: Array, required: true },
    suggestedChronicDas: { type: Object, required: true },
    rumId: { type: Number, required: true },
    standardModeDiagnosisGroup: { type: Object, required: true },
    healthData: { type: Object, required: true },
    selection: { type: Object, default: null },
    isLoading: { type: Boolean, default: false },
    mode: { type: String, required: true },
    savedSearchIntersectionChunks: { type: Array, default: null },
    staySavedSearches: { type: Array, default: null },
    displayedRumIdx: { type: Number, required: true },
    rumPredictedLabels: { type: Array, default: null },
    type: { type: String, default: null },
    readonly: { type: Boolean, required: true },
  },
  emits: ['make-selection', 'switch-display-mode', 'show-more-diags'],
  data() {
    return {
      showMore: false,
      extendedSuggestions: [],
      processingDiagnoses: []
    }
  },
  computed: {
    ...mapState({
      pmsiCountry: (state) => state.settings.pmsi.country,
    }),
    orderedSuggestedDiagnoses() {
      const orderedSuggestions = _.map(this.suggestedDiagnoses, (suggestion) => {
        suggestion.diagnoses = _.orderBy(suggestion.diagnoses, ['excluded', 'level', 'predictions.confidence'], ['asc', 'desc', 'desc'])

        return suggestion
      })

      return _.orderBy(orderedSuggestions, ['isExcluded', 'level.max', 'confidence.max'], ['asc', 'desc', 'desc'])
    },
    displayedSuggestedDiagnoses() {
      return _.filter(this.orderedSuggestedDiagnoses, (suggestion) => {
        return suggestion.isAlwaysVisible || (!suggestion.isAlwaysHidden && this.showMore)
      })
    },
  },
  methods: {
    hasVisibleSuggestion() {
      return _.some(this.suggestedDiagnoses, (suggestion) => suggestion.isAlwaysVisible || (!suggestion.isAlwaysHidden && this.showMore))
    },
    hasHiddenSuggestion() {
      return _.some(this.suggestedDiagnoses, (suggestion) => !suggestion.isAlwaysVisible && !suggestion.isAlwaysHidden)
    },
    isSelectedSuggestion(suggestion) {
      return Boolean(
        !_.isNull(this.selection)
          && this.selection.labelType === 'DAS'
          && this.selection.labelReference === suggestion.reference
          && _.isEqual(this.selection.savedSearchIds, _.map(suggestion.staySavedSearches, 'search.id').sort((a, b) => a - b))
      )
    },
    isExternalDiagnosis(diagnosis) {
      return ['external', 'unsure', 'prediction_unsure'].indexOf(diagnosis.codingOrigin) !== -1
    },
    isCurrentDiagnosis(diagnosis) {
      return _.some(
        this.standardModeDiagnosisGroup.associatedDiags,
        (das) => !das.disabled && das.associatedDiagnosis.reference === diagnosis.reference
      )
    },
    hasSuggestedDiagnosis(reference) {
      return _.some(this.suggestedDiagnoses, (suggestion) => reference.match(suggestion.reference))
    },
    hasRevaloSearch(suggestion) {
      return _.some(suggestion.searches, (search) => search.search.type === ModeEnum.OPTIMIZABLE)
    },
    getChronicJustification(chronicSuggestion) {
      return _.get(this, `suggestedChronicDas.chronicDasJustification[${chronicSuggestion.diagnosis.reference}]`, [])
    },
    async addChronicDiagnosis(suggestedDiagnosis) {
      if (this.processingDiagnoses.indexOf(suggestedDiagnosis.reference) !== -1) {
        return
      }
      this.processingDiagnoses.push(suggestedDiagnosis.reference)
      await this.$store.dispatch('addDiagnosis', {
        rumId: this.rumId,
        diagnosisType: 'DAS',
        reference: suggestedDiagnosis.reference,
        justifications: null,
        mode: this.mode
      })
      _.remove(this.processingDiagnoses, (diag) => diag === suggestedDiagnosis.reference)
    },
    async addDiagnosis(suggestedDiagnosis) {
      if (this.processingDiagnoses.indexOf(suggestedDiagnosis.reference) !== -1) {
        return
      }
      this.processingDiagnoses.push(suggestedDiagnosis.reference)
      await this.$store.dispatch('addDiagnosis', {
        rumId: this.rumId,
        diagnosisType: 'DAS',
        reference: suggestedDiagnosis.reference,
        justifications: extractIndicesFromChunkObject(extractChunksFromSuggestedDiagnosis(suggestedDiagnosis, this.savedSearchIntersectionChunks)),
        mode: this.mode
      })
      _.remove(this.processingDiagnoses, (diag) => diag === suggestedDiagnosis.reference)
    },
    getSearchesIds(diagnosis) {
      return _.map(diagnosis.staySavedSearches, (ss) => ss.search.id)
    },
    stringifySuggestionSearches(suggestion, sep, cut = false) {
      const content = _.map([...suggestion.staySavedSearches], (savedSearch) => savedSearch.search.name).join(sep)
      if (cut) {
        return content.substring(0, 20)
      }
      return content
    },
    toggleSuggestionDiagnoses(suggestion) {
      if (suggestion.isWildcard) {
        if (this.isExtendedSuggestion(suggestion)) {
          this.extendedSuggestions = _.filter(this.extendedSuggestions, (ref) => ref !== suggestion.reference)
        } else {
          this.extendedSuggestions.push(suggestion.reference)
        }
      }
    },
    isExtendedSuggestion(suggestion) {
      return this.extendedSuggestions.indexOf(suggestion.reference) !== -1
    },
    makeSelection(selection) {
      this.$emit('make-selection', selection)
    },
    switchDisplayMode() {
      this.$emit('switch-display-mode')
    },
    showMoreDiags() {
      this.showMore = !this.showMore
      this.$emit('show-more-diags')
    },
  }
}
</script>

<template>
  <div>
    <div class="card-body">
      <h1 class="card-title">
        {{ $t('diagnosis.'+type) }} <small>- Suggestion Sancare</small>
        <span
          v-tooltip="'Afficher en mode cartes'"
          class="float-right"
          @click="switchDisplayMode()"
        >
          <sancare-octicon
            name="versions"
            class="cp"
            :width="30"
            :height="30"
          />
        </span>
      </h1>
    </div>
    <div
      v-for="suggestion in displayedSuggestedDiagnoses"
      :key="suggestion.reference"
    >
      <div class="row card-body">
        <suggested-diagnosis
          :app-settings="appSettings"
          :type="type"
          :is-loading="isLoading"
          :is-wildcard="suggestion.isWildcard"
          :selected="selection !== null && selection.labelType === type && selection.labelReference === suggestion.reference"
          :selection="selection"
          :suggested-diagnosis="suggestion.diagnoses[0]"
          :health-data="healthData"
          :saved-search-intersection-chunks="savedSearchIntersectionChunks"
          :displayed-rum-idx="displayedRumIdx"
          :rum-predicted-labels="rumPredictedLabels"
          :readonly="readonly"
          class="col-12"
          @make-selection="makeSelection"
        >
          <div
            class="row no-gutters px-1 py-0 align-items-center diagnosis-item"
            :class="{ excluded: suggestion.isExcluded }"
            @click="toggleSuggestionDiagnoses(suggestion)"
          >
            <div class="col-2">
              <div
                v-if="suggestion.isWildcard"
                title="Voir les diagnostics correspondants"
                class="predicted-diagnosis-adder"
              >
                <sancare-octicon
                  :name="isExtendedSuggestion(suggestion) ? 'diff-removed' : 'diff-added'"
                  :width="18"
                  :height="18"
                />
              </div>
              <div
                v-else-if="!isCurrentDiagnosis(suggestion.diagnoses[0]) && !readonly"
                title="Ajouter le diagnostic"
                class="predicted-diagnosis-adder"
                @click.stop="addDiagnosis(suggestion.diagnoses[0])"
              >
                <div
                  v-if="processingDiagnoses.indexOf(suggestion.diagnoses[0].reference) !== -1"
                  class="loader loader-sm"
                />
                <sancare-octicon
                  v-else
                  name="arrow-left"
                  :width="20"
                  :height="20"
                />
              </div>
            </div>
            <div class="col-9 row no-gutters align-items-center">
              <div
                ref="suggestedDiagnosisTitle"
                :data-reference="suggestion.reference"
                :title="suggestion.description"
                class="col-5"
              >
                <div class="diagnosis-reference">
                  <h2>
                    {{ suggestion.reference }}
                  </h2>
                </div>
                <div class="mt-1 diagnosis-level">
                  <span v-if="!suggestion.isExcluded">Niv. {{ suggestion.level.label }}</span>
                  <span v-else>Exclu</span>
                </div>
              </div>
              <div class="col-6">
                <div
                  v-if="suggestion.searches.length"
                  v-tooltip="stringifySuggestionSearches(suggestion.diagnoses[0], ',\n')"
                  class="row no-gutters align-items-center"
                >
                  <div class="col-3">
                    <svg-search-icon
                      :is-revalo="hasRevaloSearch(suggestion)"
                    />
                  </div>
                  <div class="col-8 ml-1 diagnosis-searches">
                    {{ stringifySuggestionSearches(suggestion.diagnoses[0], ', ', true) }}
                  </div>
                </div>
                <div
                  v-if="suggestion.predictions.length"
                  class="row no-gutters align-items-center"
                >
                  <div class="col-3">
                    <prediction-icon />
                  </div>
                  <div
                    v-if="suggestion.predictions.length === 1"
                    class="col-8 ml-1 diagnosis-confidence"
                  >
                    {{ suggestion.confidence.label }}
                  </div>
                  <div
                    v-else
                    class="col-8 ml-1 diagnosis-prediction-count"
                  >
                    {{ suggestion.predictions.length }} détection{{ suggestion.predictions.length > 1 ? 's' : '' }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-1">
              <div
                v-if="suggestion.diagnoses[0] && suggestion.diagnoses[0].canShowRational && !suggestion.isWildcard && !readonly"
                :class="{ selected: isSelectedSuggestion(suggestion.diagnoses[0]) }"
                class="btn-select-diagnosis icon-block"
                @click.stop="makeSelection({ labelType: 'DAS', labelReference: suggestion.reference, savedSearchIds: getSearchesIds(suggestion.diagnoses[0]) })"
              >
                <sancare-octicon
                  name="note"
                  :width="18"
                  :height="18"
                />
              </div>
            </div>
          </div>
        </suggested-diagnosis>
      </div>
      <div v-if="suggestion.isWildcard && suggestion.diagnoses.length > 1">
        <div v-show="isExtendedSuggestion(suggestion)">
          <div
            v-for="diagnosis in suggestion.diagnoses"
            :key="`suggested_diagnosis_${type}_${diagnosis.reference}`"
            class="row card-body"
          >
            <suggested-diagnosis
              :app-settings="appSettings"
              :type="type"
              :is-loading="isLoading"
              :is-wildcard="false"
              :selected="selection !== null && selection.labelType === type && selection.labelReference === diagnosis.reference"
              :selection="selection"
              :suggested-diagnosis="diagnosis"
              :saved-search-intersection-chunks="savedSearchIntersectionChunks"
              :health-data="healthData"
              :displayed-rum-idx="displayedRumIdx"
              :rum-predicted-labels="rumPredictedLabels"
              class="col-12"
              @make-selection="makeSelection"
            >
              <div
                :class="{ excluded: suggestion.isExcluded }"
                class="row no-gutters align-items-center diagnosis-item"
              >
                <!-- @click="/toggleDiagCategory(sDiag)" -->
                <div class="col-2">
                  <div
                    v-if="!isCurrentDiagnosis(diagnosis) && !readonly"
                    title="Ajouter le diagnostic"
                    class="predicted-diagnosis-adder"
                    @click.stop="addDiagnosis(diagnosis)"
                  >
                    <div
                      v-if="processingDiagnoses.indexOf(diagnosis.reference) !== -1"
                      class="loader loader-sm"
                    />
                    <sancare-octicon
                      v-else
                      name="arrow-left"
                      :width="20"
                      :height="20"
                    />
                  </div>
                </div>
                <div class="col-9 row no-gutters align-items-center">
                  <div
                    ref="suggestedDiagnosisTitle"
                    :data-reference="diagnosis.reference"
                    :title="diagnosis.description"
                    class="col-5"
                  >
                    <h2 class="diagnosis-reference">
                      {{ diagnosis.reference }}
                    </h2>
                  </div>
                  <div class="col-6">
                    <div
                      v-if="diagnosis.staySavedSearches.length"
                      v-tooltip="stringifySuggestionSearches(diagnosis, ',\n')"
                      class="row no-gutters align-items-center"
                    >
                      <div class="col-3">
                        <svg-search-icon
                          :is-revalo="hasRevaloSearch(suggestion)"
                        />
                      </div>
                      <div class="col-8 ml-1 diagnosis-searches">
                        {{ stringifySuggestionSearches(diagnosis, ', ', true) }}
                      </div>
                    </div>
                    <div
                      v-if="diagnosis.prediction"
                      class="row no-gutters align-items-center"
                    >
                      <div class="col-3">
                        <prediction-icon />
                      </div>
                      <div
                        v-if="diagnosis.prediction"
                        class="col-8 ml-1 diagnosis-confidence"
                      >
                        {{ Math.round(diagnosis.prediction.confidence * 100) }}%
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-1">
                  <div
                    v-if="diagnosis.canShowRational"
                    :class="{ selected: isSelectedSuggestion(diagnosis) }"
                    class="btn-select-diagnosis icon-block"
                    @click.stop="makeSelection({ labelType: 'DAS', labelReference: diagnosis.reference, savedSearchIds: getSearchesIds(diagnosis) })"
                  >
                    <sancare-octicon
                      name="note"
                      :width="18"
                      :height="18"
                    />
                  </div>
                </div>
              </div>
            </suggested-diagnosis>
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="chronicSuggestion in suggestedChronicDas.chronicDasList"
      :key="chronicSuggestion.diagnosis.reference"
    >
      <div class="row card-body">
        <suggested-chronic-diagnosis
          :chronic-suggestion="chronicSuggestion"
          :chronic-justifications="getChronicJustification(chronicSuggestion)"
          class="col-12"
        >
          <div class="row no-gutters px-1 py-0 align-items-center diagnosis-item">
            <div class="col-2">
              <div
                title="Ajouter le diagnostic"
                class="predicted-diagnosis-adder"
                @click.stop="addChronicDiagnosis(chronicSuggestion.diagnosis)"
              >
                <div
                  v-if="processingDiagnoses.indexOf(chronicSuggestion.diagnosis.reference) !== -1"
                  class="loader loader-sm"
                />
                <sancare-octicon
                  v-else
                  name="arrow-left"
                  :width="20"
                  :height="20"
                />
              </div>
            </div>
            <div
              class="col-9 row no-gutters align-items-center"
            >
              <div
                ref="suggestedDiagnosisTitle"
                :data-reference="chronicSuggestion.diagnosis.reference"
                :title="chronicSuggestion.diagnosis.description"
                class="col-5"
              >
                <div class="diagnosis-reference">
                  <h2>
                    {{ chronicSuggestion.diagnosis.reference }}
                  </h2>
                </div>
                <div class="mt-1 diagnosis-level">
                  <span>Niv. {{ chronicSuggestion.diagnosis.diagnosisVersions[0].level }}</span>
                </div>
              </div>
              <div class="col-6">
                <div
                  class="row no-gutters align-items-center"
                >
                  <div
                    class="col-3"
                    title="DAS Chronique"
                  >
                    <sancare-octicon
                      name="stopwatch"
                      :width="14"
                      :height="14"
                    />
                  </div>
                  <div class="col-8 ml-1 diagnosis-confidence" />
                </div>
              </div>
            </div>
          </div>
        </suggested-chronic-diagnosis>
      </div>
    </div>
    <div
      v-if="!suggestedDiagnoses.length && !suggestedChronicDas.chronicDasList.length"
      class="card-body text"
    >
      Aucun diagnostic detecté
    </div>
    <div
      v-else-if="!hasVisibleSuggestion() && !suggestedChronicDas.chronicDasList.length"
      class="card-body text-center"
    >
      Aucun diagnostic pertinent
    </div>
    <div
      v-if="hasHiddenSuggestion()"
      class="col-12 my-1 py-0 text-center"
    >
      <span
        class="btn-more-diagnosis"
        @click="showMoreDiags"
      >
        {{ showMore ? 'Afficher moins' : 'Afficher plus' }}
      </span>
    </div>
  </div>
</template>
