<script>
import { DatePicker, SancareMultiselect } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { mapState } from 'vuex'

import { statusLabels } from '@/misc/constants'

import { AutomationModeEnum } from '../store/modules/automation/types'

export default {
  components: {
    'date-picker': DatePicker,
    'sancare-multiselect': SancareMultiselect,
  },
  props: {
    automationMode: { type: String, required: true }
  },
  emits: ['set-stay-list-param', 'refresh-staylist'],
  data() {
    return {
      AutomationModeEnum
    }
  },
  computed: {
    ...mapState({
      earlyLimit: (state) => state.settings.earlyLimit,
      lateLimit: (state) => state.settings.lateLimit,
      pageSize: (state) => state.automation.automationStayListParams.pageSize,
      showSeen: (state) => state.automation.automationStayListParams.showSeen,
      showStatus: (state) => state.automation.automationStayListParams.showStatus,
    }),
    multicheckStatus() {
      const multicheckStatus = []
      for (const [key, text] of Object.entries(statusLabels)) {
        multicheckStatus.push({ value: key, label: text })
      }

      return multicheckStatus
    }
  },
  methods: {
    setParameter(type, value) {
      this.$emit('set-stay-list-param', type, value)
    },
    setEarlylimit(limit) {
      this.$store.commit('setEarlyLimit', limit)
      this.$emit('refresh-staylist', { resetStaySelection: true, refreshUrl: true })
    },
    setLateLimit(limit) {
      this.$store.commit('setLateLimit', limit)
      this.$emit('refresh-staylist', { resetStaySelection: true, refreshUrl: true })
    },
    multicheckStatusUpdate(newMulticheckStatus) {
      if (newMulticheckStatus === null) {
        newMulticheckStatus = Object.keys(statusLabels)
      }
      if(_.isEqual(this.showStatus, newMulticheckStatus)) {
        return
      }
      this.setParameter('showStatus', newMulticheckStatus)
    }
  }
}
</script>

<template>
  <div
    name="stayListCriteria"
    class="stay-list-criteria card"
  >
    <div class="card-body">
      <div class="row px-3 py-3 align-items-end">
        <h2>Paramètres</h2>
      </div>
      <div class="row align-items-center">
        <div class="col">
          <div>
            <div
              class="row px-2 align-items-center"
              style="flex-wrap: nowrap"
            >
              <div class="col-auto px-2">
                Du
              </div>
              <div class="col-auto px-2">
                <date-picker
                  :model-value="earlyLimit"
                  @update:model-value="setEarlylimit"
                />
              </div>
              <div class="col-auto px-1">
                au
              </div>
              <div class="col-auto px-2">
                <date-picker
                  :model-value="lateLimit"
                  @update:model-value="setLateLimit"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-2">
          <sancare-multiselect
            :title="'Statuts'"
            :selected-items="showStatus"
            :items="multicheckStatus"
            :all-label="'Tous'"
            :none-label="'Aucun'"
            :display-list-limit="2"
            @update-selection="multicheckStatusUpdate"
          />
        </div>
      </div>
    </div>
  </div>
</template>
