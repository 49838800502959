import _ from 'lodash'

import Api from '@/misc/api'
import { getNewStayListOrderParams } from '@/stay-list/stay-list-table/helpers/storeHelper'
import { DEFAULTS } from '@/stay-list/storeDefaultState'
import { mutations } from '@/store/modules/automation/mutations'

import router from '../router'
import { AutomationModeEnum, AutomationTypeEnum } from '../store/modules/automation/types'
import { ModeEnum } from '../store/modules/settings/types'

const initialUrl = new URL(location.href)
const emptyGlobalCriteriaGroup = {
  criteriaList: [],
  reference: 'globalGroup',
}
function getEmptyAutomationGroup(automationType) {
  return {
    id: null,
    type: automationType,
    name: null,
    waitDuration: 7,
    presentGhmList: [],
    absentGhmList: [],
    actions: [],
    fulfillAnapathRequests: false,
    waitForAnesthesia: automationType === AutomationTypeEnum.AI,
    alertNoCro: automationType === AutomationTypeEnum.AI,
    alertNoCrh: automationType === AutomationTypeEnum.AI,
    searchOperators: 'andOr',
    globalCriteriaGroup: { ...emptyGlobalCriteriaGroup },
    criteriaGroups: [],
    AutomationTypeEnum
  }
}
const emptyCriteriaGroup = { criteriaList: [] }

const DEFAULT_STAYLIST_PAGE_SIZE = 20
const DEFAULT_STAYLIST_PAGE = 1
const DEFAULT_PAGE_COUNT = 1
const DEFAULT_SHOW_SEEN = true
const DEFAULT_SHOW_STATUS = DEFAULTS().STAYLIST_STATUS
const DEFAULT_TOTAL_COUNT = 0

export default {
  state: {
    automationGroupStats: [],
    automationGroups: [],
    automationGroupsGhmCollisions: [],
    automationGroupVersions: [],
    automationStayList: {
      items: [],
      pageCount: DEFAULT_PAGE_COUNT,
      totalCount: DEFAULT_TOTAL_COUNT,
    },
    automationStayListParams: {
      currentPage: initialUrl.searchParams.get('cp') ? parseInt(initialUrl.searchParams.get('cp')) : DEFAULT_STAYLIST_PAGE,
      pageSize: initialUrl.searchParams.get('ps') ? parseInt(initialUrl.searchParams.get('ps')) : DEFAULT_STAYLIST_PAGE_SIZE,
      showSeen: initialUrl.searchParams.get('ss') ? initialUrl.searchParams.get('ss') === 'true' : DEFAULT_SHOW_SEEN,
      showStatus: _.isEmpty(initialUrl.searchParams.getAll('sss')) || !_.isArray(initialUrl.searchParams.getAll('sss')) ? DEFAULT_SHOW_STATUS : initialUrl.searchParams.getAll('sss'),
      order: initialUrl.searchParams.get('od') ? initialUrl.searchParams.get('od') : 'id',
      reverseOrder: initialUrl.searchParams.get('ro') ? initialUrl.searchParams.get('ro') === 'true' : false,
      automationMode: initialUrl.searchParams.get('automationMode') ? initialUrl.searchParams.get('automationMode') : AutomationModeEnum.AUTOMATABLE,
      automationGroupId: initialUrl.searchParams.get('automationGroupId') ? parseInt(initialUrl.searchParams.get('automationGroupId')) : null,
    },
    ghmCollidingGroupNames: [],
    ghmUnknown: [],
    editedAutomationGroup: getEmptyAutomationGroup(AutomationTypeEnum.AI),
    internalOperator: 'OU',
    externalOperator: 'ET',

    getAutomationGroupRequest: { fetching: false, error: null, ok: false },
    getAutomationGroupVersionsRequest: { fetching: false, error: null, ok: false },
    getAllAutomationGroupsRequest: { fetching: false, error: null, ok: false },
    getAllAutomationGroupsGhmCollisionsRequest: { fetching: false, error: null, ok: false },
    getAutomationGroupsStatsRequest: { fetching: false, error: null, ok: false },
    getGhmWarningsRequest: { fetching: false, error: null, ok: false },
    removeAutomationGroupRequest: { fetching: false, error: null, ok: false },
    addAutomationGroupRequest: { fetching: false, error: null, ok: false },
    updateAutomationGroupRequest: { fetching: false, error: null, ok: false },
    automationStayListRequest: { fetching: false, error: null, ok: false },
  },
  getters: {
    shortHandAutomStayListParams(state) {
      const showStayStatus = state.automationStayListParams.showStatus
      const params = {
        od: (state.automationStayListParams.order !== DEFAULTS().STAYLIST_ORDER) ? state.automationStayListParams.order : undefined,
        ro: state.automationStayListParams.reverseOrder ? true : undefined,
        cp: (state.automationStayListParams.currentPage !== DEFAULTS().STAYLIST_PAGE) ? state.automationStayListParams.currentPage : undefined,
        ps: (state.automationStayListParams.pageSize !== DEFAULTS().STAYLIST_PAGE_SIZE) ? state.automationStayListParams.pageSize : undefined,
        ss: state.automationStayListParams.showSeen ? state.automationStayListParams.showSeen : undefined,
        sss: _.isEmpty(showStayStatus) || !_.isArray(showStayStatus) ? DEFAULT_SHOW_STATUS : showStayStatus,
        mode: ModeEnum.AUTOMATION,
        automationMode: state.automationStayListParams.automationMode,
      }
      if (state.automationStayListParams.automationGroupId) {
        params.automationGroupId = state.automationStayListParams.automationGroupId
      }

      return params
    },
  },
  mutations: {
    getAutomationGroupStart(state) {
      state.getAutomationGroupRequest = { fetching: true, error: null, ok: false }
    },
    getAutomationGroupSuccess(state, editedAutomationGroup) {
      state.getAutomationGroupRequest = { fetching: false, error: null, ok: true }
      state.editedAutomationGroup = editedAutomationGroup
      state.automationGroups[editedAutomationGroup.type] = _.map(state.automationGroups[editedAutomationGroup.type], (automGroup) => automGroup.id === editedAutomationGroup.id
        ? { ...automGroup, ...editedAutomationGroup }
        : automGroup)
      state.editedAutomationGroup.globalCriteriaGroup = state.editedAutomationGroup.globalCriteriaGroup ?? { ...emptyGlobalCriteriaGroup }
    },
    getAutomationGroupError(state, res) {
      state.getAutomationGroupRequest = { fetching: false, error: res.message, ok: false }
    },
    getAutomationGroupReset(state, automationType) {
      state.getAutomationGroupRequest = { fetching: false, error: null, ok: false }
      state.editedAutomationGroup = getEmptyAutomationGroup(automationType)
    },
    getAutomationGroupVersionsStart(state) {
      state.getAutomationGroupVersionsRequest = { fetching: true, error: null, ok: false }
    },
    getAutomationGroupVersionsSuccess(state, automationGroupVersions) {
      state.getAutomationGroupVersionsRequest = { fetching: false, error: null, ok: true }
      state.automationGroupVersions = automationGroupVersions
    },
    getAutomationGroupVersionsError(state, res) {
      state.getAutomationGroupVersionsRequest = { fetching: false, error: res.message, ok: false }
    },
    getAllAutomationGroupsStart(state) {
      state.getAllAutomationGroupsRequest = { fetching: true, error: null, ok: false }
    },
    getAllAutomationGroupsSuccess(state, groups) {
      state.getAllAutomationGroupsRequest = { fetching: false, error: null, ok: true }

      // Divide groups between AI and Rules types to display them separately
      groups = groups.reduce((acc, item) => {
        acc[item.type] = acc[item.type] ?? []
        acc[item.type].push(item)
        return acc
      }, {})

      state.automationGroups = groups
    },
    getAllAutomationGroupsError(state, res) {
      state.getAllAutomationGroupsRequest = { fetching: false, error: res.message, ok: false }
    },
    getAutomationGroupsStatsStart(state) {
      state.getAutomationGroupsStatsRequest = { fetching: true, error: null, ok: false }
    },
    getAutomationGroupsStatsSuccess(state, groups) {
      state.getAutomationGroupsStatsRequest = { fetching: false, error: null, ok: true }
      state.automationGroupStats = groups
    },
    getAutomationGroupsStatsError(state, res) {
      state.getAutomationGroupsStatsRequest = { fetching: false, error: res.message, ok: false }
    },
    removeAutomationGroupStart(state) {
      state.removeAutomationGroupRequest = { fetching: true, error: null, ok: false }
    },
    removeAutomationGroupSuccess(state, id) {
      state.removeAutomationGroupRequest = { fetching: false, error: false, ok: true }
      state.automationGroupStats = _.filter(state.automationGroupStats, (g) => g.id !== id)
      state.automationGroups[AutomationTypeEnum.AI] = _.filter(state.automationGroups[AutomationTypeEnum.AI], (g) => g.id !== id)
      state.automationGroups[AutomationTypeEnum.RULES] = _.filter(state.automationGroups[AutomationTypeEnum.RULES], (g) => g.id !== id)
    },
    removeAutomationGroupError(state, res) {
      state.removeAutomationGroupRequest = { fetching: false, error: res.message, ok: false }
    },
    addAutomationGroupStart(state) {
      state.addAutomationGroupRequest = { fetching: true, error: null, ok: false }
    },
    addAutomationGroupSuccess(state, automationGroup) {
      state.addAutomationGroupRequest = { fetching: false, error: false, ok: true }
      state.editedAutomationGroup = automationGroup
    },
    addAutomationGroupError(state, res) {
      state.addAutomationGroupRequest = { fetching: false, error: res.message, ok: false }
    },
    updateAutomationGroupStart(state) {
      state.updateAutomationGroupRequest = { fetching: true, error: null, ok: false }
    },
    updateAutomationGroupSuccess(state, automationGroup) {
      state.updateAutomationGroupRequest = { fetching: false, error: false, ok: true }
      state.automationGroup = automationGroup
    },
    updateAutomationGroupError(state, res) {
      state.updateAutomationGroupRequest = { fetching: false, error: res.message, ok: false }
    },
    getAutomationStayListStart(state) {
      state.automationStayListRequest = { fetching: true, error: null, ok: false }
    },
    getAutomationStayListSuccess(state, response) {
      state.automationStayList = {
        items: response.content,
        pageCount: response._hateoas.pageCount ? response._hateoas.pageCount : DEFAULT_PAGE_COUNT,
        totalCount: response._hateoas.filteredItemCount ? response._hateoas.filteredItemCount : DEFAULT_TOTAL_COUNT,
      }
      state.automationStayListRequest = { fetching: false, error: null, ok: true }
    },
    getAutomationStayListError(state, res) {
      state.automationStayListRequest = { fetching: false, error: res.message, ok: false }
    },
    enableAutomationGroupStart(state) {
      state.enableAutomationGroupRequest = { fetching: true, error: false, ok: false }
    },
    enableAutomationGroupSuccess(state, automationGroup) {
      state.enableAutomationGroupRequest = { fetching: false, error: false, ok: true }
      state.automationGroups[automationGroup.type] = _.map(state.automationGroups[automationGroup.type], (s) => s.id === automationGroup.id ? automationGroup : s)
    },
    enableAutomationGroupError(state, res) {
      state.enableAutomationGroupRequest = { fetching: false, error: res.message, ok: false }
    },
    disableAutomationGroupStart(state) {
      state.disableAutomationGroupRequest = { fetching: true, error: false, ok: false }
    },
    disableAutomationGroupSuccess(state, automationGroup) {
      state.disableAutomationGroupRequest = { fetching: false, error: false, ok: true }
      state.automationGroups[automationGroup.type] = _.map(state.automationGroups[automationGroup.type], (s) => s.id === automationGroup.id ? automationGroup : s)
    },
    disableAutomationGroupError(state, res) {
      state.disableAutomationGroupRequest = { fetching: false, error: res.message, ok: false }
    },
    getGhmWarningsStart(state) {
      state.getGhmWarningsRequest = { fetching: true, error: null, ok: false }
    },
    getGhmWarningsSuccess(state, ghmWarnings) {
      state.getGhmWarningsRequest = { fetching: false, error: null, ok: true }
      state.ghmCollidingGroupNames = ghmWarnings['collisions']['groupNames']
      state.ghmUnknown = ghmWarnings['unknown']['ghmList']
    },
    getGhmWarningsError(state, res) {
      state.getGhmWarningsRequest = { fetching: false, error: res.message, ok: false }
    },

    getAllAutomationGroupsGhmCollisionsStart(state) {
      state.getAllAutomationGroupsGhmCollisionsRequest = { fetching: true, error: null, ok: false }
    },
    getAllAutomationGroupsGhmCollisionsSuccess(state, automationGroupsGhmCollisions) {
      state.getAllAutomationGroupsGhmCollisionsRequest = { fetching: false, error: null, ok: true }
      state.automationGroupsGhmCollisions = automationGroupsGhmCollisions.groups
    },
    getAllAutomationGroupsGhmCollisionsError(state, res) {
      state.getAllAutomationGroupsGhmCollisionsRequest = { fetching: false, error: res.message, ok: false }
    },

    setGroupName(state, name) {
      state.editedAutomationGroup.name = name
    },
    setWaitDuration(state, waitDuration) {
      state.editedAutomationGroup.waitDuration = waitDuration
    },
    setFulfillAnapathRequests(state, fulfillAnapathRequests) {
      state.editedAutomationGroup.fulfillAnapathRequests = fulfillAnapathRequests
    },
    setWaitForAnesthesia(state, waitForAnesthesia) {
      state.editedAutomationGroup.waitForAnesthesia = waitForAnesthesia
    },
    setAlertNoCro(state, alertNoCro) {
      state.editedAutomationGroup.alertNoCro = alertNoCro
    },
    setAlertNoCrh(state, alertNoCrh) {
      state.editedAutomationGroup.alertNoCrh = alertNoCrh
    },
    populateGhmList(state, { additionMode, ghm }) {
      if (ghm === '*') {
        return
      }

      if (additionMode === true) {
        state.editedAutomationGroup.presentGhmList = _.uniq([...state.editedAutomationGroup.presentGhmList, ghm])
      } else if (additionMode === false) {
        state.editedAutomationGroup.absentGhmList = _.uniq([...state.editedAutomationGroup.absentGhmList, ghm])
      }
    },
    removeGhmFromList(state, { type, value }) {
      if (type === 'presentGhmList') {
        state.editedAutomationGroup.presentGhmList = _.filter(state.editedAutomationGroup.presentGhmList, (ghm) => ghm !== value)
        return
      }

      state.editedAutomationGroup.absentGhmList = _.filter(state.editedAutomationGroup.absentGhmList, (ghm) => ghm !== value)
    },
    addCriteriaGroup(state) {
      // TODO remove this _…uniqueId
      const newCriteriaGroup = { ..._.cloneDeep(emptyCriteriaGroup), id: _.uniqueId('frontend_') }
      state.editedAutomationGroup.criteriaGroups = [...state.editedAutomationGroup.criteriaGroups, newCriteriaGroup]
    },
    removeCriteriaGroup(state, id) {
      state.editedAutomationGroup.criteriaGroups = _.filter(state.editedAutomationGroup.criteriaGroups, (group) => group.id !== id)
    },
    updateOperators(state, operatorSystem) {
      state.editedAutomationGroup.searchOperators = operatorSystem
      if (operatorSystem === 'andOr') {
        state.internalOperator = 'OU'
        state.externalOperator = 'ET'
      } else if (operatorSystem === 'orAnd') {
        state.internalOperator = 'ET'
        state.externalOperator = 'OU'
      }
    },
    addCriteriaToList(state, { criteriaGroupId, type, value }) {
      const groupIndex = _.findIndex(state.editedAutomationGroup.criteriaGroups, (g) => g.id === criteriaGroupId)
      if (groupIndex === -1) {
        return
      }
      state.editedAutomationGroup.criteriaGroups[groupIndex].criteriaList = _.uniqBy([...state.editedAutomationGroup.criteriaGroups[groupIndex].criteriaList, { type, value }], (c) => `${c.type}__${c.value}`)
    },
    removeCriteriaFromList(state, { criteriaGroupId, criterion }) {
      const groupIndex = _.findIndex(state.editedAutomationGroup.criteriaGroups, (g) => g.id === criteriaGroupId)
      if (groupIndex === -1) {
        return
      }
      const value = criterion.value ?? criterion.content
      state.editedAutomationGroup.criteriaGroups[groupIndex].criteriaList = _.filter(state.editedAutomationGroup.criteriaGroups[groupIndex].criteriaList, (c) => c.type !== criterion.type || c.value !== value)
    },
    addGlobalCriteriaToList(state, { type, value }) {
      state.editedAutomationGroup.globalCriteriaGroup.criteriaList = _.uniqBy([...state.editedAutomationGroup.globalCriteriaGroup.criteriaList, { type, value }], (c) => `${c.type}__${c.value}`)
    },
    removeGlobalCriteriaFromList(state, { criterion }) {
      const value = criterion.value ?? criterion.content
      state.editedAutomationGroup.globalCriteriaGroup.criteriaList = _.filter(state.editedAutomationGroup.globalCriteriaGroup.criteriaList, (c) => c.type !== criterion.type || c.value !== value)
    },
    setAutomStayListParam(state, { type, value }) {
      state.automationStayListParams[type] = value
    },
    setAutomStayListOrder(state, toBeOrderedColumn) {
      state.automationStayListParams = {
        ...state.automationStayListParams,
        ...getNewStayListOrderParams(toBeOrderedColumn, state.automationStayListParams)
      }
    },
    ...mutations,
  },
  actions: {
    getAutomationGroup({ commit }, id) {
      return Api.get(
        {
          url: `/api/automation/${id}`,
          startMut: 'getAutomationGroupStart',
          successMut: 'getAutomationGroupSuccess',
          errorMut: 'getAutomationGroupError',
        },
        commit
      )
    },
    getAllAutomationGroups({ commit }) {
      return Api.get(
        {
          url: '/api/automation/automation-groups',
          startMut: 'getAllAutomationGroupsStart',
          successMut: 'getAllAutomationGroupsSuccess',
          errorMut: 'getAllAutomationGroupsError',
        },
        commit
      )
    },
    getAutomationGroupsStats({ commit }, { earlyLimit, lateLimit }) {
      return Api.get(
        {
          url: `/api/automation/groups-stats?earlyLimit=${encodeURIComponent(earlyLimit.format())}&lateLimit=${encodeURIComponent(lateLimit.format())}`,
          startMut: 'getAutomationGroupsStatsStart',
          successMut: 'getAutomationGroupsStatsSuccess',
          errorMut: 'getAutomationGroupsStatsError',
        },
        commit
      )
    },
    getAllAutomationGroupsGhmCollisions({ commit }) {
      return Api.get(
        {
          url: '/api/automation/groups-ghm-collisions',
          startMut: 'getAllAutomationGroupsGhmCollisionsStart',
          successMut: 'getAllAutomationGroupsGhmCollisionsSuccess',
          errorMut: 'getAllAutomationGroupsGhmCollisionsError',
        },
        commit
      )
    },

    removeAutomationGroup({ commit }, id) {
      return Api.del(
        {
          url: `/api/automation/${id}`,
          body: '',
          startMut: 'removeAutomationGroupStart',
          successMut: () => commit('removeAutomationGroupSuccess', id),
          errorMut: 'removeAutomationGroupError',
        },
        commit
      )
    },
    addAutomationGroup({ commit }, automationGroup) {
      return Api.post(
        {
          url: '/api/automation/add-group',
          body: automationGroup,
          startMut: 'addAutomationGroupStart',
          successMut: 'addAutomationGroupSuccess',
          errorMut: 'addAutomationGroupError',
        },
        commit
      )
    },
    updateAutomationGroup({ commit }, { id, automationGroup }) {
      return Api.put(
        {
          url: `/api/automation/${id}`,
          body: automationGroup,
          startMut: 'updateAutomationGroupStart',
          successMut: 'updateAutomationGroupSuccess',
          errorMut: 'updateAutomationGroupError',
        },
        commit
      )
    },
    enableAutomationGroup({ commit }, id) {
      return Api.put(
        {
          url: `/api/automation/${id}/enable-automation`,
          startMut: 'enableAutomationGroupStart',
          successMut: 'enableAutomationGroupSuccess',
          errorMut: 'enableAutomationGroupError',
        },
        commit
      )
    },
    disableAutomationGroup({ commit }, id) {
      return Api.put(
        {
          url: `/api/automation/${id}/disable-automation`,
          startMut: 'disableAutomationGroupStart',
          successMut: 'disableAutomationGroupSuccess',
          errorMut: 'disableAutomationGroupError',
        },
        commit
      )
    },
    updateAutomStayListOrder({ commit, dispatch }, toBeOrderedColumn) {
      commit('setAutomStayListOrder', toBeOrderedColumn)
      dispatch('getAutomationStayList', { resetStaySelection: false })
      dispatch('refreshAutomStayListUrl')
    },
    getAutomationStayList({ commit, state, rootState }) {
      const params = {
        ...state.automationStayListParams,
        earlyLimit: rootState.settings.earlyLimit.format('DD-MM-YYYY'),
        lateLimit: rootState.settings.lateLimit.format('DD-MM-YYYY'),
      }
      params.currentPage--
      return Api.post(
        {
          url: '/api/automation-stays/filtered-automation-search/',
          body: {
            mode: state.automationStayListParams.automationMode,
            automationGroupId: state.automationStayListParams.automationGroupId,
            params
          },
          startMut: 'getAutomationStayListStart',
          successMut: 'getAutomationStayListSuccess',
          errorMut: 'getAutomationStayListError',
        },
        commit
      )
    },

    getAutomationGroupVersions({ commit }, id) {
      return Api.get(
        {
          url: `/api/automation/${id}/versions`,
          startMut: 'getAutomationGroupVersionsStart',
          successMut: 'getAutomationGroupVersionsSuccess',
          errorMut: 'getAutomationGroupVersionsError',
        },
        commit
      )
    },

    getGhmWarnings({ commit }, { presentGhmList, absentGhmList, groupId }) {
      return Api.post(
        {
          url: '/api/automation/ghm-warnings',
          body: {
            presentGhmList,
            absentGhmList,
            groupId,
          },
          startMut: 'getGhmWarningsStart',
          successMut: 'getGhmWarningsSuccess',
          errorMut: 'getGhmWarningsError',
        },
        commit
      )
    },
    refreshAutomStayListUrl({ rootState, getters }) {
      const params = {
        ...getters.shortHandAutomStayListParams,
        el: rootState.settings.earlyLimit.format('DD-MM-YYYY'),
        ll: rootState.settings.lateLimit.format('DD-MM-YYYY'),
      }
      router.replace(
        {
          path: rootState.route.path,
          query: { ...params }
        })
    }
  },
}
