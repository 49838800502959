<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'

import CriteriaDisplayer from '../filters/CriteriaDisplayer.vue'
import FilterBuilder from '../filters/FilterBuilder'
import { AutomationTypeEnum } from '../store/modules/automation/types'
import { ModeEnum, PmsiTypeEnum } from '../store/modules/settings/types'

export default {
  components: {
    'filter-builder': FilterBuilder,
    'sancare-octicon': SancareOcticon,
    'criteria-displayer': CriteriaDisplayer,
  },
  props: {
    internalOperator: { type: String, required: true },
    group: { type: Object, required: true },
    groupIsGlobal: { type: Boolean, required: false },
    automationType: { type: String, default: AutomationTypeEnum.AI },
  },
  emits: ['clear-message', 'delete-criteria-group'],
  data() {
    return {
      ModeEnum,
      AutomationTypeEnum,
      PmsiTypeEnum
    }
  },
  methods: {
    addCriteria({ type, value }) {
      if(['presentMedicalAct', 'absentMedicalAct'].indexOf(type) > -1){
        // Acts are always in uppercase: AAFA001, AGMP* etc...
        value = value.toUpperCase()
      }
      this.$emit('clear-message')

      if (this.groupIsGlobal) {
        this.$store.commit('addGlobalCriteriaToList', { type, value })
      } else {
        this.$store.commit('addCriteriaToList', {
          criteriaGroupId: this.group.id,
          type,
          value
        })
      }
    },
    removeCriteria({ criterion }) {
      this.$emit('clear-message')

      if (this.groupIsGlobal) {
        this.$store.commit('removeGlobalCriteriaFromList', { criterion })
      } else {
        this.$store.commit('removeCriteriaFromList', {
          criteriaGroupId: this.group.id,
          criterion,
        })
      }
    },
    deleteCriteriaGroup() {
      if (!this.groupIsGlobal) {
        this.$emit('clear-message')
        this.$emit('delete-criteria-group', this.group.id)
      }
    },
  }
}
</script>

<template>
  <div>
    <div
      :class="{
        'card': true,
        ...(groupIsGlobal ? {'card-secondary':true} : {'card-primary':true})
      }"
    >
      <div class="card-header">
        <div class="row">
          <div class="col">
            Liste de critères{{ groupIsGlobal ? ' globaux' : '' }} :
          </div>
          <div
            v-if="!groupIsGlobal"
            class="col-auto delete-criteria-group"
            @click="() => deleteCriteriaGroup(group.id)"
          >
            <sancare-octicon
              name="x"
              :width="17"
              :height="20"
            />
          </div>
        </div>
      </div>
      <div
        v-if="(group.criteriaList && group.criteriaList.length > 0)"
        class="card-body"
      >
        <criteria-displayer
          :can-remove-criteria="true"
          :internal-operator="internalOperator"
          :criteria-group="group"
          :display-grouped-criteria="groupIsGlobal"
          :is-global="groupIsGlobal"
          :mode="ModeEnum.AUTOMATION"
          @remove-criteria="removeCriteria"
        />
      </div>
      <div class="card-footer">
        <filter-builder
          :mode="ModeEnum.AUTOMATION"
          :automation-type="automationType"
          :pmsi-type="PmsiTypeEnum.MCO"
          :is-global="groupIsGlobal"
          @add-criteria="addCriteria"
        />
      </div>
    </div>
  </div>
</template>
