import _ from 'lodash'

import { FilterValueTypeEnum } from '@/store/modules/filters/types'

import FiltersData from './FiltersData'

const filtersValueTypes = _.merge(
  _.mapValues(FiltersData, 'valueType'),
  {
    fromStayDate: FilterValueTypeEnum.SINGLE_VALUE,
    toStayDate: FilterValueTypeEnum.SINGLE_VALUE,
    startingDmsGap: FilterValueTypeEnum.SINGLE_VALUE,
  }
)

export function getFilterType(filterName: string): FilterValueTypeEnum {
  return filtersValueTypes[filterName] ? filtersValueTypes[filterName] : FilterValueTypeEnum.MULTI_VALUE
}
