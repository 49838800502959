<script>
import Graphic from './SancareGraphic.vue'

export default {
  components: {
    'sancare-graphic': Graphic,
  },
  props: {
    labelSets: { type: Array, required: true },
    stats: { type: Array, required: true },
    statsPercent: { type: Array, required: true },
    prctInfos: { type: Object, required: true },
    statsInfos: { type: Object, required: true },
  },
}
</script>

<template>
  <div class="volume-graphics">
    <div class="stat-content row justify-content-around">
      <div class="col-5 row align-items-stretch no-gutters">
        <h2 class="stat-graph-title">
          Proportion
        </h2>
        <sancare-graphic
          :label-sets="labelSets"
          :serie="statsPercent"
          :serie-infos="prctInfos"
          :min-y="0.0"
          :max-y="100.0"
          :label-y="'%'"
          type="line"
          class="col-12"
        />
      </div>
      <div class="col-5 row align-items-stretch no-gutters">
        <h2 class="stat-graph-title">
          Volume
        </h2>
        <sancare-graphic
          :label-sets="labelSets"
          :serie="stats"
          :serie-infos="statsInfos"
          :min-y="0"
          :step-y="10"
          :label-y="'Séjours'"
          type="bar"
          class="col-12"
        />
      </div>
    </div>
  </div>
</template>
