<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { mapState } from 'vuex'

import LoadingMark from '@/misc/LoadingMark.vue'

export default {
  components: {
    'loading-mark': LoadingMark,
    'sancare-octicon': SancareOcticon,
  },
  props: {
    type: { type: String, required: true },
    readOnly: { type: Boolean, default: false },
    canAddUncommonDiagnoses: { type: Boolean, default: true }
  },
  emits: ['add-diagnosis'],
  data() {
    return {
      inputStatus: {
        showInput: false,
        typedDiagnosis: '',
        errorMessage: '',
      },
      displaySearch: false,
    }
  },
  computed: mapState({
    addDiagnosisRequest(state){
      return state.stayDisplayer.addDiagnosisRequest[this.type]
    },
    diagnosisSearchResults(state) {
      return state.stayDisplayer.diagnosisSearchResults[this.type]
    },
  }),
  watch: {
    addDiagnosisRequest: function(req) {
      if (req.ok) {
        this.toggleInput(false)
        this.$store.commit('clearSearchDiagnoses', this.type)
      }
    }
  },
  methods: {
    toggleInput(openedState) {
      this.inputStatus = {
        showInput: openedState !== undefined ? openedState : !this.inputStatus.showInput,
        typedDiagnosis: '',
        errorMessage: '',
      }
      if (!this.inputStatus.showInput) {
        this.$store.commit('resetAddDiagnosis', this.type)
        this.displaySearch = false
        this.$store.commit('clearSearchDiagnoses', this.type)
      }
    },
    manualDiagInputFocus() {
      if (this.addDiagnosisRequest.ok || this.addDiagnosisRequest.error) {
        this.$store.dispatch('resetAddDiagnosis', this.type)
      }
      this.displaySearch = true
    },
    manualDiagInputTyped(event) {
      if (event.key === 'Enter') {
        this.addManualDiag()
        this.$refs.manualDiagInput.blur()
      }
      if (this.addDiagnosisRequest.ok || this.addDiagnosisRequest.error) {
        this.$store.dispatch('resetAddDiagnosis', this.type)
      }
      if (event.key === 'Escape') {
        this.displaySearch = false
      } else {
        this.displaySearch = true
      }

      this.triggerSearch()
    },
    triggerSearch: _.throttle(function() {
      if (this.inputStatus.typedDiagnosis) {
        this.$store.dispatch('searchDiagnoses', { diagnosisType: this.type, search: this.inputStatus.typedDiagnosis })
      } else {
        this.$store.commit('clearSearchDiagnoses', this.type)
      }
    }, 500, { leading: false, maxWait: 500, trailing: true }),
    handleSearchClick(ref) {
      this.inputStatus.typedDiagnosis = ref
      this.addManualDiag()
    },
    addManualDiag() {
      if (!this.canAddUncommonDiagnoses && _.findIndex(this.diagnosisSearchResults, ['reference', this.inputStatus.typedDiagnosis.toUpperCase()]) === -1) {
        this.inputStatus.errorMessage = 'Ce diagnostic est inconnu'
        return
      }

      this.$emit('add-diagnosis', this.inputStatus.typedDiagnosis.toUpperCase())
      this.displaySearch = false
      this.typedDiagnosis = ''
    },
    hideDisplaySearch() {
      this.displaySearch = false
    }
  },
}
</script>

<template>
  <div
    v-on-click-outside="hideDisplaySearch"
    class="col mb-2"
  >
    <div class="row">
      <div class="col-6 pr-1">
        <div
          v-if="!readOnly"
          :class="{ disabled: addDiagnosisRequest.fetching }"
          class="row px-2 no-gutters align-items-center btn btn-push btn-secondary btn-diagnosis"
          @click="toggleInput()"
        >
          <div
            v-if="inputStatus.showInput"
            class="col-auto icon-block"
          >
            <sancare-octicon
              name="x"
              :width="15"
              :height="15"
            />
          </div>
          <div
            v-else
            class="col-auto icon-block"
          >
            <sancare-octicon
              :name="type === 'DAS' ? 'plus' : 'pencil'"
              :width="15"
              :height="15"
            />
          </div>
          <div class="col">
            {{ $t('diagnosis.'+type) }} manuel
          </div>
        </div>
      </div>
    </div>
    <transition name="slidein-fadein">
      <div
        v-if="inputStatus.showInput"
        class="popup-search"
      >
        <div class="row no-gutters align-items-center">
          <input
            ref="manualDiagInput"
            v-model="inputStatus.typedDiagnosis"
            class="col form-control popup-search-input"
            @keyup="manualDiagInputTyped"
            @focus="manualDiagInputFocus"
          >
          <transition
            name="manual-diagnosis-loader"
            mode="out-in"
          >
            <div
              v-if="!addDiagnosisRequest.fetching"
              class="arrow col-auto text-right icon-block"
              @click="addManualDiag"
            >
              <sancare-octicon
                name="arrow-right"
                :width="20"
                :height="20"
              />
            </div>
            <loading-mark
              v-else
              :finished="!addDiagnosisRequest.fetching"
              class="spinner"
            />
          </transition>
        </div>
        <div
          v-if="inputStatus.errorMessage"
          class="text-danger my-2"
        >
          <sancare-octicon
            class="icon-danger"
            name="alert"
            :width="20"
            :height="20"
          />
          {{ inputStatus.errorMessage }}
        </div>
      </div>
    </transition>
    <div
      v-if="displaySearch && diagnosisSearchResults.length"
      class="autocompletion"
    >
      <div
        v-for="diag in diagnosisSearchResults"
        :key="diag.reference"
        class="autocompletion__item"
        @click="handleSearchClick(diag.reference)"
      >
        <div class="autocompletion__item-title">
          {{ diag.reference }}
        </div>
        <div class="autocompletion__item-subtitle">
          {{ diag.description }}
        </div>
      </div>
    </div>
  </div>
</template>
