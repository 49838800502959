import { AbstractState } from '@sancare/ui-frontend-commons/src/store/types'
import { Dayjs } from 'dayjs'

export interface BaseAct {
  reference: string
  description?: string
  isUncommon: boolean
}
export type MedicalAct = BaseAct & {
  atihExtension: string|null
  activity: number
}
export type ActChop = BaseAct

export interface BaseCodedAct<TDate, TAct extends BaseAct> {
  id: number
  associatedAct: TAct
  disabled: boolean
  executionDate: TDate|null
  practitioner: string|null
}
export type RawCodedAct = BaseCodedAct<string, BaseAct>
export type CodedAct = BaseCodedAct<Dayjs, BaseAct>

export interface BaseCodedMedicalAct<TDate> extends BaseCodedAct<TDate, MedicalAct> {
  extension: string|null
  realisationCount: number|null
}
export type RawCodedMedicalAct = BaseCodedMedicalAct<string>
export type CodedMedicalAct = BaseCodedMedicalAct<Dayjs>

export interface BaseCodedActChop<TDate> extends BaseCodedAct<TDate, ActChop> {
  laterality: string|null
}
// TODO: Not used for now
// export type RawCodedActChop = BaseCodedActChop<string>
export type CodedActChop = BaseCodedActChop<Dayjs>

export const lateralityOptions = [
  { value: '', label: 'NA' },
  { value: '0', label: 'Bilatéral' },
  { value: '1', label: 'Unilatéral droit' },
  { value: '2', label: 'Unilatéral gauche' },
  { value: '3', label: 'Unilatéral inconnu' },
  { value: '9', label: 'Inconnue' },
]

type Justification = {
  docType: string
  docId: string
  indices: number[][]
}

export type ToBeAddedAct = {
  reference: string
  executionDate?: string
  activity?: number
  laterality?: string
  justifications: Justification[]|null
}

export class MedicalActState extends AbstractState {

}
