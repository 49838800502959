<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { mapGetters } from 'vuex'

import { PmsiTypeEnum } from '@/store/modules/settings/types'

import AddSavedSearchesGroupModal from '../saved-searches/AddSavedSearchesGroupModal.vue'
import SavedSearchesGroupThumbnail from '../saved-searches/SavedSearchesGroupThumbnail.vue'

export default {
  name: 'OwnSearchGroupList',
  components: {
    'sancare-octicon': SancareOcticon,
    'saved-searches-group-thumbnail': SavedSearchesGroupThumbnail,
    'add-saved-searches-group-modal': AddSavedSearchesGroupModal,
  },
  props: {
    searchGroups: { type: Array, required: true },
    mode: { type: String, required: true },
    selectedSearches: { type: Array, required: true },
    title: { type: String, required: true },
    quickFilterResults: { type: Array, default: null },
    pmsiType: {
      validator(value) {
        return Object.values(PmsiTypeEnum).includes(value)
      },
      default: PmsiTypeEnum.MCO
    },
  },
  emits: ['refresh-stats'],
  data() {
    return {
      expand: false,
    }
  },
  computed: {
    ...mapGetters(['settingsUrlQuery']),
    displayedSearches() {
      return _.map(
        _.filter(this.searchGroups, (group) => {
          if (!_.isNull(this.quickFilterResults)) {
            return this.quickFilterResults.indexOf(group.id) !== -1
          }

          return group.countTodoStay || this.expand
        }),
        (group) => ({
          ...group,
          stayListQuery: {
            mode: this.mode,
            revType: 'undetermined',
            searchId: _.map(group.savedSearches, (search) => search.id),
            ...this.settingsUrlQuery
          },
        })
      )
    },
    hasHideableSearches() {
      return _.some(this.searchGroups, (s) => s.countTodoStay === 0) && _.isNull(this.quickFilterResults)
    },
    newSearchQuery() {
      return {
        mode: this.mode,
        revType: 'undetermined',
        sc: 1,
        ...this.settingsUrlQuery
      }
    },
    selectedSearchIds() {
      return this.selectedSearches.map((s) => s.id)
    }
  },
  methods: {
    refreshStats(searchIds) {
      this.$emit('refresh-stats', searchIds)
    }
  }
}
</script>

<template>
  <div class="own-search-list">
    <div class="home-list-part">
      <div class="row no-gutters">
        <h2 class="col home-list-part-title p-2">
          {{ title }}
        </h2>
        <div
          class="col-auto"
        >
          <add-saved-searches-group-modal
            :search-ids="selectedSearchIds"
          >
            <template #button>
              <span
                class="btn sancare-btn"
                :class="selectedSearches.length ? '' : 'disabled'"
              >
                + Créer un groupe de recherches
              </span>
            </template>
          </add-saved-searches-group-modal>
        </div>
        <div class="col-auto">
          <span
            v-if="hasHideableSearches"
            :class="{
              'home-list-chevron--extend': !expand,
              'home-list-chevron--reduce': expand
            }"
            class="home-list-chevron"
            @click="expand = !expand"
          >
            <sancare-octicon
              :name="expand ? 'chevron-up' : 'chevron-down'"
              :width="20"
              :height="20"
            />
          </span>
        </div>
      </div>
      <div class="row no-gutters">
        <div
          v-for="search in displayedSearches"
          :key="search.id"
          class="col-3 p-2"
        >
          <saved-searches-group-thumbnail
            :searches-group="search"
            :pmsi-type="pmsiType"
            @refresh-stats="refreshStats"
          />
        </div>
      </div>
    </div>
  </div>
</template>
