import _ from 'lodash'

function isMedicalUnitPresentInRums(rums, medicalUnitReference) {
  // Cannot split logic due to "no-case-declarations"
  return _.includes(
    _.map(
      _.map(rums, 'medicalUnit'),
      'reference'
    ),
    medicalUnitReference
  )
}

const getAutomationCriteriaExplanation = (stay, criteria) => {
  const explanation = {
    id: criteria.id,
    match: null
  }

  switch (criteria.type) {
    case 'presentMedicalUnit':
      explanation.match = isMedicalUnitPresentInRums(stay.rums, criteria.value)
      break
    case 'absentMedicalUnit':
      explanation.match = !isMedicalUnitPresentInRums(stay.rums, criteria.value)
      break
    case 'presentDocumentCategory':
      explanation.match = _.includes(
        _.concat(
          _.map(stay.reports, 'documentTemplate'),
          _.map(stay.textualHealthEntries, 'type')
        ),
        criteria.value
      )
      break
    case 'absentDocumentCategory':
      explanation.match = !_.includes(
        _.concat(
          _.map(stay.reports, 'documentTemplate'),
          _.map(stay.textualHealthEntries, 'type')
        ),
        criteria.value
      )
      break
    case 'gender':
      explanation.match = stay.patient.gender === criteria.value
      break
    case 'patientAge':
      explanation.match = stay.patientAge >= criteria.value.split('_')[0] && stay.patientAge <= criteria.value.split('_')[1]
      break
    case 'stayDuration':
      explanation.match = stay.stayDuration >= criteria.value.split('_')[0] && stay.stayDuration <= criteria.value.split('_')[1]
      break
    case 'admissionMode':
      explanation.match = _.includes(
        _.map(stay.rums, 'admissionMode'),
        criteria.value
      )
      break
    case 'releaseMode':
      explanation.match = _.includes(
        _.map(stay.rums, 'releaseMode'),
        criteria.value
      )
      break
    case 'origin':
      explanation.match = _.includes(
        _.map(stay.rums, 'origin'),
        criteria.value
      )
      break
    case 'destination':
      explanation.match = _.includes(
        _.map(stay.rums, 'destination'),
        criteria.value
      )
      break
    case 'presentMedicalAct':
      explanation.match = _.includes(
        _.forEach(
          _.map(stay.rums, 'codedMedicalActs'), (codedActs) => {
            _.forEach(codedActs, (act) => {
              _.map(act.associatedAct, 'reference')
            })
          }
        ),
        criteria.value
      )
      break
    case 'absentMedicalAct':
      explanation.match = !_.includes(
        _.forEach(
          _.map(stay.rums, 'codedMedicalActs'), (codedActs) => {
            _.forEach(codedActs, (act) => {
              _.map(act.associatedAct, 'reference')
            })
          }
        ),
        criteria.value
      )
      break
    case 'medicalActActivity':
      explanation.match = _.includes(
        _.map(
          _.map(
            _.flatten(_.map(stay.rums, 'codedMedicalActs')),
            'associatedAct'),
          'activity'
        ),
        _.toNumber(criteria.value)
      )
      break
    default:
      explanation.match = false
      break
  }

  return explanation
}

const getAutomationCriteriaGroupExplanation = (stay, criteriaGroup, searchOperators) => {
  const criteriaList = criteriaGroup.criteriaList
  const explanation = {
    id: criteriaGroup.id,
    match: null,
    details: {}
  }
  _.forOwn(criteriaList, (criteria) => {
    const detail = getAutomationCriteriaExplanation(stay, criteria)
    explanation.details[criteria.id] = detail

    if (searchOperators === 'orAnd') {
      // To know if there are a match === false in the loop
      explanation.match = (explanation.match === null || explanation.match) && detail.match
      return
    }
    // andOr
    explanation.match = explanation.match || detail.match
  })

  return explanation
}

const getAutomationExplanation = (stay, automationGroup) => {
  if (_.isEmpty(stay) || _.isEmpty(automationGroup)) {
    return {}
  }

  const criteriaGroups = automationGroup.criteriaGroups
  const searchOperators = automationGroup.searchOperators
  const explanation = {
    match: null,
    details: {}
  }

  // Cannot use Object.entries thanks to iE11...sniff...
  _.forOwn(criteriaGroups, (criteriaGroup) => {
    const detail = getAutomationCriteriaGroupExplanation(stay, criteriaGroup, searchOperators)
    explanation.details[criteriaGroup.id] = detail

    if (searchOperators === 'andOr') {
      // To know if there are a match === false in the loop
      explanation.match = (explanation.match === null || explanation.match) && detail.match
      return
    }
    // orAnd
    explanation.match = explanation.match || detail.match
  })

  return explanation
}

export {
  getAutomationExplanation
}
