<script>
// deprecated: use .loader instead
export default {
  props: {
    finished: { type: Boolean, default: false },
    color: { type: String, default: '' },
  },
  computed: {
    circleClose() {
      return this.finished ? 'closed' : ''
    },
    circleSpin() {
      return this.finished ? '' : 'spin'
    },
    showMark() {
      return this.finished
    },
    strokeColor() {
      switch (this.color) {
        case 'blue':
          return 'blue'
        case 'grey':
          return 'grey'
        default:
          return ''
      }
    },
  },
  watch: {
    finished(finished) {
      if (finished) {
        setTimeout(() => {
          this.circleSpin = false
        }, 200)
      } else {
        this.circleSpin = true
      }
    }
  },
}
</script>

<template>
  <svg
    class="loading-mark"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <circle
      :class="[ circleClose, circleSpin, strokeColor ]"
      class="loading-mark-circle"
      cx="16"
      cy="16"
      r="13"
    />
    <path
      v-if="showMark"
      :class="[strokeColor]"
      class="loading-mark-symbol"
      d="M9.3 16.752l5.722 4.228 7.22-10.69"
    />
  </svg>
</template>
