<script>
import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'

export default {
  props: {
    title: { type: String, required: true },
    chronicJustifications: { type: Array, required: true },
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format('L')
    }
  }
}
</script>

<template>
  <div class="justification-popover">
    <div
      v-if="chronicJustifications.length"
      class="justification-part"
    >
      <h3
        class="btn btn-sm btn-report-select"
      >
        {{ title }}
      </h3>
      <div class="justification-part-line mb-2 font-weight-bold">
        <div class="row mx-1 popover-report-content align-items-center">
          <span class="col-3">Date de fin</span>
          <div>
            <span class="col-4">Rss id</span>
            <span class="col-2">Durée du séjour</span>
            <span class="col-3" />
          </div>
        </div>
      </div>
      <div
        v-for="chronicJustification in chronicJustifications"
        :key="chronicJustification.id"
        class="justification-part-line"
      >
        <div class="row mx-1 popover-report-content align-items-center">
          <span class="col-3">
            {{ chronicJustification.stayEnd ? formatDate(chronicJustification.stayEnd) : '-' }}
          </span>
          <div class="hinted-summary-line">
            <router-link :to="`/id/${chronicJustification.id}?chronicRef=1`">
              <span class="col-4">
                {{ chronicJustification.rssId ? chronicJustification.rssId : chronicJustification.id }}
              </span>
              <span class="col-2">
                {{ typeof chronicJustification.stayDuration !== 'undefined' ? chronicJustification.stayDuration + 'j' : '-' }}
              </span>
              <span class="col-3" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="loader loader-center mb-4" />
      <p class="">
        Chargement des justifications...
      </p>
    </div>
  </div>
</template>
