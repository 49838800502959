<script>
import { SancareModal, SancareOcticon } from '@sancare/ui-frontend-commons'
import { mapGetters, mapState } from 'vuex'

import { PmsiTypeEnum } from '@/store/modules/settings/types'

import SavedSearchDetails from './SavedSearchDetails.vue'
import SavedSearchThumbnail from './SavedSearchThumbnail.vue'

export default {
  components: {
    'sancare-modal': SancareModal,
    'saved-search-thumbnail': SavedSearchThumbnail,
    'saved-search-details': SavedSearchDetails,
    'sancare-octicon': SancareOcticon,
  },
  props: {
    searchIds: { type: Array, required: true },
  },
  emits: ['group-created'],
  data() {
    return {
      showModal: false,
      name: '',
      error: null,
    }
  },
  computed: {
    ...mapGetters(['settingsUrlQuery', 'flatSancareSettings']),
    ...mapState({
      addSavedSearchesGroupRequest: (state) => state.savedSearches.addSavedSearchesGroupRequest,
      earlyLimit: (state) => state.settings.earlyLimit,
      lateLimit: (state) => state.settings.lateLimit,
      allSavedSearches: (state) => state.savedSearches.savedSearches,
      isSancareUser() {
        return this.$store.state.settings.currentUser.sancareUser
      },
    }),
    searches() {
      return this.allSavedSearches.filter((s) => this.searchIds.indexOf(s.id) > -1)
    },
  },
  watch: {
    addSavedSearchesGroupRequest(req) {
      if (req.ok) {
        this.closeModal()
        this.$emit('group-created')
      } else if (req.error) {
        this.error = req.error
      }
    }
  },
  methods: {
    async openModal() {
      if (this.searches.length) {
        // load search conditions if not already done and allowed
        const searchIds = this.searches.filter((s) => !s.criteriaGroups && (s.privacy !== 'sancareSearch' || this.isSancareUser || this.flatSancareSettings['hide_sancare_searches_details'] === false)).map((s) => s.id)
        if (searchIds.length > 0) {
          await this.$store.dispatch('fetchSavedSearches', { earlyLimit: this.earlyLimit, lateLimit: this.lateLimit, pmsiType: PmsiTypeEnum.MCO, searchIds })
        }
        this.name = ''
        this.showModal = true
      }
    },
    saveGroup() {
      this.$store.dispatch('addSavedSearchesGroup', {
        name: this.name,
        savedSearches: this.searchIds,
        ...this.settingsUrlQuery
      })
    },
    closeModal() {
      this.showModal = false
      this.name = ''
    },
  },
}
</script>

<template>
  <span class="text-left">
    <div @click="openModal">
      <slot name="button" />
    </div>
    <sancare-modal
      :open="showModal"
      :no-padding="true"
      @close-modal="closeModal"
    >
      <template #header>
        <div>
          <span class="row">
            <span class="sancare-modal-icon col-1">
              <sancare-octicon
                name="search"
                :width="20"
                :height="20"
              />
            </span>
            <span class="sancare-modal-title col">Nouveau groupe de recherche</span>
            <span
              class="sancare-modal-close col-auto"
              @click="closeModal"
            >
              <sancare-octicon
                name="x"
                :width="20"
                :height="20"
              />
            </span>
          </span>
        </div>
      </template>
      <div class="p-1">
        <div class="row p-2 align-items-center">
          <div class="col-2">
            Nom
          </div>
          <div class="col-4">
            <input
              v-model="name"
              type="text"
              class="form-control"
            >
          </div>
        </div>
        <hr>
        <div
          v-for="search in searches"
          :key="`search_${search.id}`"
        >
          <div class="row p-2 no-gutters align-items-center">
            <div class="col-3 p-2">
              <saved-search-thumbnail
                :search="search"
                :show-criteria-enabled="false"
                :select-enabled="false"
                :show-settings-enabled="false"
              />
            </div>
            <div class="col pl-2">
              <saved-search-details
                v-if="search.criteriaGroups"
                :saved-search="search"
                :can-remove-criteria="false"
              />
              <div v-else>Recherche Sancare</div>
            </div>
          </div>
          <hr>
        </div>
      </div>
      <template #footer>
        <div>
          <div class="p-1">
            <button
              class="btn btn-default"
              @click="closeModal()"
            >
              Annuler
            </button>
            <button
              class="btn btn-success"
              @click="saveGroup()"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </template>
    </sancare-modal></span>
</template>
