<script>
import asciiFoldingFilter from '@sancare/ui-frontend-commons/src/text-processing/asciiFoldingFilter'
import Fuse from 'fuse.js'
import _ from 'lodash'

export default {
  name: 'HomePageQuickFilter',
  props: {
    mode: { type: String, required: true },
    privateSearches: { type: Array, required: true },
    teamSearches: { type: Array, required: true },
    publicSearches: { type: Array, required: true },
    userSearchGroups: { type: Array, required: true },
    sancareCategories: { type: Array, required: true },
  },
  emits: ['quick-filter-disable', 'quick-filter-update'],
  data() {
    return {
      typedText: '',
    }
  },
  computed: {
    searchablePrivateSearches() {
      return new Fuse(
        _.map(this.privateSearches, (s) => ({ id: s.id, name: asciiFoldingFilter(s.name), reference: s.reference })),
        { keys: ['name', 'reference'], shouldSort: false, threshold: 0.3 },
      )
    },
    searchableTeamSearches() {
      const flatSearches = _.flatten(_.map(this.teamSearches, 'searches'))

      return new Fuse(
        _.map(flatSearches, (s) => ({ id: s.id, name: asciiFoldingFilter(s.name), reference: s.reference })),
        { keys: ['name', 'reference'], shouldSort: false, threshold: 0.3 },
      )
    },
    searchablePublicSearches() {
      const flatSearches = _.flatten(_.map(this.publicSearches, (c) => _.map(c.searches, (s) => ({
        categoryId: c.id,
        searchId: s.id,
        categoryName: asciiFoldingFilter(c.name),
        searchName: asciiFoldingFilter(s.name),
        reference: s.reference,
      }))))

      return new Fuse(
        flatSearches,
        { keys: ['categoryName', 'searchName', 'reference'], shouldSort: false, threshold: 0.3 },
      )
    },
    searchableUserSearchGroups() {
      return new Fuse(
        _.map(this.userSearchGroups, (s) => ({ id: s.id, name: asciiFoldingFilter(s.name) })),
        { keys: ['name'], shouldSort: false, threshold: 0.3 },
      )
    },
    searchableSancareSearches() {
      const flatSearches = _.flatten(_.map(this.sancareCategories, (c) => _.map(c.searches, (s) => ({
        categoryId: c.id,
        searchId: s.id,
        categoryName: asciiFoldingFilter(c.name),
        searchName: asciiFoldingFilter(s.name),
        reference: s.reference,
      }))))

      return new Fuse(
        flatSearches,
        { keys: ['categoryName', 'searchName', 'reference'], shouldSort: false, threshold: 0.3 },
      )
    },
    placeholder() {
      if (this.mode === 'optimizable') {
        return 'Nom de la recherche, de la catégorie...'
      }

      return 'Nom de la liste...'
    },
  },
  watch: {
    typedText: _.debounce(function(newTypedText) {
      const searchPattern = asciiFoldingFilter(newTypedText.trim())
      if (!searchPattern) {
        this.$emit('quick-filter-disable')

        return
      }

      this.$emit('quick-filter-update', {
        privateSearches: _.map(this.searchablePrivateSearches.search(searchPattern), 'id'),
        teamSearches: _.map(this.searchableTeamSearches.search(searchPattern), 'id'),
        publicSearches: _.mapValues(
          _.groupBy(this.searchablePublicSearches.search(searchPattern), 'categoryId'),
          (searches) => _.map(searches, (s) => s.searchId)
        ),
        userSearchGroups: _.map(this.searchableUserSearchGroups.search(searchPattern), 'id'),
        sancareCategories: _.mapValues(
          _.groupBy(this.searchableSancareSearches.search(searchPattern), 'categoryId'),
          (searches) => _.map(searches, (s) => s.searchId)
        ),  // We return an object like this: { catId1: [searchId1, searchId2, ...], ...}
      })
    }, 500, { trailing: true, maxWait: 2000 }),
  },
}
</script>

<template>
  <div class="home-list-part">
    <input
      v-model="typedText"
      :placeholder="placeholder"
      type="text"
      class="form-control mx-2"
    >
  </div>
</template>
