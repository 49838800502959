import { AutomationModeEnum, AutomationTypeEnum } from '@/store/modules/automation/types'
import { CountryEnum, ModeEnum, StayListColumns } from '@/store/modules/settings/types'

/**
 * Column names must be synced with backend/src/Resources/schemas/user_settings.json
 */
const AvailableColumns: StayListColumns = {
  notification: {
    icon: 'bell-fill',
    name: 'Notification',
    label: null,
    country: [CountryEnum.CH, CountryEnum.FR],
    order: 'notification',
    mode: [ModeEnum.AUTOMATION, AutomationModeEnum.AUTOMATABLE, AutomationModeEnum.WAITING, AutomationModeEnum.EXPIRED, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    isDefault: true,
    invertedOrder: false,
  },
  rssId: {
    isTranslated: true,
    label: 'stay.RSS',
    country: [CountryEnum.CH, CountryEnum.FR],
    order: 'rssId',
    mode: [ModeEnum.AUTOMATION, AutomationModeEnum.AUTOMATABLE, AutomationModeEnum.WAITING, AutomationModeEnum.EXPIRED, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    isDefault: false,
    invertedOrder: false,
  },
  administrativeLocalStayId: {
    label: 'N° administratif',
    country: [CountryEnum.CH, CountryEnum.FR],
    order: 'administrativeLocalStayId',
    mode: [ModeEnum.AUTOMATION, AutomationModeEnum.AUTOMATABLE, AutomationModeEnum.WAITING, AutomationModeEnum.EXPIRED, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    isDefault: true,
    invertedOrder: false,
  },
  patientName: {
    name: 'Patient',
    label: 'Nom - Prénom',
    country: [CountryEnum.FR],
    order: 'patientName',
    mode: [ModeEnum.AUTOMATION, AutomationModeEnum.AUTOMATABLE, AutomationModeEnum.WAITING, AutomationModeEnum.EXPIRED, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    isDefault: true,
    invertedOrder: false,
  },
  firstGhm: {
    label: 'GHM pré-revalo.',
    country: [CountryEnum.FR],
    order: 'startingGhm',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [],
    isDefault: false,
    invertedOrder: false,
  },
  currentGhm: {
    label: 'GHM',
    country: [CountryEnum.FR],
    order: 'currentGhm',
    mode: [ModeEnum.AUTOMATION, AutomationModeEnum.AUTOMATABLE, AutomationModeEnum.WAITING, AutomationModeEnum.EXPIRED, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    isDefault: true,
    invertedOrder: false,
  },
  firstDrg: {
    label: 'DRG pré-revalo.',
    country: [CountryEnum.CH],
    order: 'firstDrg',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [],
    isDefault: true,
    invertedOrder: false,
  },
  currentDrg: {
    label: 'DRG',
    country: [CountryEnum.CH],
    order: 'currentDrg',
    mode: [ModeEnum.AUTOMATION, AutomationModeEnum.AUTOMATABLE, AutomationModeEnum.WAITING, AutomationModeEnum.EXPIRED, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    isDefault: true,
    invertedOrder: false,
  },
  firstGhmLevel: {
    label: 'Niv. GHM pré-revalo.',
    country: [CountryEnum.FR],
    order: 'startingGhmLevel',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [],
    isDefault: false,
    invertedOrder: false,
  },
  currentGhmLevel: {
    label: 'Niv. GHM',
    country: [CountryEnum.FR],
    order: 'currentGhmLevel',
    mode: [ModeEnum.AUTOMATION, AutomationModeEnum.AUTOMATABLE, AutomationModeEnum.WAITING, AutomationModeEnum.EXPIRED, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    isDefault: false,
    invertedOrder: true,
  },
  firstValue: {
    isTranslated: true,
    label: 'stayList.labels.firstValue',
    country: [CountryEnum.CH, CountryEnum.FR],
    order: 'startingValue',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [],
    isDefault: false,
    invertedOrder: true,
  },
  currentValue: {
    isTranslated: true,
    label: 'stayList.labels.currentValue',
    country: [CountryEnum.CH, CountryEnum.FR],
    order: 'currentValue',
    mode: [ModeEnum.AUTOMATION, AutomationModeEnum.AUTOMATABLE, AutomationModeEnum.WAITING, AutomationModeEnum.EXPIRED, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    isDefault: false,
    invertedOrder: true,
  },
  moneyGap: {
    label: 'Montant revalorisé',
    country: [CountryEnum.FR],
    order: 'moneyGap',
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [],
    isDefault: false,
    invertedOrder: true,
  },
  rumCount: {
    label: 'Nb de RUM',
    country: [CountryEnum.FR],
    order: 'rumCount',
    mode: [ModeEnum.AUTOMATION, AutomationModeEnum.AUTOMATABLE, AutomationModeEnum.WAITING, AutomationModeEnum.EXPIRED, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    isDefault: true,
    invertedOrder: true,
  },
  stayStart: {
    label: 'Date d\'entrée',
    country: [CountryEnum.CH, CountryEnum.FR],
    order: 'stayStart',
    mode: [ModeEnum.AUTOMATION, AutomationModeEnum.AUTOMATABLE, AutomationModeEnum.WAITING, AutomationModeEnum.EXPIRED, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    isDefault: false,
    invertedOrder: false,
  },
  stayEnd: {
    label: 'Date de sortie',
    country: [CountryEnum.CH, CountryEnum.FR],
    order: 'stayEnd',
    mode: [ModeEnum.AUTOMATION, AutomationModeEnum.AUTOMATABLE, AutomationModeEnum.WAITING, AutomationModeEnum.EXPIRED, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    isDefault: false,
    invertedOrder: false,
  },
  validationDate: {
    label: 'Date de validation',
    country: [CountryEnum.CH, CountryEnum.FR],
    order: 'validationDate',
    mode: [ModeEnum.AUTOMATION, AutomationModeEnum.AUTOMATABLE, AutomationModeEnum.WAITING, AutomationModeEnum.EXPIRED, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    isDefault: false,
    invertedOrder: false,
  },
  stayDuration: {
    label: 'Durée',
    country: [CountryEnum.CH, CountryEnum.FR],
    order: 'stayDuration',
    mode: [ModeEnum.AUTOMATION, AutomationModeEnum.AUTOMATABLE, AutomationModeEnum.WAITING, AutomationModeEnum.EXPIRED, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    isDefault: true,
    invertedOrder: true,
  },
  confidence: {
    label: 'Confiance',
    country: [CountryEnum.FR],
    order: 'confidence',
    mode: [ModeEnum.AUTOMATION, AutomationModeEnum.AUTOMATABLE, AutomationModeEnum.WAITING, AutomationModeEnum.EXPIRED],
    automationType: [AutomationTypeEnum.AI],
    isDefault: false,
    invertedOrder: true,
  },
  dpConfidence: {
    label: 'Confiance DP',
    country: [CountryEnum.FR],
    order: 'dpConfidence',
    mode: [ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE],
    automationType: [],
    isDefault: false,
    invertedOrder: true,
  },
  countSavedSearches: {
    label: 'Nb de recherches',
    country: [CountryEnum.CH, CountryEnum.FR],
    order: null,
    mode: [ModeEnum.OPTIMIZABLE],
    automationType: [],
    isDefault: false,
    invertedOrder: true,
  },
  savedSearches: {
    label: 'Recherche(s)',
    country: [CountryEnum.CH, CountryEnum.FR],
    order: null,
    mode: [ModeEnum.OPTIMIZABLE],
    automationType: [],
    isDefault: false,
    invertedOrder: false,
  },
  status: {
    label: 'Statut',
    country: [CountryEnum.CH, CountryEnum.FR],
    order: 'status',
    mode: [ModeEnum.AUTOMATION, AutomationModeEnum.AUTOMATABLE, AutomationModeEnum.WAITING, AutomationModeEnum.EXPIRED, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    isDefault: true,
    invertedOrder: false,
  },
  seen: {
    label: 'Vu',
    country: [CountryEnum.CH, CountryEnum.FR],
    order: 'seen',
    mode: [ModeEnum.AUTOMATION, AutomationModeEnum.AUTOMATABLE, AutomationModeEnum.WAITING, AutomationModeEnum.EXPIRED, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    isDefault: true,
    invertedOrder: false,
  },
  externalPmsiStatus: {
    label: 'Statut externe',
    country: [CountryEnum.CH, CountryEnum.FR],
    order: 'externalPmsiStatus',
    mode: [ModeEnum.AUTOMATION, AutomationModeEnum.AUTOMATABLE, AutomationModeEnum.WAITING, AutomationModeEnum.EXPIRED, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    isDefault: false,
    invertedOrder: false,
  },
  supervisingMajorDoctor: {
    label: 'Médecin responsable',
    country: [CountryEnum.FR],
    order: 'supervisingMajorDoctor',
    mode: [ModeEnum.AUTOMATION, AutomationModeEnum.AUTOMATABLE, AutomationModeEnum.WAITING, AutomationModeEnum.EXPIRED, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    isDefault: false,
    invertedOrder: false,
  },
  practitionerName: {
    label: 'Médecin opérateur',
    country: [CountryEnum.FR],
    order: 'practitionerName',
    mode: [ModeEnum.AUTOMATION, AutomationModeEnum.AUTOMATABLE, AutomationModeEnum.WAITING, AutomationModeEnum.EXPIRED, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    isDefault: false,
    invertedOrder: false,
  },
  comment: {
    label: 'Commentaire',
    country: [CountryEnum.CH, CountryEnum.FR],
    order: 'comment',
    mode: [ModeEnum.AUTOMATION, AutomationModeEnum.AUTOMATABLE, AutomationModeEnum.WAITING, AutomationModeEnum.EXPIRED, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationType: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    isDefault: false,
    invertedOrder: false,
  }
}

export default AvailableColumns