<script>
import { SancareCheckbox, SancareOcticon } from '@sancare/ui-frontend-commons'
import dayjs from 'dayjs'
import numeral from 'numeral'
import { mapGetters, mapState } from 'vuex'

import { hasPermission, RoleEnum, savedSearchPermissions } from '@/misc/permissions/permissionsHelper'

import { ModeEnum, PrivacyEnum } from '../store/modules/settings/types'
import SavedSearchDetails from './SavedSearchDetails.vue'

const SAVED_SEARCH_THUMBNAIL_MAX_LABEL_LENGTH = 64
const SAVED_SEARCH_THUMBNAIL_MAX_COLOR = 9

export default {
  name: 'SavedSearchThumbnail',
  components: {
    'sancare-checkbox': SancareCheckbox,
    'saved-search-details': SavedSearchDetails,
    'sancare-octicon': SancareOcticon,
  },
  props: {
    mode: { type: String, required: false, default: '' },
    search: { type: Object, required: true },
    selected: { type: Boolean, default: false },
    copyEnabled: { type: Boolean, default: true },
    selectEnabled: { type: Boolean, default: true },
    showCriteriaEnabled: { type: Boolean, default: true },
    showSettingsEnabled: { type: Boolean, default: true },
    stayListQuery: { type: Object, default: null },
  },
  emits: ['refresh-stats', 'select-search'],
  data() {
    return {
      showSearchSettings: false,
      showRemoveConfirm: false,
      showConfirmToBeRemoved: false,
      searchPrivacyIcon: {
        privateSearch: 'lock',
        teamSearch: 'people',
        publicSearch: 'globe',
      },
      searchPrivacyLabel: {
        privateSearch: 'Confidentialité: Privée',
        teamSearch: 'Confidentialité: Equipe',
        publicSearch: 'Confidentialité: Publique',
      },
      criteriaLoadedOnce: false,
      ModeEnum,
      savedSearchPermissions
    }
  },
  computed: {
    displayedColor() {
      if (this.search.disabled) {
        return 'disabled'
      }
      return this.search.savedSearchCategory.id % SAVED_SEARCH_THUMBNAIL_MAX_COLOR
    },
    displayedCount() {
      return numeral(this.search.countTodoStay).format(this.search.countTodoStay >= 1000 ? '0.0a' : '0a').toUpperCase()
    },
    displayedLabel() {
      if (this.search.name.length > SAVED_SEARCH_THUMBNAIL_MAX_LABEL_LENGTH) {
        return this.search.name.substr(0, SAVED_SEARCH_THUMBNAIL_MAX_LABEL_LENGTH)
      } else {
        return this.search.name
      }
    },
    ...mapGetters(['flatSancareSettings']),
    ...mapState({
      currentUser: (state) => state.settings.currentUser,
      isOwner(state) {
        return state.login.username === this.search.user.username
      },
      isFacilityManager: (state) => hasPermission(RoleEnum.ROLE_FACILITY_SEARCH_MANAGER, state.login.userRole),
      isSancareUser() {
        return this.$store.state.settings.currentUser.sancareUser
      },
      isTeamMember(state) {
        if (this.search.user.team && state.settings.currentUser.team) {
          return this.search.user.team.id === state.settings.currentUser.team.id
        } else {
          return false
        }
      },
      removeSavedSearchRequest: (state) => state.savedSearches.removeSavedSearchRequest,
      showDetailsPermission() {
        return savedSearchPermissions.canShowDetails(this.search, this.currentUser, this.flatSancareSettings['hide_sancare_searches_details'])
      },
      canShowDetails() {
        return this.showCriteriaEnabled && this.showDetailsPermission
      },
      canCopy() {
        return this.copyEnabled && this.showDetailsPermission
      },
      canEdit() {
        return savedSearchPermissions.canEdit(this.search, this.currentUser)
      },
      canDisable() {
        return savedSearchPermissions.canDisable(this.search, this.currentUser)
      },
      canRemove() {
        return (this.isOwner && this.search.privacy !== PrivacyEnum.SANCARE_SEARCH && this.search.privacy !== PrivacyEnum.TEAM_SEARCH) || (this.isFacilityManager && this.search.privacy === PrivacyEnum.PUBLIC_SEARCH)
      },
      showSettings() {
        return this.showSettingsEnabled && (this.canCopy || this.canEdit || this.canDisable || this.canRemove)
      }
    })
  },
  methods: {
    selectSavedSearch() {
      if (this.selectEnabled && !this.search.disabled) {
        this.$emit('select-search')
      }
    },
    disableSavedSearch() {
      this.$store.dispatch('disableSavedSearch', this.search.id)
      if (this.selected) {
        // trigger event to unselect it on disable if is in selection
        this.$emit('select-search')
      }
      this.$emit('refresh-stats', [this.search.id])
    },
    enableSavedSearch() {
      this.$store.dispatch('enableSavedSearch', this.search.id)
      this.$emit('refresh-stats', [this.search.id])
    },
    removeSavedSearch() {
      this.$store.dispatch('removeSavedSearch', this.search.id)
      this.$emit('refresh-stats', [this.search.id])
    },
    validateTeamSearch() {
      this.$store.dispatch('validateTeamSearch', { searchId: this.search.id })
    },
    toBeRemovedTeamSearch(toBeRemoved) {
      this.$store.dispatch('toBeRemovedTeamSearch', { searchId: this.search.id, toBeRemoved: toBeRemoved })
      this.showSearchSettings = false
    },
    getSearchLastUpdate(search) {
      return dayjs(search.updatedAt).format('DD/MM/YYYY')
    },
    redirectToEditionPage() {
      if (this.stayListQuery) {
        this.$store.commit('setStoredCriteria', {
          isAdvancedSearch: this.search.isAdvancedSearch,
          searchOperators: this.search.searchOperators,
          globalCriteriaGroup: this.search.globalCriteriaGroup,
          criteriaGroups: this.search.criteriaGroups,
          name: this.search.name,
          savedSearchCategory: this.search.savedSearchCategory,
        })

        const stayListQuery = { ...this.stayListQuery, edit: this.stayListQuery.searchId, searchId: undefined }
        const newRoute = {
          name: 'staylist',
          query: stayListQuery,
          params: { pmsiType: this.search.pmsiType },
        }

        this.$router.push(newRoute)
      }
    },
    redirectToCopyPage() {
      if (this.stayListQuery) {
        this.$store.commit('setStoredCriteria', {
          isAdvancedSearch: this.search.isAdvancedSearch,
          searchOperators: this.search.searchOperators,
          globalCriteriaGroup: this.search.globalCriteriaGroup,
          criteriaGroups: this.search.criteriaGroups,
          name: this.search.name,
          savedSearchCategory: this.search.savedSearchCategory,
        })

        const stayListQuery = { ...this.stayListQuery, sc: 1, copy: this.search.id, searchId: undefined }
        const newRoute = {
          name: 'staylist',
          query: stayListQuery,
          params: { pmsiType: this.search.pmsiType },
        }

        this.$router.push(newRoute)
      }
    },
    consultStayList() {
      this.$store.commit('resetStoredCriteria')

      if (this.stayListQuery) {
        const newRoute = {
          name: 'staylist',
          query: this.stayListQuery,
          params: { pmsiType: this.search.pmsiType },
        }

        this.$router.push(newRoute)
      }
    },
    showSearchCriteria() {
      if (!this.criteriaLoadedOnce) {
        this.criteriaLoadedOnce = true
        this.$store.dispatch('fetchSavedSearch', this.search.id)
      }
    }
  }
}
</script>

<template>
  <div :class="`saved-search-thumbnail saved-search-thumbnail-color-${displayedColor}`">
    <div
      class="row no-gutters align-items-center saved-search-thumbnail-header"
      @click="consultStayList()"
    >
      <div
        class="col-auto px-2 text-center saved-search-thumbnail-count"
      >
        {{ displayedCount }}
      </div>
      <div
        :title="search.name"
        class="col px-2 text-right saved-search-thumbnail-name"
      >
        {{ displayedLabel }}
      </div>
      <div class="col-auto mx-1">
        <v-dropdown
          v-if="savedSearchPermissions.canShowDetails(search, currentUser, flatSancareSettings['hide_sancare_searches_details'])"
          :triggers="['hover']"
          placement="bottom"
          class="popover-container"
          popover-class="saved-search-thumbnail-criteria-popover"
          @show="showSearchCriteria"
        >
          <span class="saved-search-thumbnail-criteria-icon">
            <sancare-octicon
              name="info"
              :width="18"
              :height="18"
            />
          </span>
          <template #popper>
            <div
              class="saved-search-thumbnail-criteria-list"
            >
              <saved-search-details
                v-if="search.criteriaGroups"
                :saved-search="search"
                :can-remove-criteria="false"
                :max-criteria="5"
              />
              <div
                v-else
                class="loader loader-center"
              />
            </div>
          </template>
        </v-dropdown>
      </div>
    </div>
    <div class="row no-gutters px-1 align-items-center saved-search-thumbnail-footer">
      <div class="col-2 mt-2">
        <sancare-checkbox
          v-if="selectEnabled"
          :checked="selected"
          @change="selectSavedSearch()"
        />
      </div>
      <div class="col" />
      <div class="small">
        <div v-if="isSancareUser">
          Ref : {{ search.reference }}
        </div>
        <div>Modifiée le {{ getSearchLastUpdate(search) }} </div>
      </div>
      <div class="col" />
      <div
        v-if="search.privacy === 'teamSearch'
          && isTeamMember
          && search.disabled === false
          && mode !== ModeEnum.QUALITY_STAYS"
        class="col-auto btn"
      >
        <v-dropdown
          :shown="showSearchSettings"
          :hide-triggers="[]"
          placement="right"
          class="popover-container"
          popover-class="saved-search-thumbnail-settings-popover"
          @show="showSearchSettings = true"
          @hide="showRemoveConfirm = false; showConfirmToBeRemoved = false"
          @auto-hide="showSearchSettings = false"
        >
          <div>
            <span
              v-tooltip="'Gestion de la recherche Sancare'"
              :class="{'icon-danger': search.toBeRemoved}"
            >
              <sancare-octicon
                name="rocket"
                :width="18"
                :height="18"
              />
            </span>
          </div>
          <template #popper>
            <div
              v-if="!search.validated && !search.toBeRemoved"
              class="row"
            >
              <button
                class="btn btn-link"
                @click="validateTeamSearch()"
              >
                Valider
              </button>
            </div>
            <div class="row">
              <button
                v-if="!search.toBeRemoved && !showConfirmToBeRemoved"
                class="btn btn-link text-danger"
                @click="showConfirmToBeRemoved = true"
              >
                À supprimer sur tous les hôpitaux
              </button>
              <button
                v-if="!search.toBeRemoved && showConfirmToBeRemoved"
                class="btn btn-link text-danger"
                @click="toBeRemovedTeamSearch(true)"
              >
                Confirmer
              </button>
              <button
                v-if="search.toBeRemoved"
                class="btn btn-link"
                @click="toBeRemovedTeamSearch(false)"
              >
                Annuler la suppression
              </button>
            </div>
          </template>
        </v-dropdown>
      </div>
      <div class="col-auto px-1">
        <span
          v-if="search.privacy !== 'sancareSearch'"
          v-tooltip="searchPrivacyLabel[search.privacy]"
        >
          <sancare-octicon
            :name="searchPrivacyIcon[search.privacy]"
            :width="18"
            :height="18"
          />
        </span>
      </div>
      <div
        v-if="showSettings"
        class="col-auto px-1"
      >
        <v-dropdown
          placement="right"
          class="popover-container"
          popover-class="saved-search-thumbnail-settings-popover"
          @hide="showRemoveConfirm = false"
        >
          <div class="saved-search-thumbnail-settings-icon">
            <span>
              <sancare-octicon
                name="kebab-horizontal"
                :width="18"
                :height="18"
              />
            </span>
          </div>
          <template #popper>
            <div
              v-if="canCopy"
              class="btn btn-link"
              @click="redirectToCopyPage()"
            >
              Copier
            </div>
            <div
              v-if="canEdit"
            >
              <div
                class="btn btn-link"
                @click="redirectToEditionPage()"
              >
                Modifier
              </div>
            </div>
            <div
              v-if="canDisable"
            >
              <button
                class="btn btn-link"
                @click="search.disabled ? enableSavedSearch() : disableSavedSearch()"
              >
                {{ search.disabled ? 'Activer' : 'Désactiver' }}
              </button>
            </div>
            <div
              v-if="canRemove"
            >
              <div v-if="!showRemoveConfirm">
                <button
                  class="btn btn-link text-danger"
                  @click="showRemoveConfirm = true"
                >
                  Supprimer
                </button>
              </div>
              <div v-else-if="removeSavedSearchRequest && !removeSavedSearchRequest.fetching">
                <button
                  class="btn btn-link text-danger"
                  @click="removeSavedSearch()"
                >
                  Confirmer
                </button>
              </div>
              <div v-if="removeSavedSearchRequest && removeSavedSearchRequest.fetching">
                <div class="loader loader-sm ml-4" />
              </div>
            </div>
          </template>
        </v-dropdown>
      </div>
    </div>
  </div>
</template>
